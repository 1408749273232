<template>
  <div class="choose-password">
    <base-input
      :modelValue="modelValue"
      type="password"
      :icon="icon"
      :placeholder="placeholder"
      :error="error"
      :rounded="rounded"
      :size="size"
      required
      @focus="$emit('focus')"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
    <template v-if="show">
      <div class="passwordDetail">
        <rule-validation
          :ruleIsMandatory="passwordLengthRequired"
          :ruleName="minPasswordLengthRuleName"
          :ruleValue="passwordLength"
          :ruleRegex="minPasswordLengthRegex"
          :modelValue="modelValue"
          @rule-valid="hasMinPasswordLength"
        />
        <rule-validation
          :ruleIsMandatory="lowerCaseRequired"
          :ruleName="minLowerCaseRuleName"
          :ruleValue="minLowerCase"
          :ruleRegex="minLowerCaseRegex"
          :modelValue="modelValue"
          @rule-valid="hasMinLowerCase"
        />
        <rule-validation
          :ruleIsMandatory="upperCaseRequired"
          :ruleName="minUpperCaseRuleName"
          :ruleValue="minUpperCase"
          :ruleRegex="minUpperCaseRegex"
          :modelValue="modelValue"
          @rule-valid="hasMinUpperCase"
        />
        <rule-validation
          :ruleIsMandatory="numbersRequired"
          :ruleName="minNumbersRuleName"
          :ruleValue="minNumbers"
          :ruleRegex="minNumbersRegex"
          :modelValue="modelValue"
          @rule-valid="hasMinNumbers"
        />
        <rule-validation
          :ruleIsMandatory="specialCharactersRequired"
          :ruleName="minSpecialCharsRuleName"
          :ruleValue="specialCharacters"
          :ruleText="specialCharacterSet"
          :ruleRegex="minSpecialCharsRegex"
          :modelValue="modelValue"
          @rule-valid="hasMinSpecialCharacters"
        />
      </div>
    </template>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import { mapGetters } from 'vuex';
import notify from '@/utils/notify';
import RuleValidation from './RuleValidation';

export default {
  name: 'ChoosePassword',
  components: { RuleValidation },
  props: {
    placeholder: {
      type: String,
      default: 'enter_password',
    },
    error: {
      type: [Boolean, String],
    },
    modelValue: {
      type: String,
    },
    rounded: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    disallowedStrings: {
      type: Array,
      default: () => [],
    },
    isReset: {
      type: Boolean,
    },
    isTrial: {
      type: Boolean,
    },
    isEnterprise: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "fa fa-lock"
    }
  },
  emits: ['update:modelValue', 'focus', 'valid-new-password'],
  data() {
    return {
      specialCharacterSet: '!@#$%^&*()_+|-=\\`{}[]:";\'<>?,./',
      minPasswordLengthRuleName: 'min_password_length',
      minLowerCaseRuleName: 'min_lowercase_letters',
      minUpperCaseRuleName: 'min_uppercase_letters',
      minNumbersRuleName: 'min_numbers',
      minSpecialCharsRuleName: 'min_special_characters',
      minPasswordLengthRegex: /./g,
      minLowerCaseRegex: /[a-z]/g,
      minUpperCaseRegex: /[A-Z]/g,
      minNumbersRegex: /[0-9]/g,
      // Disabled since regex pattern is invalid due to escape character
      // eslint-disable-next-line
      minSpecialCharsRegex: /[!@#$%^&*()_+|\-=\\`{}\[\]:";\'<>?,.\/]/g,
      minPasswordLength: 0,
      minLowerCaseCharacters: 0,
      minUpperCaseCharacters: 0,
      minimumNumbers: 0,
      minSpecialCharacters: 0,
      minPasswordLengthValid: false,
      minLowerCaseCharactersValid: false,
      minUpperCaseCharactersValid: false,
      minNumbersValid: false,
      minSpecialCharactersValid: false,
      constraints: {},
    };
  },
  computed: {
    ...mapGetters([
      'myOrganization',
    ]),
    show() {
      return (this.modelValue);
    },
    passwordLengthRequired() {
      return this.constraints.minPasswordLength.isMandatory;
    },
    lowerCaseRequired() {
      return this.constraints.minLowerCase.isMandatory;
    },
    upperCaseRequired() {
      return this.constraints.minUpperCase.isMandatory;
    },
    numbersRequired() {
      return this.constraints.minNumbers.isMandatory;
    },
    specialCharactersRequired() {
      return this.constraints.specialChars.isMandatory;
    },
    passwordLength() {
      return this.constraints.minPasswordLength.value;
    },
    minLowerCase() {
      return this.constraints.minLowerCase.value;
    },
    minUpperCase() {
      return this.constraints.minUpperCase.value;
    },
    minNumbers() {
      return this.constraints.minNumbers.value;
    },
    specialCharacters() {
      return this.constraints.specialChars.value;
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      await this.fetchConstraints();
    },
    hasMinPasswordLength(value) {
      this.minPasswordLengthValid = value;
      this.validatePassword();
    },
    hasMinLowerCase(value) {
      this.minLowerCaseCharactersValid = value;
      this.validatePassword();
    },
    hasMinUpperCase(value) {
      this.minUpperCaseCharactersValid = value;
      this.validatePassword();
    },
    hasMinNumbers(value) {
      this.minNumbersValid = value;
      this.validatePassword();
    },
    hasMinSpecialCharacters(value) {
      this.minSpecialCharactersValid = value;
      this.validatePassword();
    },
    async fetchConstraints() {
      let res = null;
      if (this.isTrial) {
        res = await apis.passwordPolicies.list();
      } else if (this.isReset) {
        res = await apis.users.getPasswordPolicy(this.$route.query.resetToken);
      } else if (this.isEnterprise) {
        res = await apis.enterpriseRegistrations.getPasswordPolicy(this.$route.query.registrationToken);
      } else {
        res = await apis.organizations.getPasswordPolicy(this.myOrganization.id);
      }
      if (!res.ok) {
        notify.error(this.$t('password_policy_error'));
        return;
      }
      this.constraints = { ...res.data };
      const passwordLength = Object.values(this.constraints).filter(
        constraint => constraint.name === this.minPasswordLengthRuleName,
      );
      const lowerCasePolicy = Object.values(this.constraints).filter(
        constraint => constraint.name === this.minLowerCaseRuleName,
      );
      const upperCasePolicy = Object.values(this.constraints).filter(
        constraint => constraint.name === this.minUpperCaseRuleName,
      );
      const numbersPolicy = Object.values(this.constraints).filter(
        constraint => constraint.name === this.minNumbersRuleName,
      );
      const specialCharsPolicy = Object.values(this.constraints).filter(
        constraint => constraint.name === this.minSpecialCharsRuleName,
      );
      this.constraints = {
        minPasswordLength: { ...passwordLength[0] },
        minLowerCase: { ...lowerCasePolicy[0] },
        minUpperCase: { ...upperCasePolicy[0] },
        minNumbers: { ...numbersPolicy[0] },
        specialChars: { ...specialCharsPolicy[0] },
      };
    },
    validatePassword() {
      let allValid = true;
      if (this.passwordLengthRequired && !this.minPasswordLengthValid) {
        allValid = false;
      }
      if (this.lowerCaseRequired && !this.minLowerCaseCharactersValid) {
        allValid = false;
      }
      if (this.upperCaseRequired && !this.minUpperCaseCharactersValid) {
        allValid = false;
      }
      if (this.numbersRequired && !this.minNumbersValid) {
        allValid = false;
      }
      if (this.specialCharactersRequired && !this.minSpecialCharactersValid) {
        allValid = false;
      }
      this.$emit('valid-new-password', allValid);
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

  .passwordDetail {
    width: 100%;
    font-size: 90%;
    padding-left: 20px;
    padding-top: 10px;
  }
</style>
