<template>
  <cmc-block
    :id="id"
    padding="s"
  >
    <cmc-stack take-full-height>
      <cmc-align at-horizontal-center>
        <cmc-icon
          :icon="icon"
          svg
          :custom-size="5"
        >
        </cmc-icon>
        <cmc-text
          v-if="plainMessage"
          :text="plainMessage"
          :with-i18n="withPlainMessageI18n"
          :align="textAlignCenter"
        ></cmc-text>
        <cmc-app-link
          v-if="clickableMessage"
          class="link-on-primary"
          :to="toNavigateOnClick || ''"
        >
          <cmc-text
            class="cmc-message-display-clickable-text"
            :text="clickableMessage || ''"
            :with-i18n="withClickableMessageI18n"
          >
          </cmc-text>
        </cmc-app-link>
       
        
        <slot name="custom-clickable-message"></slot>
      </cmc-align>
    </cmc-stack>
  </cmc-block>
</template>
<script setup lang="ts">

import { ref, defineComponent, defineProps, withDefaults } from 'vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcAppLink from '@/components/nextgen/navigation/CmcAppLink.vue';
import type { Alignment } from "@/components/nextgen/typography/types";
import { RouteLocationRaw } from 'vue-router';

 

defineComponent({
    CmcGrid,
    CmcGridCol,
    CmcAlign,
    CmcIcon,
    CmcText,
    CmcAppLink
});

interface Props {

    /**
     * Id of the component
     */
    id?: string
    /**
     * The icon to display
     */
    icon?: string

    /**
     * Should be set if a plain message is to be displayed under the icon
     */
    plainMessage?: string

    /**
     * If the plain message is an i18n label
     */
    withPlainMessageI18n?: boolean

    /**
     * Should be set if the entire message is clickable
     */
    clickableMessage?: string

    /**
     * If the clickable message is an i18n label
     */
     withClickableMessageI18n?: boolean

    /**
     * Adds path to navigate to, for the clickable message
     */
    toNavigateOnClick? : RouteLocationRaw
}

withDefaults(defineProps<Props>(), {
    svg: true,
    icon: 'warning',
    withClickableMessageI18n: false
});

const textAlignCenter = ref<Alignment>("center");


</script>
<style lang="css" scoped>
.cmc-message-display-clickable-text {
  color: var(--ng-text-link);
}
</style>
