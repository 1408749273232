<template>
  <div
    id="base-multi-select"
    ref="base-multi-select"
    class="container"
    tabindex="0"
    :aria-label="$t('select_values')"
    @focusout="handleFocusOut"
    @keyup.enter="onEnter"
  >
    <div
      v-show="!opened || !shouldDisplaySearchBar"
      class="input-container"
      @click="onClick"
    >
      <div class="input">
        <span
          v-if="selection.size == 0"
          :class="'placeholder'"
        >{{ $t('select') }}</span>
        <span
          v-else
          :class="disabled ? 'disabled' : ''"
        >{{ getDisplaySelection() }}</span>
        <span
          v-if="remaining > 0"
          class="more-tags"
        >+{{ remaining }}</span>
      </div>
      <div v-if="filter.clearable">
        <div
          v-if="selection.size > 0 && !disabled"
          tabindex="0"
          :aria-label="$t('clear_values')"
          class="icon clear-all"
          @click="onClearAll"
          @keyup.enter="onClearAll"
        >
          <base-icon icon="fa fa-times" />
        </div>
      </div>
      <div v-else>
        <div
          v-if="!opened"
          :class="disabled ? 'disabled icon' : 'icon'"
        >
          <base-icon icon="fa fa-caret-down" />
        </div>
        <div
          v-if="opened"
          :class="disabled ? 'disabled icon' : 'icon'"
        >
          <base-icon icon="fa fa-caret-up" />
        </div>
      </div>
    </div>
    <div
      v-if="opened"
      class="dropdown-container"
    >
      <checkbox-dropdown
        :items="items"
        :opened="opened"
        :withKeyboard="withKeyboard"
        :selection="selection"
        :singleOption="filter.singleOption"
        :shouldDisplaySearchBar="shouldDisplaySearchBar"
        :selectedPlaceholder="selectedPlaceholder"
        :maxCharacters="maxCharactersOnCheckbox"
        @apply="onApply($event)"
        @clear="onClear($event)"
        @update:modelValue="onUpdate"
      >
      </checkbox-dropdown>
    </div>
  </div>
</template>
<script>

const MAX_OPTIONS = 5;
const MAX_NUMBER_CHARACTERS = 16;

export default {
    name: 'BaseMultiSelect',
    props: {
        items: {
            type: Array,
            required: true,
        },
        filter: {
          type: Object,
          required: true,
        },
        placeholder: {
            type: String,
            default: 'select',
        },
        selectedPlaceholder: {
            type: String,
            default: 'selected_tags'
        },
        disabled: {
          type: Boolean,
          default: false,
        },
        maxCharactersOnPreview: {
          type: Number,
          default: MAX_NUMBER_CHARACTERS
        },
        maxCharactersOnCheckbox: {
          type: Number
        }
    },
    emits: ['update:modelValue', 'clear', 'apply'],
    data() {
        return {
            MAX_NUMBER_CHARACTERS,
            MIN_NUMBER_CHARACTERS: this.maxCharactersOnPreview - 3, // To account for potential Ellipsis
            MAX_OPTIONS,
            selection: new Set(),
            withKeyboard: false,
            opened: false,
            remaining: 0,
            shouldDisplaySearchBar: this.filter?.type !== 'date_preset' && this.items.length >= MAX_OPTIONS
        };
    },
    watch: {
      filter(newFilterValue) {
        if (newFilterValue.hasSelectedValue) {
          this.selection = new Set([...newFilterValue.values]);
        }
      }
    },
    created() {
      if (this.filter.hasSelectedValue) {
        this.selection = new Set([...this.filter.values])
      } else if (this.filter.loadDefaultValue && this.items.length > 0) {
        // The first item should always be selected by default.
        this.selection = new Set([this.items[0]]);
      }
    },
    methods: {
        /**
         * This method is triggered whenever the input is clicked
         */
        onClick() {
          if (this.disabled) {
            return;
          }
          this.opened = !this.opened;
        },
        /**
         * Detect keyboard events and handle
         * @param {event} $event The event
         */
        onEnter($event) {
          // On enter, open/close the dropdown
          if ($event.target.id === 'base-multi-select') {
              if (!this.opened) {
                window.addEventListener("keydown", this.disableScrollbar);
              } else {
                window.removeEventListener("keydown", this.disableScrollbar);
                this.focusMultiSelect();
              }
              this.opened = !this.opened;
              this.withKeyboard = this.opened;
          }
        },
        /**
         * This method ensures that this component respects the best practices of accessibility
         */
        handleFocusOut($event) {
          const innerTargetIds = ['search-bar', 'apply-button', 'clear-button', 'options-container', 'selected-container'];
          if (innerTargetIds.includes($event.relatedTarget?.id) || innerTargetIds.includes($event.target?.id)) {
            return;
          }

          // If you've focus out to a checkbox, keep the panel opened.
          if ($event.target?.className === 'clickable' || $event.relatedTarget?.className === 'clickable') {
            return;
          }

          // If there isn't any related target, it means you've clicked outside of the panel.
          if ($event.relatedTarget === null || $event.relatedTarget === undefined) {
              // In the case the user selected something, send a pulse to update the connecting words 
              this.$emit('update:modelValue', this.selection);
              this.opened = false;
          }

          const targetIds = ['apply-button', 'base-multi-select'];
          if (targetIds.includes($event.target.id)) {
            this.opened = false;
          }

          const relatedTargetIds = ['add-filter-button', 'remove-filter-button', 'base-multi-select'];
          if (relatedTargetIds.includes($event.relatedTarget?.id)) {
              this.opened = false;
          }
        },
        /**
         * Get the display value of the selection in a comma-separated format.
         */
        getDisplaySelection() {
          let display = '';
          let count = 0;
          for (const entry of Array.from(this.selection)) {
            const label = entry.label? this.$t(entry.label) : (entry.display || entry.value || entry);
            if (display.length + label.length < this.maxCharactersOnPreview) {
              display += label + ", ";
              count++;
            } else {
              break;
            }
          }

          // If there is none remaining, remove the last comma
          if (this.selection.size - count == 0) {
            display = display.substring(0, display.length - 2) + " ";
          }

          // If the display is empty, but there is a selection!
          if (display === '' && this.selection.size > 0) {
            // It means that the value itself is higher than the maximum number of characters, cut it.
            const l = [...this.selection][0].label? [...this.selection][0].label : ([...this.selection][0].display || [...this.selection][0].value);
            display = l.substring(0, this.MIN_NUMBER_CHARACTERS) + "...";

            // Reduce the number of remaining of tag.
            count--;
          }

          this.remaining = this.selection.size - Math.abs(count);

          return display;
        },
        onUpdate($event) {
            this.$emit('update:modelValue', $event);
        },
        onApply($event) {
            this.selection = $event;
            this.opened = false;
            this.$emit('apply', this.selection);
            this.focusMultiSelect();
        },
        onClear($event) {
          this.onUpdate($event);
        },
        /**
         * Event triggered whenever the user clicks on the "X" beside the select bar.
         */
        onClearAll($event) {
          $event.stopPropagation()

          this.selection = new Set();
          this.remaining = 0;
          this.opened = false;
          this.$emit('apply', this.selection);
          this.focusMultiSelect();
        },
        /**
         * Method to reset the selection from a parent.
         */
        reset() {
          this.selection = new Set();
          this.opened = false;
        },
        /**
         * Method to disable the main scrollbar if the panel is opened
         * 
         * @param {event} $event A keyboard event
         */
        disableScrollbar($event) {
          if(["ArrowUp","ArrowDown"].indexOf($event.code) > -1) {
            $event.preventDefault();
          }
        },
        /**
         * Method to focus on the Multi Select
         */
        focusMultiSelect() {
          this.$refs['base-multi-select'].focus();
          this.withKeyboard = false;
        }
    },
};
</script>

<style scoped lang="scss">
@use '@/styles/variables.scss';

.container {
    width: inherit;
    margin: 5px;
    background-color: var(--background);
    border-radius: 3px;
    
    .input-container {
        display: flex;
        box-sizing: border-box;
        padding: 0px 15px;
        font-size: 1rem;
        cursor: pointer;
        height: 40px;
        border: 1px solid var(--plain-dark);
        border-radius: 3px;

        .disabled {
          opacity: 0.5;
          color: var(--inactive);
        }

        .placeholder {
            opacity: 0.5;
        }

        .input {
          display: flex;
          align-items: center;
          width: 100%;
        }
        
        .icon {
            font-size: 15px;
            float: right;
            margin: 12px 0;
        }

        .clear-all {
          display: flex;
          align-items: center;
        }

        .more-tags {
          border-radius: 3px;
          padding: 3px;
          text-align: center;
          color: var(--text);
          background-color: var(--section-header-bg-dark);
        }
    }

    .dropdown-container {
        position: relative;
        background-color: var(--background);
        z-index: 1;
        width: inherit;
        border: 1px solid var(--plain-dark);
        border-radius: 2px;
    }
}

</style>