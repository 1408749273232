<template>
  <cmc-block
    padding-top="3xl"
  >
    <cmc-align at-center>
      <cmc-group spacing="xs">
        <cmc-button
          v-if="!stepProps.first"
          text="back"
          with-i18n
          @click="doBack"
        ></cmc-button>
        <cmc-button
          text="cancel"
          with-i18n
          @click="cancel"
        ></cmc-button>
        <cmc-button
          v-if="!stepProps.last"
          text="next"
          with-i18n
          call-to-action
          :disabled="disabledNext"
          :loading="loadingNext"
          @click="doNext"
        ></cmc-button>
        <cmc-button
          v-if="stepProps.last"
          :disabled="stepProps.disabledSubmit"
          :loading="loadingSubmit"
          call-to-action
          type="submit"
          :with-i18n="stepProps.withSubmitLabelI18n"
          :text="stepProps.submitLabel ? stepProps.submitLabel : $t('submit')"
        ></cmc-button>
      </cmc-group>
    </cmc-align>
  </cmc-block>
</template>

<script setup lang="ts">
import { inject, defineComponent, ref } from 'vue';
import { StepProviderProps } from './CmcStepProvider.vue';

import CmcAlign from '../layout/CmcAlign.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcReadOnly from '../inputs/CmcReadOnly.vue';
import CmcButton from '../buttons/CmcButton.vue';

defineComponent({
  CmcAlign,
  CmcTitle,
  CmcText,
  CmcBlock,
  CmcStack,
  CmcGroup,
  CmcReadOnly,
  CmcButton,
})

type Props = {
  /**
   * Disable next button.
   */
   disabledNext?: boolean;
}

const loadingNext = ref(false);
const props = defineProps<Props>()

const loadingSubmit = inject('cmc-multi-form-submit-loading');
const stepProps = inject<StepProviderProps>('cmc-multi-form-step-props')!
const goToStepNumber = inject<(stepNumber: number) => Promise<void>>('cmc-multi-form-go-to-step-number')!
const goToNextStep = inject<() => Promise<void>>('cmc-multi-form-go-to-next-step')!
const cancel = inject<() => void>('cmc-multi-form-cancel')!

const doBack = () => {
  goToStepNumber(stepProps.currentStep - 1);
}

const doNext = async () => {
  if (props.disabledNext) {
    return;
  }
  loadingNext.value = true;
  await goToNextStep();
  loadingNext.value = false;
}

</script>
