<template>
  <cmc-chart
    ref="cmcChart"
    v-bind="chartProps"
    @click="eventListeners.handleClick"
    @legend-select-changed="eventListeners.handleLegendSelectChanged"
  >
    <template #tooltip-template>
      <slot name="tooltip-template"></slot>
    </template>
  </cmc-chart>
</template>

<script setup lang="ts">
import CmcChart from "@/components/nextgen/charts/CmcChart.vue";
import { use } from "echarts/core";
import { GaugeChart } from "echarts/charts";
import { computed, getCurrentInstance, ref } from "vue";
import { EChartsOption, GaugeSeriesOption } from "echarts";
import {
  GridOption,
  LegendOption,
  TooltipOption
} from "echarts/types/dist/shared";
import { ToolboxOption } from "echarts/types/src/component/toolbox/ToolboxModel";
import {
  EChartStyleOptions,
  GraphColor,
  GraphTextStyle
} from "@/components/nextgen/charts/branding";
import useCmcChartEventListeners from "@/components/nextgen/composables/useCmcChartEventListeners";

use([GaugeChart]);

/** 
 * THERE IS A BUG IN VUE3 with TYPESCRIPT in which we can chain several interfaces together if one is imported from another file
 * The solution is that we unfortunately need to list off this huge chain (Props + CmcChartVisibleProps + CmcChartProps), 
 * and this causes issues with Jest testing.  We should eventually retest this in mid 2025 to see if it Typescript support has improved. 
*/

interface Props {
  /** THIS BATCH OF PROPS IS ORIGINALLY FROM CMCCHARTPROPS */

  /**
   * The default colors used for the chart.
   * Specific colors can be specified for each series
   */
   colors?: GraphColor[];

  /**
   * The default text style used for the chart.
   */
  textStyle?: GraphTextStyle;

  /**
   * The inherited html classes from custom implementation
   */
  class?: Record<string, any>,

  /**
   * The inherited css inline styles from custom implementation
   */
  style?: Record<string, any>
  /** END OF CMCCHARTPROPS */

  /** START OF CMCCHARTVISIBLEPROPS */
  /**
     * The chart tooltip options
     */
    tooltip?: Omit<TooltipOption, EChartStyleOptions>;

  /**
   * The chart legend options
   */
  legend? : Omit<LegendOption, EChartStyleOptions>;

  /**
   * The chart grid options
   */
  grid?   : Omit<GridOption, EChartStyleOptions>;

  /**
   * The chart toolbox options
   */
  toolbox?: Omit<ToolboxOption, EChartStyleOptions>;
  /** END OF CMCCHARTVISIBLEPROPS */

  /** START ACTUAL PROPS WHICH EXTEND ABOVE */
  /**
   * The minimum value of the gauge chart
   */
  min: number;

  /**
   * The maximal value of the gauge chart
   */
  max: number;

  /**
   * The value unit, will be visible in the tooltip
   */
  unit: string;

  /**
   * The series to display on the gauge chart.
   * The style options are not supported, this is why they are omitted (EChartStyleOptions) from the base type of the series.
   * this is to forget to go through the colors and textStyles props and to reinforce the conformity of the design.
   * @see {@link https://echarts.apache.org/en/option.html#series-gauge}
   */
  series: Omit<GaugeSeriesOption, EChartStyleOptions>[];

  /**
   * Additional set of various options that will be merged with the known ones
   * @see CmcChart.props.customOptions
   * @see {@link https://echarts.apache.org/en/option.html}
   */
  customOptions?: EChartsOption;
}

const props = defineProps<Props>();
const GAUGE_POINTER_ICON = 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z';

const legendOptions = computed(() => ({ show: false }));
const tooltipOptions = computed(() => ({
  confine: true,
  show: true,
  formatter: (params: Record<string, any>) => {
    const defaultSeriesName = params.seriesName.startsWith('series');
    if (!defaultSeriesName && params.name) {
      return `${params.seriesName} <br/>${params.name} : ${params.value} ${props.unit}`;
    } else if (!defaultSeriesName || params.name) {
      const name = (!defaultSeriesName ? params.seriesName : false) || params.name;
      return `${name} : ${params.value} ${props.unit}`;
    }
    return `${params.value} ${props.unit}`;
  }
}));

const seriesOptions = computed(() => {
  return props.series.map(s => ({
    name: s.name,
    type: 'gauge',
    radius: '100%',
    center: ['50%', '75%'],
    startAngle: 180,
    endAngle: 0,
    max: props.max,
    min: props.min,
    splitNumber: 2,
    pointer: {
      icon: GAUGE_POINTER_ICON,
      width: 10,
      length: '45%',
      offsetCenter: [0, '5%'],
    },
    progress: {
      show: true,
      overlap: false,
      roundCap: true,
    },
    title: { show: false },
    detail: { show: false },
    splitLine: {
      width: 2,
      distance: 3,
    },
    axisTick: {
      splitNumber: 5,
      distance: 3,
    },
    axisLabel: {
      distance: 25,
    },
    axisLine: {
      lineStyle: {
        width: 20,
      },
    },
    data: s.data,
  }));
});

const chartProps = computed(() => ({
  ...props,
  series : seriesOptions.value as GaugeSeriesOption[],
  tooltip: tooltipOptions.value as TooltipOption,
  legend : legendOptions.value as LegendOption,
  customOptions: props.customOptions as EChartsOption
}));

const emit = defineEmits(['click', 'legendSelectChanged']);
const eventListeners = useCmcChartEventListeners(getCurrentInstance(), emit);

const cmcChart = ref<InstanceType<typeof CmcChart>>();
defineExpose({
  updateLegends: (selectedLegends: any) => cmcChart.value?.updateLegends(selectedLegends),
  toggleAllLegends: (allLegends: string[], enable: boolean) => cmcChart.value?.toggleAllLegends(allLegends, enable)
});

</script>