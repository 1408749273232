import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, resolveComponent as _resolveComponent } from "vue"

import { ref, defineComponent } from 'vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcAppLink from '@/components/nextgen/navigation/CmcAppLink.vue';
import type { Alignment } from "@/components/nextgen/typography/types";
import { RouteLocationRaw } from 'vue-router';

 

interface Props {

    /**
     * Id of the component
     */
    id?: string
    /**
     * The icon to display
     */
    icon?: string

    /**
     * Should be set if a plain message is to be displayed under the icon
     */
    plainMessage?: string

    /**
     * If the plain message is an i18n label
     */
    withPlainMessageI18n?: boolean

    /**
     * Should be set if the entire message is clickable
     */
    clickableMessage?: string

    /**
     * If the clickable message is an i18n label
     */
     withClickableMessageI18n?: boolean

    /**
     * Adds path to navigate to, for the clickable message
     */
    toNavigateOnClick? : RouteLocationRaw
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcMessageDisplay',
  props: {
    id: {},
    icon: { default: 'warning' },
    plainMessage: {},
    withPlainMessageI18n: { type: Boolean },
    clickableMessage: {},
    withClickableMessageI18n: { type: Boolean, default: false },
    toNavigateOnClick: {}
  },
  setup(__props: any) {


defineComponent({
    CmcGrid,
    CmcGridCol,
    CmcAlign,
    CmcIcon,
    CmcText,
    CmcAppLink
});



const textAlignCenter = ref<Alignment>("center");



return (_ctx: any,_cache: any) => {
  const _component_cmc_stack = _resolveComponent("cmc-stack")!
  const _component_cmc_block = _resolveComponent("cmc-block")!

  return (_openBlock(), _createBlock(_component_cmc_block, {
    id: _ctx.id,
    padding: "s"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_cmc_stack, { "take-full-height": "" }, {
        default: _withCtx(() => [
          _createVNode(CmcAlign, { "at-horizontal-center": "" }, {
            default: _withCtx(() => [
              _createVNode(CmcIcon, {
                icon: _ctx.icon,
                svg: "",
                "custom-size": 5
              }, null, 8, ["icon"]),
              (_ctx.plainMessage)
                ? (_openBlock(), _createBlock(CmcText, {
                    key: 0,
                    text: _ctx.plainMessage,
                    "with-i18n": _ctx.withPlainMessageI18n,
                    align: textAlignCenter.value
                  }, null, 8, ["text", "with-i18n", "align"]))
                : _createCommentVNode("", true),
              (_ctx.clickableMessage)
                ? (_openBlock(), _createBlock(CmcAppLink, {
                    key: 1,
                    class: "link-on-primary",
                    to: _ctx.toNavigateOnClick || ''
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcText, {
                        class: "cmc-message-display-clickable-text",
                        text: _ctx.clickableMessage || '',
                        "with-i18n": _ctx.withClickableMessageI18n
                      }, null, 8, ["text", "with-i18n"])
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "custom-clickable-message")
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["id"]))
}
}

})