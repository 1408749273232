<template>
  <cmc-block
    :class="[
      'cmc-alert',
      {
        'cmc-alert-at-full-width': withFullWidth
      }]"
    :with-border="withOutline"
    padding-vertical="none"
    padding-horizontal="xs"
  >
    <cmc-align at-center-left>
      <cmc-group 
        with-vertical-align="center"
        spacing="xs"
      >
        <cmc-icon 
          :icon="actualIcon" 
          :color="actualColor" 
          svg
        />
        <cmc-text
          :text="text" 
          :with-i18n="withI18n"
        />
      </cmc-group>
    </cmc-align>
  </cmc-block>
</template>

<script setup lang="ts">
import { computed, defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcIcon from '../misc/CmcIcon.vue';

type Color = 'green' | 'red' | 'blue' | 'yellow';

defineComponent({
  CmcBlock, CmcText, CmcIcon, CmcGroup, CmcAlign,
})

type Props = {
  /**
   * Text to add in the alert
   */
  text: string;
  /**
   * Color of the alert
   */
  color?: Color;
  /**
   * Text is an i18n label key
   */
  withI18n?: boolean;
  /**
   * Whether to display the Text as a warning
   */
  asWarning?: boolean;
  /**
   * Add an outline around alert
   */
  withOutline?: boolean;
  /**
   * Show the Alert at full width
   */
  withFullWidth?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  asWarning: false,
  withOutline: true,
  withFullWidth: false,
})

const actualColor = computed(() => {
  if (props.color) {
    return props.color
  } else if (props.asWarning) {
    return 'yellow';
  }
  return 'blue';
})

const actualIcon = computed(() => {
    return props.asWarning ? 'exclamation-filled-bold' : 'info-filled-bold'
})
</script>

<style scoped lang="scss">
.cmc-alert {
  width: 100%;
  max-width: 24rem;
  border-radius: 0.1875rem;
  height: 2rem;

  &.cmc-alert-at-full-width {
    max-width: 100%;
  }
}
</style>