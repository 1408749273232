<template>
  <div 
    :id="id ? `cmc-badge-${id}` : undefined"
    :class="[
      'cmc-badge', 
      { 
        'cmc-badge-with-outline' : withOutline,
        'cmc-badge-with-transparent' : withTransparent,
      }]"
  >
    <div :class="['cmc-badge-circle', color, { 'cmc-badge-animate' : animate}]"></div>
    <cmc-text
      ref="badgeTextRef"
      class="cmc-badge-text"
      size="l"
      :with-i18n="withI18n"
      :text="text"
      :interpolation="interpolation"
      without-wrap
    >
    </cmc-text>
  </div>
</template>

<script setup lang="ts">
import { defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';

type Color = 'green' | 'red' | 'blue' | 'yellow' | 'grey';

defineComponent({
  CmcText,
})

interface Props {
  /**
   * HTML Element id
   */
  id?: string;
  /**
   * Text to add in the badge
   */
  text: string;
  /**
   * Color of the badge
   */
  color?: Color;
  /**
   * Text is an i18n label key
   */
  withI18n?: boolean;
  /**
   * Add an outline around badge
   */
  withOutline?: boolean;
  /**
   * Make the background transparent instead of default.
   */
  withTransparent?: boolean;
  /**
   * Make the badge color animate
   */
  animate?:boolean;
  /**
   * Interpolation for the text.
   */
  interpolation?: any;
}

defineProps<Props>()

</script>

<style lang="scss">
:root {
  --ng-badge-bg: var(--ng-primary-surface);
  --ng-badge-border-color: var(--ng-secondary-border);

  --ng-badge-green: var(--ng-primary-green);
  --ng-badge-blue: var(--ng-primary-blue);
  --ng-badge-red: var(--ng-primary-red);
  --ng-badge-yellow: var(--ng-primary-yellow);
  --ng-badge-grey: var(--ng-primary-grey);
}
</style>

<style scoped lang="scss">
@use '@/styles/animations.scss';

.cmc-badge {
  display: inline-flex;
  font-family: 'Roboto', sans-serif;
  height: 1.75rem;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.25rem;
  border-radius: 0.9375rem;
  max-width: 100%;
  user-select: none;
  position: relative;
  background-color: var(--ng-badge-bg);
  &.cmc-badge-with-transparent {
    background-color: transparent;
  }

  &.cmc-badge-with-outline {
    border: 1px solid var(--ng-badge-border-color);
  }

  .cmc-badge-circle {
    display: inline-block;

    min-width: 0.75rem;
    
    min-height: 0.75rem;
    border-radius: 50%;
    background-color: var(--ng-primary-background);
    &.green {
      background-color: var(--ng-badge-green);
    }
    &.blue {
      background-color: var(--ng-badge-blue);
    }
    &.red {
      background-color: var(--ng-badge-red);
    }
    &.yellow {
      background-color: var(--ng-badge-yellow);
    }
    &.grey {
      background-color: var(--ng-badge-grey);
    }
    &.cmc-badge-animate {
      animation: blink 2s infinite;
    }
  }

  .cmc-badge-text {
    min-width: 1rem;
  }
}

</style>