<template>
  <cmc-block
    with-hover
  >
    <cmc-block
      v-if="transition !== undefined"
      :padding-vertical="spacing"
      :padding-horizontal="horizontalPadding"
      @click.stop="emit('click', $event)"
    >
      <cmc-pair
        :stretchRhs="true"
        with-vertical-align="center"
        class="pair-container"
      >
        <cmc-block
          v-if="icon"
          padding-right="s"
          padding-left="s"
        >
          <cmc-align at-center>
            <cmc-icon
              :svg="svg"
              :icon="iconClass"
              :img="img"
              :size="iconSize"
              as-plugin-icon
            >
            </cmc-icon>
          </cmc-align>
        </cmc-block>

        <cmc-pair
          :stretchLhs="true"
          :style="{ maxWidth: customTextMaxWidth }"
        >
          <div
            v-if="withTitleOnHover"
            :title="text"
            class="title-container"
          >
            <cmc-text
              :withI18n="withI18n"
              :text="text"
              without-wrap
              without-tooltip-on-hover
              size="m"
            ></cmc-text>
          </div>
          <cmc-text
            v-else
            :withI18n="withI18n"
            :text="text"
            :without-wrap="withEllipsisOnTextOverflow"
            size="m"
          ></cmc-text>
          <cmc-icon
            v-if="withChevron"
            icon="drawer-chevron-right"
            :svg="true"
            :size="iconSize"
          ></cmc-icon>
        </cmc-pair>
      </cmc-pair>
    </cmc-block>
    <app-link
      v-else
      :key="text"
      :to="redirectTo"
      @click.stop="emit('click', $event)"
    >
      <cmc-block
        :padding-vertical="spacing"
        :padding-horizontal="horizontalPadding"
      >
        <cmc-pair
          :stretchRhs="true"
          with-vertical-align="center"
          class="pair-container"
        >
          <cmc-block
            v-if="icon"
            padding-right="s"
            padding-left="s"
          >
            <cmc-align at-center>
              <cmc-icon
                :svg="svg"
                :icon="iconClass"
                :img="img"
                :size="iconSize"
                as-plugin-icon
              >
              </cmc-icon>
            </cmc-align>
          </cmc-block>

          <cmc-pair
            :stretchLhs="true"
            :style="{ maxWidth: customTextMaxWidth }"
          >
            <div
              v-if="withTitleOnHover"
              :title="text"
              class="title-container"
            >
              <cmc-text
                :withI18n="withI18n"
                :text="text"
                without-wrap
                without-tooltip-on-hover
                size="m"
              ></cmc-text>
            </div>
            <cmc-text
              v-else
              :withI18n="withI18n"
              :text="text"
              :without-wrap="withEllipsisOnTextOverflow"
              size="m"
            ></cmc-text>
            <cmc-icon
              v-if="withChevron"
              icon="drawer-chevron-right"
              :svg="true"
              :size="iconSize"
            ></cmc-icon>
          </cmc-pair>
        </cmc-pair>
      </cmc-block>
    </app-link>
  </cmc-block>
  <cmc-divider v-if="withDivider"></cmc-divider>
</template>
  
<script setup lang="ts">
import { defineComponent, computed } from 'vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcText from '../typography/CmcText.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import { Size } from '../sizes';

defineComponent({
  CmcBlock,
  CmcPair,
  CmcAlign,
  CmcIcon,
  CmcText,
  CmcDivider
})

const emit = defineEmits<{
  /**
   * Emitted when menu item was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()

const iconClass = computed(() => {
  if (!props.svg) {
    return props.icon;
  }
  try {
    require(`@/../public/static/icons/plugins/${props.icon}.svg`);
    return props.icon;
  } catch (_error) {
    return 'default';
  }
})


type Props = {
  /**
  * Label of the menu item
  */
  text: string;
  /**
  * True if the text is a label key.
  */
  withI18n?: boolean;
  /**
  * True if the menu item is disabled.
  */
  disabled?: boolean;
  /**
  * True if chevron is visible on the menu item.
  */
  withChevron?: boolean;
  /**
  * True if the menu item has a divider after it
  */
  withDivider?: boolean;
  /**
  * Class of the svg icon
  */
  icon?: string;
  /**
  * Size of the icon 
  */
  iconSize?: Size;
  /**
  * The spacing between menu items
  */
  spacing?: Size;
  /**
  * True if the icon is a svg
  */
  svg?: boolean;
  /**
  * True if the icon is a image url
  */
  img?: boolean;
  /**
  * Link to redirect on click 
  */
  redirectTo?: string;
  /** 
  * Transition to emit a modal or other action
  */
  transition?: string;
  /**
  * True if the menu item text is to be displayed without wrap
  */
  withEllipsisOnTextOverflow: boolean;
  /**
  * True if the title is to be displayed on hover instead of cmc-text tooltip
  */
  withTitleOnHover?: boolean;
  /**
  * Max width of the text wrapper if ellipsis is to be shown
  */
  customTextMaxWidth?: string;
  /**
  * Add padding horizontally.
  */
  horizontalPadding?: Size;
}
const props = withDefaults(defineProps<Props>(), {
  withI18n: false,
  disabled: false,
  withChevron: false,
  withDivider: false,
  icon: '',
  iconSize: 'xl',
  svg: false,
  img: false,
  withEllipsisOnTextOverflow: false,
  withTitleOnHover: false
})
</script>
<style scoped>
.title-container {
  display: flex;
  width: 100%;
}
</style>