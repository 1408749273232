<template>
  <cmc-block>
    <cmc-grid>
      <cmc-grid-col 
        v-if="!mobile"
        u="2-12"
      >
        <cmc-align at-top-center>
          <cmc-pie-chart
            v-if="asNextGen"
            ref="doughnutChart"
            class="chart"
            :series="displayedSeries"
            :colors="colors"
            :showLabelLine="false"
            :showLabel="false"
            :showLegends="false"
            :showSaveButton="false"
          >
          </cmc-pie-chart>
          <pie-chart
            v-else
            ref="doughnutChart"
            class="chart"
            :series="displayedSeries"
            :defaultColors="defaultColors"
            :showLabelLine="false"
            :showLabel="false"
            :showLegends="false"
            :showSaveButton="false"
          >
          </pie-chart>
        </cmc-align>
      </cmc-grid-col>
      <cmc-grid-col
        u="10-12"
      >
        <cmc-list-multi-select
          :modelValue="selectedLegends"
          :headers="headers"
          :rows="rows"
          :layout="layout"
          @update:model-value="updateLegends"
        />
      </cmc-grid-col>
    </cmc-grid>
  </cmc-block>
</template>

<script setup lang="ts">
import { watch, ref, computed, defineComponent, onMounted, nextTick } from 'vue';
import PieChart from "@/components/echart/PieChart.vue";
import CmcBlock from "@/components/nextgen/layout/CmcBlock.vue";
import CmcGrid from "@/components/nextgen/layout/CmcGrid.vue";
import CmcGridCol from "@/components/nextgen/layout/CmcGridCol.vue";
import CmcAlign from "@/components/nextgen/layout/CmcAlign.vue";
import deepEqual from 'deep-equal';
import CmcPieChart from "@/components/nextgen/charts/CmcPieChart.vue";
import CmcListMultiSelect from '@/components/nextgen/inputs/CmcListMultiSelect.vue';
import { isMobileMode } from '@/utils';
import { getColorHex, GRAPH_COLORS, GraphColor } from "@/components/nextgen/charts/branding";
import { ListCheckboxSelectHeader, ListCheckboxSelectCol, ListCheckboxSelectRow } from '@/components/nextgen/inputs/CmcListMultiSelect.vue';
import { ColLayout } from '../display/list/types';

defineComponent({
  CmcGrid, CmcBlock, CmcListMultiSelect
})

type Props = {
  /**
   * The data to display in the chart in eCharts format.
   */
  series: any[];

  /**
   * @deprecated Should use props.colors to enforce design compliance.
   * An array of colors to use for the chart in hex format.
   * These colors will be used if props.asNextGen is false
   */
  defaultColors?: string[];

  /**
   * An array of graph colors name to use for the chart.
   * These colors will be used if props.asNextGen is true
   */
  colors?: GraphColor[];

  /**
   * Headers for the table
   */
  headers: ListCheckboxSelectHeader[];

  /**
   * Column data for the rows corresponding to the entries in the series
   */
  rowColumns?: ListCheckboxSelectCol[];

  /**
   * Layout of the columns
   */
  layout: ColLayout[];

  /**
   * Extra summary row at the bottom of the table with no corresponding series checkbox
   */
  summaryRow?: ListCheckboxSelectRow;

  /**
   * If we want to render as a nextGen component. IF true, the colors taken into account
   * will be those of props.colors, and the series data will have to be put in series[$i].data.
   * If the value is false, the colors used will instead be props.defaultColors and
   * the series data will have to be put in props.series[$i].value.
   */
  asNextGen?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  series: () => [],
  defaultColors: () => ['#4B8CF0','#DC6E8C', '#87BE46', '#8255AA', '#F0C864', '#825A32','#828C8C'],
  colors: () => GRAPH_COLORS,
  asNextGen: false
})

const mobile = ref(isMobileMode());

const doughnutChart = props.asNextGen ? ref<InstanceType<typeof CmcPieChart>>() : ref(null)

const selectedLegends = ref(
  (props.series[0].value || props.series[0].data || []).map((entry: any) => {
    return entry.name;
  }));

const chartEntries = computed(() => {
  return props.series[0].value || props.series[0].data || [];
})

const displayedSeries = computed(() => {
  if (selectedLegends.value.length === 0) {
    const series = JSON.parse(JSON.stringify(props.series));
    series[0].data = [];
    series[0].value = [];
    return series;
  }
  return props.series;
})

const allLegends = computed(() => {
  return chartEntries.value.map((entry: any) => {
    return entry.name;
  });
})

const rows = computed(() => {
  const rows = chartEntries.value.map((entry: any, idx: number) => {
    return {
      label: `${entry.displayName ? entry.displayName : entry.name} (${computeEntryPercentage(entry)}%)`,
      value: entry.name,
      color: props.asNextGen ? (props.colors.length > idx ? getColorHex(props.colors[idx]) : undefined) :
        (props.defaultColors.length > idx ? props.defaultColors[chartEntries.value.indexOf(entry)] : undefined),
      columns: props.rowColumns ? props.rowColumns[idx] : [],
    }
  });
  if (props.summaryRow) {
    rows.push(props.summaryRow);
  }
  return rows;
})

watch(allLegends, (newAllLegends, oldAllLegends) => {
  if (!deepEqual(newAllLegends, oldAllLegends)) {
    selectedLegends.value = newAllLegends;
  }
});

onMounted(() => {
  nextTick(() =>
    window.addEventListener('resize', () => {
      mobile.value = isMobileMode();
    }),
  );
})

/**
 * Computes the percentage of each entry based on the selected legends.
 * @param entry the checkbox entry to compute the percentage for.
 */
const computeEntryPercentage = (entry: any) => {
  if (!selectedLegends.value.includes(entry.name)) {
    return 0;
  }
  const totalOfSelectedEntries = chartEntries.value.reduce((runningTotal: number, entry: any) => {
    return selectedLegends.value.includes(entry.name) ? runningTotal + parseFloat(entry.value) : runningTotal;
  }, 0);
  if (totalOfSelectedEntries === 0) {
    return 0;
  }
  const percentage = Math.round((parseFloat(entry.value) / totalOfSelectedEntries) * 100);
  return percentage;
}

/**
 * Updates the legends in the echarts chart, and emits an echart event with the new selected legends.
 * Echarts event format:
 * {
 *  name: string, // the legend that was toggled
 *  selected: { [key: string]: boolean }, // the new selected legends
 *  type: "legendselectchanged" // the type of event
 * }
 */
const updateLegends = (newLegends: any) => {
  const selectedLegend = newLegends.filter((item) => selectedLegends.value.indexOf(item) < 0)[0] ||
  selectedLegends.value.filter((item) => newLegends.indexOf(item) < 0)[0];
  selectedLegends.value = newLegends;  
  const echartsLegendsUpdateEvent = legendsToEchartsLegends(newLegends, selectedLegend);
  doughnutChart!.value!.updateLegends(echartsLegendsUpdateEvent);
  return emit('update:legends', echartsLegendsUpdateEvent);
}

/**
 * Converts the selected legends to the format that echarts expects.
 * @param legends the selected legends.
 * @param toggledCheckbox the legend that was toggled.
 * 
 * Echarts event format:
 * {
 *  name: string, // the legend that was toggled
 *  selected: { [key: string]: boolean }, // the new selected legends
 *  type: "legendselectchanged" // the type of event
 * }
 */
const legendsToEchartsLegends = (legends: string[], toggledCheckbox: string) => {
  const selected = allLegends.value.reduce((echartLegends: any, legend: string) => {
    echartLegends[legend] = legends.includes(legend);
    return echartLegends;
  }, {});
  return {
    name: toggledCheckbox,
    selected: selected,
    type: "legendselectchanged"
  };
}

const emit = defineEmits<{
  /**
   * Emitted when toggled.
   * @arg any[]
   */
  (event: 'update:legends', values: any[]): void,
  /**
   * Emitted when all legends are toggled.
   */
  (event: 'update:allLegends', values: boolean): void
}>()
</script>

<style lang="scss" scoped>
@use '@/styles/mixins.scss';

:deep(.chart) {
  height: 13rem;
  width: 13rem;
  margin: 0rem;
  left: -1.75rem;
  top: -3.25rem;
}

</style>