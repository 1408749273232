<template>
  <cmc-badge
    :with-transparent="withTransparent"
    :text="stateBadge.label"
    with-i18n
    :color="stateBadge.color"
    :animate="stateBadge.animated"
    :with-outline="withOutline"
  />
</template>

<script setup lang="ts">
import { defineComponent, computed } from 'vue';
import {
  ERROR_PROVISIONING,
  ERROR_PURGING,
  PENDING,
  PROVISIONING,
  PROVISIONED,
  PENDING_PURGE,
  PURGED,
  PURGING,
} from '@/utils/states';
import CmcBadge from '@/components/nextgen/display/CmcBadge.vue';
import { StateBadge } from './types';

defineComponent({ CmcBadge });
interface Props {
  state?: string;
  overrideState? : string;
  overrideStateColor?: string;
  withTransparent?: boolean;
  withOutline?: boolean;
}
const props = defineProps<Props>();

const defaultStateBadge = computed<StateBadge>(() => {
  switch (props.state) {
    case PROVISIONED: {
      return {
        color: 'green',
        label: PROVISIONED.toLocaleLowerCase(),
        animated: false,
      };
    }
    case ERROR_PURGING:
    case ERROR_PROVISIONING: {
      return {
        color: 'red',
        label: 'error',
        animated: false,
      };
    }
    case PROVISIONING: {
      return {
        color: 'blue',
        label: PROVISIONING.toLocaleLowerCase(),
        animated: true,
      };
    }
    case PENDING_PURGE:
    case PURGING: {
      return {
        color: 'grey',
        label: PURGING.toLocaleLowerCase(),
        animated: true,
      };
    }
    case PURGED: {
      return {
        color: 'grey',
        label: PURGED.toLocaleLowerCase(),
        animated: false,
      };
    }
    case PENDING: {
      return {
        color: 'grey',
        label: PENDING.toLocaleLowerCase(),
        animated: false,
      };
    }
    default: {
      return {
        color: 'grey',
        label: 'unknown',
        animated: false,
      };
    }
  }
})


const stateBadge = computed<StateBadge>(() => {
  if (props.overrideState && props.overrideStateColor) {
    return {
      color: (props.overrideStateColor.toLocaleLowerCase()) || defaultStateBadge.value.color,
      label: (props.overrideState) || defaultStateBadge.value.label,
      animated: false,
    }
  }
  return defaultStateBadge.value;
})
</script>
