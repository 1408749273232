<template>
  <header class="onboarding-header">
    <div class="logo">
      <app-link 
        v-if="linkRegister" 
        :to="'/register'"
      >
        <app-logo 
          class="long" 
          :src="`/rest/appearance/logo`"
        />
      </app-link>
      <app-link 
        v-if="linkRegister" 
        :to="'/register'"
      >
        <app-logo 
          class="short" 
          :src="`/rest/appearance/logo_square`"
        />
      </app-link>
      <app-logo 
        v-if="!linkRegister" 
        class="long" 
        :src="`/rest/appearance/logo`"
      />
      <app-logo 
        v-if="!linkRegister" 
        class="short" 
        :src="`/rest/appearance/logo_square`"
      />
    </div>
    <div 
      v-if="!noLogin" 
      class="is-already-member"
    >
      <div>{{ $t('onboarding.already_member') }}</div>
      <a href="/login">{{ $t('onboarding.sign_in') }}</a>
    </div>
    <div 
      v-if="withLogout" 
      class="sign-out"
    >
      <a 
        href="#" 
        @click="doLogout"
      >{{ $t('onboarding.sign_out') }}</a>
    </div>
  </header>
</template>

<script>
import AppLogo from '@/components/AppLogo';
import { mapActions } from 'vuex';
import apis from '@/utils/apis';

export default {
  name: 'OnboardHeader',
  components: { AppLogo },
  props: {
    noLogin: {
      type: Boolean,
      default: false,
    },
    withLogout: {
      type: Boolean,
      default: false,
    },
    linkRegister: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions([
      'logout',
    ]),
    async doLogout() {
      const resp = await apis.auth.logout();
      if (resp.status >= 300) {
        window.location.reload();
      }
      this.logout();
      this.$router.push(`/login`);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/mixins.scss';

.onboarding-header {
  background-color: var(--secondary);
  height: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;

}

.is-already-member, .sign-out {
  display: flex;
  color: var(--menu-text);
  font-size: 0.8em;
  * {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  a {
    color: var(--menu-text);

    @include mixins.not-phone {
      margin-right: 50px;
    }

    padding: 5px 15px;
    border: 1px solid var(--menu-text);
    border-radius: 18px;
    text-transform: uppercase;
  }
}

.logo {
  display: flex;
  align-content: center;
  flex: 1;
  padding: 10px;

  @include mixins.phone {
    .short {
      height: 28px;
      display: inline-block;
    }
    .long {
      display: none;
    }
  }

  @include mixins.not-phone {
    .short {
      display: none;
    }

    .long {
      display: inline-block;
      max-width: 200px;
      max-height: 50px;
    }
  }
}


</style>
