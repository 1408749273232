import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineComponent } from 'vue';
import CmcCard from "../display/CmcCard.vue";
import CmcDimmed from './CmcDimmed.vue';
import CmcResponsive from '../misc/CmcResponsive.vue';
import CmcGroup from "@/components/nextgen/layout/CmcGroup.vue";
import CmcAlign from "@/components/nextgen/layout/CmcAlign.vue";
import CmcButton from "@/components/nextgen/buttons/CmcButton.vue";
import { Size } from '../sizes';

type Props = {
  /**
   * Title of the modal
   */
  title : string;

  /**
   * True if the title should be translated
   */
  withTitleI18n?: boolean;

  /**
   * Category of the modal
   */
  category?: string;

  /**
   * True if the category should be translated
   */
  withCategoryI18n?: boolean;

  /**
   * Description of the modal
   */
  description?: string;

  /**
   * True if the description should be translated
   */
  withDescriptionI18n?: boolean;

  /**
   * Will display a count badge on the card if count > 0
   */
  count?: number;

  /**
   * Padding around the card body.
   */
  padding?: Size;

  /**
   * Vertical padding of the card body. Overrides `padding`.
   */
  paddingVertical?: Size;

  /**
   * Horizontal padding of the card body. Overrides `padding`.
   */
  paddingHorizontal?: Size;

  /**
   * Add padding on top of the block.
   */
  paddingTop?: Size;

  /**
   * Add padding on bottom of the block.
   */
  paddingBottom?: Size;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcCardModal',
  props: {
    title: {},
    withTitleI18n: { type: Boolean, default: false },
    category: { default: "" },
    withCategoryI18n: { type: Boolean, default: false },
    description: { default: "" },
    withDescriptionI18n: { type: Boolean, default: false },
    count: { default: 0 },
    padding: {},
    paddingVertical: {},
    paddingHorizontal: {},
    paddingTop: {},
    paddingBottom: {}
  },
  setup(__props: any) {

defineComponent({
  CmcDimmed,
  CmcCard,
  CmcResponsive,
  CmcGroup,
  CmcAlign,
  CmcButton
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcGroup, { "take-full-width": "" }, {
    default: _withCtx(() => [
      _createVNode(CmcCard, {
        class: "cmc-card-modal-card",
        "with-collapse": false,
        title: _ctx.title,
        "with-title-i18n": _ctx.withTitleI18n,
        category: _ctx.withCategoryI18n ? _ctx.category : _ctx.$t(_ctx.category),
        description: _ctx.withDescriptionI18n ? _ctx.description : _ctx.$t(_ctx.description),
        count: _ctx.count,
        padding: _ctx.padding,
        "padding-horizontal": _ctx.paddingHorizontal,
        "padding-vertical": _ctx.paddingVertical,
        "padding-top": _ctx.paddingTop,
        "padding-bottom": _ctx.paddingBottom
      }, {
        "rhs-content": _withCtx(() => [
          _renderSlot(_ctx.$slots, "rhs")
        ]),
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["title", "with-title-i18n", "category", "description", "count", "padding", "padding-horizontal", "padding-vertical", "padding-top", "padding-bottom"])
    ]),
    _: 3
  }))
}
}

})