<template>
  <cmc-block
    padding="xs"
  >
    <cmc-stack
      spacing="xs"
      :class="['cmc-key-value', {'cmc-key-value-narrow': withNarrow}]"
    >
      <cmc-stack
        v-for="entry in keyValueList"
        :key="entry[0]"
        :spacing="'4xs'"
      >
        <cmc-title
          :title="entry[0]"
          heading="h6"
          with-i18n
        >
        </cmc-title>
        <cmc-text
          :text="entry[1]"
          size="m"
          class="entry-value"
        > 
        </cmc-text>
      </cmc-stack>
    </cmc-stack>
  </cmc-block>
</template>

<script setup lang="ts">
import { computed, defineComponent } from 'vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcStack from '../layout/CmcStack.vue';

defineComponent({
  CmcBlock, CmcText, CmcStack, CmcTitle,
})

type Props = {
  /**
   * Key-values to display
   */
  keyValues: {[key: string]: string},

  /**
   * True if the key-value pairs should have a narrow width
   */
  withNarrow?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
})

const keyValueList = computed(() => {
  let keyValues: string[][] = [];
  if (props.keyValues !== undefined && Object.keys(props.keyValues).length > 0) {
    keyValues = Object.keys(props.keyValues)
    .map(key => [key, props.keyValues[key]]);
  }
  return keyValues;
})
</script>

<style scoped lang="scss">

.cmc-key-value {
  width: 100%;
  &.cmc-key-value-narrow {
    max-width: 10rem;
  }
}
.entry-value {
  color: var(--ng-text-main);
}
</style>