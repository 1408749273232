import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import { inject, defineComponent, ref } from 'vue';
import { StepProviderProps } from './CmcStepProvider.vue';

import CmcAlign from '../layout/CmcAlign.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcReadOnly from '../inputs/CmcReadOnly.vue';
import CmcButton from '../buttons/CmcButton.vue';

type Props = {
  /**
   * Disable next button.
   */
   disabledNext?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcStepButtons',
  props: {
    disabledNext: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({
  CmcAlign,
  CmcTitle,
  CmcText,
  CmcBlock,
  CmcStack,
  CmcGroup,
  CmcReadOnly,
  CmcButton,
})

const loadingNext = ref(false);
const props = __props

const loadingSubmit = inject('cmc-multi-form-submit-loading');
const stepProps = inject<StepProviderProps>('cmc-multi-form-step-props')!
const goToStepNumber = inject<(stepNumber: number) => Promise<void>>('cmc-multi-form-go-to-step-number')!
const goToNextStep = inject<() => Promise<void>>('cmc-multi-form-go-to-next-step')!
const cancel = inject<() => void>('cmc-multi-form-cancel')!

const doBack = () => {
  goToStepNumber(stepProps.currentStep - 1);
}

const doNext = async () => {
  if (props.disabledNext) {
    return;
  }
  loadingNext.value = true;
  await goToNextStep();
  loadingNext.value = false;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, { "padding-top": "3xl" }, {
    default: _withCtx(() => [
      _createVNode(CmcAlign, { "at-center": "" }, {
        default: _withCtx(() => [
          _createVNode(CmcGroup, { spacing: "xs" }, {
            default: _withCtx(() => [
              (!_unref(stepProps).first)
                ? (_openBlock(), _createBlock(CmcButton, {
                    key: 0,
                    text: "back",
                    "with-i18n": "",
                    onClick: doBack
                  }))
                : _createCommentVNode("", true),
              _createVNode(CmcButton, {
                text: "cancel",
                "with-i18n": "",
                onClick: _unref(cancel)
              }, null, 8, ["onClick"]),
              (!_unref(stepProps).last)
                ? (_openBlock(), _createBlock(CmcButton, {
                    key: 1,
                    text: "next",
                    "with-i18n": "",
                    "call-to-action": "",
                    disabled: _ctx.disabledNext,
                    loading: loadingNext.value,
                    onClick: doNext
                  }, null, 8, ["disabled", "loading"]))
                : _createCommentVNode("", true),
              (_unref(stepProps).last)
                ? (_openBlock(), _createBlock(CmcButton, {
                    key: 2,
                    disabled: _unref(stepProps).disabledSubmit,
                    loading: _unref(loadingSubmit),
                    "call-to-action": "",
                    type: "submit",
                    "with-i18n": _unref(stepProps).withSubmitLabelI18n,
                    text: _unref(stepProps).submitLabel ? _unref(stepProps).submitLabel : _ctx.$t('submit')
                  }, null, 8, ["disabled", "loading", "with-i18n", "text"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})