<template>
  <div
    :class="[
      'cmc-sensitive-text-area-wrapper',
      {'cmc-sensitive-text-area-disabled': isDisabled}
    ]"
  >
    <cmc-text-input
      v-if="displayTextInput"
      :type="'password'"
      :modelValue="actualValue"
      :label="label"
      :with-label-i18n="withLabelI18n"
      :description="description"
      :with-description-i18n="withDescriptionI18n"
      :with-warning-tooltip="withWarningTooltip"
      :with-warning-tooltip-i18n="withWarningTooltipI18n"
      :placeholder="adjustedPlaceholder"
      :with-placeholder-i18n="withPlaceholderI18n"
      :with-tooltip="withTooltip"
      :with-tooltip-i18n="withTooltipI18n"
      :read-only="readOnly"
      :inherit-read-only="inheritReadOnly"
      :with-error-text="withErrorText"
      :with-error-text-i18n="withErrorTextI18n"
      :disabled="isDisabled"
      @update:model-value="emit('update:modelValue', $event)"
      @click="emit('click', $event)"
      @focus="emit('focus', $event)"
      @blur="emit('blur', $event)"
    >
      <template #rhs>
        <cmc-icon
          v-if="!isEditMode && !disabled && hasInitialValue"  
          class="rhs-icon"
          icon="edit"
          size="l"
          svg
          :with-clickable="!disabled"
          @click="toggleEdit"
        />
      </template>
    </cmc-text-input>
    <cmc-text-area
      v-else
      :modelValue="actualValue"
      :label="label"
      :with-label-i18n="withLabelI18n"
      :description="description"
      :with-description-i18n="withDescriptionI18n"
      :with-warning-tooltip="withWarningTooltip"
      :with-warning-tooltip-i18n="withWarningTooltipI18n"
      :with-placeholder="adjustedPlaceholder"
      :with-placeholder-i18n="withPlaceholderI18n"
      :with-tooltip="withTooltip"
      :with-tooltip-i18n="withTooltipI18n"
      :read-only="readOnly"
      :inherit-read-only="inheritReadOnly"
      :with-error-text="withErrorText"
      :with-error-text-i18n="withErrorTextI18n"
      :disabled="disabled"
      @update:model-value="emit('update:modelValue', $event)"
    />
  </div>
</template>

<script setup lang="ts">
  import { computed, defineComponent, ref } from 'vue';
  import CmcTextArea from './CmcTextArea.vue';
  import CmcTextInput from './CmcTextInput.vue';
  import { EditorLanguage } from "../enums/NextGenEnums";
  import { useI18n } from 'vue-i18n';

  defineComponent({
    CmcTextArea,
    CmcTextInput
  });

  const { t } = useI18n();

  type Props = {
    /**
     * HTML element id
     */
    id?: string;

    /**
     * Value of the text area
     */
    modelValue?: string;

    /**
     * Label on top of the input
     */
    label?: string;

    /**
     * True if the label is a label key.
     */
    withLabelI18n?: boolean;

    /**
     * Description to display under label.
     */
    description?: string;

    /**
     * True if the description is a label key.
     */
    withDescriptionI18n?: boolean;

    /**
     * Show a warning tooltip next to the label
     */
    withWarningTooltip?: string;

    /**
     * True if the warning tooltip is a label key
     */
    withWarningTooltipI18n?: boolean;

    /**
     * Set a placeholder in the input.
     */
    placeholder?: string;

    /**
     * True if the placeholder is an i18n label.
     */
    withPlaceholderI18n?: boolean;

    /**
     * Show a tooltip next to the label
     */
    withTooltip?: string;

    /**
     * True if the tooltip is a label key.
     */
    withTooltipI18n?: boolean;

    /**
     * Make the input read-only.
     */
    readOnly?: boolean;

    /**
     * Inherit read only flag from form. Defaults to true.
     */
    inheritReadOnly?: boolean

    /**
     * Will show an error text under the input.
     */
    withErrorText?: string;

    /**
     * True if the error text is i18n.
     */
    withErrorTextI18n?: boolean;

    /**
     * Disable the input
     */
    disabled?: boolean;

    /**
     * True if the input should obscure the initial value
     * Defaults to true
     */
    obscureInitialValue?: boolean;
  };

  const props = withDefaults(defineProps<Props>(), {
    inheritReadOnly: true,
    asCodeEditor: false,
    language: EditorLanguage.YAML,
    obscureInitialValue: true,
  });

  const emit = defineEmits<{
    /**
     * Emitted when input was changed.
     * @arg text value
     */
    (event: 'update:modelValue', str: string): void

    /**
     * On click of the input
     */
    (event: 'click', e: Event): void

    /**
     * On focus of the input
     */
    (event: 'focus', e: Event): void

    /**
     * On blur of the input
     */
    (event: 'blur', e: Event): void
  }>();

  const isEditMode = ref(false);
  const hasInitialValue = ref(props.modelValue !== '' && props.modelValue !== undefined && props.modelValue !== null);

  const adjustedPlaceholder = computed(() => {
    if (props.placeholder) {
      return props.placeholder;
    }
    if (hasInitialValue.value) {
      return t('input_new_value');
    }
    return undefined;
  });

  const isDisabled = computed(() => (!isEditMode.value || props.disabled) && hasInitialValue.value);

  const displayTextInput = computed(() => (!isEditMode.value && hasInitialValue.value) || props.readOnly);

  const actualValue = computed(() => {
    if (props.obscureInitialValue && displayTextInput.value) {
      return '[' + t('redacted') + ']';
    }
    return props.modelValue;
  });
  
  const toggleEdit = () => {
    if (props.obscureInitialValue) {
      emit('update:modelValue', '');
    }
    isEditMode.value = !isEditMode.value;
  };
</script>

<style scoped lang="scss">
.cmc-sensitive-text-area-wrapper {
  .rhs-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.4rem;
  }

  &.cmc-sensitive-text-area-disabled {
    :deep(input) {
      color: var(--ng-text-description) !important;
    }
  }
}
</style>