<template>
  <div>
    <div class="col">
      <div 
        v-for="fe in children" 
        :key="fe.field" 
        class="item"
      >
        <form-element 
          v-model="computedVal[fe.field]" 
          :formElement="fe" 
          :disabled="fe.disabled || disabled" 
          :imageResolver="imageResolver"
          :error="errorValue(fe.field)" 
          :absoluteError="hasError"
          @change="change" 
          @reload="$emit('reload', $event)" 
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLayout',
  props: {
    children: {
      type: Array,
      required: true,
    },
    errors: {
      type: [Array, Object],
    },
    modelValue: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imageResolver: {
      type: Function,
    },
  },
  emits: ['update:modelValue', 'change', 'reload'],
  computed: {
    computedVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    hasError() {
      return this.errors && this.children.some(fe => this.fieldHasError(fe.field, fe.type));
    },
  },
  methods: {
    change(v) {
      this.$emit('update:modelValue', v);
      this.$emit('change', v);
    },
    getErrorList(feField) {
      if (!this.errors || JSON.stringify(this.errors) === '{}') {
        return [];
      }
      if (Array.isArray(this.errors)) {
        return this.errors.filter(e => e.context.field[0] === feField);
      }
      return this.errors[feField];
    },
    errorValue(field) {
      const associatedErrors = this.getErrorList(field);
      if (!associatedErrors || associatedErrors.length === 0) {
        return undefined;
      }
      return associatedErrors;
    },
    fieldHasError(field, type) {
      return this.errorValue(field) !== undefined && !['repeatable', 'composite'].includes(type);
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

.col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mixins.not-phone {
    align-items: flex-end;
    max-width: 600px;

    .item:not(:first-child) {
      padding-left: 15px;
    }
  }

  @include mixins.phone {
    flex-direction: column;

    .item:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.col >.form-row , .form-element {
    padding-bottom: 0px !important;
}

.item {
  flex: 2;
}

</style>
