<template>
  <component
    :is="asLabel ? 'label': 'div'"
    ref="text"
  >
    <cmc-pair
      stretchLhs
      spacing="3xs"
    >
      <cmc-block as-inline>
        <cmc-align at-right>
          <cmc-title
            v-if="asHeader"
            ref="text"
            :title="formattedAmount"
            heading="h5"
          />
          <cmc-text
            v-else
            ref="text"
            :text="formattedAmount"
            :withBold="withBold"
            :asLabel="asLabel"
            :asDisabled="asDisabled"
            :without-wrap="withoutWrap"
            size="l"
          />
        </cmc-align>
      </cmc-block>
      <cmc-block
        as-inline
      >
        <cmc-align at-bottom-left>
          <cmc-text
            :text="showCurrency||reserveCurrencySpace?currency || '':''"
            size="m"
            :without-wrap="withoutWrap"
            :class="{'currency-value': showCurrency, 'hide-currency-abbr': reserveCurrencySpace}"
          />
        </cmc-align>
      </cmc-block>
    </cmc-pair>
  </component>
</template>

  <script setup lang="ts">
  import { computed, defineComponent, ref } from "vue";
  import CmcPair from "@/components/nextgen/layout/CmcPair.vue";
  import { currencyMixin } from '@/mixins/currencyMixin';
  import CmcText from '@/components/nextgen/typography/CmcText.vue';
  import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';

  defineComponent({
    CmcPair, CmcText, CmcTitle
  })

  const text = ref()

  type Props = {

    /**
     * True if the text is bold.
     */
    withBold?: boolean;

    /**
     * Use a label instead of a div element.
     */
    asLabel?: boolean;

    /**
     * Use a header instead of a div element.
     */
    asHeader?: boolean;

    /**
     * Display text as disabled text.
     */
    asDisabled?: boolean;

    /**
     * Contains the currency symbol to be shown
     */
    currency?: string;

    /**
     * Contains the currency amount to be shown
     */
    amount?: number;

    /**
     * Contains the locale to be shown
     */
    locale?: string;

    /**
     * Contains the max fraction digits to be shown
     */
    maxFractionDigits?: number;

    /**
     * Show currency code at the end of the string (ex. "1,300$ CAD")
     */
    showCurrency?: boolean;

    /**
     * Hide the narrow currency, defaults to false
     * examples: "CA 1,300.00$" (en-US) => "1,300.00$"
     * "1 300,00$ CA" (en-FR) => "1 300,00$"
     */
    hideNarrowCurrency?: boolean;

    /**
     * Reserve currency space
     */
    reserveCurrencySpace?: boolean;

    /**
     * Without wrap
     */
    withoutWrap?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    withBold: false,
    asLabel: false,
    showCurrency:false,
    reserveCurrencySpace:false,
    withoutWrap: false,
    hideNarrowCurrency: false,
  });

  const formattedAmount = computed(() => {
    return currencyMixin.methods.formatLocaleCurrencyWithoutAbbreviation(props.amount, props.currency, props.locale, props.maxFractionDigits, props.hideNarrowCurrency);
  })

  </script>

<style scoped> /* Scoped styles for this component */
.currency-value {
  padding-bottom: 1px;
}
.hide-currency-abbr {
  visibility: hidden;
}
</style>
