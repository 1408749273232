import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "loading-wrapper" }

import CmcCard from '@/components/nextgen/display/CmcCard.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';
import CmcMessageDisplay from '@/components/nextgen/display/CmcMessageDisplay.vue';
import CmcAppLink from '@/components/nextgen/navigation/CmcAppLink.vue';
import OrganizationServicesList from './OrganizationServicesList.vue';
import CmcLoader from '@/components/nextgen/display/CmcLoader.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { AssignedServiceConnection, Organization } from './types';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { useI18n } from 'vue-i18n';
import authz from '@/authz';

type Props = {
  /**
   * Name of the organization
   */
  organization: Organization;

  /**
   * Whether to hide the title
   */
  hideTitle?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ManageAssignedServices',
  props: {
    organization: {},
    hideTitle: { type: Boolean, default: false }
  },
  setup(__props: any) {

defineComponent({
  CmcCard, CmcButton, CmcMessageDisplay, CmcAppLink, CmcLoader
})

const props = __props;

const { t } = useI18n();
const hasEditPermission = ref(false);
const loading = ref(true);
const componentKey = ref(0);
const availableConnections = ref<AssignedServiceConnection[]>([]);

const hasAssignedConnections = computed<boolean>(() => {
  const availableConnectionIds = availableConnections.value.map(a => a.id);
  return props.organization.serviceConnections.filter(sc => availableConnectionIds.includes(sc.id)).length > 0;
});

onMounted(async () => {
  await Promise.all([
      fetchAvailableConnections(),
      setCanEdit()
    ])
    loading.value = false;
});

/**
 * Loads all data.
 */
async function fetchAvailableConnections(): Promise<void> {
  const resp = await apis.organizations.findServiceConnections(props.organization.id);
      if (resp.status !== 200 || !resp.data) {
        notify.error(t('unexpected_error'));
      } else {
        const responseData = resp.data;
        availableConnections.value = responseData.map((sc: { id: string | number; }) => ({
          ...sc,
          state: assignedConnectionState.value[sc.id],
        }));
      }
}

async function setCanEdit() {
      const orgPermissions = await apis.users.getEffectivePermissions(props.organization.id);
      const allPermissions = orgPermissions ? [
        ...(orgPermissions.system || []),
        ...(orgPermissions.subs || []),
        ...(orgPermissions.service || []),
        ...(orgPermissions.global || [])
      ]
        : [];

      hasEditPermission.value = authz.hasPermission('reseller:connections') ||
        authz.hasPermission('reseller:assignConnections') ||
        allPermissions.some(p => p === 'reseller:assignConnections' || p === 'reseller:connections');
    }

    const assignedConnectionState = computed<Record<string, any>>(() => {
  return props.organization.serviceConnections.reduce((acc: Record<string, any>, a) => {
    acc[a.id] = a.state;
    return acc;
  }, {} as Record<string, any>);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcCard, {
    title: "assigned_services",
    "with-title-i18n": "",
    padding: "none"
  }, {
    "rhs-content": _withCtx(() => [
      (hasEditPermission.value)
        ? (_openBlock(), _createBlock(CmcButton, {
            key: 0,
            text: "assign_connection.label",
            "with-i18n": "",
            "lhs-icon": "plus",
            "call-to-action": "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`${_ctx.$route.path}/assign`)))
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (loading.value)
          ? (_openBlock(), _createBlock(CmcLoader, { key: 0 }))
          : _createCommentVNode("", true),
        (!hasAssignedConnections.value)
          ? (_openBlock(), _createBlock(CmcMessageDisplay, {
              key: 1,
              id: "select-service-scope-warning",
              icon: "assign-service",
              "clickable-message": "assign_connection.label",
              "to-navigate-on-click": `${_ctx.$route.path}/assign`,
              "with-clickable-message-i18n": ""
            }, null, 8, ["to-navigate-on-click"]))
          : (_openBlock(), _createBlock(OrganizationServicesList, {
              key: 2,
              organization: _ctx.organization,
              availableConnections: availableConnections.value,
              componentKey: componentKey.value
            }, null, 8, ["organization", "availableConnections", "componentKey"]))
      ])
    ]),
    _: 1
  }))
}
}

})