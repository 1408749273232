<template>
  <cmc-read-only
    :id="id ? `cmc-counter-${id}` : undefined"
    :label="label"
    :model-value="modelValue"
    :with-label-i18n="withLabelI18n"
    :read-only="readOnly"
    :inherit-read-only="inheritReadOnly"
    :with-error-text="withErrorText"
    :with-error-text-i18n="withErrorTextI18n"
  >
    <div
      class="cmc-counter"
    >
      <cmc-stack
        spacing="3xs"
      >
        <cmc-align
          :at-horizontal-center="withLabelCentered"
        >
          <cmc-label
            :label="label"
            :with-label-i18n="withLabelI18n"
          />
        </cmc-align>
        <cmc-group
          :class="['cmc-counter-inputs', {'cmc-counter-disabled': disabled}]"
          spacing="none"
          with-vertical-align="bottom"
        >
          <cmc-button
            :id="id ? `cmc-counter-button-up-${id}` : undefined"
            :disabled="disableDecrement"
            lhs-icon="chevron-bold-down"
            lhs-icon-svg
            icon-size="s"
            :aria-label="$t('decrease')"
            @click="incrementCounter(-1)"
          />
          <cmc-text-input
            :id="id ? `cmc-counter-input-${id}` : undefined"
            type="number"
            :decimal="false"
            :model-value="modelValue"
            :disabled="disableTextInput"
            :read-only="readOnly"
            :inherit-read-only="inheritReadOnly"
            @update:model-value="updateValue($event)"
            @click="emit('click', $event)"
            @focus="emit('focus', $event)"
            @blur="emit('blur', $event)"
          />
          <cmc-button
            :id="id ? `cmc-counter-button-down-${id}` : undefined"
            :disabled="disableIncrement"
            lhs-icon="chevron-bold-up"
            lhs-icon-svg
            icon-size="s"
            :aria-label="$t('increase')"
            @click="incrementCounter(1)"
          />
        </cmc-group>
        <cmc-text
          v-if="withErrorText"
          :text="withErrorText"
          :with-i18n="withErrorTextI18n"
          as-error
          size="m"
        ></cmc-text>
      </cmc-stack>
    </div>
  </cmc-read-only>
</template>

<script setup lang="ts">
import { computed, defineComponent } from 'vue';
import CmcTextInput from './CmcTextInput.vue';
import CmcButton from '../buttons/CmcButton.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcReadOnly from './CmcReadOnly.vue';

defineComponent({
  CmcTextInput,
  CmcButton,
  CmcGroup,
  CmcReadOnly,
})

export type CmcCounterProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Value of the input
   */
  modelValue?: string;

  /**
   * Label on top of the input
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * True if the label should be centered above the input, defaults to false
   */
  withLabelCentered?: boolean;

  /**
   * Will show an error text under the input.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Make the input read-only.
   */
  readOnly?: boolean;
  
  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean;
  
  /**
   * Minimum value (inclusive) of the input before the decrement button is disabled
   * Defaults to 0;
   */
  min?: number;

  /**
   * Maximum value (inclusive) of the input before the increment button is disabled
   */
  max?: number;

  /**
   * True if the input should allow for the user to type within the input component.
   * Defaults to true.
   */
  allowTyping?: boolean;

  /**
   * Disable the input
   */
  disabled?: boolean;
};

const props = withDefaults(defineProps<CmcCounterProps>(), {
  inheritReadOnly: true,
  allowTyping: true,
  withLabelCentered: false,
  min: 0,
});

const disableTextInput = computed(() => {
  return props.disabled || !props.allowTyping;
})
const disableIncrement = computed(() => {
  const currentValue = Number(props.modelValue);
  if (!isNaN(currentValue) && (props.max !== undefined)) {
    return (currentValue >= props.max) || props.disabled;
  }
  return props.disabled;
})
const disableDecrement = computed(() => {
  const currentValue = Number(props.modelValue);
  if (!isNaN(currentValue) && (props.min !== undefined)) {
    return (currentValue <= props.min) || props.disabled;
  }
  return props.disabled;
})

const emit = defineEmits<{
  /**
   * Emitted when input is changed.
   * @arg text value
   */
   (event: 'update:modelValue', str: string): void
  
   /**
   * On click of the input
   */
  (event: 'click', e: Event): void

  /**
   * On focus of the input
   */
  (event: 'focus', e: Event): void

  /**
   * On blur of the input
   */
  (event: 'blur', e: Event): void
}>()

const updateValue = (v: string) => {
  emit('update:modelValue', v);
}
const incrementCounter = (v: number) => {
  const updatedCount = Number(props.modelValue) + v;
  emit('update:modelValue', String(updatedCount));
}
</script>

<style scoped lang="scss">
.cmc-counter {
  max-width: 24rem;
}
.cmc-counter-inputs {
  :deep(input) {
    text-align: center;
    border-radius: 0px !important;
    border-right-width: 0px !important;
    border-left-width: 0px !important;
  }
  :deep(button) {
    border-radius: 0.1875rem;

    &:first-child {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
    &:last-child {
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 0rem;
    }
  }
  &.cmc-counter-disabled {
    :deep(input) {
      color: var(--ng-text-description) !important;
    }
  }
}
</style>