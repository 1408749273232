<template>
  <base-tooltip
    v-if="tooltip"
    direction="bottom"
    :message="tooltip"
  >
    <div class="badge">
      <span
        class="state"
        :class="[color, { animate }]"
      ></span>
      <span class="title">{{ $t(state, interpolation) }}</span>
    </div>
  </base-tooltip>
  <div
    v-else
    class="badge"
  >
    <span
      class="state"
      :class="[color, { animate }]"
    ></span>
    <span class="title">{{ $t(state, interpolation) }}</span>
  </div>
</template>

<script>

export default {
  name: 'BaseBadge',
  props: {
    /**
     * Display text of the badge
     */
    state: {
      type: String,
    },
    /**
     * Color of the badge
     */
    color: {
      type: String,
    },
    /**
     * Badge will have an animation to imply progress
     */
    animate: {
      type: Boolean,
    },
    /**
     * Add tooltip to badge.
     */
    tooltip: {
      type: String,
    },
    /**
     * the entity to execute interpolation on
     */
    interpolation: {
      type: Object,
      required: false,
      default: () => ({})
    },
  },
  data() {
    return {};
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/animations.scss';

.badge {
  padding: .2em .9em .3em .7em;
  white-space: nowrap;
  border: 1px solid #d2d2d2;
  border-radius: 1em;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    vertical-align: middle;
  }

  .state {
    border-radius: 50%;
    display: inline-block;
    width: .8em;
    height: .8em;
    margin-right: .5em;
    line-height: .8em;

    &.primary {
      background-color: var(--primary);
    }

    &.primary-dark {
      background-color: var(--primary-dark);
    }

    &.primary-light {
      background-color: var(--primary-light);
    }
  }

  .animate {
    animation: blink 2s infinite;
  }
}

</style>
