<template>
  <cmc-block
    id="cmc-breadcrumb-container"
    as-inline
    without-wrap
  >
    <cmc-group
      v-for="(item, index) in visibleCrumbsWithPreviousQuery"
      :key="item.label"
      with-vertical-align="center"
      spacing="2xs"
    >
      <cmc-icon
        v-if="item.icon"
        :icon="item.icon"
        :svg="item.iconWithSvg"
        :img="item.iconWithImg"
      />
      <cmc-block 
        v-if="item.path"
        as-inline 
        padding-left="xs"
      >
        <cmc-app-link
          :to="item.path"
        >
          <cmc-text
            :text="item.label"
            :with-i18n="item.withI18n"
            :interpolation="item.interpolation"
            without-wrap
            :max-chars="MAX_CHARACTER_LIMIT"
            size="l"
          />
        </cmc-app-link>
      </cmc-block>
      <cmc-block
        v-else-if="item.label === '...'"
        id="cmc-breadcrumb-hidden-crumb-wrapper"
        key="item.label"
      >
        <cmc-text
          as-clickable
          :text="item.label"
          @click="setOpenMenu"
        />
        <cmc-block
          v-if="openMenu"
          v-click-outside="setOpenMenu"
          class="cmc-breadcrumb-menu"
          padding="s"
        >
          <cmc-stack>
            <cmc-block
              v-for="(hiddenCrumb, idx) in hiddenCrumbsWithPreviousQuery"
              :key="idx"
            >
              <cmc-app-link
                v-if="hiddenCrumb.path"
                :to="hiddenCrumb.path"
              >
                <cmc-text
                  :text="hiddenCrumb.label"
                  :with-i18n="hiddenCrumb.withI18n"
                  :interpolation="hiddenCrumb.interpolation"
                  size="l"
                />
              </cmc-app-link>
              <cmc-text
                v-else
                :text="hiddenCrumb.label"
                :with-i18n="hiddenCrumb.withI18n"
                :interpolation="hiddenCrumb.interpolation"
                without-wrap
                padding-left="2xs"
                size="l"
              />
            </cmc-block>
          </cmc-stack>
        </cmc-block>
      </cmc-block>
      <cmc-block
        v-else
        paddingLeft="xs"
      >
        <cmc-text
          :text="item.label"
          :with-i18n="item.withI18n"
          :interpolation="item.interpolation"
          without-wrap
          padding-left="2xs"
          size="l"
        />
      </cmc-block>
      <cmc-icon
        v-if="shouldRenderChevron(index)"
        icon="chevron-right"
        svg
        size="s"
      />
    </cmc-group>
  </cmc-block>
</template>
  
<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/utils';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcAppLink from './CmcAppLink.vue';
import { BreadCrumbItem } from './types';

const store = useStore();


type Props = {
  /**
   * Breadcrumbs that are required for the page.
   */
  items: BreadCrumbItem[];
}
const props = withDefaults(defineProps<Props>(), {
})

const visibleBreadcrumbs = ref<BreadCrumbItem[]>(props.items);
const hiddenBreadcrumbs = ref<BreadCrumbItem[]>([]);
const menuButtonBreadcrumbItem: BreadCrumbItem = {
  label: '...',
  withI18n: false
}
const openMenu = ref(false);
const MAX_CHARACTER_LIMIT = 35;
const BREADCRUMB_CONTAINER_PADDING = 64;
const HIDDEN_CRUMBS_BUTTON_WIDTH = 30.64;
const CHEVRON_ICON_WIDTH = 20;

const visibleCrumbsWithPreviousQuery = computed(() => {
  const adaptedCrumbs: BreadCrumbItem[] = visibleBreadcrumbs.value;
  return toWithPreviousQuery(adaptedCrumbs);
})

const hiddenCrumbsWithPreviousQuery = computed(() => {
  const adaptedCrumbs: BreadCrumbItem[] = hiddenBreadcrumbs.value;
  return toWithPreviousQuery(adaptedCrumbs);
})

const toWithPreviousQuery = (crumbs: BreadCrumbItem[]) => {
  return crumbs.map(c => {
    if (typeof c.path === 'object' && 'name' in c.path && c.path.name === (store.getters.previousRoute || {}).name) {
      c.path.query = {
      ...store.getters.previousRoute.query,
      ...c.path.query,
      };
    }
    return c;
  })
}

const shouldRenderChevron = ((index: number) => {
  return index !== visibleBreadcrumbs.value.length - 1;
})

onMounted(() => {
  calculateRenderableCrumbs(true);
  window.addEventListener('resize', debouncedCrumbCalculation);
})

const debouncedCrumbCalculation = debounce(() => {
  calculateRenderableCrumbs(false);
}, 200)

const setOpenMenu = () => {
  openMenu.value = !openMenu.value;
}

const calculateRenderableCrumbs = (isMounted: boolean) => {
  const breadcrumbContainer = document.getElementById('cmc-breadcrumb-container');
  const parentElement = breadcrumbContainer?.parentElement;

  let breadCrumbElementsToRender = 0;

  if (breadcrumbContainer && parentElement) {
      // Add this width to account for the chevron icon width that is not calculated properly on mount due to the icon not being rendered yet
  const chevronIconWidth = isMounted ? CHEVRON_ICON_WIDTH : 0;

    const breadcrumbGroups = breadcrumbContainer.querySelectorAll('#cmc-breadcrumb-container > .cmc-group');
    let alreadyRenderedWidth = breadcrumbGroups[0].offsetWidth + HIDDEN_CRUMBS_BUTTON_WIDTH + chevronIconWidth * 2;

    for (let i = breadcrumbGroups.length - 1; i >= 0; i--) {
      const crumb = breadcrumbGroups[i];
      if (alreadyRenderedWidth + crumb.offsetWidth + chevronIconWidth <= parentElement.offsetWidth - BREADCRUMB_CONTAINER_PADDING) {
        breadCrumbElementsToRender += 1;
        alreadyRenderedWidth += crumb.offsetWidth;
      }
    }
    setCrumbs(breadCrumbElementsToRender);
  }
}

const setCrumbs = (breadCrumbElementsToRender: number) => {
  if (breadCrumbElementsToRender === props.items.length) {
    visibleBreadcrumbs.value = props.items;
    hiddenBreadcrumbs.value = [];
    return;
  }

  const crumbsToRender = [];
  const crumbsToHide: BreadCrumbItem[] = []

  crumbsToRender.push(props.items[0]);
  crumbsToRender.push(menuButtonBreadcrumbItem);

  props.items.map((item, index) => {
    if (index > props.items.length - breadCrumbElementsToRender) {
      crumbsToRender.push(item);
    } else if (index > 0) {
      crumbsToHide.push(item);
    }
    return item;
  })
  visibleBreadcrumbs.value = crumbsToRender;
  hiddenBreadcrumbs.value = crumbsToHide;
}
</script>

<style scoped>
.cmc-breadcrumb-menu {
  position: absolute;
  background-color: white;
  border: 0.063rem solid var(--ng-secondary-border);
  border-radius: var(--ng-border-radius);
  box-shadow: var(--ng-box-shadow);
  z-index: 100;
  padding: var(--ng-spacing-2xs);
  margin-top: var(--ng-spacing-2xs);
  left: -0.875rem;
}

#cmc-breadcrumb-hidden-crumb-wrapper {
  position: relative;
}
</style>