import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

import { useRouter } from 'vue-router'
import CmcListRow from '@/components/nextgen/display/list/CmcListRow.vue';
import CmcListCol from '@/components/nextgen/display/list/CmcListCol.vue';
import CmcText from "@/components/nextgen/typography/CmcText.vue";
import CmcStack from "@/components/nextgen/layout/CmcStack.vue";
import CmcBlock from "@/components/nextgen/layout/CmcBlock.vue";
import { computed, defineComponent, onMounted, ref } from 'vue';
import { AssignedServiceConnection, BackendOrganization, OverrideStatus, Color, Parameter, Organization } from './types';
import { upperFirstCharOnly } from '@/utils/label';
import CmcServiceConnection from '@/components/nextgen/display/CmcServiceConnection.vue';
import CmcBadge from '@/components/nextgen/display/CmcBadge.vue';
import CmcStateBadge from '@/components/nextgen/display/CmcStateBadge.vue';
import CmcPair from '@/components/nextgen/layout/CmcPair.vue';
import authz from '@/authz';
import apis from '@/utils/apis';

type Props = {
  /**
   * Connection to be rendered 
   */
  connection: AssignedServiceConnection;

  /**
   * Whether the connection is assigned
   */
  assigned: boolean;
  /**
   * Name of the organization
   */
  organization: Organization;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectionAssignmentRow',
  props: {
    connection: {},
    assigned: { type: Boolean },
    organization: {}
  },
  setup(__props: any) {


defineComponent({
  CmcListRow, CmcListCol, CmcServiceConnection, CmcBadge
})

const router = useRouter();

const props = __props;
const quotaId = ref<string | undefined>(props.connection.quotaId);
const hasEditPermission = ref(false);

onMounted(async () => {
  await Promise.all([
    setCanEdit()
  ])
});

const reachable = computed<boolean>(() => {
  return (props.connection.status && props.connection.status.reachable);
});

const quotaName = computed<string>(() => {
  return upperFirstCharOnly(props.connection.quotas?.find((q: { id: string | undefined; }) => q.id === quotaId.value)?.name || '');
}) 

const assignedOrganization = computed<BackendOrganization>(() => props.connection.assignedOrganization);

const assignedOrganizationName = computed<string>(() => props.connection.assignedOrganization?.name || '');

const hasAssignedOrganization = computed<boolean>(() => !!assignedOrganization.value);

const overrideStatus = computed<OverrideStatus>(() => props.connection.overrideStatus || {});

const shouldOverrideStatus = computed<boolean>(() => !!(props.connection.overrideStatus?.statusLabel || props.connection.overrideStatus?.statusColor))

const connectionTooltipMessage = computed<string>(() => {
  if (!reachable.value) {
    return 'unreachable';
  }

  if (shouldOverrideStatus.value) {
    return (props.connection.overrideStatus?.statusLabel) || 'connected';
  }
  return 'connected';
})

const connectionTooltipColor = computed<Color>(() => {
  if (!reachable.value) {
    return 'red';
  }
  if (shouldOverrideStatus.value && overrideStatus.value.statusColor) {
    const colorToOverride = overrideStatus.value.statusColor?.toLocaleLowerCase() || 'green';
    if (['green', 'red', 'yellow', 'blue', 'grey'].includes(colorToOverride)) {
      return colorToOverride as Color;
    }
  }
  return 'green';
})

const params = computed<Parameter[]>(() => {
  let paramList = [];
  for(const [, value] of Object.entries(props.connection.assignedParameters || {})) {
    for (const [k, v] of Object.entries(value)) {
      paramList.push({
        label: k,
        value: v
      });
    }
  }
  return paramList;
})

const detailsDataPresent = computed<boolean>(() => {
  return quotaName.value !== '' || assignedOrganizationName.value !== '' || params.value.length > 0;
})

function handleEditConnection(connectionId: string, quotaId: string): void {
  router.push({ name: 'editAssignedConnection',params: { connectionId }, query: { isEdit: 'true', quotaId }});
}

async function setCanEdit() {
  const orgPermissions = await apis.users.getEffectivePermissions(props.organization.id);
  const allPermissions = orgPermissions ? [
    ...(orgPermissions.system || []),
    ...(orgPermissions.subs || []),
    ...(orgPermissions.service || []),
    ...(orgPermissions.global || [])
  ]
    : [];

  hasEditPermission.value = authz.hasPermission('reseller:connections') ||
    allPermissions.some(p => p === 'reseller:connections');
}


return (_ctx: any,_cache: any) => {
  const _component_cmc_icon = _resolveComponent("cmc-icon")!

  return (props.assigned)
    ? (_openBlock(), _createBlock(CmcListRow, { key: 0 }, {
        "action-rhs": _withCtx(() => [
          (hasEditPermission.value)
            ? (_openBlock(), _createBlock(_component_cmc_icon, {
                key: 0,
                icon: "edit",
                svg: "",
                "with-clickable": "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (handleEditConnection(props.connection.id, props.connection.quotaId || '')))
              }))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(CmcListCol, null, {
            default: _withCtx(() => [
              _createVNode(CmcServiceConnection, {
                name: props.connection.name,
                type: props.connection.type
              }, null, 8, ["name", "type"])
            ]),
            _: 1
          }),
          _createVNode(CmcListCol, null, {
            default: _withCtx(() => [
              _createVNode(CmcBadge, {
                "with-transparent": "",
                text: connectionTooltipMessage.value,
                "with-i18n": "",
                color: connectionTooltipColor.value
              }, null, 8, ["text", "color"])
            ]),
            _: 1
          }),
          _createVNode(CmcListCol, null, {
            default: _withCtx(() => [
              _createVNode(CmcStateBadge, {
                state: _ctx.connection.state,
                "override-state": overrideStatus.value.statusLabel,
                "override-state-color": overrideStatus.value.statusColor,
                "with-transparent": ""
              }, null, 8, ["state", "override-state", "override-state-color"])
            ]),
            _: 1
          }),
          _createVNode(CmcListCol, null, {
            default: _withCtx(() => [
              (detailsDataPresent.value)
                ? (_openBlock(), _createBlock(CmcBlock, {
                    key: 0,
                    "padding-top": "3xs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcStack, { spacing: "none" }, {
                        default: _withCtx(() => [
                          (quotaName.value !== '')
                            ? (_openBlock(), _createBlock(CmcBlock, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(CmcPair, {
                                    "stretch-rhs": "",
                                    spacing: "3xs"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(CmcText, {
                                        text: "quota",
                                        "with-i18n": "",
                                        "as-description": ""
                                      }),
                                      _createVNode(CmcText, { text: quotaName.value }, null, 8, ["text"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (hasAssignedOrganization.value)
                            ? (_openBlock(), _createBlock(CmcBlock, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createVNode(CmcPair, {
                                    "stretch-rhs": "",
                                    spacing: "3xs"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(CmcText, {
                                        text: "linked_to_service_organization",
                                        "with-i18n": "",
                                        "as-description": ""
                                      }),
                                      _createVNode(CmcText, { text: assignedOrganizationName.value }, null, 8, ["text"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(params.value, (param) => {
                            return (_openBlock(), _createBlock(CmcBlock, {
                              key: `${param.label}`
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcPair, {
                                  "stretch-rhs": "",
                                  spacing: "3xs"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(CmcText, {
                                      text: param.label,
                                      "with-i18n": "",
                                      "as-description": ""
                                    }, null, 8, ["text"]),
                                    _createVNode(CmcText, {
                                      text: param.value
                                    }, null, 8, ["text"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(CmcBlock, { key: 1 }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" - ")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})