<template>
  <cmc-block
    class="cmc-page"
    with-page-bg
    padding-horizontal="m"
    padding-bottom="m"
  >
    <!-- This is a stack of cmc-card components containing breadcrumb, title card, secondary tabs and content-->
    <cmc-stack spacing="xs">
      <!--If breadcrumb exists on the page-->
      <cmc-card
        v-if="breadcrumb"
        id="cmc-breadcrumb-card"
        padding-vertical="s"
        :with-collapse="false"
        padding-horizontal="2xl"
      >
        <cmc-breadcrumb :items="breadcrumb" />
      </cmc-card>
      <!--Card with title, description, primary tabs and slots for more components-->
      <cmc-card
        v-if="title || description || primaryTabs"
        :class="{'no-tabs-bottom-padding': !primaryTabs}"
        padding-vertical="m"
        :with-collapse="false"
        padding-horizontal="2xl"
        padding-top="m"
      >
        <cmc-block>
          <cmc-stack spacing="none">
            <cmc-group
              v-if="!description"
              with-vertical-align="center"
              spacing="s"
            >
              <cmc-icon
                v-if="!!props.icon"
                :icon="props.icon"
                :svg="props.withSvg"
                :custom-size="2.75"
                class="cmc-page-icon"
              />
              <cmc-title
                class="cmc-title-colour"
                heading="h1"
                :title="title"
                :with-i18n="withTitleI18n"
                with-bold
              />
            </cmc-group>
            <cmc-group
              v-else
              with-vertical-align="bottom"
              spacing="s"
            >
              <cmc-icon
                v-if="!!props.icon"
                class="cmc-page-icon"
                :icon="props.icon"
                :svg="props.withSvg"
                :custom-size="2.75"
              />
              <cmc-stack spacing="4xs">
                <cmc-title
                  class="cmc-title-colour"
                  heading="h1"
                  :title="title"
                  :with-i18n="withTitleI18n"
                  with-bold
                />
                <cmc-text
                  v-if="description"
                  class="cmc-description-colour"
                  as-label
                  size="m"
                  :text="description"
                  :with-i18n="withDescriptionI18n"
                />
              </cmc-stack>
            </cmc-group>
            <cmc-block
              v-if="primaryTabs"
              :padding-top="paddingTopForTitleSlot"
            >
              <div class="slot-container">
                <slot
                  name="title-card-left-slot"
                ></slot>
                <cmc-tabs
                  :tabs="primaryTabs"
                  :active-tab="primaryActiveTab"
                  @change="updatePrimaryActiveTab"
                />
                <slot
                  name="title-card-right-slot"
                ></slot>
              </div>
            </cmc-block>
          </cmc-stack>
        </cmc-block>
      </cmc-card>
      <!--Card with second level of tab navigation (if exists)-->
      <cmc-card
        v-if="nestedTabsExist"
        padding-vertical="m"
        padding-horizontal="2xl"
        :with-collapse="false"
      >
        <cmc-tabs
          :tabs="availableSecondaryTabs"
          :active-tab="secondaryActiveTab"
          @change="updateSecondaryActiveTab"
        />
      </cmc-card>
      <!--content-->
      <slot></slot>
    </cmc-stack>
  </cmc-block>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcCard from '../display/CmcCard.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcTabs from '../navigation/CmcTabs.vue';
import {Tab} from '../navigation/types';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcBreadcrumb from '../navigation/CmcBreadcrumb.vue';
import {BreadCrumbItem} from '../navigation/types';
import { Size } from '../sizes';

type SecondaryTabs = {
  item: Tab;
  parentKey: string;
}

type Props = {
    /**
     * Header title of the page.
     */
    title?: string;
    /**
     * Icon in the header title of the page.
     */
    icon?: string;
    /**
     * If the icon in the header title of the page is an svg.
     */
     withSvg?: boolean
    /**
     * True if the header title is a label key to be translated.
     */
    withTitleI18n?: boolean;
    /**
     * Header description of the page.
     */
    description?: string;
    /**
     * True if the header description is a label key to be translated.
     */
    withDescriptionI18n?: boolean

    /**
     * Breadcrumbs that are required for the page.
     */
    breadcrumb?: BreadCrumbItem[];

    /**
     * List of navigation tabs if required.
     */
    primaryTabs?: Tab[];

    /**
     * List of navigation tabs that are nested inside a specific primary tab.
     */
    secondaryTabs? : SecondaryTabs[];

    /**
     * Adds required top padding to the container with the exposed slots on the title card. (to adjust space between description and the slot row)
     */
     paddingTopForTitleSlot? : Size;

     /**
      * By default, the first tab in the list is selected, however if we want to set the value based on query param, then use this option.
      */
     withQueryParamOnTabNavigation? : boolean;
}

const route = useRoute();
const router = useRouter();

const props = withDefaults(defineProps<Props>(), {
  withSvg: true,
  paddingTopForTitleSlot: 'xl'
})
const getDefaultTab = () => {
  if(props.withQueryParamOnTabNavigation) {
    return route.query.activeTab || '';
  } else {
    return props.primaryTabs? props.primaryTabs[0].key : ''
  }
}

const primaryActiveTab = ref(getDefaultTab())
const secondaryActiveTab = ref('')

const updatePrimaryActiveTab = (tabKey: string) => {
    primaryActiveTab.value = tabKey;
    secondaryActiveTab.value = availableSecondaryTabs.value.length > 0 ? availableSecondaryTabs.value[0].key : '';
    emit('update:primaryActiveTab', primaryActiveTab.value);
}

const updateSecondaryActiveTab = (tabKey: string) => {
    secondaryActiveTab.value = tabKey;
    emit('update:secondaryActiveTab', secondaryActiveTab.value);
}

const availableSecondaryTabs = computed(() => {
  return props.secondaryTabs?.filter((tab) => tab.parentKey === primaryActiveTab.value).map((tab) => tab.item) || []
});

const nestedTabsExist = computed(() => {
  return props.secondaryTabs?.some((tab) => tab.parentKey === primaryActiveTab.value) || false;
});

const toNavigation = computed(() => {
  return props.primaryTabs?.every(tab => tab.to);
});

onMounted(() => {
  if (toNavigation.value) {
    const curTab = props.primaryTabs?.find(tab => {
      if (tab.to) {
        const resolved = router.resolve(tab.to);
        return route.path.startsWith(resolved.fullPath)
      }
      return false;
    });
    primaryActiveTab.value = curTab?.key ? curTab?.key : primaryActiveTab.value;
    if (nestedTabsExist.value) {
      const curSecondaryTab = props.secondaryTabs?.find(tab => route.path.startsWith(router.resolve(tab.item.to || '').fullPath));
      secondaryActiveTab.value = curSecondaryTab?.item.key ? curSecondaryTab?.item.key : availableSecondaryTabs.value[0].key;
    }
  }
});

const emit = defineEmits<{
  /**
   * Emitted when primary navigation tab is updated
   */
  (event: 'update:primaryActiveTab', primaryActiveTab: string): void,

  /**
   * Emitted when secondary navigation tab is updated
   */
   (event: 'update:secondaryActiveTab', secondaryActiveTab: string): void

}>()
</script>

<style lang="scss" scoped>
@use '@/styles/mixins.scss';

.no-tabs-bottom-padding {
  :deep(.cmc-card-content) {
    padding-bottom: 4.25rem;
  }
}

.cmc-title-colour {
  color: var(--ng-text-main);
}

.cmc-description-colour {
  color: var(--ng-text-header);
}

.cmc-page-icon {
  color: var(--ng-primary-main);
}

.cmc-page{
  @include mixins.phone {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0.5rem !important;
  }
}
.slot-container {
  display: flex;
  align-items: flex-end;
}
</style>
