import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, defineComponent, onMounted, ref } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcButton from '../buttons/CmcButton.vue';
import CmcText from '../typography/CmcText.vue';
import CmcSelect from '../inputs/CmcSelect.vue';

type Props = {
  /**
   * The page number selected. Defaults to the first page
   */
  currentPage?: number;

  /**
   * Total records displayed in paginable list
   */
  totalRecords: number;

  /**
   * The possible numbers of rows to display per page
   */
  options?: number[],   
}


const PAGE_SELECTED = "pageSelected";
const PAGE_SIZE_SELECTED = "pageSizeSelected"


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcPagination',
  props: {
    currentPage: { default: 1 },
    totalRecords: {},
    options: { default: () => [5, 10, 25, 50, 100, 250] }
  },
  emits: ["pageSelected", "pageSizeSelected"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcBlock, CmcGrid, CmcGridCol, CmcButton, CmcText, CmcSelect, CmcAlign
})

const props = __props

const page = ref(props.currentPage);
const pageSize = ref(getPageSize());

const startIndex = computed(() => (page.value - 1) * pageSize.value + 1);

const endIndex = computed(() => {
  const end = page.value * pageSize.value;
  return end > props.totalRecords ? props.totalRecords : end;
})

const totalPages = computed(() => Math.ceil(props.totalRecords / pageSize.value));
const pageSizeOptions = computed(() => {
  return props.options.map((o) => {
    return {
      value: o,
      label: `${o}`,
      withLabelI18n: false,
      detail: 'pagination.items',
      withDetailI18n: true,
      hideDetailInOptions: true,
    };
  });
});

const emit = __emit

onMounted(updatePageSize);

function previousPage() {
  if (page.value > 1) {
    page.value--;
    emit(PAGE_SELECTED, page.value);
  }
}

function nextPage() {
  if (page.value < totalPages.value) {
    page.value++;
    emit(PAGE_SELECTED, page.value);
  }
}

function updatePageSize() {
  page.value = 1;
  localStorage.setItem('pageSize', pageSize.value.toString(10));
  emit(PAGE_SELECTED, page.value);
  emit(PAGE_SIZE_SELECTED, pageSize.value);
}

function getPageSize() : number {
  let pageSize = localStorage.getItem('pageSize');
  if (pageSize === null) {
    return 5;
  }
  return parseInt(pageSize, 10);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, {
    class: "cmc-pagination-container",
    "padding-horizontal": "2xl",
    "padding-vertical": "m"
  }, {
    default: _withCtx(() => [
      _createVNode(CmcGroup, {
        "take-full-width": "",
        "with-vertical-align": "center"
      }, {
        default: _withCtx(() => [
          _createVNode(CmcGrid, null, {
            default: _withCtx(() => [
              _createVNode(CmcGridCol, { u: "1-3" }),
              _createVNode(CmcGridCol, { u: "1-3" }, {
                default: _withCtx(() => [
                  _createVNode(CmcAlign, { "at-center": "" }, {
                    default: _withCtx(() => [
                      _createVNode(CmcGroup, {
                        spacing: "none",
                        "take-full-height": "",
                        "with-horizontal-align": "center",
                        class: "cmc-pagination"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcButton, {
                            id: "previous-page",
                            "lhs-icon": "chevron-bold-left",
                            "lhs-icon-svg": "",
                            "icon-size": "m",
                            disabled: page.value === 1,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (previousPage()))
                          }, null, 8, ["disabled"]),
                          _createVNode(CmcBlock, {
                            class: "cmc-pagination-text",
                            "with-border": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(CmcAlign, { "at-center": "" }, {
                                default: _withCtx(() => [
                                  _createVNode(CmcGroup, {
                                    "with-horizontal-align": 'center',
                                    "with-vertical-align": "center",
                                    spacing: "none"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(CmcText, {
                                        text: `${startIndex.value} - ${endIndex.value} `
                                      }, null, 8, ["text"]),
                                      _createVNode(CmcText, {
                                        text: `of ${_ctx.totalRecords}`,
                                        "as-description": ""
                                      }, null, 8, ["text"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(CmcButton, {
                            id: "next-page",
                            "lhs-icon": "chevron-bold-right",
                            "lhs-icon-svg": "",
                            "icon-size": "m",
                            disabled: page.value === totalPages.value,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (nextPage()))
                          }, null, 8, ["disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(CmcGridCol, { u: "1-3" }, {
                default: _withCtx(() => [
                  _createVNode(CmcBlock, { class: "pagination-size" }, {
                    default: _withCtx(() => [
                      _createVNode(CmcSelect, {
                        modelValue: pageSize.value,
                        "onUpdate:modelValue": [
                          _cache[2] || (_cache[2] = ($event: any) => ((pageSize).value = $event)),
                          updatePageSize
                        ],
                        options: pageSizeOptions.value,
                        "allow-empty": false,
                        "as-number": ""
                      }, null, 8, ["modelValue", "options"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})