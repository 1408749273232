import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue';
import { Codemirror } from 'vue-codemirror';
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@codemirror/lang-css';
import { json } from '@codemirror/lang-json';
import { xml } from '@codemirror/lang-xml';

import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import { LanguageSupport, StreamLanguage } from "@codemirror/language";
import { EditorLanguage } from '../enums/NextGenEnums';

// Map languages to their respective functions

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcCodeEditor',
  props: {
    modelValue: { default: '' },
    language: { default: EditorLanguage.YAML },
    readOnly: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const EditorLanguages: { [key in EditorLanguage]: () => LanguageSupport | StreamLanguage<unknown> } = {
  [EditorLanguage.YAML]: () => StreamLanguage.define(yaml),
  [EditorLanguage.JSON]: json,
  [EditorLanguage.XML]: xml,
  [EditorLanguage.JavaScript]: javascript,
  [EditorLanguage.CSS]: css,
  [EditorLanguage.HTML]: html,
};


const props = __props;

const emit = __emit;

const tabSize = ref(2);

const extensions = computed(() => {
  const languageFunction = EditorLanguages[props.language];
  return languageFunction ? [languageFunction()] : [];
});

const input = (e: string) => {
  if (!props.readOnly) {
    emit('update:modelValue', e);
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Codemirror), {
    modelValue: _ctx.modelValue,
    "tab-size": tabSize.value,
    extensions: extensions.value,
    disabled: _ctx.readOnly,
    class: _normalizeClass({ 'read-only': _ctx.readOnly } ),
    "onUpdate:modelValue": input
  }, null, 8, ["modelValue", "tab-size", "extensions", "disabled", "class"]))
}
}

})