<template>
  <advanced-details
    class="main-title"
    :loading="!loaded"
    titleLabel="api_docs_customization.customization.title"
  >
    <template #operations>
      <div class="operations">
        <action-icon
          v-if="!editMode"
          class="operation"
          icon="fa fa-edit"
          tooltipLabel="edit"
          expandOnHover
          clickable
          @click="initEditMode"
        />
      </div>
    </template>
    <div class="api-doc-alert">
      <alert-box
        v-if="!atleastOnePluginSelected"
        class="plugin-alert"
        label="api_docs_customization.alert_label"
        alert-type="INFO"
        :border="true"
      />
    </div>
    <base-form
      :disabled="hasError"
      :executing="executing"
      :hideCancel="!editMode"
      :hideSubmit="!editMode"
      class="code-editor"
      submitLabel="save"
      @cancel="cancel"
      @submit="submit"
    >
      <form-row
        label="api_docs_customization.customization.plugin_visibility"
        descriptionLabel="api_docs_customization.customization.plugin_visibility_description"
      >
        <tag-select
          placeholderLabel="api_docs_customization.customization.add_plugins"
          tagPlaceholderLabel="api_docs_customization.customization.press_enter"
          :modelValue="selectedPluginsList"
          :options="pluginOptions"
          :disabled="!editMode"
          @addTag="addPlugin"
          @removeTag="removePlugin"
        ></tag-select>
      </form-row>

      <form-row
        label="api_docs_customization.customization.content_title"
        descriptionLabel="api_docs_customization.customization.content_description"
        :error="validCustomization ? null : $t('invalid_json')"
      >
        <code-editor
          v-model="customization"
          :readOnly="!editMode"
          language="JSON"
        />
      </form-row>

      <confirm-modal
        v-if="leave"
        :open="confirmLeave"
        confirmLabel="discard"
        detailsLabel="api_docs_customization.leave_confirmation"
        headerIcon="fa fa-check-circle"
        headerLabel="api_docs_customization.leave_confirmation_title"
        @cancel="confirmLeave = false"
        @ok="leave"
      />
    </base-form>
  </advanced-details>
</template>

<script>
import apis from "@/utils/apis";
import notify from "@/utils/notify";
import { sortBy } from "@/utils";
import { resellerOrgNavigationMixin } from "@/mixins/resellerOrgNavigationGuard";
import { mapGetters } from "vuex";

export default {
  name: "CustomizeApiDocsView",
  components: {},
  mixins: [resellerOrgNavigationMixin],
  beforeRouteLeave(_to, _from, next) {
    if (!this.editMode) {
      next();
    } else {
      this.confirmLeave = true;
      this.leave = next;
    }
  },
  data() {
    return {
      executing: false,
      editMode: false,
      loaded: false,
      leave: null,
      confirmDelete: false,
      confirmLeave: false,
      selectedPlugins: [],
      customization: "",
      fetched: null,
      isUpdate: false,
    };
  },
  computed: {
    ...mapGetters([
      "globalProperties",
      "selectedOrganization",
      "serviceDescriptors",
    ]),
    pluginOptions() {
      return (this.serviceDescriptorsList || []).map((p) => ({
        ...p,
        disabled: !this.editMode,
      }));
    },
    selectedPluginsList() {
      return this.selectedPlugins || [];
    },
    atleastOnePluginSelected() {
      return this.selectedPlugins.length > 0;
    },
    serviceDescriptorsList() {
      let descriptors = Object.values(this.serviceDescriptors)
        .map((n) => ({
          name: `${n.type}.label.service_provider_name`,
          value: n.type,
          image: `/rest/plugins/${n.type}/logo`,
          is18n: true,
        }))
        .sort(sortBy((conn) => conn.type));
      return descriptors;
    },
    apiCustomizationBody() {
      return {
        ...this.fetched,
        organization: {
          id: this.selectedOrganization.id,
        },
        plugins: this.selectedPlugins,
        customization: this.customization || "",
      };
    },
    validCustomization() {
      if (
        this.customization === "" ||
        this.customization === null ||
        this.customization === undefined
      ) {
        return true;
      }
      try {
        const parsed = JSON.parse(this.customization);
        return parsed && typeof parsed === "object";
      } catch (_err) {
        return false;
      }
    },
    hasError() {
      return !this.validCustomization;
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.loaded = false;
      await Promise.all([this.fetchPluginCustomization()]);
      this.loaded = true;
    },
    async fetchPluginCustomization() {
      const resp = await apis.apiDocsCustomization.list({
        qs: { organization_id: this.selectedOrganization.id },
      });
      if (resp.status === 200) {
        const fetched = resp.data[0] || {};
        this.setData(fetched);
      }
    },
    async setData(fetched) {
      this.fetched = fetched;
      if (this.fetched) {
        this.selectedPlugins = this.fetched.plugins || [];
        this.customization =
          JSON.stringify(
            this.getCustomizationObjFromString(this.fetched.customization),
            null,
            2
          ) || "{}";
        if (this.fetched.id) {
          this.isUpdate = true;
        }
      }
    },
    getCustomizationObjFromString(str) {
      try {
        const parsed = JSON.parse(str);
        return parsed;
      } catch (_err) {
        return {};
      }
    },
    async submit() {
      this.executing = true;
      let resp = {};
      if (this.isUpdate) {
        resp = await this.doUpdate();
      } else {
        resp = await this.doCreate();
      }
      if (resp.status === 204 || resp.status === 200) {
        const fetched = resp.data;
        notify.success(
          this.isUpdate
            ? this.$t("api_docs_customization.update_notify")
            : this.$t("api_docs_customization.success_notify")
        );
        this.setData(fetched);
        this.executing = false;
        this.editMode = false;
      } else {
        this.executing = false;
        notify.error(this.$t("api_docs_customization.failure_notify"));
      }
    },
    async doUpdate() {
      const apiDocCustomizationBody = this.apiCustomizationBody;
      return apis.apiDocsCustomization.update(
        this.fetched.id,
        apiDocCustomizationBody
      );
    },
    async doCreate() {
      const apiDocCustomizationBody = this.apiCustomizationBody;
      return apis.apiDocsCustomization.create(apiDocCustomizationBody);
    },
    cancel() {
      this.selectedPlugins =
        this.fetched && this.fetched.plugins ? this.fetched.plugins : [];
      this.customization =
        this.fetched && this.fetched.customization
          ? this.fetched.customization
          : "";
      this.editMode = false;
    },
    initEditMode() {
      this.editMode = true;
    },
    addPlugin(plugin) {
      if (!this.selectedPlugins.includes(plugin)) {
        const p = [...this.selectedPlugins, plugin];
        this.selectedPlugins = p;
      }
    },
    removePlugin(plugin) {
      if (this.selectedPlugins.includes(plugin)) {
        const filered = this.selectedPlugins.filter((p) => p !== plugin);
        this.selectedPlugins = filered;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-title {
  padding-left: 5%;
}
.operations {
  display: flex;
  flex-direction: row;

  .operation {
    padding: 0px 50px 0px 0px;
  }
}

.api-doc-alert {
  padding-left: 5%;
}

.code-editor {
  width: 75%;
}

.plugin-alert {
  max-width: 500px
}
</style>
