import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

interface Props {
  /**
   * Make the divider vertical
   */
  vertical?: boolean
  /**
   * Will the divider appear on a primary background.
   */
  onPrimary?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcDivider',
  props: {
    vertical: { type: Boolean, default: false },
    onPrimary: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'cmc-divider',
      {
        'cmc-divider-on-primary': _ctx.onPrimary,
        'cmc-divider-horizontal': !_ctx.vertical, 
        'cmc-divider-vertical': _ctx.vertical,
      }])
  }, null, 2))
}
}

})