<template>
  <v-chart
    ref="chart"
    :class="['chart', {...htmlClass}]"
    :style="style"
    :option="option"
    autoresize
    @click="$emit('click', $event)"
    @legendselectchanged="$emit('legendSelectChanged', $event)"
  />
  <div
    ref="tooltipTemplateWrapper"
    class="cmc-chart-tooltip-template-wrapper"
  >
    <slot name="tooltip-template" />
  </div>
</template>

<script setup lang="ts">
/**
 * This is the basic chart component. It can be used directly to display a chart (<cmc-chart> </cmc-chart>),
 * or be extended by wrapping it with another more specific chart component.
 *
 * When used directly, the series type must be specified for each series.
 * The list of props is limited to basic configurations (series, tooltip, toolbox, ...).
 * More advanced configurations, accepting any native Echarts options can be added in the customOptions props.
 * These will be merged with the other configurations with high priority
 *
 * The tooltip-template slot allows you to configure the template for the tooltip.
 *   The template is written in html + vuejs (Can contain interpolation, directives like v-if, f-for...).
 *   the rendered html will be used as a template (formatter) for the tooltip.
 *   The variables {a}, {b}, {c},... are defined by ECharts and are used to display chart information.
 *   @see {@link https://echarts.apache.org/en/option.html#tooltip.formatter}
 *   Example:
 *   <cmc-chart>
 *     <template #tooltip-template>
 *       <div style="padding: 16px">
 *         <h3>{b}</h3>
 *         <div v-for="(s, idx) in args.series">
 *           {a{{idx}}} : &nbsp;&nbsp;&nbsp;&nbsp;<b style="float:right">{c{{idx}}}</b>
 *         </div>
 *       </div>
 *     </template>
 *   </cmc-bar-chart>
 *
 * @see CmcBarChart
 * @see CmcBarChart
 * @see CmcLineChart
 * @see CmcPieChart
 * @see {@link https://echarts.apache.org/en/option.html}
 */
import merge from 'deepmerge';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';

import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components';
import { computed, ref } from 'vue';
import {
  getColorHex,
  getTextStyle,
  GRAPH_COLORS,
  GraphTextStyle,
  EChartStyleOptions,
  GraphColor,
  useBrandingStyles,
  Styles,
} from '@/components/nextgen/charts/branding';
import { ECBasicOption, GridOption, LegendOption, TooltipOption } from 'echarts/types/dist/shared';
import { ToolboxOption } from 'echarts/types/src/component/toolbox/ToolboxModel';
import { EChartsOption } from 'echarts';
import { TextCommonOption } from 'echarts/types/src/util/types';

use([
  CanvasRenderer,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent
]);

interface CmcChartProps {

  /**
   * The list of chart series
   * @see {@link https://echarts.apache.org/en/option.html#series}
   */
  series?: any[];

  /**
   * The chart tooltip options
   * @see {@link https://echarts.apache.org/en/option.html#tooltip}
   */
  tooltip?: TooltipOption;

  /**
   * The chart legend options
   * @see {@link https://echarts.apache.org/en/option.html#legend}
   */
  legend?: LegendOption;

  /**
   * The chart grid options
   * @see {@link https://echarts.apache.org/en/option.html#grid}
   */
  grid?: GridOption;

  /**
   * The chart toolbox options
   * @see {@link https://echarts.apache.org/en/option.html#toolbox}
   */
  toolbox?: ToolboxOption;

  /**
   * The default colors used for the chart.
   * Specific colors can be specified for each series
   */
  colors?: GraphColor[];

  /**
   * The default text style used for the chart.
   */
  textStyle?: GraphTextStyle;

  /**
   * Additional set of various options that will be merged with the known ones
   * using customOptions, the view components that use charts
   * can override a chart's properties depending on use cases
   * @see {@link https://echarts.apache.org/en/option.html}
   */
  customOptions?: EChartsOption;

  /**
   * The inherited html classes from custom implementation
   */
  class?: Record<string, any>,

  /**
   * The inherited css inline styles from custom implementation
   */
  style?: Record<string, any>
}

/**
 * The props that are visible to the end user of the chart. They represent the props with style options
 * (colors, text style, etc. to be excluded) to only allow developers to style the chart
 * using the means provided (graphColor, GraphTextStyle, etc.) and thus maintain consistent branding.
 * Specific chart props (CmcBarChart, CmcPieChart,...) must inherit from these props
 */
export interface CmcChartVisibleProps extends CmcChartProps {
  /**
   * The chart tooltip options
   */
  tooltip?: Omit<TooltipOption, EChartStyleOptions>;

  /**
   * The chart legend options
   */
  legend? : Omit<LegendOption, EChartStyleOptions>;

  /**
   * The chart grid options
   */
  grid?   : Omit<GridOption, EChartStyleOptions>;

  /**
   * The chart toolbox options
   */
  toolbox?: Omit<ToolboxOption, EChartStyleOptions>;

  /**
   * The inherited html classes
   */
  class?  : Record<string, any>,

  /**
   * The inherited css inline styles
   */
  style?  : Record<string, any>
}

const styles: Styles = useBrandingStyles();

const props = withDefaults(defineProps<CmcChartProps>(), {
  tooltip: () => ({} as TooltipOption),
  legend: () => ({} as LegendOption),
  grid: () => ({} as GridOption),
  toolbox: () => ({
    show: true,
    feature: {
      saveAsImage: {
        show: true,
        title: 'save',
      },
    }
  } as ToolboxOption),
  colors: () => GRAPH_COLORS,
  textStyle: () => 'body-3',
  customOptions: () => ({} as EChartsOption),
  class: () => ({}),
  style: () => ({})
});

defineEmits(['click', 'legendSelectChanged']);

const option = computed(() => {
  let optn: ECBasicOption = {
    tooltip: {
      ...props.tooltip,
      ...(tooltipTemplate.value && {formatter: tooltipTemplate.value, className: 'cmc-chart-custom-tooltip'}),
    },
    legend: props.legend,
    grid: props.grid,
    toolbox: props.toolbox,
    series: props.series,
    color: props.colors.map(color => getColorHex(color, styles)),
    textStyle: getTextStyle(props.textStyle) as TextCommonOption
  };
  // merge the customOptions into optn to deeply override the fields
  optn = merge(optn, props.customOptions);
  return optn;
});

const chart = ref<InstanceType<typeof VChart>>();
const tooltipTemplateWrapper= ref<HTMLDivElement | null>()
const tooltipTemplate = computed(() => tooltipTemplateWrapper.value?.innerHTML)
const htmlClass = computed(() => props.class);

defineExpose({
  dispatchAction: (actionData: any) => chart.value?.dispatchAction(actionData),
  updateLegends: (selectedLegends: any) => {
    chart.value?.dispatchAction({
      type: 'legendToggleSelect',
      name: selectedLegends.name
    });
  },
  toggleAllLegends: (allLegends: string[], enable: boolean) => {
    allLegends.forEach((legend: string) => {
      chart.value?.dispatchAction({
        type: enable ? 'legendSelect' : 'legendUnSelect',
        name: legend,
      });
    });
  }
})

</script>

<style lang="scss" scoped>
.cmc-chart-tooltip-template-wrapper {
  display: none !important;
}

:deep(.cmc-chart-custom-tooltip) {
  padding: 0 !important;
  border: none !important;
}
</style>