import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["type", "value", "placeholder", "disabled"]

import { computed, defineComponent, ref } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import { useI18n } from 'vue-i18n';

type TextInputType = 'text' | 'password' | 'number';

export type CmcTextInputProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Type of the text input.
   */
  type?: TextInputType;

  /**
   * Value of the input
   */
  modelValue?: string;

  /**
   * Label on top of the input
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Set a placeholder in the input.
   */
  placeholder?: string;

  /**
   * True if the placeholder is an i18n label.
   */
  withPlaceholderI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Make the input read-only.
   */
  readOnly?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean

  /**
   * Will show a valid icon on the right-hand side.
   */
  withValid?: boolean;

  /**
   * Will show an error text under the input.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Disable the input
   */
  disabled?: boolean;

  /**
   * Should render the input as a search input.
   */
  asSearch?: boolean;

  /**
   * True if the input should allow decimal values when the input type is 'number'
   */
  decimal?: boolean;

  /**
   * Whether to show or hide the error status icon within the input when an error is displayed
   */
  hideErrorStatusIcon?: boolean;

  /**
   * True if the input is optional.
   */
  asOptional?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcTextInput',
  props: {
    id: {},
    type: { default: 'text' },
    modelValue: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    placeholder: {},
    withPlaceholderI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    withValid: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    disabled: { type: Boolean },
    asSearch: { type: Boolean },
    decimal: { type: Boolean, default: true },
    hideErrorStatusIcon: { type: Boolean, default: false },
    asOptional: { type: Boolean, default: false }
  },
  emits: ["update:modelValue", "click", "focus", "blur"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcStack,
  CmcText,
  CmcLabel,
  CmcIcon,
  CmcReadOnly,
})

const props = __props;

const showStatusIcon = computed(() => {
  return (props.withValid || (props.withErrorText && !props.hideErrorStatusIcon));
})

const emit = __emit

const { t } = useI18n()
const inputEl = ref<HTMLInputElement | null>(null);
const adaptedPlaceholder = computed(() => {
  if (props.asSearch && !props.placeholder) {
    return t('search')
  }
  if (props.placeholder) {
    return props.withPlaceholderI18n ? t(props.placeholder) : props.placeholder
  }
  return undefined;
})
const filterOnlyNumeric = (evt: KeyboardEvent) => {
  const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'e', 'E', '-'];
  if (!props.decimal) {
    keysAllowed.splice(keysAllowed.indexOf('.'), 1);
  }

  const keyPressed = evt.key;
  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-text-input-${_ctx.id}` : undefined,
    label: _ctx.label,
    "model-value": _ctx.modelValue,
    "with-label-i18n": _ctx.withLabelI18n,
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-sensitive": _ctx.type === 'password',
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        class: _normalizeClass([
        'cmc-text-input',
        { 'cmc-text-input-as-status-icon': showStatusIcon.value },
      ]),
        spacing: "3xs"
      }, {
        default: _withCtx(() => [
          _createVNode(CmcLabel, _mergeProps(props, { "as-header": "" }), null, 16),
          _createElementVNode("div", {
            class: _normalizeClass(['cmc-text-input-wrapper', {'cmc-text-input-wrapper-as-search': _ctx.asSearch}])
          }, [
            _renderSlot(_ctx.$slots, "lhs"),
            (_ctx.asSearch)
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 0,
                  icon: "search",
                  svg: "",
                  class: "search-icon"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("input", {
              ref_key: "inputEl",
              ref: inputEl,
              type: _ctx.type,
              value: _ctx.modelValue,
              placeholder: adaptedPlaceholder.value,
              disabled: _ctx.disabled,
              onInput: _cache[0] || (_cache[0] = ($event: any) => (emit('update:modelValue', ($event.target as HTMLInputElement)?.value, inputEl.value as HTMLInputElement))),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('click', $event))),
              onFocus: _cache[2] || (_cache[2] = ($event: any) => (emit('focus', $event))),
              onBlur: _cache[3] || (_cache[3] = ($event: any) => (emit('blur', $event))),
              onKeypress: _cache[4] || (_cache[4] = ($event: any) => (_ctx.type === 'number' ? filterOnlyNumeric($event) : () => null))
            }, null, 40, _hoisted_1),
            _renderSlot(_ctx.$slots, "rhs"),
            (showStatusIcon.value)
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 1,
                  class: "status-icon",
                  icon: _ctx.withValid ? 'check-circle' : 'times-circle',
                  color: _ctx.withValid ? 'green' : 'red',
                  size: "l"
                }, null, 8, ["icon", "color"]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.withErrorText)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 0,
                text: _ctx.withErrorText,
                "with-i18n": _ctx.withErrorTextI18n,
                "as-error": "",
                size: "m"
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["id", "label", "model-value", "with-label-i18n", "read-only", "inherit-read-only", "with-sensitive", "with-error-text", "with-error-text-i18n"]))
}
}

})