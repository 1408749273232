<template>
  <div 
    class="indicators" 
    :class="[size]"
  >
    <div>
      <span 
        v-for="l in allItems" 
        :key="l.value" 
        class="item-badge"
        :class="[ missingItem(l.value) ? 'gray': 'green' ]"
      >
        <base-icon :icon="missingItem(l.value) ? 'fa fa-times gray': 'fa fa-check green'" />
        {{ $t(l.label) }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseIndicator',
  props: {
    currentItems: {
      type: Object,
      default: () => {},
    },
    allItems: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator: v => ['sm', 'md', 'lg'].includes(v),
    },
  },
  methods: {
    missingItem(item) {
      return !this.currentItems[item];
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/styles/animations.scss';

.indicators {
  padding: 5px;
  padding-left:5px;

  .item-badge {
    padding-top: 10px;
    margin-right: 6px;
    font-size: 0.8rem
  }

  &.sm {
    .item-badge {
      font-size: 0.7rem;
    }
  }

  &.lg {
    .item-badge {
      font-size: 0.9rem;
    }
  }
}
</style>
