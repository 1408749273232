import { getPathWithQueryParams, debounce } from '@/utils';
import moment from 'moment-timezone';
import notify from '../notify';
import utils from '../httputils';

const INTERVAL_ACCESS_TOKEN_CHECK_IN_SECONDS = 1000 * 15;
const ACCESS_EXPIRE_BEFORE_IN_MINUTES = 2;
let accessTokenExpiration = null;

export const setAccessTokenExpiration = (expiration) => {
  if (expiration) {
    accessTokenExpiration = moment.utc(expiration);
  } else {
    accessTokenExpiration = null;
  }
};

export const restPath = (path, staticResource) => {
  if (staticResource) {
    return `/${path.startsWith('/') ? path.substring(1) : path}`;
  }
  return `/rest/${path.startsWith('/') ? path.substring(1) : path}`;
};
const debouncedError = debounce(notify.error, 1000);
async function toJson(resp, includeStatus = true) {
  const body = await resp.text();
  try {
    const json = JSON.parse(body);
    if (typeof json === 'string') {
      return { body: json, status: resp.status, ok: resp.ok };
    }
    if (!includeStatus) {
      return json;
    }
    if (Array.isArray(json)) {
      return json;
    }
    return { status: resp.status, ok: resp.ok, ...json };
  } catch (_e) {
    return { body, status: resp.status, ok: resp.ok };
  }
}

export async function fetchAccessToken() {
  const resp = await fetch('/rest/auth/tokens/refresh', {
    method: utils.HTTP_POST,
  });
  const body = await toJson(resp, true);
  if (resp.status === 200) {
    setAccessTokenExpiration(body.data.accessExpiration);
    return { logout: false, valid: true };
  } else if (resp.status === 401) {
    setAccessTokenExpiration(null);
    return { logout: true, valid: false };
  }
  return { logout: false, valid: false };
}

async function toBlob(resp, includeStatus = true) {
  const body = await resp.blob();
  if (!includeStatus) {
    return body;
  }
  return { body, status: resp.status, ok: resp.ok };
}

function getHeaders(options) {
  const headers = {
    [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON,
    [utils.CSRF_TOKEN_HEADER]: utils.getCsrfToken(),
    ...options.headers,
  };
  return headers;
}

export async function logout() {
  const options = {
    method: utils.HTTP_POST,
    headers: { [utils.CONTENT_TYPE_HEADER]: utils.URL_ENCODED },
  };
  const resp = await fetch(getPathWithQueryParams(utils.LOGOUT_EP, ''), { ...options });
  return toJson(resp, true);
}

export const refreshJob = (store) => {
  const shouldExpire = date => accessTokenExpiration
    && moment.utc().isAfter(moment.utc(date).subtract(ACCESS_EXPIRE_BEFORE_IN_MINUTES, 'minute'));
  setInterval(async () => {
    if (store.getters.loggedIn && shouldExpire(accessTokenExpiration)) {
      const result = await fetchAccessToken();
      if (result.logout) {
        logout();
        store.dispatch('sessionTimedOut');
      }
    }
  }, INTERVAL_ACCESS_TOKEN_CHECK_IN_SECONDS);
};

export default class Api {

  constructor() {
    this.head = this.fetchForMethod(utils.HTTP_HEAD);
    this.get = this.fetchForMethod(utils.HTTP_GET);
    this.post = this.fetchForMethod(utils.HTTP_POST);
    this.put = this.fetchForMethod(utils.HTTP_PUT);
    this.del = this.fetchForMethod(utils.HTTP_DELETE);
    this.patch = this.fetchForMethod(utils.HTTP_PATCH);  
  }

  use(store) {
    this.$store = store;
  }

  sfetch(path, options, handleTimeout = true, includeStatus = true) {
    if (this.$store.getters.sessionTimeout && handleTimeout) {
      return Promise.reject();
    }
    const headers = getHeaders(options);
    const addWindowId = !path.startsWith('/static') && !path.startsWith('/rest/i18n');
    const qs = {
      ...(addWindowId && { windowId: this.$store.getters.windowId }),
      v: 'v2',
      ...options.qs,
    };
    return fetch(getPathWithQueryParams(path, qs), {
      ...options,
      body: typeof options.body !== 'string' ? JSON.stringify(options.body) : options.body,
      credentials: 'include',
      headers,
    })
      .then((response) => {
        if (response.status === 504) {
          debouncedError('Unexpected network connectivity issue');
        }
        if (options && options.responseType === 'blob') {
          return toBlob(response, includeStatus);
        }
        return toJson(response, includeStatus);
      }).then((data) => {
        if (data.status === 401 && handleTimeout
          && this.$store.getters.loggedIn === true) {
          const invalidCredentials = (data.errors[0] || {}).errorCode === 'INVALID_CREDENTIALS';
          if (invalidCredentials) {
            logout();
            this.$store.dispatch('sessionTimedOut');
          }
        }
        return data;
      });
  }

  fetchForMethod(method) {
    return (path, options, handleTimeout, staticResource = false, includeStatus = true) => {
      const opts = { ...options, method };
      return this.sfetch(restPath(path, staticResource), opts, handleTimeout, includeStatus);
    };
  }
}

export class CrudApi extends Api {
  constructor(path) {
    super();
    this.path = path;
  }

  async list(options) {
    return this.get(this.path, options);
  }

  async find(id, options) {
    return this.get(`${this.path}/${id}`, options);
  }

  async create(obj, options) {
    return this.post(this.path, {
      headers: { [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON },
      body: JSON.stringify(obj),
      ...options,
    });
  }

  async update(id, obj, options) {
    return this.put(`${this.path}/${id}`, {
      headers: { [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON },
      body: JSON.stringify(obj),
      ...options,
    });
  }

  async patch(id, obj, options) {
    return this.patch(`${this.path}/${id}`, {
      headers: { [utils.CONTENT_TYPE_HEADER]: utils.APPLICATION_JSON },
      body: JSON.stringify(obj),
      ...options,
    });
  }

  async remove(id, options) {
    return this.del(`${this.path}/${id}`, options);
  }
}

export class ReadOnlyApi extends Api {
  constructor(path) {
    super();
    this.path = path;
  }

  async list(options) {
    return this.get(this.path, options);
  }

  async find(id, options) {
    return this.get(`${this.path}/${id}`, options);
  }
}
