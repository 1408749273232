import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { computed, defineComponent, ref } from 'vue';
import { useSlots } from 'vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcHeaderContent from './CmcHeaderContent.vue';
import { Size } from '../sizes';

type Props = {
  /**
   * HTML element id for the card element
   */
  id?: string;

  /**
   * Padding around the card body.
   */
  padding?: Size;

  /**
   * Vertical padding of the card body. Overrides `padding`.
   */
  paddingVertical?: Size;

  /**
   * Horizontal padding of the card body. Overrides `padding`.
   */
  paddingHorizontal?: Size;

  /**
   * Add padding on top of the block.
   */
  paddingTop?: Size;
  /**
   * Add padding on bottom of the block.
   */
  paddingBottom?: Size;
  
  /**
   * Header title of the card.
   */
  title?: string;
  /**
   * True if the header title is a label key to be translated.
   */
  withTitleI18n?: boolean;
  
  /**
   * Header category of the card.
   */
  category?: string;
  /**
   * True if the header category is a label key to be translated.
   */
  withCategoryI18n?: boolean;

  /**
   * Header description of the card.
   */
  description?: string;
  /**
   * True if the header description is a label key to be translated.
   */
  withDescriptionI18n?: boolean

  /**
   * True if card should be collapsible
   */
  withCollapse?: boolean;

  /**
   * Add a count badge in the header.
   */
  count?: number;

  /**
   * Simple look removes borders and box-shadows.
   */
  asSimple?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcCard',
  props: {
    id: {},
    padding: {},
    paddingVertical: {},
    paddingHorizontal: {},
    paddingTop: {},
    paddingBottom: {},
    title: {},
    withTitleI18n: { type: Boolean },
    category: {},
    withCategoryI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withCollapse: { type: Boolean, default: false },
    count: { default: 0 },
    asSimple: { type: Boolean }
  },
  setup(__props: any) {

const collapsed = ref(false)

defineComponent({
  CmcTitle, CmcText, CmcStack, CmcGroup, CmcBlock, CmcGrid, CmcGridCol, CmcHeaderContent, CmcPair, CmcAlign,
})

const props = __props;
const onHeaderClick = () => {
  if (props.withCollapse) {
    collapsed.value = !collapsed.value;
  }
};
const slots = useSlots();
const includeHeader = computed(() => {
  // Some Title might take a while to load, but we still want to include the header.
  if (props.title === '') {
    return true;
  }
  return !!props.title || !!slots['lhs-content'] || !!slots['rhs-content']
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id ? `cmc-card-${_ctx.id}` : undefined,
    class: _normalizeClass(['cmc-card', { 'cmc-card-as-simple': _ctx.asSimple}])
  }, [
    (includeHeader.value)
      ? (_openBlock(), _createBlock(CmcBlock, {
          key: 0,
          class: _normalizeClass(['cmc-card-header', {'cmc-card-header-with-collapse': _ctx.withCollapse}]),
          "padding-left": _ctx.withCollapse ? 's' : '2xl',
          "padding-right": "2xl",
          "padding-vertical": _ctx.category || _ctx.description ? 'l' : 'm',
          "with-header-bg": "",
          onClick: onHeaderClick
        }, {
          default: _withCtx(() => [
            _createVNode(CmcPair, { "stretch-lhs": "" }, {
              default: _withCtx(() => [
                _createVNode(CmcGroup, {
                  spacing: "3xs",
                  "with-vertical-align": "center"
                }, {
                  default: _withCtx(() => [
                    (_ctx.title || _ctx.description)
                      ? (_openBlock(), _createBlock(CmcStack, {
                          key: 0,
                          spacing: "3xs"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(CmcHeaderContent, {
                              title: _ctx.title,
                              "with-title-i18n": _ctx.withTitleI18n,
                              subtitle: _ctx.category,
                              "with-subtitle-i18n": _ctx.withCategoryI18n,
                              "with-collapse": _ctx.withCollapse,
                              collapsed: collapsed.value,
                              count: _ctx.count
                            }, null, 8, ["title", "with-title-i18n", "subtitle", "with-subtitle-i18n", "with-collapse", "collapsed", "count"]),
                            (_ctx.description)
                              ? (_openBlock(), _createBlock(CmcPair, {
                                  key: 0,
                                  "stretch-rhs": "",
                                  spacing: "4xs"
                                }, {
                                  default: _withCtx(() => [
                                    (_ctx.withCollapse)
                                      ? (_openBlock(), _createBlock(CmcBlock, {
                                          key: 0,
                                          "padding-left": "l"
                                        }))
                                      : _createCommentVNode("", true),
                                    _createVNode(CmcAlign, { "at-left": "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(CmcText, {
                                          text: _ctx.description,
                                          "with-i18n": _ctx.withDescriptionI18n,
                                          size: "m"
                                        }, null, 8, ["text", "with-i18n"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "lhs-content")
                  ]),
                  _: 3
                }),
                _createVNode(CmcGroup, {
                  "take-full-width": "",
                  "with-vertical-align": "center",
                  "with-horizontal-align": "right"
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "rhs-content")
                  ]),
                  _: 3
                })
              ]),
              _: 3
            })
          ]),
          _: 3
        }, 8, ["class", "padding-left", "padding-vertical"]))
      : _createCommentVNode("", true),
    _createVNode(CmcBlock, {
      padding: _ctx.padding,
      "padding-horizontal": _ctx.paddingHorizontal ? _ctx.paddingHorizontal : (_ctx.padding || '2xl'),
      "padding-vertical": _ctx.paddingVertical ? _ctx.paddingVertical : (_ctx.padding || 'xl'),
      "padding-top": _ctx.paddingTop,
      "padding-bottom": _ctx.paddingBottom,
      class: _normalizeClass(['cmc-card-content', {'cmc-card-content-collapsed': collapsed.value}])
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["padding", "padding-horizontal", "padding-vertical", "padding-top", "padding-bottom", "class"])
  ], 10, _hoisted_1))
}
}

})