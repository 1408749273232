<template>
  <cmc-block
    :class="['cmc-tag', `cmc-tag-${color}`, `cmc-tag-${size}`]"
    :padding-horizontal="horizontalPadding"
  >
    <cmc-align
      at-vertical-center
    >
      <cmc-group
        class="cmc-tag-inner"
        spacing="xs"
        with-vertical-align="center"
      >
        <cmc-text
          :text="text"
          :with-i18n="withI18n"
          :with-tooltip="withTooltip"
          :with-tooltip-i18n="withTooltipI18n"
          without-wrap
          :size="size"
        ></cmc-text>
        <cmc-icon
          v-if="withRemove"
          icon="times-bold"
          size="xs"
          svg
          @click="emit('remove', value)"
          @mousedown.prevent.stop
        ></cmc-icon>
      </cmc-group>
    </cmc-align>
  </cmc-block>
</template>

<script setup lang="ts">
import { defineComponent } from 'vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcText from '../typography/CmcText.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import { TagColor } from './types';
import { Size } from '../sizes';

type textSize = 's' | 'm' | 'l'

defineComponent({
  CmcText,
  CmcBlock,
  CmcGroup,
  CmcIcon,
  CmcAlign,
})

type Props = {
  /**
   * Identifier of the tag
   */
  value?: string;

  /**
   * Text to display in tag
   */
  text: string;

  /**
   * Should text be i18n
   */
  withI18n?: boolean;

  /**
   * Color of the tag
   */
  color?: TagColor;

  /**
   * Make the tag removable
   */
  withRemove?: boolean;

  /**
   * Size of text
   */
  size?: textSize

  /** 
   * Select size of horizontal padding within tag
   */
  horizontalPadding?: Size;

  /**
   * Tooltip text to be displayed
   */
  withTooltip?: string;

  /**
   * Tooltip text is an i18n label key
   */
  withTooltipI18n?: boolean;
}

withDefaults(defineProps<Props>(), {
  color: 'light-gray',
  horizontalPadding: 'xs'
})

const emit = defineEmits<{
  /**
   * When the remove tag is called
   */
  (event: 'remove', value?: string): void
}>()
</script>

<style scoped lang="scss">
.cmc-tag {
  border-radius: 0.125rem;
  user-select: none;
  display: inline-block;
  .cmc-tag-l {
    height: 1.5rem;
  }
  .cmc-tag-m {
    height: 1.25rem;
  }
  .cmc-tag-m {
    height: 1rem;
  }
  .cmc-tag-inner {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  &.cmc-tag-light-green {
    background-color: var(--ng-secondary-green);
  }
  &.cmc-tag-light-gray {
    background-color: var(--ng-secondary-grey);
  }
  .cmc-icon {
    cursor: pointer;
  }
}
</style>
