<template>
  <slot></slot>
  <cmc-modal></cmc-modal>
  <cmc-drawer></cmc-drawer>
  <cmc-footer></cmc-footer>
</template>

<script setup lang="ts">
import { defineComponent, provide, ref } from 'vue';
import CmcModal from './CmcModal.vue';
import CmcDrawer from './CmcDrawer.vue';
import CmcFooter from './CmcFooter.vue';

defineComponent({
  CmcModal,
  CmcDrawer,
  CmcFooter,
})

const initRefs = () => { 
  const component = ref()
  const props = ref()
  const parentProps = ref()
  const opened = ref(false)
  return {
    component,
    props,
    parentProps,
    opened,
    close: () => {
      opened.value = false;
      setTimeout(() => {
        component.value = null;
        props.value = undefined;  
      }, 100)
    },
  } 
}
const modal = initRefs()
const drawer = initRefs()
const footer = initRefs()

provide('cmc-modal', modal)
provide('cmc-drawer', drawer)
provide('cmc-footer', footer)

</script>
