import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, defineComponent } from 'vue'
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcCheckbox from './CmcCheckbox.vue'
import CmcStack from '../layout/CmcStack.vue'
import CmcReadOnly from './CmcReadOnly.vue'
import { useI18n } from 'vue-i18n';

type Option = {
  /**
   * HTML element id for the checkbox
   */
  id?: string;
  /**
   * Value of the option.
   */
  value: any;
  /**
   * Label of the option.
   */
  label: string;
  /**
   * True if the label of the option is i18n.
   */
   withI18n?: boolean;

   /**
    * Color of the checkbox in hexadecimal format.
    */
   color?: string;
}

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Values that are checked.
   */
  modelValue?: any[]

  /**
   * Options to offer in the checkbox.
   */
  options: Option[]

  /**
   * Label of the checkbox group.
   */
  label?: string

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the checkbox.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Should be display in read-only mode
   */
  readOnly?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean

  /**
   * Should the checkbox be disabled
   */
  disabled?: boolean;

  /**
   * Display checkboxes as toggle.
   */
  asToggle?: boolean
   /**
    * True if the optional
    */
    asOptional?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcCheckboxGroup',
  props: {
    id: {},
    modelValue: {},
    options: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    disabled: { type: Boolean },
    asToggle: { type: Boolean },
    asOptional: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcText, CmcCheckbox, CmcStack, CmcReadOnly, CmcLabel,
})

const props = __props

const checkedValues = computed(() => {
  return (props.modelValue || []).reduce((acc, cur) => {
    acc[cur] = true
    return acc
  }, {})
})

const emit = __emit

const updateChecked = (value: any, checked: boolean) => {
  if (checked) {
    return emit('update:modelValue', [...new Set([...(props.modelValue || []), value])])
  } else {
    return emit('update:modelValue', [...(props.modelValue || [])].filter(v => v !== value))
  }
}

const { t } = useI18n()
const valueLabels = computed(() => {
  return props.options.reduce((acc, opt) => {
    acc[opt.value] = opt.withI18n ? t(opt.label) : opt.label;
    return acc
  }, {} as { [key: string]: string })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-checkbox-group-${_ctx.id}` : undefined,
    label: _ctx.label,
    modelValue: _ctx.modelValue?.map(v => valueLabels.value[v]).join(', '),
    "with-label-i18n": _ctx.withLabelI18n,
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        class: "cmc-checkbox-group",
        spacing: "2xs"
      }, {
        default: _withCtx(() => [
          _createVNode(CmcStack, { spacing: "none" }, {
            default: _withCtx(() => [
              _createVNode(CmcLabel, _mergeProps(props, { "as-header": "" }), null, 16),
              (_ctx.withErrorText)
                ? (_openBlock(), _createBlock(CmcText, {
                    key: 0,
                    text: _ctx.withErrorText,
                    "with-i18n": _ctx.withErrorTextI18n,
                    "as-error": "",
                    size: "m"
                  }, null, 8, ["text", "with-i18n"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt) => {
            return (_openBlock(), _createBlock(CmcCheckbox, {
              id: opt.id,
              key: opt.value,
              label: opt.label,
              "with-label-i18n": opt.withI18n,
              "model-value": checkedValues.value[opt.value],
              disabled: _ctx.disabled,
              "checkbox-color": opt.color,
              reversed: "",
              "as-toggle": _ctx.asToggle,
              "as-group": "",
              large: "",
              "onUpdate:modelValue": ($event: any) => (updateChecked(opt.value, $event))
            }, null, 8, ["id", "label", "with-label-i18n", "model-value", "disabled", "checkbox-color", "as-toggle", "onUpdate:modelValue"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["id", "label", "modelValue", "with-label-i18n", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n"]))
}
}

})