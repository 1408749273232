<template>
  <div 
    class="cmc-dimmed" 
    @click="emit('click', $event)"
    @dblclick="emit('dblclick', $event)"
  ></div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  /**
   * Emitted when dimmed bg was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void

    /**
   * Emitted when dimmed bg was double clicked
   * @arg HTML click event
   */
   (event: 'dblclick', payload: any): void

}>()
</script>

<style scoped lang="scss">
.cmc-dimmed {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,.25);
  top: 0;
  left: 0;
}
</style>