<template>
  <div>
    <template v-if="ruleIsMandatory">
      <div class="rule">
        <span class="icons">
          <base-icon 
            v-show="ruleIsValid"
            icon="fa fa-check green" 
          />
          <base-icon 
            v-show="!ruleIsValid"
            icon="fa fa-times red" 
          />
        </span>
        <span class="text">
          {{ $t($t(ruleName.concat('_validation'), { number: ruleValue, characterSet: ruleText })) }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RuleValidation',
  props: {
    ruleIsMandatory: {
      type: Boolean,
    },
    ruleName: {
      type: String,
    },
    ruleValue: {
      type: Number,
    },
    ruleText: {
      type: String,
      default: '',
    },
    ruleRegex: {
      type: RegExp,
    },
    modelValue: {
      type: String,
    },
  },
  emits: ['rule-valid'],
  computed: {
    ruleIsValid() {
      if (this.modelValue) {
        const isValid = ((this.modelValue.match(this.ruleRegex) || []).length >= this.ruleValue);
        this.$emit('rule-valid', isValid);
        return isValid;
      }
      this.$emit('rule-valid', false);
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

  .rule {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .text {
    padding-left: 5px;
    text-align: left;
  }
  .icons {
    height: 12px;
    width: 12px;
    margin-right:5px;
  }
</style>
