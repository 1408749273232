import store from '@/store';
import moment from 'moment-timezone';
import i18n from '@/i18n';

moment.suppressDeprecationWarnings = true;

/** Default date formats */
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const ACTIVITY_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';
const LIST_SIMPLE_DATE_FORMAT = 'YYYY-MM-DD';
const SIMPLE_DATE_FORMAT = 'DD MMM YYYY';
const SCHEDULED_JOB_FIRE_TIME_FORMAT = 'ddd, MMM D YYYY LTS';
const HOUR_MIN_IN_12_FORMAT = 'hh:mm a';
const HOUR_MIN_IN_24_FORMAT = 'HH:mm';
const HOUR_IN_24_FORMAT = 'HH';
const MINUTES_FORMAT = 'mm';
const MONTH_NAME_YEAR_FORMAT = 'MMMM, YYYY';
const MONTH_DIGIT_YEAR_FORMAT = 'MM-YYYY';
const DATE_MONTH_FORMAT = 'DD MMM';
const DATE_MONTH_SHORT_YEAR_FORMAT = 'DD MMM \'YY';
const MONTH_YEAR_FORMAT = 'MMM YYYY';
const SHORT_MONTH = 'MMM';

/** Date functions */
const getUserDate = (date, utc = false) => {
  let mdate = moment(date);
  if (utc) {
    mdate = moment.utc(date);
  } else if (store.getters && store.getters.loggedIn) {
    if (store.getters.userContext.timezone) {
      mdate = moment.tz(date, store.getters.userContext.timezone);
    } else {
      mdate = moment(date);
    }
  }
  return mdate;
};

const shortMonth = (date) => {
  return moment(date).format(SHORT_MONTH);
}

const spansMultipleBillingCycles = (startDate, endDate, billingDay) => {
  const billingDate = billingDay < 1 || billingDay > 28 ? 1 : billingDay;
  let counter = 0;
  for (let dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
    if (dt.getDate() === billingDate) {
      counter += 1;
    }
  }
  return counter > 1;
};

const getDate = (date, utc = false) => {
  const userDate = getUserDate(date, utc);
  return userDate.isValid() ? userDate.toDate() : null;
};

const getTime = (date, utc = false, format = HOUR_MIN_IN_12_FORMAT) => {
  const userDate = getUserDate(date, utc);
  return userDate.isValid() ? userDate.format(format) : null;
};

// eslint-disable-next-line
const getNow = (utc = false) => getDate(new Date(), utc);
// eslint-disable-next-line
const getTimeNow = (utc = false) => getTime(new Date(), utc);

/**
 * Given a time string in the Local time format this method returns a time object with hours,
 * minutes and the period (AM/PM). If not parameter is passed the current time is generated.
 * @param {String} timeInLTFormat time string in the Local-time format [HH:mm a/p]
 */
const getTimeObj = (timeInLTFormat = getTimeNow(false)) => {
  const timeAndPeriod = timeInLTFormat.split(/(\s+)/).filter(e => e.trim().length > 0);
  const minuteAndHour = timeAndPeriod[0].split(':');
  return {
    hours: minuteAndHour[0],
    minutes: minuteAndHour[1],
    // to handle formats like 'p.m.' and make it like 'pm'
    period: timeAndPeriod[1] ? timeAndPeriod[1].split('.').join('') : undefined,
  };
};

/**
 * Given a time string in the Local time format, this method first converts it into its 24h format.
 * Then based on the 'utc' flag it returns the appropriate time object
 * @param {String} timeInLTFormat time string in the Local-time format [HH:mm a/p]
 * @param {Boolean} utc denotes if it must be converted to its UTC equivalent
 */
const get24HTimeObj = (timeInLTFormat, utc = false) => {
  const hourAndMin = moment(timeInLTFormat, HOUR_MIN_IN_12_FORMAT).format(HOUR_MIN_IN_24_FORMAT);
  const time = getTimeObj(hourAndMin);
  if (!utc) {
    return time;
  }
  // eslint-disable-next-line
  let tzMoment = getUserDate(new Date(), false).startOf('day');
  tzMoment.hours(time.hours || '00');
  tzMoment = tzMoment.minutes(time.minutes || '00');
  const utcMoment = moment.utc(tzMoment);
  return {
    hours: utcMoment.format(HOUR_IN_24_FORMAT),
    minutes: utcMoment.format(MINUTES_FORMAT),
  };
};

const isPast = (date, utc = false) => getUserDate(date, utc).isBefore(getNow());
const isBefore = (date1, date2) => getUserDate(date1).isBefore(getUserDate(date2));
const isEqual = (date1, date2) => !!date1 && !!date2
  && getUserDate(date1).isSame(getUserDate(date2));
const isAfter = (date1, date2) => getUserDate(date1).isAfter(getUserDate(date2));
const isFuture = (date, utc = false) => getUserDate(date, utc).isAfter(getNow());

const getMin = (d1, d2) => (isBefore(d1, d2) ? d1 : d2);
const getMax = (d1, d2) => (isAfter(d1, d2) ? d1 : d2);

const diff = (date1, date2, period = 'days') => getUserDate(date1).diff(getUserDate(date2), period);

const format = (date, formatString, utc) => getUserDate(date, utc).format(formatString);

const distanceInWords = (date, dateFrom, utc = false) => getUserDate(date, utc).from(dateFrom, true);
const distanceInWordsToNow = (date, utc = false) => getUserDate(date, utc).fromNow(true);
const relativeFormattedDateTime = (
  date, 
  dateTimeformat = 'MMMM Do YYYY, h:mm a', 
  timeformat = 'h:mm a', 
  utc = false) => {
    const today = getUserDate(new Date(), utc).startOf('day');
    const compareToDate = getUserDate(date, utc).startOf('day');
    const diff = today.diff( compareToDate, "days" );
    if (diff === 0) {
      return `${i18n.global.t('today')} ${format(date, timeformat, utc)}`;
    } else if (diff === 1) {
      return `${i18n.global.t('yesterday')} ${format(date, timeformat, utc)}`;
    } else { 
      return format(date, dateTimeformat, utc);
    }
}
const getMilliseconds = date => moment(date).valueOf();

const timezoneAbbr = timezone => moment.tz(timezone).zoneAbbr();

const getDay = (date, utc) => getUserDate(date, utc).day();

const startOfDay = (date, utc = false) => getUserDate(date, utc).startOf('day').toDate();
const startOfWeek = (date, utc = false) => getUserDate(date, utc).startOf('week').toDate();
const startOfMonth = (date, utc = false) => getUserDate(date, utc).startOf('month').toDate();
const startOfYear = (date, utc = false) => getUserDate(date, utc).startOf('year').toDate();

const endOfDay = (date, utc = false) => getUserDate(date, utc).endOf('day').toDate();
const endOfWeek = (date, utc = false) => getUserDate(date, utc).endOf('week').toDate();
const endOfMonth = (date, utc = false) => getUserDate(date, utc).endOf('month').toDate();
const endOfYear = (date, utc = false) => getUserDate(date, utc).endOf('year').toDate();

const addSeconds = (date, seconds = 1, utc = false) => getUserDate(date, utc).add(seconds, 'second').toDate();
const addMinutes = (date, minutes = 1, utc = false) => getUserDate(date, utc).add(minutes, 'minute').toDate();
const addHours = (date, hours = 1, utc = false) => getUserDate(date, utc).add(hours, 'hour').toDate();
const addDays = (date, days = 1, utc = false) => getUserDate(date, utc).add(days, 'day').toDate();
const addWeeks = (date, weeks = 1, utc = false) => getUserDate(date, utc).add(weeks, 'week').toDate();
const addMonths = (date, months = 1, utc = false) => getUserDate(date, utc).add(months, 'month').toDate();
const addYears = (date, years = 1, utc = false) => getUserDate(date, utc).add(years, 'year').toDate();

const subMinutes = (date, minutes = 1, utc = false) => addMinutes(date, -1 * minutes, utc);
const subHours = (date, hours = 1, utc = false) => addHours(date, -1 * hours, utc);
const subDays = (date, days = 1, utc = false) => addDays(date, -1 * days, utc);
const subWeeks = (date, weeks = 1, utc = false) => addWeeks(date, -1 * weeks, utc);
const subMonths = (date, months = 1, utc = false) => addMonths(date, -1 * months, utc);
const subYears = (date, years = 1, utc = false) => addYears(date, -1 * years, utc);

const setHour = (date, hours, utc = false) => getUserDate(date, utc).hours(hours).toDate();
const setMinute = (date, minutes, utc = false) => getUserDate(date, utc).minutes(minutes).toDate();
const setSecond = (date, seconds, utc = false) =>
  getUserDate(date, utc).seconds(seconds).millisecond(0).toDate();
const setTime = (date, hours, minutes, seconds = 0, utc = false) => getUserDate(date, utc)
  .hours(hours)
  .minutes(minutes)
  .seconds(seconds)
  .milliseconds(0)
  .toDate();

/*
 * Return the number of days between two dates.
 * The second date should be after the first date.
 */
const daysBetweenDates = (date1, date2, utc = false) =>
  Math.round((getDate(date2, utc) - getDate(date1, utc)) / (1000 * 60 * 60 * 24));

const isSameDay = (date1, date2, utc = false) => !!date1 && !!date2
  && getUserDate(date1, utc).isSame(getUserDate(date2, utc), 'day');

const datesForPresets = (utc) => {
  const negativeMonthRange = (now, delta) => {
    const month = subMonths(now, delta, utc);
    return [startOfMonth(month, utc), endOfMonth(month, utc)];
  };

  const negativeYearRange = (now, delta) => {
    const year = subYears(now, delta, utc);
    return [startOfYear(year, utc), endOfYear(year, utc)];
  };

  const defaultDates = {
    '15m': now => [addMinutes(now, -15, utc), now],
    '1h': now => [addHours(now, -1, utc), now],
    '6h': now => [addHours(now, -6, utc), now],
    '24h': now => [addHours(now, -24, utc), now],
    '1d': now => [startOfDay(now, utc), now],
    '7d': now => [startOfDay(addDays(now, -7, utc), utc), now],
    '30d': now => [startOfDay(addDays(now, -30, utc), utc), now],
    'month-0': now => negativeMonthRange(now, 0),
    'month-1': now => negativeMonthRange(now, 1),
    'month-2': now => negativeMonthRange(now, 2),
    'month-3': now => negativeMonthRange(now, 3),
    'year-0': now => negativeYearRange(now, 0),
    'year-1': now => negativeYearRange(now, 1),
  };
  return defaultDates;
};

const isWithinRange = (cur, date1, date2) => getUserDate(cur).isBetween(date1, date2);

const isWithinRangeInclusiveStart = (cur, date1, date2) => getUserDate(cur).isBetween(date1, date2, undefined, '[)');

const dayList = (startDate = 1, endDate = 29) => {
  let dayListArr = [];
  for (let num = startDate; num < endDate; num += 1) {
    const nowDate = getNow();
    const monthPaddedStr = `0${nowDate.getMonth() + 1}`;
    const dayPaddedStr = `0${num}`;
    const month = monthPaddedStr.slice(monthPaddedStr.length - 2);
    const day = dayPaddedStr.slice(dayPaddedStr.length - 2);
    const tempDate = getDate([nowDate.getFullYear(), month, day].join('-'));
    const newDayWithSuffix = format(tempDate, 'Do');
    const newDay = { label: newDayWithSuffix, value: `${num}`, num };
    dayListArr = [...dayListArr, newDay];
  }
  return dayListArr;
};

const getLastTwelveMonthDates = () => {
  const result = [];
  const date = getNow();
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  for (let i = 0; i <= 11; i += 1) {
    // eslint-disable-next-line
    result.push(new Date(date));
    date.setMonth(date.getMonth() - 1);
  }
  return result;
};

export {
  getDate,
  spansMultipleBillingCycles,
  format,
  isPast,
  isBefore,
  isEqual,
  isAfter,
  isFuture,
  getMin,
  getMax,
  diff,
  getMilliseconds,
  distanceInWords,
  distanceInWordsToNow,
  relativeFormattedDateTime,
  timezoneAbbr,
  getDay,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfYear,
  addSeconds,
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  subMinutes,
  subHours,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  datesForPresets,
  isSameDay,
  isWithinRange,
  isWithinRangeInclusiveStart,
  getNow,
  getTime,
  getTimeNow,
  get24HTimeObj,
  getTimeObj,
  setHour,
  setMinute,
  setSecond,
  setTime,
  dayList,
  getLastTwelveMonthDates,
  daysBetweenDates,
  shortMonth,
  SIMPLE_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  LIST_SIMPLE_DATE_FORMAT,
  ACTIVITY_DATE_FORMAT,
  SCHEDULED_JOB_FIRE_TIME_FORMAT,
  HOUR_MIN_IN_12_FORMAT,
  HOUR_MIN_IN_24_FORMAT,
  MONTH_NAME_YEAR_FORMAT,
  MONTH_DIGIT_YEAR_FORMAT,
  DATE_MONTH_FORMAT,
  DATE_MONTH_SHORT_YEAR_FORMAT,
  MONTH_YEAR_FORMAT,
};
