import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { Size } from '../sizes';
import CmcGroup from '@/components/nextgen/layout/CmcGroup.vue';
import CmcStack from '@/components/nextgen/layout/CmcStack.vue';

type Layout = 'stack' | 'group' | 'none'
type VerticalAlign = 'top' | 'center' | 'bottom'
type HorizontalAlign = 'left' | 'center' | 'right'

type Props = {
  /**
 * HTML element id.
 */
  id?: string,

  /**
   * Spacing between each stack element.
   */
  spacing?: Size,

  /**
   * Take full height of the parent container.
   */
  takeFullHeight?: boolean,

  /**
   * Reverse the ordering of child elements
   */
  reversed?: boolean,
  
  /**
   * True if the group should take the full width. Each element will have equal width.
   */
  takeFullWidth?: boolean

  /**
   * Align the group vertically.
   */
  withVerticalAlign?: VerticalAlign
  /**
   * Align the group horizontally.
   */
  withHorizontalAlign?: HorizontalAlign,

  /**
   * If true, all items are aligned such that their flex container baselines align.
   */
  withBaselineAlign?: boolean

  /**
   * Whether it's a group, stack or 'none' type layout
   */
  layout: Layout
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcLayout',
  props: {
    id: {},
    spacing: {},
    takeFullHeight: { type: Boolean },
    reversed: { type: Boolean },
    takeFullWidth: { type: Boolean },
    withVerticalAlign: {},
    withHorizontalAlign: {},
    withBaselineAlign: { type: Boolean },
    layout: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_ctx.layout === 'stack')
    ? (_openBlock(), _createBlock(CmcStack, {
        key: 0,
        id: props.id,
        spacing: props.spacing,
        "take-full-height": props.takeFullHeight,
        reversed: props.reversed
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["id", "spacing", "take-full-height", "reversed"]))
    : (_ctx.layout === 'group')
      ? (_openBlock(), _createBlock(CmcGroup, {
          key: 1,
          id: props.id,
          spacing: props.spacing,
          "take-full-height": props.takeFullHeight,
          reversed: props.reversed,
          "take-full-width": props.takeFullWidth,
          "with-vertical-align": props.withVerticalAlign,
          "with-horizontal-align": props.withHorizontalAlign,
          "with-baseline-align": props.withBaselineAlign
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["id", "spacing", "take-full-height", "reversed", "take-full-width", "with-vertical-align", "with-horizontal-align", "with-baseline-align"]))
      : (_ctx.layout === 'none')
        ? _renderSlot(_ctx.$slots, "default", { key: 2 })
        : _createCommentVNode("", true)
}
}

})