<template>
  <div 
    :id="id"
    :class="[
      'cmc-pair',
      `cmc-pair-spacing-${spacing}`,
      {
        'cmc-pair-stretch-lhs': noneStretch || stretchLhs,
        'cmc-pair-stretch-rhs': noneStretch || stretchRhs,
        'cmc-pair-reversed': reversed,
      }
    ]"
  >
    <!--
      @slot Content to be in a pair layout. (Must be maximum two elements.)
    -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Size } from '../sizes';

type Props = {
  id?: string;
  /**
   * Make the left-hand side take as much space as possible
   */
  stretchLhs?: boolean;
  /**
   * Make the right-hand side take as much space as possible
   */
  stretchRhs?: boolean;
  /**
   * Spacing between left and right side.
   */
  spacing?: Size;
  /**
   * Should the ordering of the pair be reversed.
   */
  reversed?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  spacing: 'none',
});

const noneStretch = computed(() => !props.stretchLhs && !props.stretchRhs);
</script>

<style lang="scss">
@import "../sizes";

:root {
  @include generate-size-variables('cmc-pair-spacing');
}
</style>

<style scoped lang="scss">
@import "../sizes";

.cmc-pair {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  @include generate-size-classes('cmc-pair-spacing', 'gap', true);

  &.cmc-pair-reversed {
    flex-direction: row-reverse;
  }

  &.cmc-pair-stretch-lhs {
    :deep(> *:first-child) {
      flex: 1;
      max-width: 100%;
    }
  }
  &.cmc-pair-stretch-rhs {
    :deep(> *:last-child) {
      flex: 1;
      max-width: 100%;
    }
  }
}
</style>