import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { boldify } from "@/utils";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcGroup from "../layout/CmcGroup.vue";
import { Heading } from "./types";

type Props = {
  /**
   * Title to display.
   */
  title: string;
  /**
   * Heading to use.
   */
  heading: Heading;

  /**
   * True if the title is a subtitle.
   */
  asSubtitle?: boolean;

  /**
   * True if the title is a label key.
   */
  withI18n?: boolean;

  /**
   * Interpolation for the label.
   */
  interpolation?: any;  

  /**
   * True if the title should be bold.
  */
  withBold?: boolean;

  /**
   * True if the interpolation should be bold.
   */
  withBoldInterpolation?: boolean;  

  /**
   * Show the text as optional.
   */
  asOptional?: boolean;

  /**
   * Tooltip text.
   */
  withTooltip?: string;

  /**
   * Tooltip placement. Possible values are:
   * 'auto-start'
   * 'auto-end'
   * 'top'
   * 'top-start'
   * 'top-end'
   * 'right'
   * 'right-start'
   * 'right-end'
   * 'bottom'
   * 'bottom-start'
   * 'bottom-end'
   * 'left'
   * 'left-start'
   * 'left-end'
   */
  withTooltipPlacement?: string;

  /**
   * Key-value pairs displayed in tooltip.
   */
  withTooltipKeyValue?: {[key: string]: string};

  /**
   * Tooltip text is an i18n label.
   */
  withTooltipI18n?: boolean;

  /**
   * Tooltip is HTML.
   */
  withTooltipHtml?: boolean;

  /**
   * Tooltip width (m or l)
   */
  withTooltipSize?: string;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Display text as disabled text.
   */
   asDisabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcTitle',
  props: {
    title: {},
    heading: { default: 'h1' },
    asSubtitle: { type: Boolean, default: false },
    withI18n: { type: Boolean, default: false },
    interpolation: { default: {} },
    withBold: { type: Boolean, default: false },
    withBoldInterpolation: { type: Boolean, default: false },
    asOptional: { type: Boolean },
    withTooltip: {},
    withTooltipPlacement: {},
    withTooltipKeyValue: {},
    withTooltipI18n: { type: Boolean },
    withTooltipHtml: { type: Boolean },
    withTooltipSize: { default: 'm' },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    asDisabled: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { t } = useI18n();

const titleText = computed(() => {
  const interpolation = props.withBoldInterpolation ? boldify(props.interpolation) : props.interpolation;
  return props.withI18n ? t(props.title, interpolation) : props.title;
})

const tooltipLabel = computed(() => {
  if (props.withTooltipHtml) {
    return props.withTooltip
  }
  return props.withTooltipI18n ? t(props.withTooltip || '') : props.withTooltip
})

const warningTooltipLabel = computed(() => {
  return props.withWarningTooltipI18n ? t(props.withWarningTooltip || '') : props.withWarningTooltip
});


return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.heading), {
    class: _normalizeClass(['cmc-title', {'cmc-title-with-bold': _ctx.withBold}, `cmc-title-${_ctx.heading}`, { 'cmc-title-as-subtitle': _ctx.asSubtitle }, { 'cmc-title-as-disabled': _ctx.asDisabled }])
  }, {
    default: _withCtx(() => [
      _createVNode(CmcGroup, {
        spacing: "2xs",
        "with-vertical-align": "center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(titleText.value) + " ", 1),
          (_ctx.asOptional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, " (" + _toDisplayString(_unref(t)('optional')) + ") ", 1))
            : _createCommentVNode("", true),
          (_ctx.withTooltip)
            ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                key: 1,
                icon: "info-filled-bold",
                class: "cmc-text-tooltip-icon",
                svg: "",
                size: _ctx.withTooltipSize
              }, null, 8, ["size"])), [
                [_directive_tooltip, { content: tooltipLabel.value, popperClass: 'cmc-tooltip-size-' + _ctx.withTooltipSize, placement: _ctx.withTooltipPlacement || 'auto' }]
              ])
            : _createCommentVNode("", true),
          (_ctx.withWarningTooltip)
            ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                key: 2,
                icon: "exclamation-filled-bold",
                class: "cmc-text-tooltip-warning-icon",
                svg: "",
                size: _ctx.withTooltipSize
              }, null, 8, ["size"])), [
                [_directive_tooltip, { content: warningTooltipLabel.value, popperClass: 'cmc-tooltip-size-' + _ctx.withTooltipSize, placement: _ctx.withTooltipPlacement || 'auto' }]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})