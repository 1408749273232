import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/utils';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcAppLink from './CmcAppLink.vue';
import { BreadCrumbItem } from './types';

type Props = {
  /**
   * Breadcrumbs that are required for the page.
   */
  items: BreadCrumbItem[];
}
const MAX_CHARACTER_LIMIT = 35;
const BREADCRUMB_CONTAINER_PADDING = 64;
const HIDDEN_CRUMBS_BUTTON_WIDTH = 30.64;
const CHEVRON_ICON_WIDTH = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcBreadcrumb',
  props: {
    items: {}
  },
  setup(__props: any) {

const store = useStore();


const props = __props

const visibleBreadcrumbs = ref<BreadCrumbItem[]>(props.items);
const hiddenBreadcrumbs = ref<BreadCrumbItem[]>([]);
const menuButtonBreadcrumbItem: BreadCrumbItem = {
  label: '...',
  withI18n: false
}
const openMenu = ref(false);
const visibleCrumbsWithPreviousQuery = computed(() => {
  const adaptedCrumbs: BreadCrumbItem[] = visibleBreadcrumbs.value;
  return toWithPreviousQuery(adaptedCrumbs);
})

const hiddenCrumbsWithPreviousQuery = computed(() => {
  const adaptedCrumbs: BreadCrumbItem[] = hiddenBreadcrumbs.value;
  return toWithPreviousQuery(adaptedCrumbs);
})

const toWithPreviousQuery = (crumbs: BreadCrumbItem[]) => {
  return crumbs.map(c => {
    if (typeof c.path === 'object' && 'name' in c.path && c.path.name === (store.getters.previousRoute || {}).name) {
      c.path.query = {
      ...store.getters.previousRoute.query,
      ...c.path.query,
      };
    }
    return c;
  })
}

const shouldRenderChevron = ((index: number) => {
  return index !== visibleBreadcrumbs.value.length - 1;
})

onMounted(() => {
  calculateRenderableCrumbs(true);
  window.addEventListener('resize', debouncedCrumbCalculation);
})

const debouncedCrumbCalculation = debounce(() => {
  calculateRenderableCrumbs(false);
}, 200)

const setOpenMenu = () => {
  openMenu.value = !openMenu.value;
}

const calculateRenderableCrumbs = (isMounted: boolean) => {
  const breadcrumbContainer = document.getElementById('cmc-breadcrumb-container');
  const parentElement = breadcrumbContainer?.parentElement;

  let breadCrumbElementsToRender = 0;

  if (breadcrumbContainer && parentElement) {
      // Add this width to account for the chevron icon width that is not calculated properly on mount due to the icon not being rendered yet
  const chevronIconWidth = isMounted ? CHEVRON_ICON_WIDTH : 0;

    const breadcrumbGroups = breadcrumbContainer.querySelectorAll('#cmc-breadcrumb-container > .cmc-group');
    let alreadyRenderedWidth = breadcrumbGroups[0].offsetWidth + HIDDEN_CRUMBS_BUTTON_WIDTH + chevronIconWidth * 2;

    for (let i = breadcrumbGroups.length - 1; i >= 0; i--) {
      const crumb = breadcrumbGroups[i];
      if (alreadyRenderedWidth + crumb.offsetWidth + chevronIconWidth <= parentElement.offsetWidth - BREADCRUMB_CONTAINER_PADDING) {
        breadCrumbElementsToRender += 1;
        alreadyRenderedWidth += crumb.offsetWidth;
      }
    }
    setCrumbs(breadCrumbElementsToRender);
  }
}

const setCrumbs = (breadCrumbElementsToRender: number) => {
  if (breadCrumbElementsToRender === props.items.length) {
    visibleBreadcrumbs.value = props.items;
    hiddenBreadcrumbs.value = [];
    return;
  }

  const crumbsToRender = [];
  const crumbsToHide: BreadCrumbItem[] = []

  crumbsToRender.push(props.items[0]);
  crumbsToRender.push(menuButtonBreadcrumbItem);

  props.items.map((item, index) => {
    if (index > props.items.length - breadCrumbElementsToRender) {
      crumbsToRender.push(item);
    } else if (index > 0) {
      crumbsToHide.push(item);
    }
    return item;
  })
  visibleBreadcrumbs.value = crumbsToRender;
  hiddenBreadcrumbs.value = crumbsToHide;
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_stack = _resolveComponent("cmc-stack")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(CmcBlock, {
    id: "cmc-breadcrumb-container",
    "as-inline": "",
    "without-wrap": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleCrumbsWithPreviousQuery.value, (item, index) => {
        return (_openBlock(), _createBlock(CmcGroup, {
          key: item.label,
          "with-vertical-align": "center",
          spacing: "2xs"
        }, {
          default: _withCtx(() => [
            (item.icon)
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 0,
                  icon: item.icon,
                  svg: item.iconWithSvg,
                  img: item.iconWithImg
                }, null, 8, ["icon", "svg", "img"]))
              : _createCommentVNode("", true),
            (item.path)
              ? (_openBlock(), _createBlock(CmcBlock, {
                  key: 1,
                  "as-inline": "",
                  "padding-left": "xs"
                }, {
                  default: _withCtx(() => [
                    _createVNode(CmcAppLink, {
                      to: item.path
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcText, {
                          text: item.label,
                          "with-i18n": item.withI18n,
                          interpolation: item.interpolation,
                          "without-wrap": "",
                          "max-chars": MAX_CHARACTER_LIMIT,
                          size: "l"
                        }, null, 8, ["text", "with-i18n", "interpolation"])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024))
              : (item.label === '...')
                ? (_openBlock(), _createBlock(CmcBlock, {
                    id: "cmc-breadcrumb-hidden-crumb-wrapper",
                    key: "item.label"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcText, {
                        "as-clickable": "",
                        text: item.label,
                        onClick: setOpenMenu
                      }, null, 8, ["text"]),
                      (openMenu.value)
                        ? _withDirectives((_openBlock(), _createBlock(CmcBlock, {
                            key: 0,
                            class: "cmc-breadcrumb-menu",
                            padding: "s"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_cmc_stack, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hiddenCrumbsWithPreviousQuery.value, (hiddenCrumb, idx) => {
                                    return (_openBlock(), _createBlock(CmcBlock, { key: idx }, {
                                      default: _withCtx(() => [
                                        (hiddenCrumb.path)
                                          ? (_openBlock(), _createBlock(CmcAppLink, {
                                              key: 0,
                                              to: hiddenCrumb.path
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(CmcText, {
                                                  text: hiddenCrumb.label,
                                                  "with-i18n": hiddenCrumb.withI18n,
                                                  interpolation: hiddenCrumb.interpolation,
                                                  size: "l"
                                                }, null, 8, ["text", "with-i18n", "interpolation"])
                                              ]),
                                              _: 2
                                            }, 1032, ["to"]))
                                          : (_openBlock(), _createBlock(CmcText, {
                                              key: 1,
                                              text: hiddenCrumb.label,
                                              "with-i18n": hiddenCrumb.withI18n,
                                              interpolation: hiddenCrumb.interpolation,
                                              "without-wrap": "",
                                              "padding-left": "2xs",
                                              size: "l"
                                            }, null, 8, ["text", "with-i18n", "interpolation"]))
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })), [
                            [_directive_click_outside, setOpenMenu]
                          ])
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createBlock(CmcBlock, {
                    key: 3,
                    paddingLeft: "xs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcText, {
                        text: item.label,
                        "with-i18n": item.withI18n,
                        interpolation: item.interpolation,
                        "without-wrap": "",
                        "padding-left": "2xs",
                        size: "l"
                      }, null, 8, ["text", "with-i18n", "interpolation"])
                    ]),
                    _: 2
                  }, 1024)),
            (shouldRenderChevron(index))
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 4,
                  icon: "chevron-right",
                  svg: "",
                  size: "s"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}
}

})