import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

type Props = {
  /**
   * Position the element at the left side.
   */
  atLeft?: boolean;
  /**
   * Position the element at the right side.
   */
  atRight?: boolean;
  /**
   * Position the element at the center.
   */
  atCenter?: boolean;
    /**
   * Position the element at the center-left side.
   */
  atCenterLeft?: boolean;
  /**
   * Position the element at the center-right side.
   */
  atCenterRight?: boolean;

  /**
   * Position the element at the vertical center.
   */
  atVerticalCenter?: boolean;
  /**
   * Position the element at the horizontal center.
   */
  atHorizontalCenter?: boolean;

  /**
   * Position the element at the top.
   */
  atTop?: boolean;

  /**
   * Position the element at the top center.
   */
  atTopCenter?: boolean;

  /**
   * Position the element at the top-left corner.
   */
  atTopLeft?: boolean;
  /**
   * Position the element at the top-right corner.
   */
   atTopRight?: boolean;

  /**
   * Position the element at the bottom.
   */
  atBottom?: boolean;
  /**
   * Position the element at the bottom center.
   */
  atBottomCenter?: boolean;

  /**
   * Position the element at the bottom-left corner.
   */
  atBottomLeft?: boolean;
  /**
   * Position the element at the bottom-right corner.
   */
  atBottomRight?: boolean;

}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcAlign',
  props: {
    atLeft: { type: Boolean },
    atRight: { type: Boolean },
    atCenter: { type: Boolean },
    atCenterLeft: { type: Boolean },
    atCenterRight: { type: Boolean },
    atVerticalCenter: { type: Boolean },
    atHorizontalCenter: { type: Boolean },
    atTop: { type: Boolean },
    atTopCenter: { type: Boolean },
    atTopLeft: { type: Boolean },
    atTopRight: { type: Boolean },
    atBottom: { type: Boolean },
    atBottomCenter: { type: Boolean },
    atBottomLeft: { type: Boolean },
    atBottomRight: { type: Boolean }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'cmc-align',
      {
        'cmc-align-at-center': _ctx.atCenter,
        'cmc-align-at-vertical-center': _ctx.atVerticalCenter || _ctx.atCenterRight || _ctx.atCenterLeft,
        'cmc-align-at-horizontal-center': _ctx.atHorizontalCenter || _ctx.atTopCenter || _ctx.atBottomCenter,
        'cmc-align-at-top': _ctx.atTop || _ctx.atTopCenter || _ctx.atTopRight || _ctx.atTopLeft,
        'cmc-align-at-bottom': _ctx.atBottom || _ctx.atBottomCenter || _ctx.atBottomRight || _ctx.atBottomLeft,
        'cmc-align-at-left': _ctx.atLeft || _ctx.atTopLeft || _ctx.atBottomLeft,
        'cmc-align-at-right': _ctx.atRight || _ctx.atTopRight || _ctx.atBottomRight,
      }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})