import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

import { computed, defineComponent, ref } from "vue";
  import CmcPair from "@/components/nextgen/layout/CmcPair.vue";
  import { currencyMixin } from '@/mixins/currencyMixin';
  import CmcText from '@/components/nextgen/typography/CmcText.vue';
  import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';

  type Props = {

    /**
     * True if the text is bold.
     */
    withBold?: boolean;

    /**
     * Use a label instead of a div element.
     */
    asLabel?: boolean;

    /**
     * Use a header instead of a div element.
     */
    asHeader?: boolean;

    /**
     * Display text as disabled text.
     */
    asDisabled?: boolean;

    /**
     * Contains the currency symbol to be shown
     */
    currency?: string;

    /**
     * Contains the currency amount to be shown
     */
    amount?: number;

    /**
     * Contains the locale to be shown
     */
    locale?: string;

    /**
     * Contains the max fraction digits to be shown
     */
    maxFractionDigits?: number;

    /**
     * Show currency code at the end of the string (ex. "1,300$ CAD")
     */
    showCurrency?: boolean;

    /**
     * Hide the narrow currency, defaults to false
     * examples: "CA 1,300.00$" (en-US) => "1,300.00$"
     * "1 300,00$ CA" (en-FR) => "1 300,00$"
     */
    hideNarrowCurrency?: boolean;

    /**
     * Reserve currency space
     */
    reserveCurrencySpace?: boolean;

    /**
     * Without wrap
     */
    withoutWrap?: boolean;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CmcAmount',
  props: {
    withBold: { type: Boolean, default: false },
    asLabel: { type: Boolean, default: false },
    asHeader: { type: Boolean },
    asDisabled: { type: Boolean },
    currency: {},
    amount: {},
    locale: {},
    maxFractionDigits: {},
    showCurrency: { type: Boolean, default: false },
    hideNarrowCurrency: { type: Boolean, default: false },
    reserveCurrencySpace: { type: Boolean, default: false },
    withoutWrap: { type: Boolean, default: false }
  },
  setup(__props: any) {

  defineComponent({
    CmcPair, CmcText, CmcTitle
  })

  const text = ref()

  const props = __props;

  const formattedAmount = computed(() => {
    return currencyMixin.methods.formatLocaleCurrencyWithoutAbbreviation(props.amount, props.currency, props.locale, props.maxFractionDigits, props.hideNarrowCurrency);
  })

  
return (_ctx: any,_cache: any) => {
  const _component_cmc_align = _resolveComponent("cmc-align")!
  const _component_cmc_block = _resolveComponent("cmc-block")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.asLabel ? 'label': 'div'), {
    ref_key: "text",
    ref: text
  }, {
    default: _withCtx(() => [
      _createVNode(CmcPair, {
        stretchLhs: "",
        spacing: "3xs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cmc_block, { "as-inline": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_cmc_align, { "at-right": "" }, {
                default: _withCtx(() => [
                  (_ctx.asHeader)
                    ? (_openBlock(), _createBlock(CmcTitle, {
                        key: 0,
                        ref_key: "text",
                        ref: text,
                        title: formattedAmount.value,
                        heading: "h5"
                      }, null, 8, ["title"]))
                    : (_openBlock(), _createBlock(CmcText, {
                        key: 1,
                        ref_key: "text",
                        ref: text,
                        text: formattedAmount.value,
                        withBold: _ctx.withBold,
                        asLabel: _ctx.asLabel,
                        asDisabled: _ctx.asDisabled,
                        "without-wrap": _ctx.withoutWrap,
                        size: "l"
                      }, null, 8, ["text", "withBold", "asLabel", "asDisabled", "without-wrap"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_cmc_block, { "as-inline": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_cmc_align, { "at-bottom-left": "" }, {
                default: _withCtx(() => [
                  _createVNode(CmcText, {
                    text: _ctx.showCurrency||_ctx.reserveCurrencySpace?_ctx.currency || '':'',
                    size: "m",
                    "without-wrap": _ctx.withoutWrap,
                    class: _normalizeClass({'currency-value': _ctx.showCurrency, 'hide-currency-abbr': _ctx.reserveCurrencySpace})
                  }, null, 8, ["text", "without-wrap", "class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}
}

})