<template>
  <cmc-read-only
    :id="id ? `cmc-textarea-${id}` : undefined"
    :label="label"
    :modelValue="modelValue"
    :with-label-i18n="withLabelI18n"
    :read-only="readOnly"
    :inherit-read-only="inheritReadOnly"
    :with-error-text="withErrorText"
    :with-error-text-i18n="withErrorTextI18n"
  >
    <cmc-stack
      :class="[
        'cmc-textarea',
        { 'cmc-textarea-monospace': asMonospace }
      ]"
      spacing="2xs"
    >
      <cmc-label
        v-bind="props"
        as-header
      ></cmc-label>
      <cmc-grid :contextual="true">
        <cmc-grid-col
          sm="1-1"
          md="1-1"
          lg="6-12"
        >
          <div :class="['cmc-textarea-wrapper']">
            <!-- Conditionally render the code editor -->
            <cmc-code-editor
              v-if="asCodeEditor"
              :modelValue="modelValue"
              :language="language"
              :readOnly="readOnly"
              :placeholder="
                withPlaceholderI18n
                  ? $t(withPlaceholder || '')
                  : withPlaceholder
              "
              @update:modelValue="$emit('update:modelValue', $event)"
            />
            <textarea
              v-else
              :disabled="disabled"
              :value="modelValue"
              :placeholder="
                withPlaceholderI18n
                  ? $t(withPlaceholder || '')
                  : withPlaceholder
              "
              @input="emit('update:modelValue', ($event.target as HTMLInputElement)?.value)"
            ></textarea>
          </div>
        </cmc-grid-col>
      </cmc-grid>
      <cmc-text
        v-if="withErrorText"
        :text="withErrorText"
        :with-i18n="withErrorTextI18n"
        as-error
      ></cmc-text>
    </cmc-stack>
  </cmc-read-only>
</template>

<script setup lang="ts">
import { defineComponent } from "vue";
import { EditorLanguage } from "../enums/NextGenEnums";
import CmcStack from "../layout/CmcStack.vue";
import CmcText from "../typography/CmcText.vue";
import CmcLabel from "../typography/CmcLabel.vue";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcReadOnly from "./CmcReadOnly.vue";
import CmcCodeEditor from "../inputs/CmcCodeEditor.vue";
import CmcGrid from "../layout/CmcGrid.vue";
import CmcGridCol from "../layout/CmcGridCol.vue";

defineComponent({
  CmcStack,
  CmcLabel,
  CmcText,
  CmcIcon,
  CmcReadOnly,
  CmcCodeEditor,
  CmcGridCol,
  CmcGrid
});

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Value of the text area
   */
  modelValue?: string;

  /**
   * Label on top of the text area
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Make the text area read-only.
   */
  readOnly?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Will show an error text under the input.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Add placeholder to the text area
   */
  withPlaceholder?: string;

  /**
   * The placeholder is an i18n label key.
   */
  withPlaceholderI18n?: boolean;

  /**
   * Disable the input
   */
  disabled?: boolean;

  /**
   * Sets a monospace font family
   */
   asMonospace?: boolean;

  /**
   * Conditonally check if Code Editor
   */
  asCodeEditor?: boolean;
  language?: EditorLanguage;
  asOptional?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  inheritReadOnly: true,
  asCodeEditor: false,
  language: EditorLanguage.YAML,
  asOptional: false,
});

const emit = defineEmits<{
  /**
   * Emitted when input was changed.
   * @arg text value
   */
  (event: "update:modelValue", str: string): void;
}>();
</script>

<style scoped lang="scss">
.cmc-textarea {
  font-family: "Roboto", sans-serif;
  width: 100%;
  flex: 1;
  &.cmc-textarea-monospace {
    font-family: "Roboto Mono", sans-serif;
  }
  .cmc-text {
    margin-left: 0.125rem;
  }
  .cmc-textarea-wrapper {
    position: relative;
    textarea {
      display: block; /* fix to remove 4px extra padding in chrome */
      font-size: 1rem;
      border-radius: 0.1875rem;
      border: 1px solid var(--ng-secondary-border);
      color: var(--ng-input-text);
      padding: 0.25rem 0.5rem;
      box-sizing: border-box;
      width: 100%;
      resize: vertical;
      min-height: 7.5rem;
      height: 100%;
      &::placeholder {
        line-height: 1.3rem;
        color: var(--ng-text-description);
        opacity: 1; /* Firefox compatability */
      }
    }
  }
}
</style>
