<template>
  <cmc-read-only
    v-if="!asCustom"
    :id="id ? `cmc-radio-${id}` : undefined"
    :label="label"
    :modelValue="selectedOpt?.label"
    :with-label-i18n="selectedOpt?.withI18n"
    :read-only="readOnly"
    :with-error-text="withErrorText"
    :with-error-text-i18n="withErrorTextI18n"
  >
    <cmc-stack
      ref="radioGroup"
      class="cmc-radio-group"
      spacing="2xs"
    >
      <cmc-stack spacing="none">
        <cmc-label
          v-bind="props"
          as-header
        >
        </cmc-label>
        <cmc-text
          v-if="withErrorText"
          :text="withErrorText"
          :with-i18n="withErrorTextI18n"
          as-error
          size="m"
        ></cmc-text>
      </cmc-stack>
      <cmc-radio
        v-for="opt in (options || [])"
        :key="opt.label"
        v-bind="{ ...opt, value: opt.value, withLabelI18n: opt.withI18n, disabled: disabled || opt.disabled }"
      ></cmc-radio>
    </cmc-stack>
  </cmc-read-only>
  <slot
    v-else
    ref="radioGroup"
  ></slot>
</template>

<script setup lang="ts">
import { provide, defineComponent, ref, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcText from '../typography/CmcText.vue';
import CmcRadio from './CmcRadio.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from '../inputs/CmcReadOnly.vue';

defineComponent({
  CmcLabel,
  CmcText,
  CmcStack,
  CmcRadio,
  CmcReadOnly,
})

type Option = {
  /**
   * HTML element id for the checkbox
   */
  id?: string;
  /**
   * Value of the option.
   */
  value: any;
  /**
   * Label of the option.
   */
  label: string;
  /**
   * True if the label of the option is i18n.
   */
  withI18n?: boolean;

  /**
   * Should the radio be disabled.
   */
  disabled?: boolean;
}

export type CmcRadioGroupProps = {
  id?: string;
  modelValue: any;

  options?: Option[];

  /**
  * Label to display on the left-hand side of the radio.
  */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the checkbox.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Should be display in read-only mode.
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Should be display in read-only mode
   */
  readOnly?: boolean;

  /**
   * The radio group is implemented as a custom UI.
   */
  asCustom?: boolean;

  /**
   * Should the radio be disabled.
   */
  disabled?: boolean;

}

const emit = defineEmits<{
  /**
   * Emitted when option is selected.
   */
  (event: 'update:modelValue', value: any): void
}>()

const props = defineProps<CmcRadioGroupProps>()
const initialized = ref(false);
provide('cmc-radio-group-init',  (v: any) => {
  if (!initialized.value) {
    emit('update:modelValue', v)
  }
  initialized.value = true;
})
provide('cmc-radio-group-set-value', (v: any) => {
  emit('update:modelValue', v);
})
provide('cmc-radio-group-props', props)
provide('cmc-radio-group-name', `cmc-radio-group-name-${uuidv4}`)

const selectedOpt = computed(() => {
  const selected = props.options?.find(o => o.value === props.modelValue)
  if (selected) {
    return selected;
  }
  return undefined;
})

</script>
