<template>
  <div
    :id="id"
    :class="[
      'cmc-block', 
      { 
        'cmc-block-with-header-bg': withHeaderBg,
        'cmc-block-with-border': withBorder,
        'cmc-block-with-page-bg': withPageBg,
      }, 
      `cmc-block-padding-${padding}`,
      {
        'cmc-block-hover': withHover && isHovered
      },
      {'cmc-block-no-padding-mobile': withNoPaddingForMobileView},
      { 'cmc-inline-block': asInline },
      { 'cmc-block-no-wrap': withoutWrap },
      ...paddingClasses]"
    @click="emit('click', $event)"
    @mouseover="handleMouseHover(true)"
    @mouseout="handleMouseHover(false)"
  >
    <!--
      @slot This is will appear in the body of the block
    -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Size } from '../sizes';

const isHovered = ref(false);

const handleMouseHover = (state: boolean) => {
  isHovered.value = state;
}

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Add padding around the block
   */
  padding?: Size;
  /**
   * Add padding horizontally.
   */
  paddingHorizontal?: Size;
  /**
   * Add padding vertically.
   */
  paddingVertical?: Size;
  
  /**
   * Add padding on top of the block.
   */
  paddingTop?: Size;
  /**
   * Add padding on bottom of the block.
   */
  paddingBottom?: Size;
  /**
   * Add padding on right side of the block.
   */
  paddingRight?: Size;
  /**
   * Add padding on left side of the block.
   */
  paddingLeft?: Size;

  /**
   * Add header background to the block.
   */
  withHeaderBg?: boolean;
  
  /**
   * Add page background color to the block.
   */
  withPageBg?: boolean;

  /**
   * Unset padding for the block in mobile view.
   */
  withNoPaddingForMobileView?: boolean;

  /**
   * Add border to the block.
   */
  withBorder?: boolean;
  
  /**
   * True if different background color is to be shown on hover
   */
  withHover?: boolean;

  /**
   * True if the block should not wrap text
   */
  withoutWrap?: boolean;

  asInline?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  padding: 'none',
});
const paddingClasses = computed(() => {
  return [
    ['cmc-block-padding-top', props.paddingTop || props.paddingVertical],
    ['cmc-block-padding-bottom', props.paddingBottom || props.paddingVertical],
    ['cmc-block-padding-right', props.paddingRight || props.paddingHorizontal],
    ['cmc-block-padding-left', props.paddingLeft || props.paddingHorizontal],
  ].filter(a => a[1]).map(a => `${a[0]}-${a[1]}`)
});

const emit = defineEmits<{
  /**
   * Emitted when block was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()
</script>

<style lang="scss">
@import "../sizes";

:root {
  --ng-page-bg: var(--ng-primary-background);
  @include generate-size-variables('cmc-block-padding');
  @include generate-size-variables('cmc-block-padding-top');
  @include generate-size-variables('cmc-block-padding-bottom');
  @include generate-size-variables('cmc-block-padding-left');
  @include generate-size-variables('cmc-block-padding-right');
  @include generate-size-variables('cmc-block-padding-horizontal');
  @include generate-size-variables('cmc-block-padding-vertical');
}
</style>

<style scoped lang="scss">
@use '@/styles/mixins.scss';
@import "../sizes";

.cmc-block {
  display: block;
  box-sizing: border-box;

  @include generate-size-classes('cmc-block-padding', 'padding', true);
  @include generate-size-classes('cmc-block-padding-top', 'padding-top', true);
  @include generate-size-classes('cmc-block-padding-bottom', 'padding-bottom', true);
  @include generate-size-classes('cmc-block-padding-left', 'padding-left', true);
  @include generate-size-classes('cmc-block-padding-right', 'padding-right', true);
  @include generate-size-classes('cmc-block-padding-horizontal', 'padding-left', true);
  @include generate-size-classes('cmc-block-padding-horizontal', 'padding-right', true);
  @include generate-size-classes('cmc-block-padding-vertical', 'padding-top', true);
  @include generate-size-classes('cmc-block-padding-vertical', 'padding-bottom', true);

  
  &.cmc-block-with-header-bg {
    background-color: var(--ng-secondary-background);
  }

  &.cmc-block-with-border {
    border: 1px solid var(--ng-secondary-border);
  }
  &.cmc-block-hover {
    background-color: var(--ng-secondary-main);
  }

  &.cmc-inline-block {
    display: inline-block;
  }

  &.cmc-block-no-wrap {
    white-space: nowrap;
  }
  &.cmc-block-with-page-bg {
    background-color: var(--ng-page-bg);
  }

  &.cmc-block-no-padding-mobile {
    @include mixins.phone {
      padding: 0;
    }
  }
}
</style>
