<template>
  <Teleport 
    to="body"
  >
    <cmc-dimmed 
      v-if="opened"
      @dblclick="close"
    ></cmc-dimmed>
    <div
      v-if="opened"
      class="cmc-drawer"
    >
      <cmc-stack>
        <cmc-card
          as-simple
        >
          <cmc-stack>
            <div>Header</div>
            <cmc-divider></cmc-divider>
          </cmc-stack>
        </cmc-card>
        <component 
          :is="component"
          v-bind="props"
          :close="close"
        ></component>
      </cmc-stack>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { Ref, defineComponent, inject } from 'vue';
import CmcDimmed from './CmcDimmed.vue';
import CmcCard from '../display/CmcCard.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import { DrawerProps } from './useDrawer';

defineComponent({
  CmcDimmed,
  CmcCard,
  CmcStack,
  CmcDivider,
})

const { opened, props, component, close } = inject('cmc-drawer') as { component: Ref<any>, props: Ref<DrawerProps>, opened: Ref<boolean>, close: () => void } 
</script>

<style lang="scss">
:root {
  --ng-drawer-bg: var(--ng-primary-background);
}
</style>

<style scoped lang="scss">
.cmc-drawer {
  background-color: var(--ng-drawer-bg);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30rem;
  box-shadow: -1px 0 1px rgba(0,0,0,.1);
}

</style>