import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { Size } from '../sizes';

type VerticalAlign = 'top' | 'center' | 'bottom'
type HorizontalAlign = 'left' | 'center' | 'right'

export type GroupProps = {
  /**
   * HTML element id
   */
  id?: string;
  /**
   * Spacing between group elements.
   */
  spacing?: Size;
  /**
   * True if the group should take the full width. Each element will have equal width.
   */
  takeFullWidth?: boolean
  /**
   * True if the group children should take full height.
   */
  takeFullHeight?: boolean

  /**
   * Align the group vertically.
   */
  withVerticalAlign?: VerticalAlign
  /**
   * Align the group horizontally.
   */
  withHorizontalAlign?: HorizontalAlign,

  /**
   * Reverse the ordering of child elements
   */
  reversed?: boolean,

  /**
   * If true, all items are aligned such that their flex container baselines align.
   */
  withBaselineAlign?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcGroup',
  props: {
    id: {},
    spacing: { default: 'l' },
    takeFullWidth: { type: Boolean, default: false },
    takeFullHeight: { type: Boolean },
    withVerticalAlign: { default: 'top' },
    withHorizontalAlign: { default: 'left' },
    reversed: { type: Boolean },
    withBaselineAlign: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass([
      'cmc-group', 
      { 
        'cmc-group-take-full-width': _ctx.takeFullWidth, 
        'cmc-group-reversed': _ctx.reversed,
      }, 
      `cmc-group-spacing-${_ctx.spacing}`, 
      { 
        [`cmc-group-vertical-align-${_ctx.withVerticalAlign}`] : !_ctx.takeFullHeight,
        [`cmc-group-horizontal-align-${_ctx.withHorizontalAlign}`]: !_ctx.takeFullHeight,
        [`cmc-group-align-baseline`]: _ctx.withBaselineAlign
      },
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})