import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "menu-container" }

import { defineComponent } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcNavMenuItem from './CmcNavMenuItem.vue'
import { Size } from '../sizes';
import { NavigationMenuItem } from '@/app/Main/components/navigation/schemas';

interface Props {
  /**
  * The list of items to be displayed in the menu
  */
  menuItems: Array<NavigationMenuItem>;
  /**
  * The header text of the menu
  */
  withHeader?: string;
  /**
  * True if the text is a label key.
  */
  withHeaderI18n?: boolean;
  /**
  * True if each menu item is seperated by a divider
  */
  withDivider?: boolean;
  /**
  * The spacing between menu items
  */
  spacing?: Size;
  /**
  * True if the menu item text is to be displayed without wrap
  */
  withEllipsisOnTextOverflow?: boolean;
  /**
  * Max width of the text wrapper if ellipsis is to be shown
  */
  customTextMaxWidth?: string;
  /**
  * True if the title is to be displayed on hover instead of cmc-text tooltip
  */
  withTitleOnHover? : boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcNavMenu',
  props: {
    menuItems: {},
    withHeader: { default: '' },
    withHeaderI18n: { type: Boolean, default: false },
    withDivider: { type: Boolean, default: false },
    spacing: { default: 'm' },
    withEllipsisOnTextOverflow: { type: Boolean, default: false },
    customTextMaxWidth: { default: '100%' },
    withTitleOnHover: { type: Boolean, default: false }
  },
  emits: ["clickItem"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcStack,
  CmcTitle,
  CmcDivider,
  CmcAlign,
  CmcNavMenuItem
})



const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CmcStack, {
      class: "menu-items-container",
      spacing: "none"
    }, {
      default: _withCtx(() => [
        (_ctx.withHeader !== '')
          ? (_openBlock(), _createBlock(CmcAlign, {
              key: 0,
              class: "nav-menu-header",
              "at-center": ""
            }, {
              default: _withCtx(() => [
                _createVNode(CmcTitle, {
                  title: _ctx.withHeader,
                  heading: "h4",
                  "with-i18n": _ctx.withHeaderI18n
                }, null, 8, ["title", "with-i18n"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(CmcDivider),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (listItem) => {
          return (_openBlock(), _createBlock(CmcNavMenuItem, _mergeProps({ ref_for: true }, listItem, {
            key: listItem.text,
            "with-divider": _ctx.withDivider,
            spacing: _ctx.spacing,
            "with-title-on-hover": _ctx.withTitleOnHover,
            "with-ellipsis-on-text-overflow": _ctx.withEllipsisOnTextOverflow,
            "custom-text-max-width": _ctx.customTextMaxWidth,
            onClick: ($event: any) => (emit('clickItem', listItem.transition))
          }), null, 16, ["with-divider", "spacing", "with-title-on-hover", "with-ellipsis-on-text-overflow", "custom-text-max-width", "onClick"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})