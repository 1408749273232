<template>
  <div class="invalid-message-container">
    <div class="message-container">
      <h1 class="title">
        {{ $t('expired_reset_link') }}
      </h1>
      <div class="body">
        {{ $t('expired_reset_link_body') }}
      </div>
    </div>
    <div class="link-container">
      <a 
        role="button" 
        tabindex="0" 
        @keyup.enter="resendLink" 
        @click="resendLink"
      >{{ $t('resend_reset_link') }}</a>
    </div>
    <div class="link-container">
      <a 
        role="button" 
        tabindex="0" 
        @keyup.enter="returnToLogin" 
        @click="returnToLogin"
      >{{ $t("back_to_login") }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InvalidTokenMessage',
  emits: ['rememberedPassword', 'forgotPassword'],
  computed: {
    ...mapGetters([
      'locale',
    ]),
  },
  methods: {
    returnToLogin() {
      this.$router.replace('');
      this.$emit('rememberedPassword');
    },
    resendLink() {
      this.$router.replace('');
      this.$emit('forgotPassword');
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/login-form.scss';

.invalid-message-container {
    margin:-20px;
}
</style>
