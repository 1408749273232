<template>
  <div 
    v-if="loaded"
    class="trial-container" 
  >
    <div 
      v-if="registerSuccess"
      class="trial-box" 
    >
      <div class="trial-icon top-icon">
        <base-icon
          v-if="registerSuccess" 
          icon="fa fa-envelope"
        />
      </div>
      <div class="confirm-container">
        <h1 class="title">
          {{ $t('email_notification_after_trial_reg') }}
        </h1>
        <div class="body">
          {{ $t('email_notification_after_trial_reg_body') }}
        </div>
      </div>
    </div>
    <div 
      v-else
      class="trial-box" 
    >
      <div class="reg-box">
        <div class="left-intro">
          <TrialRegistrationLeft :registrationInfo="registrationHTML" />
        </div>
        <div class="right-form">
          <TrialRegistrationForm @registerSuccess="registerSuccess=true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTranslationMap } from '@/i18n';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import TrialRegistrationForm from './TrialRegistrationForm';
import TrialRegistrationLeft from './TrialRegistrationLeft';

export default {
  name: 'TrialRegistrationBox',
  components: {
    TrialRegistrationForm,
    TrialRegistrationLeft,
  },
  data() {
    return {
      loaded:false,
      registerSuccess: false,
      registrationHTML: getTranslationMap(),
    };
  },
  async created() {
    await this.fetchTrialsRegistrationData();
  },
  methods: {
    async fetchTrialsRegistrationData() {
      this.loaded = false;
      const resp = await apis.trials.getTrialsRegistrationInfo();
      if (!resp || resp.status !== 200) {
        notify.error(this.$t('trials.error_fetch_settings'));
        return;
      }
      if (resp.data) {
        this.registrationHTML = resp.data.registrationHTML;
      } else {
        notify.error(this.$t('trials.error_fetch_settings'));
      }
      this.loaded = true;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

.trial-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  @include mixins.phone {
    padding: 20px 10px;
  }
  margin-bottom: 50px;
  @include mixins.not-phone {
    padding: 60px;
  }
}

.reg-box {
  display: flex;
  flex-direction: row;
  @include mixins.phone {
    margin-top: 5px;
    padding: 10px 10px;
    flex-direction: column;
    align-items: center;
  }
}

.left-intro {
  display: flex;
  flex-direction: row;
  padding: 5px 10px 0px 10px;
  @include mixins.phone {
    display: none;
  }
}

.right-form {
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  @include mixins.phone {
    padding: 0;
    min-width: 250px;
  }
  @include mixins.not-phone {
    min-width: 450px;
  }
}

.confirm-container {
  display: flex;
  @include mixins.phone {
    padding: 20px;
  }

  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  max-width: 600px;
}

.title {
  margin-top: 0;
  margin-bottom: 20px;
}

.body {
  text-align: left;
}

.trial-container {
  @include mixins.phone {
    width: 90%;
  }
}

.top-icon {
  position:absolute;
  right:-15px;
  top:-15px;
  width:65px;
  height:65px;
  font-size:35px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;

  @include mixins.phone {
    right: 0;
    left: 0;
    top: -30px;
    font-size: 30px;
    height: 55px;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
