<template>
  <cmc-chart
    ref="cmcChart"
    v-bind="chartProps"
    @click="eventListeners.handleClick"
    @legend-select-changed="eventListeners.handleLegendSelectChanged"
  >
    <template #tooltip-template>
      <slot name="tooltip-template"></slot>
    </template>
  </cmc-chart>
</template>

<script lang="ts" setup>
import CmcChart from "@/components/nextgen/charts/CmcChart.vue";
import { use } from "echarts/core";
import { PieChart } from "echarts/charts";
import { computed, getCurrentInstance, ref} from "vue";
import merge from "deepmerge";
import { PieSeriesOption } from "echarts";
import { LegendOption, TooltipOption, GridOption } from "echarts/types/dist/shared";
import { ToolboxOption } from "echarts/types/src/component/toolbox/ToolboxModel";
import useCmcChartEventListeners from "@/components/nextgen/composables/useCmcChartEventListeners";
import {
  GraphTextStyle,
  EChartStyleOptions,
  GraphColor
} from '@/components/nextgen/charts/branding';
import { EChartsOption } from 'echarts';


use([PieChart]);

/** 
 * THERE IS A BUG IN VUE3 with TYPESCRIPT in which we can chain several interfaces together if one is imported from another file
 * The solution is that we unfortunately need to list off this huge chain (Props + CmcChartVisibleProps + CmcChartProps), 
 * and this causes issues with Jest testing.  We should eventually retest this in mid 2025 to see if it Typescript support has improved. 
*/

interface Props {
  /** THIS BATCH OF PROPS IS ORIGINALLY FROM CMCCHARTPROPS */

  /**
   * The default colors used for the chart.
   * Specific colors can be specified for each series
   */
  colors?: GraphColor[];

  /**
   * The default text style used for the chart.
   */
  textStyle?: GraphTextStyle;

  /**
   * Additional set of various options that will be merged with the known ones
   * using customOptions, the view components that use charts
   * can override a chart's properties depending on use cases
   * @see {@link https://echarts.apache.org/en/option.html}
   */
  customOptions?: EChartsOption;

  /**
   * The inherited html classes from custom implementation
   */
  class?: Record<string, any>,

  /**
   * The inherited css inline styles from custom implementation
   */
  style?: Record<string, any>
  /** END OF CMCCHARTPROPS */

  /** START OF CMCCHARTVISIBLEPROPS */
  /**
     * The chart tooltip options
     */
    tooltip?: Omit<TooltipOption, EChartStyleOptions>;

  /**
   * The chart legend options
   */
  legend? : Omit<LegendOption, EChartStyleOptions>;

  /**
   * The chart grid options
   */
  grid?   : Omit<GridOption, EChartStyleOptions>;

  /**
   * The chart toolbox options
   */
  toolbox?: Omit<ToolboxOption, EChartStyleOptions>;
  /** END OF CMCCHARTVISIBLEPROPS */

  /** START ACTUAL PROPS WHICH EXTEND ABOVE */

  /**
   * True, if you want to display the line that connects the label to the corresponding portion on the chart
   */
  showLabelLine?: boolean;

  /**
   * True if you want to display the labels
   */
  showLabel?: boolean;

  /**
   * The chart labels
   */
  labels?: any[];

  /**
   * True if we want to display the legends
   */
  showLegends?: boolean;

  /**
   * True if we want to display the save button in toolbox
   */
  showSaveButton?: boolean;

  /**
   * The series to display on the chart.
   * The style options are not supported, this is why they are omitted (EChartStyleOptions) from the base type of the series.
   * this is to forget to go through the colors and textStyles props and to reinforce the conformity of the design.
   * @see {@link https://echarts.apache.org/en/option.html#series-pie}
   */
  series: Omit<PieSeriesOption, EChartStyleOptions>[];
}

const props = withDefaults(defineProps<Props>(), {
  showLabelLine : true,
  showLabel     : true,
  labels        : () => [] as any[],
  showLegends   : true,
  showSaveButton: true
});

const tooltipOptions = computed(() => ({
  trigger: 'item',
  // these variables (a, b, c and d) are defined in EChart library and can't be renamed or
  // modified.Details can be found on https://echarts.apache.org/en/option.html#tooltip.formatter
  formatter: '{a} <br/>{b} : {c} ({d}%)',
  confine: true
}));

const legendOptions = computed(() => ({
  left: 'left',
  top : '10%',
  data: props.labels,
  show: props.showLegends
}));

const seriesOptions = computed(() => props.series?.map(s => merge(s, {
    type: 'pie',
    radius: s.radius || "65%",
    center: ['50%', '58%'],
    emphasis: s.emphasis || {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    labelLine: {
      show: props.showLabelLine,
      length: 10,
    },
    label: {
      show: props.showLabel
    }
  }))
);

const toolboxOptions = computed(() => ({
  show: true,
  feature: {
    saveAsImage: {
      show: props.showSaveButton,
      title: 'save',
    }
  }
}));

const chartProps = computed(() => ({
  ...props,
  series : seriesOptions.value as PieSeriesOption[],
  tooltip: tooltipOptions.value as TooltipOption,
  legend : legendOptions.value as LegendOption,
  toolbox: toolboxOptions.value as ToolboxOption
}));

const emit = defineEmits(['click', 'legendSelectChanged']);
const eventListeners = useCmcChartEventListeners(getCurrentInstance(), emit);

const cmcChart = ref<InstanceType<typeof CmcChart>>();
defineExpose({
  updateLegends: (selectedLegends: any) => cmcChart.value?.updateLegends(selectedLegends),
  toggleAllLegends: (allLegends: string[], enable: boolean) => cmcChart.value?.toggleAllLegends(allLegends, enable)
});

</script>