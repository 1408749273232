<template>
  <cmc-stack
    v-if="label || description"
    spacing="none"
    class="cmc-label"
  >
    <cmc-title
      v-if="label && asHeader"
      heading="h5"
      :title="label"
      :as-optional="asOptional"
      :with-i18n="withLabelI18n"
      :with-tooltip="withTooltip"
      :with-tooltip-i18n="withTooltipI18n"
      :with-warning-tooltip="withWarningTooltip"
      :with-warning-tooltip-i18n="withWarningTooltipI18n"
    ></cmc-title>
    <cmc-text
      v-else-if="label && !asHeader"
      size="m"
      :text="label"
      :as-optional="asOptional"
      :with-i18n="withLabelI18n"
      :with-tooltip="withTooltip"
      :with-tooltip-i18n="withTooltipI18n"
      :with-warning-tooltip="withWarningTooltip"
      :with-warning-tooltip-i18n="withWarningTooltipI18n"
      as-label
      :max-line-display="maxLineDisplay"
    ></cmc-text>

    <cmc-text
      v-if="description"
      size="m"
      :text="description"
      :with-i18n="withDescriptionI18n"
      as-description
    ></cmc-text>
  </cmc-stack>
</template>


<script setup lang="ts">
import { defineComponent } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';

defineComponent({
  CmcStack,
  CmcText,
  CmcTitle,
})

type Props = {
  /**
   * Label on top of the text area
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * True if the label should be displayed with header type style
   */
  asHeader?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
  /**
   * Show optional label
   */
  asOptional?: boolean;
  /**
   * The number of lines to display before we truncated with ellipsis rather than wrapping text.
   * Relevant only when 'asHeader' is false.
   */
  maxLineDisplay?: number;
}

withDefaults(defineProps<Props>(), {
  asOptional: false,
  asHeader: false
});

</script>
