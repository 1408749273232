import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sorting-icon"
}
const _hoisted_2 = { key: 0 }

import { computed, defineComponent, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import CmcGridCol from '../../layout/CmcGridCol.vue';
import CmcBlock from '../../layout/CmcBlock.vue';
import { Direction, DirectionType, Sortable } from '../../composables/useListSorting'
import { v4 as uuidv4 } from 'uuid';
import { getCurrentInstance } from "vue";
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";
import CmcPair from "@/components/nextgen/layout/CmcPair.vue";
import { getColOffsetStyle } from "@/components/nextgen/display/list/offsetHelper";
import { ColLayout } from './types';

type CmcListColProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * The sorting instructions for the column, only used if this column is a header that needs sorting
   */
  sortable?: Sortable

  /**
   * Allows this sorting column to watch when other sorting columns have emitted their sorting events 
   * with lastSortedBy in useListSorting.ts
   */
  otherColumnsHook?: Sortable;

  /**
   * True if this column cell renders a select element without a label on top of it. 
   */
  asListSelectItemWithoutLabel? : boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListCol',
  props: {
    id: {},
    sortable: {},
    otherColumnsHook: {},
    asListSelectItemWithoutLabel: { type: Boolean }
  },
  emits: ["sort"],
  setup(__props: any, { emit: __emit }) {

const colId = uuidv4()

defineComponent({
  CmcGridCol,
  CmcBlock,
})

const props = __props

const layout       = inject('cmc-list-layout') as ColLayout[]
const header       = inject('cmc-list-header') as boolean
const nestedLevel  = inject('cmc-list-nested-level', 0) as number
const withCollapse = inject('cmc-list-with-collapse', false) as boolean
const collapsed    = inject('cmc-list-collapsed', false) as boolean

const emit = __emit

const mouseOver = ref(false)

const sortedDirection = ref<DirectionType | undefined>(undefined)
const isActiveSort = ref(false)

const idx = ref(0)
const findAndSetIndex = (parentEl: HTMLElement) => {
  idx.value = [...parentEl.children].findIndex(e => {
    return e.getAttribute('data-col-id') === colId
  })
}
const colLayout = computed(() => {
  return layout[idx.value]
})
const colOffsetStyle = computed(() => idx.value == 0 ? getColOffsetStyle(nestedLevel, withCollapse) : {})


const hook = computed(() => props.otherColumnsHook)
watch(hook, () => {
  if (hook.value && props.sortable && hook.value.tableId === props.sortable.tableId) {
    isActiveSort.value = hook.value?.columnId == props.sortable.columnId
  }
})

onMounted(() => {
  sortedDirection.value = props.sortable?.sortOrder
  if (props.sortable?.default) {
    emitSortInstructions(props.sortable?.sortOrder)
  }
  
  const parentComponent = getCurrentInstance()!!.parent!!
  if (parentComponent && parentComponent.vnode.el instanceof HTMLElement) {
    findAndSetIndex(parentComponent.vnode.el as HTMLElement)

    const observer = new MutationObserver(() => {
      const parentEl = parentComponent.vnode.el as HTMLElement
      findAndSetIndex(parentEl)
    });

    const observerConfig = { childList: true };
    observer.observe(parentComponent.vnode.el, observerConfig);
    onBeforeUnmount(() => {
      observer.disconnect()
    })
  }
})

function emitSortInstructions(order: DirectionType | undefined) {
  if (props.sortable != undefined && order != undefined) {
    const sortingInstructions = props.sortable

    if (isActiveSort.value) {
      sortedDirection.value = !order
      sortingInstructions.sortOrder = !order
    }
    emit('sort', sortingInstructions)
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcGridCol, _mergeProps({
    class: [
      'cmc-list-col',
      'cmc-list-col-' + idx.value,
      `cmc-list-col-${colLayout.value?.asColType}`,
      {
        'cmc-list-col-select' : _ctx.asListSelectItemWithoutLabel,
      },
      {
        'cmc-list-header-col': _unref(header),
      },
      {
        'cmc-list-content-col': !_unref(header),
      }]
  }, { ...props, ...colLayout.value }, { "data-col-id": _unref(colId) }), {
    default: _withCtx(() => [
      _createVNode(CmcPair, {
        spacing: "s",
        "stretch-rhs": "",
        style: _normalizeStyle({...colOffsetStyle.value}),
        class: "cmc-list-col-collapse-icon-container"
      }, {
        default: _withCtx(() => [
          (_unref(withCollapse) && idx.value == 0)
            ? (_openBlock(), _createBlock(CmcBlock, {
                key: 0,
                "padding-top": "4xs"
              }, {
                default: _withCtx(() => [
                  _createVNode(CmcIcon, {
                    icon: _unref(collapsed) ? 'chevron-bold-right' : 'chevron-bold-down',
                    svg: "",
                    size: "s",
                    class: "cmc-list-col-collapse-icon"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(CmcBlock, {
            class: _normalizeClass([`cmc-list-col-${colLayout.value?.asColType}`])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass([
            {
              'pointer': _ctx.sortable
            },
            {
              'cmc-list-col-with-sort-icon': _ctx.sortable || _unref(header)
            }]),
                onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (mouseOver.value = true)),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (mouseOver.value = false)),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (emitSortInstructions(sortedDirection.value)))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass({'no-click': _ctx.sortable})
                }, [
                  _renderSlot(_ctx.$slots, "default")
                ], 2),
                (_ctx.sortable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (isActiveSort.value || mouseOver.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (sortedDirection.value == _unref(Direction).ASCENDING)
                              ? (_openBlock(), _createBlock(CmcIcon, {
                                  key: 0,
                                  icon: "sort-ascending",
                                  svg: "",
                                  "custom-size": 0.9
                                }))
                              : _createCommentVNode("", true),
                            (sortedDirection.value == _unref(Direction).DESCENDING)
                              ? (_openBlock(), _createBlock(CmcIcon, {
                                  key: 1,
                                  icon: "sort-descending",
                                  svg: "",
                                  "custom-size": 0.9
                                }))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 34)
            ]),
            _: 3
          }, 8, ["class"])
        ]),
        _: 3
      }, 8, ["style"])
    ]),
    _: 3
  }, 16, ["class", "data-col-id"]))
}
}

})