<template>
  <div>
    <base-details
      :state="state"
      :name="commitment.name"
      :summaryColumns="summaryColumns"
      :description="description"
    >
      <template #details>
        <base-attributes :attributes="attributes">
          <template #default="slotProps">
            <div>
              <copyable-field
                v-if="slotProps.att.value"
                :modelValue="slotProps.att.value"
                :disabled="!slotProps.att.copyable"
                onHover
              >
                <div>{{ ($tn(slotProps.att.value) || slotProps.att.value) }}</div>
              </copyable-field>
              <copyable-field
                v-else-if="slotProps.att.date"
                :modelValue="slotProps.att.date"
                :disabled="!slotProps.att.copyable"
                onHover
              >
                <div>{{ getDate(slotProps.att.date) }}</div>
              </copyable-field>
              <base-icon
                v-else-if="slotProps.att.icon"
                :icon="slotProps.att.icon"
              />
            </div>
          </template>
        </base-attributes>
      </template>
      <template #rhs>
        <commitment-actions
          :commitment="commitment"
          :organization="commitment.organization"
          :billingCycles="billingCycles"
          shouldRedirect
          :fromGlobalView="fromGlobalView"
          @clicked="actionsOpen = true"
          @close="actionsOpen = false"
        >
        </commitment-actions>
      </template>
    </base-details>
    <committed-product-list
      class="product-list"
      :commitment="commitment"
      :pricing="pricing"
      :billingCycle="cycle"
      :currency="cycle.currency"
      :isDiscount="isDiscount"
      :fixedRateCommittedProductEstimations="fixedRateCommittedProductEstimations"
      :variableRateCommittedProductEstimations="variableRateCommittedProductEstimations"
    />
  </div>
</template>

<script>
import { format, LIST_SIMPLE_DATE_FORMAT, getNow, diff } from '@/utils/dates';
import apis from '@/utils/apis';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import { currencyMixin } from '@/mixins/currencyMixin';
import { commitmentMixin } from '@/mixins/commitmentMixin';
import notify from '@/utils/notify';

import CommitmentActions from './CommitmentActions';
import CommittedProductList from './CommittedProductList';

export default {
  name: 'CommitmentDetails',
  components: {
    CommittedProductList,
    CommitmentActions
  },
  mixins: [billingCycleMixin, currencyMixin, commitmentMixin],
  props: {
    commitment: {
      type: Object,
      required: true,
    },
    billingCycles: {
      type: Array,
      required: true,
    },
    pricing: {
      type: Object,
      required: true,
    },
    cycle: {
      type: Object,
      required: true,
    },
    fromGlobalView: {
      type: Boolean,
    },
  },
  data() {
    return {
      fixedRateCommittedProductPrices: {},
      variableRateCommittedProductPrices: {},
    };
  },
  computed: {
    summaryColumns() {
      return [
        {
          label: this.$tn('start_date'),
          value: this.startDate(this.commitment),
        },
        {
          label: this.$tn('end_date'),
          value: this.endDate(this.commitment),
        },
        {
          label: this.$tn('monetization.commitments.scopes.title'),
          value: this.$tn(`monetization.commitments.scopes.${this.commitment.scope.toLowerCase()}`),
        },
        {
          label: this.$tn('monetization.commitments.pricing_methods.title'),
          value: this.$tn(`monetization.commitments.pricing_methods.${this.commitment.pricingMethod.toLowerCase()}`),
        },
        ...(this.commitment.rateType ? [{
          label: this.$tn('monetization.commitments.rate_types.title'),
          value: this.$tn(`monetization.commitments.rate_types.${this.commitment.rateType.toLowerCase()}`),
        }] : []),
        {
          label: this.$tn('monetization.commitments.monthly_price'),
          value: this.monthlyPrice,
        },
      ];
    },
    monthlyPrice() {
      if (this.commitment.pricingMethod === 'FIXED_PRICE') {
        return this.formatShortCurrency(this.commitment.fixedPrice, this.cycle.currency);
      }
      return this.formatShortCurrency(this.utilityDiscountMonthlyEstimation, this.cycle.currency);
    },
    state() {
      return {
        label: this.getCommitmentState(this.commitment),
        color: this.getCommitmentColor(this.commitment)
      };
    },
    attributes() {
      return [
        {
          labelKey: 'monetization.commitments.id',
          value: this.commitment.id,
          copyable: true
        },
        {
          labelKey: 'organization',
          value: this.commitment.organization.name,
          copyable: true
        },
        ...(this.isEnvironmentScoped ? [{
          labelKey: 'environment',
          value: this.commitment.environment?.name,
          copyable: true
        }] : []),
        ...(this.isServiceConnectionScoped ? [{
          labelKey: 'service_connection',
          value: this.commitment.serviceConnection?.name,
          copyable: true
        }] : []),
        {
          labelKey: 'monetization.pricing_package',
          value: this.billingCyclePackageName[this.locale],
          copyable: true
        },
        ...(this.commitment.pricingMethod === 'FIXED_PRICE' ? [{
          labelKey: 'monetization.tax_code',
          value: this.commitment.fixedPriceTaxCode?.code
        }] : []),
        {
          labelKey: 'monetization.commitments.terminated',
          value: this.commitment.terminated ? this.$t('yes'): this.$t('no')
        },
        {
          labelKey: 'monetization.commitments.created',
          value: this.getDate(this.commitment.createdAt),
          copyable: true
        },
        {
          labelKey: 'monetization.commitments.updated',
          value: this.getDate(this.commitment.updatedAt),
          copyable: true
        }
      ];
    },
    isDiscount() {
      return this.commitment.pricingMethod === 'UTILITY_DISCOUNT';
    },
    isFixedRate() {
      return this.isDiscount && this.commitment.rateType === 'FIXED_RATE';
    },
    isVariableRate() {
      return this.isDiscount && this.commitment.rateType === 'VARIABLE_RATE';
    },
    isServiceConnectionScoped() {
      return this.commitment.scope === 'SERVICE_CONNECTION';
    },
    isEnvironmentScoped() {
      return this.commitment.scope === 'ENVIRONMENT';
    },
    billingCyclePackageName() {
      return this.cycle.pricingPackages[0].name || {};
    },
    fixedRateCommittedProductEstimations() {
      return this.isFixedRate ?
        this.computeCommittedProductEstimate(this.commitment, this.cycle, this.fixedRateCommittedProductPrices) : {};
    },
    variableRateCommittedProductEstimations() {
      return this.isVariableRate ?
        this.computeCommittedProductEstimate(this.commitment, this.cycle, this.variableRateCommittedProductPrices) : {};
    },
    utilityDiscountMonthlyEstimation() {
      if (this.isFixedRate || this.isVariableRate) {
        const estimation = this.isFixedRate ? this.fixedRateCommittedProductEstimations :
          this.variableRateCommittedProductEstimations;
        return Object.values(estimation).reduce((acc, cur) => {
          if (cur.deprecated) {
            return acc;
          }
          return acc + cur.discountCost;
        }, 0).toFixed(2);
      }
      return 0;
    },
    description() {
      if (this.commitment?.terminated) {
        const difference = diff(this.commitment.endDate, getNow(true), 'days');
        if (difference > 0) {
          return this.$t('monetization.commitments.remaining_days', { 'days': difference });
        }
      }
      return '';
    }
  },
  async created() {
    if (this.isFixedRate) {
      await this.fetchFixedRateProductPrices();
    } else if (this.isVariableRate) {
      await this.fetchVariableRateProductPrices();
    }
  },
  methods: {
    getDate(value) {
      return format(value, LIST_SIMPLE_DATE_FORMAT, true);
    },
    async fetchFixedRateProductPrices() {
      const resp = await apis.commitments.fetchPricedProducts(this.commitment.id);
      if (!resp || !resp.ok) {
        notify.error(this.$t('monetization.commitments.error_fetching_pricing_info'));
        return;
      }
      this.fixedRateCommittedProductPrices = resp.data;
    },
    async fetchVariableRateProductPrices() {
      const resp = await apis.commitments.fetchPricedProducts(this.commitment.id, this.cycle.startDate, this.cycle.endDate);
      if (!resp || !resp.ok) {
        notify.error(this.$t('monetization.commitments.error_fetching_pricing_info'));
        return;
      }
      this.variableRateCommittedProductPrices = resp.data;
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

:deep(.lhs) {
  display: block !important;
}

.product-list {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
