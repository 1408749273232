import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["onClick", "onKeyup", "onMouseenter"]
const _hoisted_2 = ["onClick", "onKeyup", "onMouseenter"]

import type { Tab } from './types';
import { defineComponent, ref, computed } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcAppLink from './CmcAppLink.vue';

interface Props {
  /**
   * Id of the HTML element
   */
  id?: string;

  /**
   * Tabs to display
   */
  tabs: Tab[];

  /**
   * Key of the active tab. If undefined will default to the first non-disabled tab.
   */
  activeTab?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcTabs',
  props: {
    id: {},
    tabs: {},
    activeTab: {}
  },
  emits: ["change"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcGroup,
  CmcText,
  CmcDivider,
  CmcBlock,
  CmcAppLink,
})

const props = __props
const emit = __emit

const onChange = (tab: Tab) => {
  if (tab.key !== props.activeTab && !tab.disabled) {
    emit('change', tab.key)
  }
}
const firstAvailableTab = props.tabs.find(t => !t.disabled);
if (!props.activeTab && firstAvailableTab) {
  onChange(firstAvailableTab);
}

const hoveredTab = ref<string>()
const onMouseEnter = (tab: Tab) => {
  hoveredTab.value = tab.key
}
const onMouseLeave = () => {
  hoveredTab.value = undefined
}

const toNavigation = computed(() => {
  return props.tabs?.every(tab => tab.to);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(CmcBlock, {
      id: _ctx.id ? `cmc-tabs-${_ctx.id}` : undefined,
      class: "cmc-tabs",
      tabindex: 0,
      "as-inline": ""
    }, {
      default: _withCtx(() => [
        _createVNode(CmcGroup, { spacing: "none" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, idx) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: tab.key
              }, [
                (toNavigation.value)
                  ? (_openBlock(), _createBlock(CmcAppLink, {
                      key: 0,
                      to: tab.to
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass(['cmc-tabs-tab', {
              'cmc-tabs-tab-active': tab.key === _ctx.activeTab && !tab.disabled,
              'cmc-tabs-tab-disabled': tab.disabled,
              'cmc-tabs-tab-normal': tab.key !== _ctx.activeTab && !tab.disabled,
            }]),
                          onClick: ($event: any) => (onChange(tab)),
                          onKeyup: _withKeys(($event: any) => (onChange(tab)), ["enter"]),
                          onMouseenter: ($event: any) => (onMouseEnter(tab)),
                          onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (onMouseLeave()))
                        }, [
                          _createVNode(CmcText, {
                            id: _ctx.id ? `cmc-tabs-tab-${_ctx.id}-${tab.key}` : undefined,
                            size: "l",
                            text: tab.label,
                            "with-i18n": tab.withI18n,
                            "as-disabled": tab.disabled,
                            "with-tooltip": tab.withTooltip,
                            "with-tooltip-i18n": tab.withTooltipI18n,
                            tabindex: 0
                          }, null, 8, ["id", "text", "with-i18n", "as-disabled", "with-tooltip", "with-tooltip-i18n"])
                        ], 42, _hoisted_1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(['cmc-tabs-tab', {
            'cmc-tabs-tab-active': tab.key === _ctx.activeTab && !tab.disabled,
            'cmc-tabs-tab-disabled': tab.disabled,
            'cmc-tabs-tab-normal': tab.key !== _ctx.activeTab && !tab.disabled,
          }]),
                      onClick: ($event: any) => (onChange(tab)),
                      onKeyup: _withKeys(($event: any) => (onChange(tab)), ["enter"]),
                      onMouseenter: ($event: any) => (onMouseEnter(tab)),
                      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (onMouseLeave()))
                    }, [
                      _createVNode(CmcText, {
                        id: _ctx.id ? `cmc-tabs-tab-${_ctx.id}-${tab.key}` : undefined,
                        size: "l",
                        text: tab.label,
                        "with-i18n": tab.withI18n,
                        "as-disabled": tab.disabled,
                        "with-tooltip": tab.withTooltip,
                        "with-tooltip-i18n": tab.withTooltipI18n,
                        tabindex: 0
                      }, null, 8, ["id", "text", "with-i18n", "as-disabled", "with-tooltip", "with-tooltip-i18n"])
                    ], 42, _hoisted_2)),
                (idx !== _ctx.tabs.length - 1)
                  ? (_openBlock(), _createBlock(CmcDivider, {
                      key: 2,
                      vertical: "",
                      style: _normalizeStyle({
            visibility: 
              (tab.key === _ctx.activeTab || _ctx.tabs[idx + 1]?.key === _ctx.activeTab
                || tab.key === hoveredTab.value 
                || _ctx.tabs[idx + 1]?.key === hoveredTab.value) ? 'hidden' : undefined })
                    }, null, 8, ["style"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["id"]),
    _renderSlot(_ctx.$slots, _ctx.activeTab)
  ], 64))
}
}

})