import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { computed, ref } from 'vue';
import { Size } from '../sizes';

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Add padding around the block
   */
  padding?: Size;
  /**
   * Add padding horizontally.
   */
  paddingHorizontal?: Size;
  /**
   * Add padding vertically.
   */
  paddingVertical?: Size;
  
  /**
   * Add padding on top of the block.
   */
  paddingTop?: Size;
  /**
   * Add padding on bottom of the block.
   */
  paddingBottom?: Size;
  /**
   * Add padding on right side of the block.
   */
  paddingRight?: Size;
  /**
   * Add padding on left side of the block.
   */
  paddingLeft?: Size;

  /**
   * Add header background to the block.
   */
  withHeaderBg?: boolean;
  
  /**
   * Add page background color to the block.
   */
  withPageBg?: boolean;

  /**
   * Unset padding for the block in mobile view.
   */
  withNoPaddingForMobileView?: boolean;

  /**
   * Add border to the block.
   */
  withBorder?: boolean;
  
  /**
   * True if different background color is to be shown on hover
   */
  withHover?: boolean;

  /**
   * True if the block should not wrap text
   */
  withoutWrap?: boolean;

  asInline?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcBlock',
  props: {
    id: {},
    padding: { default: 'none' },
    paddingHorizontal: {},
    paddingVertical: {},
    paddingTop: {},
    paddingBottom: {},
    paddingRight: {},
    paddingLeft: {},
    withHeaderBg: { type: Boolean },
    withPageBg: { type: Boolean },
    withNoPaddingForMobileView: { type: Boolean },
    withBorder: { type: Boolean },
    withHover: { type: Boolean },
    withoutWrap: { type: Boolean },
    asInline: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const isHovered = ref(false);

const handleMouseHover = (state: boolean) => {
  isHovered.value = state;
}

const props = __props;
const paddingClasses = computed(() => {
  return [
    ['cmc-block-padding-top', props.paddingTop || props.paddingVertical],
    ['cmc-block-padding-bottom', props.paddingBottom || props.paddingVertical],
    ['cmc-block-padding-right', props.paddingRight || props.paddingHorizontal],
    ['cmc-block-padding-left', props.paddingLeft || props.paddingHorizontal],
  ].filter(a => a[1]).map(a => `${a[0]}-${a[1]}`)
});

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass([
      'cmc-block', 
      { 
        'cmc-block-with-header-bg': _ctx.withHeaderBg,
        'cmc-block-with-border': _ctx.withBorder,
        'cmc-block-with-page-bg': _ctx.withPageBg,
      }, 
      `cmc-block-padding-${_ctx.padding}`,
      {
        'cmc-block-hover': _ctx.withHover && isHovered.value
      },
      {'cmc-block-no-padding-mobile': _ctx.withNoPaddingForMobileView},
      { 'cmc-inline-block': _ctx.asInline },
      { 'cmc-block-no-wrap': _ctx.withoutWrap },
      ...paddingClasses.value]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event))),
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (handleMouseHover(true))),
    onMouseout: _cache[2] || (_cache[2] = ($event: any) => (handleMouseHover(false)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 42, _hoisted_1))
}
}

})