import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { ref, onMounted, computed } from 'vue';
import { useI18n } from "vue-i18n";

import CmcDivider from '../misc/CmcDivider.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcPartialCheckbox from '../inputs/CmcPartialCheckbox.vue';
import { Size } from '../sizes';
import { Count } from '../inputs/types';
import { PartialCheckboxState } from '../inputs/constants';

interface Props  {
  /**
   * HTML element id for the card element
   */
   id?: string;
  /**
   * Set if the element is expanded or not.
   */
  openedByDefault?: boolean,
  /**
   * The text of the header
   */
  text: string,
  /**
   * If the text needs to use I18n
   */
  withI18n?: boolean;
  /**
   * Whether to include a partial checkbox (ternary) beside the header text.  Requires a count to be provided via 'withCount'.
   */
  withCheckbox?: boolean;
  /**
   * If included, renders a counter tag (ex: '4/6') along with the partial checkbox. Requires 'withCheckbox' to be true.
   */
  withCount?: Count;
  /**
   * Adjusts the top padding of the expanded content.  Optional.
   */
  contentPaddingTop?: Size,  
  /**
   * Adjusts the bottom padding of the expanded content.  Optional.
   */
  contentPaddingBottom?: Size,  
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcAccordion',
  props: {
    id: {},
    openedByDefault: { type: Boolean, default: false },
    text: {},
    withI18n: { type: Boolean, default: false },
    withCheckbox: { type: Boolean, default: false },
    withCount: {},
    contentPaddingTop: { default: "xl" },
    contentPaddingBottom: { default: "s" }
  },
  emits: ["update:checkedStatus"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const { t } = useI18n();

const expanded = ref(false);
onMounted(() => {
  expanded.value = props.openedByDefault;
})

const ariaLabel = computed(() => props.withI18n? t(props.text): props.text);
const accordionBottomPadding = computed<Size>(() => expanded.value ? "s" : "m")

const checkedState = computed<PartialCheckboxState>(() => {
  if (props.withCount?.numSelected == props.withCount?.total) {
    return PartialCheckboxState.CHECKED;
  } else if (props.withCount?.numSelected == 0) {
    return PartialCheckboxState.UNCHECKED;
  }
  return PartialCheckboxState.PARTIAL;
})

const toggleAccordion = () => {
  expanded.value = !expanded.value;
}

const handleUpdateCheckbox = (data: boolean) => {
  emit('update:checkedStatus', data);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id ? `cmc-accordion-${_ctx.id}` : undefined,
    class: "cmc-accordion"
  }, [
    _createVNode(CmcDivider, { class: "cmc-accordion-top-divider" }),
    _createVNode(CmcBlock, {
      "padding-horizontal": "none",
      "padding-top": "m",
      "padding-bottom": accordionBottomPadding.value
    }, {
      default: _withCtx(() => [
        _createVNode(CmcStack, { spacing: "none" }, {
          default: _withCtx(() => [
            _createVNode(CmcGroup, {
              class: "cmc-accordion-header",
              spacing: "xs",
              "with-vertical-align": "center",
              tabindex: "0",
              role: "button",
              "aria-label": ariaLabel.value,
              onClick: toggleAccordion
            }, {
              default: _withCtx(() => [
                _createVNode(CmcIcon, {
                  icon: expanded.value ? 'minus' : 'plus',
                  size: "s",
                  svg: "",
                  class: "title-color"
                }, null, 8, ["icon"]),
                (!_ctx.withCheckbox)
                  ? (_openBlock(), _createBlock(CmcTitle, {
                      key: 0,
                      title: _ctx.text,
                      heading: "h4",
                      "with-i18n": _ctx.withI18n
                    }, null, 8, ["title", "with-i18n"]))
                  : (_openBlock(), _createBlock(CmcPartialCheckbox, {
                      key: 1,
                      "checked-state": checkedState.value,
                      label: _ctx.text,
                      "with-i18n": _ctx.withI18n,
                      reversed: "",
                      "as-title": "",
                      "heading-for-title": "h4",
                      "with-count": _ctx.withCount,
                      "onUpdate:checkedStatus": handleUpdateCheckbox
                    }, null, 8, ["checked-state", "label", "with-i18n", "with-count"]))
              ]),
              _: 1
            }, 8, ["aria-label"]),
            _withDirectives(_createVNode(CmcBlock, {
              "padding-top": props.contentPaddingTop,
              "padding-bottom": props.contentPaddingBottom
            }, {
              default: _withCtx(() => [
                _createVNode(CmcStack, {
                  class: "cmc-accordion-content",
                  spacing: "2xl"
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                  ]),
                  _: 3
                })
              ]),
              _: 3
            }, 8, ["padding-top", "padding-bottom"]), [
              [_vShow, expanded.value]
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["padding-bottom"]),
    _createVNode(CmcDivider, { class: "cmc-accordion-bottom-divider" })
  ], 8, _hoisted_1))
}
}

})