<template>
  <div class="organization-hierarchy-row">
    <list-row
      v-if="shown"
      @mouseover="onMouseOver(organization.id)"
      @mouseleave="onMouseExit(organization.id)"
      v-on="hasChildren ? { click: toggleExpansion } : {}"
    >
      <div>
        <base-tooltip
          v-if="showOrganizationHierarchyIcon"
          :message="organizationHierarchy"
          overflow
        >
          <cmc-icon
            size="m"
            class="hierarchy-list-icon"
            icon="hierarchy-list"
            :svg="true"
          />
        </base-tooltip>
      </div>
      <list-column
        :size="2"
        :class="[!flat || showOrganizationHierarchyIcon ? '' : 'name-column-padding']"
      >
        <div
          class="name-container"
          :style="{ 'padding-left': flat ? 0 : indent }"
        >
          <div class="name">
            <div v-if="hasChildren && !flat">
              <base-icon
                class="expand-icon"
                :icon="caret"
              />
            </div>
            <app-link
              v-if="navigation"
              class="name"
              :to="`/admin/organizations/${organization.id}`"
            >
              <u><column-text>{{ organization.name }}</column-text></u>
            </app-link>
            <column-text v-else>
              {{ organization.name }}
            </column-text>
            <div
              v-if="hasChildren && !flat"
              class="suborgs-number-badge"
            >
              {{ organization.children.length }}
            </div>
          </div>
          <div v-if="organization.adopted">
            <base-tooltip :message="$t('incomplete_tree')">
              <base-icon
                icon="info-circle"
                :customizable="false"
              />
            </base-tooltip>
          </div>
        </div>
      </list-column>
      <slot
        name="columns"
        :org="organization"
        @click.stop
      ></slot>
      <template #actions>
        <slot
          name="actions"
          :org="organization"
          @click.stop
        ></slot>
      </template>
    </list-row>
    <template v-if="organization.children">
      <OrganizationHierarchyRow
        v-for="o in organization.children"
        :key="o.id"
        :navigation="navigation"
        :organization="o"
        :shown="expanded && shown"
        @mouseover="onMouseOver(o.id)"
        @mouseleave="onMouseExit(o.id)"
      >
        <template #columns="{ org }">
          <slot
            name="columns"
            :org="org"
          ></slot>
        </template>
        <template #actions="{ org }">
          <slot
            name="actions"
            :org="org"
          ></slot>
        </template>
      </OrganizationHierarchyRow>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";

const INDENT_MULTIPLIER = 20;

export default {
  name: 'OrganizationHierarchyRow',
  components: {CmcIcon},
  props: {
    organization: {
      type: Object,
      required: true,
    },
    flat: {
      type: Boolean,
    },
    shown: {
      type: Boolean,
    },
    navigation: {
      type: Boolean,
      default: true,
    },
    organizationIdToOrganization: {
      type: Object,
    }
  },
  emits: ['onMouseOver', 'onMouseExit'],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
    caret() {
      return this.expanded ? 'caret-down' : 'caret-right';
    },
    hasChildren() {
      return this.organization.children && this.organization.children.length > 0;
    },
    indent() {
      const indent = INDENT_MULTIPLIER * this.organization.indent;
      return `${indent}px`;
    },
    organizationHierarchy() {
      let index = 0;
      this.organization.lineage.slice().reverse().forEach(org => {
        if (org.id !== this.selectedOrganization.id) {
          index += 1;
        }
      });

      const ancestryString = this.organization.lineage.slice(this.organization.lineage.length - index)
        .filter(o => this.organizationIdToOrganization[o])
        .map(o => this.organizationIdToOrganization[o].name)
        .join(' > ');

      return ancestryString + ' > ' + this.organization.name;
    },
    showOrganizationHierarchyIcon() {
      return this.organization.parent && this.flat;
    },
  },
  created() {
    this.expanded = !(
      this.organization.parent &&
      this.selectedOrganization.id !== this.organization.id
    );
  },
  methods: {
    onMouseOver(organizationId) {
      this.$emit('onMouseOver', organizationId);
    },
    onMouseExit(organizationId) {
      this.$emit('onMouseExit', organizationId);
    },
    toggleExpansion() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/styles/variables.scss';

.expand-icon {
  padding-right: 0.125rem;
}
.name-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.name-column-padding {
  padding-left: 2rem;
}
.name {
  position: relative;
  margin-right: 0.313rem;
  display: flex;
  align-items: center;
}

.suborgs-number-badge {
  background: var(--count-bg);
  font-size: 0.75rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  border-radius: 0.125rem;
}
.hierarchy-list-icon {
  width: 1rem;
  padding-top: 0.125rem;
  margin-right: 1rem;
}
</style>
