<template>
  <div
    :id="id ? `cmc-accordion-${id}` : undefined"
    class="cmc-accordion"
  >
    <cmc-divider class="cmc-accordion-top-divider" />
    <cmc-block
      padding-horizontal="none"
      padding-top="m"
      :padding-bottom="accordionBottomPadding"
    >
      <cmc-stack spacing="none">
        <cmc-group
          class="cmc-accordion-header"
          spacing="xs"
          with-vertical-align="center"
          tabindex="0"
          role="button"
          :aria-label="ariaLabel"
          @click="toggleAccordion"
        >
          <cmc-icon
            :icon="expanded ? 'minus' : 'plus'"
            size="s"
            svg
            class="title-color"
          />
          <cmc-title
            v-if="!withCheckbox"
            :title="text"
            heading="h4"
            :with-i18n="withI18n"
          />
          <cmc-partial-checkbox
            v-else
            :checked-state="checkedState"
            :label="text"
            :with-i18n="withI18n"
            reversed
            as-title
            heading-for-title="h4"
            :with-count="withCount"
            @update:checked-status="handleUpdateCheckbox"
          >
          </cmc-partial-checkbox>
        </cmc-group>
        <cmc-block
          v-show="expanded"
          :padding-top="props.contentPaddingTop"
          :padding-bottom="props.contentPaddingBottom"
        >
          <cmc-stack
            class="cmc-accordion-content"
            spacing="2xl"
          >
            <slot></slot>
          </cmc-stack>
        </cmc-block>
      </cmc-stack>
    </cmc-block>
    <cmc-divider class="cmc-accordion-bottom-divider" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useI18n } from "vue-i18n";

import CmcDivider from '../misc/CmcDivider.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcPartialCheckbox from '../inputs/CmcPartialCheckbox.vue';
import { Size } from '../sizes';
import { Count } from '../inputs/types';
import { PartialCheckboxState } from '../inputs/constants';

interface Props  {
  /**
   * HTML element id for the card element
   */
   id?: string;
  /**
   * Set if the element is expanded or not.
   */
  openedByDefault?: boolean,
  /**
   * The text of the header
   */
  text: string,
  /**
   * If the text needs to use I18n
   */
  withI18n?: boolean;
  /**
   * Whether to include a partial checkbox (ternary) beside the header text.  Requires a count to be provided via 'withCount'.
   */
  withCheckbox?: boolean;
  /**
   * If included, renders a counter tag (ex: '4/6') along with the partial checkbox. Requires 'withCheckbox' to be true.
   */
  withCount?: Count;
  /**
   * Adjusts the top padding of the expanded content.  Optional.
   */
  contentPaddingTop?: Size,  
  /**
   * Adjusts the bottom padding of the expanded content.  Optional.
   */
  contentPaddingBottom?: Size,  
}

const props = withDefaults(defineProps<Props>(), {
  openedByDefault: false,
  withI18n: false,
  withCheckbox: false,
  contentPaddingTop: "xl",
  contentPaddingBottom: "s"
})

const emit = defineEmits<{
  /**
   * Emitted when optional checkbox is toggled.
   * @arg boolean
   */
  (event: 'update:checkedStatus', checked: boolean): void
}>()

const { t } = useI18n();

const expanded = ref(false);
onMounted(() => {
  expanded.value = props.openedByDefault;
})

const ariaLabel = computed(() => props.withI18n? t(props.text): props.text);
const accordionBottomPadding = computed<Size>(() => expanded.value ? "s" : "m")

const checkedState = computed<PartialCheckboxState>(() => {
  if (props.withCount?.numSelected == props.withCount?.total) {
    return PartialCheckboxState.CHECKED;
  } else if (props.withCount?.numSelected == 0) {
    return PartialCheckboxState.UNCHECKED;
  }
  return PartialCheckboxState.PARTIAL;
})

const toggleAccordion = () => {
  expanded.value = !expanded.value;
}

const handleUpdateCheckbox = (data: boolean) => {
  emit('update:checkedStatus', data);
}

</script>
<style scoped lang="scss">
.title-color {
  color: var(--ng-text-header);
}
.cmc-accordion-header {
  cursor: pointer;
  user-select: none;
}

</style>
