<template>
  <cmc-chart
    ref="cmcChart"
    v-bind="chartProps"
    @click="eventListeners.handleClick"
    @legend-select-changed="eventListeners.handleLegendSelectChanged"
  >
    <template #tooltip-template>
      <slot name="tooltip-template"></slot>
    </template>
  </cmc-chart>
</template>

<script setup lang="ts">
import CmcChart from "@/components/nextgen/charts/CmcChart.vue";
import { use } from "echarts/core";
import { LineChart } from "echarts/charts";
import { computed, getCurrentInstance, ref } from "vue";
import merge from "deepmerge";
import {
  getColorHex,
  GraphColor,
  GraphTextStyle,
  EChartStyleOptions,
  GRAPH_COLORS
} from "@/components/nextgen/charts/branding";
import { EChartsOption, LineSeriesOption } from "echarts";
import {
  GridOption,
  TooltipOption,
  LegendOption
} from "echarts/types/dist/shared";
import { ToolboxOption } from "echarts/types/src/component/toolbox/ToolboxModel";
import useCmcChartEventListeners from "@/components/nextgen/composables/useCmcChartEventListeners";

use([LineChart]);

/** 
 * THERE IS A BUG IN VUE3 with TYPESCRIPT in which we can chain several interfaces together if one is imported from another file
 * The solution is that we unfortunately need to list off this huge chain (Props + CmcChartVisibleProps + CmcChartProps), 
 * and this causes issues with Jest testing.  We should eventually retest this in mid 2025 to see if it Typescript support has improved. 
*/

interface Props {
  /** THIS BATCH OF PROPS IS ORIGINALLY FROM CMCCHARTPROPS */

  /**
   * The default colors used for the chart.
   * Specific colors can be specified for each series
   */
   colors?: GraphColor[];

  /**
   * The default text style used for the chart.
   */
  textStyle?: GraphTextStyle;

  /**
   * The inherited html classes from custom implementation
   */
  class?: Record<string, any>,

  /**
   * The inherited css inline styles from custom implementation
   */
  style?: Record<string, any>
  /** END OF CMCCHARTPROPS */

  /** START OF CMCCHARTVISIBLEPROPS */
  /**
    * The chart tooltip options
   */
  tooltip?: Omit<TooltipOption, EChartStyleOptions>;

  /**
   * The chart legend options
   */
  legend? : Omit<LegendOption, EChartStyleOptions>;

  /**
   * The chart grid options
   */
  grid?   : Omit<GridOption, EChartStyleOptions>;

  /**
   * The chart toolbox options
   */
  toolbox?: Omit<ToolboxOption, EChartStyleOptions>;
  /** END OF CMCCHARTVISIBLEPROPS */

  /** START ACTUAL PROPS WHICH EXTEND ABOVE */
  /**
   * The line color palette
   */
  lineColors?: GraphColor[];

  /**
   * The area color palette
   */
  areaColors?: GraphColor[];

  /**
   * The area color opacity
   */
  areaOpacity?: number;

  /**
   * The series to display on the chart.
   * The style options are not supported, this is why they are omitted (EChartStyleOptions) from the base type of the series.
   * this is to forget to go through the colors and textStyles props and to reinforce the conformity of the design.
   * @see {@link https://echarts.apache.org/en/option.html#series-line}
   */
  series: Omit<LineSeriesOption, EChartStyleOptions>[];

  /**
   * Additional set of various options that will be merged with the known ones
   * @see CmcChart.props.customOptions
   * @see {@link https://echarts.apache.org/en/option.html}
   */
  customOptions?: EChartsOption;
}

const props = withDefaults(defineProps<Props>(), {
  lineColors: () => GRAPH_COLORS,
  areaColors: () => GRAPH_COLORS,
  areaOpacity: 0.4,
});

const toolboxOptions = computed(() => ({}))
const seriesOptions = computed(() => {
  return props.series.map(((s, idx) => ({
      ...s,
      type: 'line',
      symbol: 'none',
      lineStyle: {
        color: getColorHex(props.lineColors.length > idx ? props.lineColors[idx] : GRAPH_COLORS[7])
      },
      areaStyle: {
        color: getColorHex(props.areaColors.length > idx ? props.areaColors[idx] : GRAPH_COLORS[7]),
        opacity: props.areaOpacity
      },
      smooth: true,
    }
  )));
});

const gridOptions = computed(() => ({
    containLabel: false,
    left: '0%',
    right: '0%',
    top: '0%',
    bottom: '0%'
}));

const customOptionsMerged = computed(() => {
  const showAxis = props.series.length !== 0;
  const xAxis = {
    type: 'category',
    axisLine: {
      show: showAxis,
      lineStyle: {
        width: 3,
      },
    },
    axisLabel: {
      show: false,
    },
    show: true,
  };
  const yAxis = {
    show: false,
    type: 'value',
    axisLabel: {
      show: false,
    },
    data: props.series,
    smooth: true,
  };

  const optionsOverride = {xAxis, yAxis};
  return props.customOptions ?
    merge(optionsOverride, props.customOptions) :
    optionsOverride;
});

const chartProps = computed(() => ({
  ...props,
  series : seriesOptions.value as LineSeriesOption[],
  grid   : gridOptions.value as GridOption,
  toolbox: toolboxOptions.value as ToolboxOption,
  customOptions: customOptionsMerged.value as EChartsOption
}));

const emit = defineEmits(['click', 'legendSelectChanged']);
const eventListeners = useCmcChartEventListeners(getCurrentInstance(), emit);

const cmcChart = ref<InstanceType<typeof CmcChart>>();
defineExpose({
  updateLegends: (selectedLegends: any) => cmcChart.value?.updateLegends(selectedLegends),
  toggleAllLegends: (allLegends: string[], enable: boolean) => cmcChart.value?.toggleAllLegends(allLegends, enable)
});

</script>
