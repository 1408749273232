<template>
  <cmc-block
    :class="[
      'cmc-list-header',
      {
        'header-highlighted': asHighlight
      },
    ]"
    padding-horizontal="2xl"
    :padding-left="withInput ? 'xl' : undefined"
  >
    <cmc-block
      v-if="withInput"
      class="cmc-list-header-lhs"
      :padding-right="withInput ? 's' : 'm'"
    >
      <cmc-checkbox
        v-if="withInput === 'checkbox'"
      />
      <div
        v-if="withInput === 'radio'"
        class="cmc-list-header-radio-space"
      ></div>
    </cmc-block>
    <cmc-grid at-vertical-center>
      <slot></slot>
    </cmc-grid>
    <cmc-block
      class="cmc-list-row-rhs"
    >
      <div
        v-if="withActions"
        class="cmc-list-header-actions-space"
      ></div>
    </cmc-block>
  </cmc-block>
</template>

<script setup lang="ts">
import { defineComponent, inject, provide } from 'vue';
import CmcBlock from '../../layout/CmcBlock.vue';
import CmcGrid from '../../layout/CmcGrid.vue';
import CmcCheckbox from '../../inputs/CmcCheckbox.vue';

type Props = {
  /**
   * Determines if the header is highlighted
   */
   asHighlight?: boolean
}

withDefaults(defineProps<Props>(), {
  asHighlight: false
})

defineComponent({
  CmcBlock, CmcGrid, CmcCheckbox,
})

provide('cmc-list-header', true)
const withInput = inject('cmc-list-with-input', undefined)
const withActions = inject('cmc-list-with-actions', undefined)

</script>

<style scoped lang="scss">
.cmc-list-header {
  background-color: var(--ng-list-header-bg);
  border-bottom: 1px solid var(--ng-list-border);
  display: flex;
  min-height: 3rem;

  .cmc-list-header-lhs {
    input[type="checkbox"] {
      width: 1rem;
      height: 1rem;
    }
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-top: 0.75rem;
    margin-top: 0.75rem;
  }
  .cmc-grid {
    flex: 1;
  }
  .cmc-list-header-radio-space {
    width: 1rem;
  }

  &.header-highlighted {
    background-color: var(--ng-list-header-bg-highlight);
  }

  .cmc-list-header-actions-space {
    width: 2.75rem;
  }
}
</style>
