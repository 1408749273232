<template>
  <cmc-list
    class="organization-services-list"
    :layout="listDetailsCols"
  >
    <cmc-list-header>
      <cmc-list-col
        :sortable="sortableServiceName"
        :other-columns-hook="lastSortedBy"
        @sort="(sortingInstructions) => sortInMemory(availableConnections, sortingInstructions)"
      >
        <cmc-title 
          title="service"
          with-i18n
          heading="h5" 
          with-bold
        ></cmc-title>
      </cmc-list-col>
      <cmc-list-col
        :sortable="sortableStatus"
        :other-columns-hook="lastSortedBy"
        @sort="(sortingInstructions) => sortInMemory(availableConnections, sortingInstructions)"
      >
        <cmc-title 
          title="service_status"
          with-i18n
          heading="h5"
          with-bold
        ></cmc-title>
      </cmc-list-col>
      <cmc-list-col
        :sortable="sortableState"
        :other-columns-hook="lastSortedBy"
        @sort="(sortingInstructions) => sortInMemory(availableConnections, sortingInstructions)"
      >
        <cmc-title 
          title="state"
          with-i18n
          heading="h5" 
          with-bold
        >
        </cmc-title>
      </cmc-list-col>
      <cmc-list-col>
        <cmc-title 
          title="details"
          with-i18n
          heading="h5" 
          with-bold
        ></cmc-title>
      </cmc-list-col>
    </cmc-list-header>
    <connection-assignment-row  
      v-for="sc in connections"
      :key="sc.id+componentKey"
      :assigned="connectionIsAssigned(sc)" 
      :connection="sc" 
      :organization="props.organization"
    />
    <empty-message 
      v-if="connections.length < 1" 
      icon="fa fa-plug" 
      textLabel="no_available_connections"
    ></empty-message>
  </cmc-list>
</template>
<script setup lang="ts">
import CmcList from '@/components/nextgen/display/list/CmcList.vue';
import CmcListRow from '@/components/nextgen/display/list/CmcListRow.vue';
import CmcListHeader from '@/components/nextgen/display/list/CmcListHeader.vue';
import CmcListCol from '@/components/nextgen/display/list/CmcListCol.vue';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Organization, AssignedServiceConnection } from './types';
import EmptyMessage from '@/components/EmptyMessage.vue';
import { ColLayout } from '@/components/nextgen/display/list/types';
import ConnectionAssignmentRow from './ConnectionAssignmentRow.vue';
import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';
import { Sortable, sortInMemory, Direction, lastSortedBy } from '@/components/nextgen/composables/useListSorting'

defineComponent({
  CmcList, CmcListRow, CmcListHeader, CmcListCol, CmcTitle
})

const { t } = useI18n();

type Props = {
  /**
   * Name of the organization
   */
  organization: Organization;

  /**
   * List of available service connections
   */
   availableConnections: AssignedServiceConnection[];
  
   /**
   * Key to uniquely assign to each row
   */
    componentKey: number;

}
const props = defineProps<Props>();

const listDetailsCols = ref<ColLayout[]>([
  { u: '3-12', asColType: 'text-with-icon' },
  { u: '2-12', asColType: 'badge' },
  { u: '2-12', asColType: 'badge' },
  { u: '5-12', asColType: 'text' },
]);

const sortableServiceName = ref<Sortable>({
  comparator: (a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  },
  default: true,
  sortOrder: Direction.ASCENDING,
  columnId: "serviceName",
  tableId: "assignedServices"
})

const sortableStatus = ref<Sortable>({
  comparator: (a, b) => {
    const statusA = !a.status.reachable ? t('unreachable') : a.overrideStatus ? t(a.overrideStatus.statusLabel) : t('connected');
    const statusB = !b.status.reachable ? t('unreachable') : b.overrideStatus ? t(b.overrideStatus.statusLabel) : t('connected');
    return statusA.localeCompare(statusB);
  },
  default: false,
  sortOrder: Direction.ASCENDING,
  columnId: "serviceStatus",
  tableId: "assignedServices"
})

const sortableState = ref<Sortable>({
  comparator: (a, b) => {
    const stateA = t(a.overrideStatus?.statusLabel || a.state?.toLowerCase() || '');
    const stateB = t(b.overrideStatus?.statusLabel || b.state?.toLowerCase() || '');
    return stateA.localeCompare(stateB);
  },
  default: false,
  sortOrder: Direction.ASCENDING,
  columnId: "serviceState",
  tableId: "assignedServices"
})

const connectionQuotas = computed<Record<string, any>>(() => {
  return (props.organization.quotas || []).reduce((acc: Record<string, any>, q) => {
    acc[q.serviceConnection.id] = q.id;
    return acc;
  }, {});
});

const connections = computed<AssignedServiceConnection[]>(() => {
  return props.availableConnections.map(sc => ({
    ...sc,
    quotaId: quotaOrDefault(sc),
  }));
})

function connectionIsAssigned(connection : AssignedServiceConnection): boolean {
  return !!props.organization.serviceConnections.find(sc => sc.id === connection.id);
}

function quotaOrDefault(connection : AssignedServiceConnection): string | undefined {
  if (connectionQuotas.value[connection.id]) {
    return connectionQuotas.value[connection.id];
  } else if (connection.quotas && connection.quotas.length > 0) {
    return connection.quotas[0].id;
  }
  return undefined;
}

</script>
<style scoped>
:deep(.organization-services-list .cmc-list-header .cmc-list-row-rhs) {
  min-width: 2.75rem;
}
</style>
