import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineComponent, computed } from 'vue';
import {
  ERROR_PROVISIONING,
  ERROR_PURGING,
  PENDING,
  PROVISIONING,
  PROVISIONED,
  PENDING_PURGE,
  PURGED,
  PURGING,
} from '@/utils/states';
import CmcBadge from '@/components/nextgen/display/CmcBadge.vue';
import { StateBadge } from './types';

interface Props {
  state?: string;
  overrideState? : string;
  overrideStateColor?: string;
  withTransparent?: boolean;
  withOutline?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcStateBadge',
  props: {
    state: {},
    overrideState: {},
    overrideStateColor: {},
    withTransparent: { type: Boolean },
    withOutline: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({ CmcBadge });
const props = __props;

const defaultStateBadge = computed<StateBadge>(() => {
  switch (props.state) {
    case PROVISIONED: {
      return {
        color: 'green',
        label: PROVISIONED.toLocaleLowerCase(),
        animated: false,
      };
    }
    case ERROR_PURGING:
    case ERROR_PROVISIONING: {
      return {
        color: 'red',
        label: 'error',
        animated: false,
      };
    }
    case PROVISIONING: {
      return {
        color: 'blue',
        label: PROVISIONING.toLocaleLowerCase(),
        animated: true,
      };
    }
    case PENDING_PURGE:
    case PURGING: {
      return {
        color: 'grey',
        label: PURGING.toLocaleLowerCase(),
        animated: true,
      };
    }
    case PURGED: {
      return {
        color: 'grey',
        label: PURGED.toLocaleLowerCase(),
        animated: false,
      };
    }
    case PENDING: {
      return {
        color: 'grey',
        label: PENDING.toLocaleLowerCase(),
        animated: false,
      };
    }
    default: {
      return {
        color: 'grey',
        label: 'unknown',
        animated: false,
      };
    }
  }
})


const stateBadge = computed<StateBadge>(() => {
  if (props.overrideState && props.overrideStateColor) {
    return {
      color: (props.overrideStateColor.toLocaleLowerCase()) || defaultStateBadge.value.color,
      label: (props.overrideState) || defaultStateBadge.value.label,
      animated: false,
    }
  }
  return defaultStateBadge.value;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBadge, {
    "with-transparent": _ctx.withTransparent,
    text: stateBadge.value.label,
    "with-i18n": "",
    color: stateBadge.value.color,
    animate: stateBadge.value.animated,
    "with-outline": _ctx.withOutline
  }, null, 8, ["with-transparent", "text", "color", "animate", "with-outline"]))
}
}

})