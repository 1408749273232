<template>
  <component
    :is="heading"
    :class="['cmc-title', {'cmc-title-with-bold': withBold}, `cmc-title-${heading}`, { 'cmc-title-as-subtitle': asSubtitle }, { 'cmc-title-as-disabled': asDisabled }]"
  >
    <cmc-group
      spacing="2xs"
      with-vertical-align="center"
    >
      {{ titleText }}
      <span v-if="asOptional">
        ({{ t('optional') }})
      </span>
      <cmc-icon
        v-if="withTooltip"
        v-tooltip="{ content: tooltipLabel, popperClass: 'cmc-tooltip-size-' + withTooltipSize, placement: withTooltipPlacement || 'auto' }"
        icon="info-filled-bold"
        class="cmc-text-tooltip-icon"
        svg
        :size="withTooltipSize"
      ></cmc-icon>
      <cmc-icon
        v-if="withWarningTooltip"
        v-tooltip="{ content: warningTooltipLabel, popperClass: 'cmc-tooltip-size-' + withTooltipSize, placement: withTooltipPlacement || 'auto' }"
        icon="exclamation-filled-bold"
        class="cmc-text-tooltip-warning-icon"
        svg
        :size="withTooltipSize"
      ></cmc-icon>
    </cmc-group>
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { boldify } from "@/utils";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcGroup from "../layout/CmcGroup.vue";
import { Heading } from "./types";

type Props = {
  /**
   * Title to display.
   */
  title: string;
  /**
   * Heading to use.
   */
  heading: Heading;

  /**
   * True if the title is a subtitle.
   */
  asSubtitle?: boolean;

  /**
   * True if the title is a label key.
   */
  withI18n?: boolean;

  /**
   * Interpolation for the label.
   */
  interpolation?: any;  

  /**
   * True if the title should be bold.
  */
  withBold?: boolean;

  /**
   * True if the interpolation should be bold.
   */
  withBoldInterpolation?: boolean;  

  /**
   * Show the text as optional.
   */
  asOptional?: boolean;

  /**
   * Tooltip text.
   */
  withTooltip?: string;

  /**
   * Tooltip placement. Possible values are:
   * 'auto-start'
   * 'auto-end'
   * 'top'
   * 'top-start'
   * 'top-end'
   * 'right'
   * 'right-start'
   * 'right-end'
   * 'bottom'
   * 'bottom-start'
   * 'bottom-end'
   * 'left'
   * 'left-start'
   * 'left-end'
   */
  withTooltipPlacement?: string;

  /**
   * Key-value pairs displayed in tooltip.
   */
  withTooltipKeyValue?: {[key: string]: string};

  /**
   * Tooltip text is an i18n label.
   */
  withTooltipI18n?: boolean;

  /**
   * Tooltip is HTML.
   */
  withTooltipHtml?: boolean;

  /**
   * Tooltip width (m or l)
   */
  withTooltipSize?: string;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Display text as disabled text.
   */
   asDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  withI18n: false,
  interpolation: {},
  heading: 'h1',
  withBold: false,
  withBoldInterpolation: false,
  withTooltipSize: 'm',
  asSubtitle: false,
});

const { t } = useI18n();

const titleText = computed(() => {
  const interpolation = props.withBoldInterpolation ? boldify(props.interpolation) : props.interpolation;
  return props.withI18n ? t(props.title, interpolation) : props.title;
})

const tooltipLabel = computed(() => {
  if (props.withTooltipHtml) {
    return props.withTooltip
  }
  return props.withTooltipI18n ? t(props.withTooltip || '') : props.withTooltip
})

const warningTooltipLabel = computed(() => {
  return props.withWarningTooltipI18n ? t(props.withWarningTooltip || '') : props.withWarningTooltip
});

</script>

<style scoped lang="scss">
.cmc-title {
  margin: 0;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: var(--ng-text-header);

  &.cmc-title-with-bold {
    font-weight: 500;
  }

  &.cmc-title-as-disabled {
    color: var(--ng-text-description);
}
}

.cmc-title-as-subtitle {
  color: var(--ng-text-description);
}

.cmc-text-tooltip-icon {
  color: var(--ng-text-description);
}

.cmc-text-tooltip-warning-icon {
  color: var(--ng-primary-yellow);
}

</style>
