import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "loading-wrapper" }

import apis from '@/utils/apis';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, watch, defineComponent, onBeforeUnmount } from 'vue';
import notify from '@/utils/notify';
import { useI18n } from "vue-i18n";
import { upperFirstCharOnly } from '@/utils/label';
import { waitForCompletion } from '@/utils/tasks';
import CmcLoader from '@/components/nextgen/display/CmcLoader.vue';
import CmcSelect from  '@/components/nextgen/inputs/CmcSelect.vue';
import CmcForm from '@/components/nextgen/form/CmcForm.vue';
import CmcFormElement from '@/components/nextgen/sdk/CmcFormElement.vue';

type Quota = {
  id: string;
  name: string;
};
type ManageableConnection = {
  id: string;
  name: string;
  type: string;
  isAssignable: boolean;
  quotas: Quota[]
  assignableOrganizations: boolean;
  supportsOrganizationLinking: boolean;
  supportsOrganizationCreation: boolean;
};
type AssigneableOrganization = {
  id: string;
  name: string;
};
type ErrorContext = {
  field: string;
  labelKey: string;
};
type ApiError = {
  message: string;
  context: ErrorContext;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectionAssignmentForm',
  emits: ["refreshOrg"],
  setup(__props, { emit: __emit }) {

defineComponent({
  CmcSelect, CmcForm, CmcFormElement, CmcLoader
})

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const isEdit = computed(() => route.query.isEdit === 'true');
const quotaId = computed(() => route.query.quotaId);
const isActive = ref(true);

const hasValueChanged = ref(false);

const init = ref(false)
const availableConnections = ref<ManageableConnection[]>([]);
const fieldErrors = ref<Record<string, ApiError | null>>({});
const fetchAvailableConnections  = async () => {
  const resp = await apis.organizations.findServiceConnections(route.params.id);
  if (resp.status !== 200 || !resp.data) {
    notify.error(t('unexpected_error'));
  } else {
    availableConnections.value = resp.data;
    loading.value = false
  }
  init.value = true;
};

const connectionOpts = computed(() => {
  if (isEdit.value) {
    return availableConnections.value
      .filter(ac => ac.id === assignmentBody.value.serviceConnectionId)
      .map(ac => ({
        label: ac.name,
        value: ac.id,
        isServiceConnection: true,
        type: ac.type,
      }));
  } else {
    return availableConnections.value
      .filter(ac => ac.isAssignable)
      .map(ac => ({
        label: ac.name,
        value: ac.id,
        isServiceConnection: true,
        type: ac.type,
      }));
  }
});

fetchAvailableConnections();

const assignmentBody = ref({
  serviceConnectionId: '',
  assignmentParameters: {
  } as { [key: string]: any },
  quotaId: undefined as string | undefined,
  backendOrganizationId: undefined,
});

const selectedConnection = computed(() => {
  if (isEdit.value) {
    return availableConnections.value.find(ac => ac.id === route.params.connectionId);
  }
  return availableConnections.value.find(sc => sc.id === assignmentBody.value.serviceConnectionId);
});

const assignmentFormElements = ref()

const fetchAssignmentFormElements  = async (connId: string, isEdit: boolean) => {
  const resp = await apis.organizations.getConnectionAssignmentParameters(route.params.id, connId, isEdit);
  if (resp.status !== 200 || !resp.data) {
    notify.error(t('unexpected_error'));
    throw Error("Cannot load form elements")
  } else {
    return resp.data;
  }
};

const fetchBackendOrganizations  = async (connId: string) => {
  const resp = await apis.organizations.getBackendOrganizations(route.params.id, connId);
  if (resp.status !== 200 || !resp.data) {
    notify.error(t('unexpected_error'));
    throw Error("Cannot load form elements")
  } else {
    return resp.data;
  }
};

const assignableOrganizations = ref<AssigneableOrganization[]>([])

const assignableOrganizationOptions = computed(() => {
  if (!selectedConnection?.value || !assignableOrganizations.value) {
    return []
  }
  const options = assignableOrganizations.value.map(o => ({
    value: o.id,
    label: o.name,
  }));
  const createOption = [{
    value: '',
    label: t('add_new_customer'),
  }];
  if (selectedConnection?.value.supportsOrganizationCreation) {
    return [...createOption, ...options];
  }
  return options;
})

const assignedOrganizationOption = computed(() => {
  if (isEdit.value && selectedConnection.value && selectedConnection.value.assignedOrganization) {
    return [{
      label: selectedConnection.value.assignedOrganization.name,
      value: selectedConnection.value.assignedOrganization.id,
    }];
  }
  return [];
});

const loading = ref(true)

const validData = computed(() => {
  return !loading.value 
    && !!assignmentBody.value.serviceConnectionId
    && (assignmentFormElements.value || [])
        .filter((fe: any) => !fe.optional)
        .every((fe: any) => assignmentBody.value.assignmentParameters[fe.field]);
})

onBeforeUnmount(() => {
  isActive.value = false; 
});

watch(selectedConnection, async (newValue) => {
  if (!newValue) {
    return
  }
  loading.value = true;
  assignmentBody.value.quotaId = undefined;
  assignmentBody.value.backendOrganizationId = undefined;
  assignmentBody.value.assignmentParameters = {};
  fieldErrors.value = {};
  assignableOrganizations.value = [];
  const elements = await fetchAssignmentFormElements(newValue.id, isEdit.value);
  let backendOrgs = [];
  if (newValue.supportsOrganizationLinking) {
    backendOrgs = await fetchBackendOrganizations(newValue.id);
  }

  let normalizedElements = [];
  if (isEdit.value) {
    assignmentBody.value.quotaId = quotaId.value as string || undefined;
    assignmentBody.value.serviceConnectionId = newValue.id;
    assignmentBody.value.backendOrganizationId = newValue.supportsOrganizationLinking ? newValue.assignedOrganization?.id : undefined;
    for (const key in elements) {
      const {formElement, value} = elements[key];
      assignmentBody.value.assignmentParameters[formElement.field] = value;
      normalizedElements.push(formElement);
    }
  } else {
    for (const key in elements) {
      const {formElement} = elements[key];
      normalizedElements.push(formElement);
    }
  }

  if (newValue.id === assignmentBody.value.serviceConnectionId) {
    assignmentFormElements.value = normalizedElements;
    assignableOrganizations.value = backendOrgs;
    loading.value = false;
  }
});

const goBack = () => router.push(`/admin/organizations/${route.params.id}/services`);

const onChange = (formElement?: any) => {
  // If there is no pointer event, return early, it is just the form initializing.
  if (event === undefined) {
    return;
  }

  hasValueChanged.value = true;

  // Reset any errors associated with the field
  if (formElement) {
    fieldErrors.value[formElement.field] = null;
  }
};

// To avoid the flickering of the header title when the component is loading
const headerTitle = computed(() => {
  if (loading.value !== true) {
    return isEdit.value ? t('edit_assigned_connection', { name: selectedConnection?.value?.name }) : t('assign_connection.label');
  }
  return '';
});

const emit = __emit;

const executing = ref(false);
const saveConnection = async () => {
  if (executing.value) {
    return;
  }
  executing.value = true;
  let resp;
  if (isEdit.value) {
    resp = await apis.organizations.updateAssignedConnection(route.params.id, assignmentBody.value);
  } else {
    resp = await apis.organizations.assignConnection(route.params.id, assignmentBody.value);
  }
  if (resp.errors) {
    populateFieldErrors(resp.errors)
    executing.value = false;
    notify.error(t('connection_assignment_error'))
    return;
  }

  // Wait for the asynchronous task to complete
  const pollingStatus = await waitForCompletion(resp.data.id)
  executing.value = false;
  if (pollingStatus === 'FAILURE') {
    notify.error(t('unexpected_error'));
    throw Error("Cannot load form elements")
  } else {
    if (isEdit.value) {
      notify.success(t('connection_assignment_updated_success', { 'name': selectedConnection?.value?.name }));
    } else {
      notify.success(t('connection_assignment_assigned_success', { 'name': selectedConnection?.value?.name }));
    }

    if (isActive.value) {
      emit('refreshOrg')
      goBack();
    }
  }
};

const populateFieldErrors = (newErrors: ApiError[]) => {
  const fieldMap: { [key: string]: ApiError } = {};
  newErrors.filter(ok => ok.context && ok.context.field)
    .forEach((err) => { fieldMap[err.context.field] = err; });
  fieldErrors.value = fieldMap; 
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_title = _resolveComponent("cmc-title")!
  const _component_cmc_text = _resolveComponent("cmc-text")!
  const _component_cmc_block = _resolveComponent("cmc-block")!
  const _component_cmc_stack = _resolveComponent("cmc-stack")!
  const _component_cmc_card = _resolveComponent("cmc-card")!

  return (_openBlock(), _createBlock(_component_cmc_card, {
    title: headerTitle.value,
    "with-title-i18n": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(CmcForm, {
          "disable-submit": !validData.value || !hasValueChanged.value,
          executing: executing.value,
          submitLabel: isEdit.value ? _ctx.$t('save') : _ctx.$t('add'),
          onSubmit: saveConnection,
          onCancel: goBack
        }, {
          default: _withCtx(() => [
            (!isEdit.value)
              ? (_openBlock(), _createBlock(CmcSelect, {
                  key: 0,
                  modelValue: assignmentBody.value.serviceConnectionId,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((assignmentBody.value.serviceConnectionId) = $event)),
                    onChange
                  ],
                  label: "service",
                  "with-label-i18n": "",
                  options: connectionOpts.value
                }, null, 8, ["modelValue", "options"]))
              : _createCommentVNode("", true),
            (loading.value || !init.value)
              ? (_openBlock(), _createBlock(CmcLoader, { key: 1 }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (isEdit.value)
                    ? (_openBlock(), _createBlock(CmcSelect, {
                        key: 0,
                        modelValue: assignmentBody.value.serviceConnectionId,
                        "onUpdate:modelValue": [
                          _cache[1] || (_cache[1] = ($event: any) => ((assignmentBody.value.serviceConnectionId) = $event)),
                          onChange
                        ],
                        label: "service",
                        "with-label-i18n": "",
                        options: connectionOpts.value,
                        disabled: ""
                      }, null, 8, ["modelValue", "options"]))
                    : _createCommentVNode("", true),
                  (selectedConnection.value?.quotas.length)
                    ? (_openBlock(), _createBlock(CmcSelect, {
                        key: 1,
                        modelValue: assignmentBody.value.quotaId,
                        "onUpdate:modelValue": [
                          _cache[2] || (_cache[2] = ($event: any) => ((assignmentBody.value.quotaId) = $event)),
                          onChange
                        ],
                        label: "quota",
                        "with-label-i18n": "",
                        "allow-empty": false,
                        options: selectedConnection.value?.quotas.map(q => ({ value: q.id, label: _unref(upperFirstCharOnly)(q.name) }))
                      }, null, 8, ["modelValue", "options"]))
                    : _createCommentVNode("", true),
                  (selectedConnection.value?.supportsOrganizationLinking)
                    ? (_openBlock(), _createBlock(CmcSelect, {
                        key: 2,
                        modelValue: assignmentBody.value.backendOrganizationId,
                        "onUpdate:modelValue": [
                          _cache[3] || (_cache[3] = ($event: any) => ((assignmentBody.value.backendOrganizationId) = $event)),
                          onChange
                        ],
                        label: "linked_to_service_organization",
                        "with-label-i18n": "",
                        "allow-empty": false,
                        "with-tooltip": isEdit.value ? 'assign_connection.link_to_existing' : '',
                        "with-tooltip-i18n": "",
                        options: isEdit.value ? assignedOrganizationOption.value : assignableOrganizationOptions.value,
                        disabled: isEdit.value
                      }, null, 8, ["modelValue", "with-tooltip", "options", "disabled"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assignmentFormElements.value, (fe) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: fe.field
                    }, [
                      (fe.disabled && !fe.sensitive)
                        ? (_openBlock(), _createBlock(_component_cmc_stack, {
                            key: 0,
                            spacing: "3xs"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_cmc_title, {
                                title: fe.label,
                                "with-i18n": "",
                                heading: "h5",
                                "with-tooltip": fe.descriptionLabel,
                                "with-tooltip-i18n": ""
                              }, null, 8, ["title", "with-tooltip"]),
                              _createVNode(_component_cmc_block, { "padding-top": "3xs" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_cmc_text, {
                                    text: assignmentBody.value.assignmentParameters[fe.field]
                                  }, null, 8, ["text"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        : (!fe.disabled)
                          ? (_openBlock(), _createBlock(CmcFormElement, {
                              key: 1,
                              modelValue: assignmentBody.value.assignmentParameters[fe.field],
                              "onUpdate:modelValue": ($event: any) => ((assignmentBody.value.assignmentParameters[fe.field]) = $event),
                              formElement: fe,
                              error: fieldErrors.value[fe.field],
                              disabled: fe.disabled,
                              onChange: ($event: any) => (onChange(fe)),
                              onReload: _cache[4] || (_cache[4] = ($event: any) => (fetchAssignmentFormElements(assignmentBody.value.serviceConnectionId, isEdit.value)))
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "formElement", "error", "disabled", "onChange"]))
                          : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ], 64))
          ]),
          _: 1
        }, 8, ["disable-submit", "executing", "submitLabel"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})