import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcDimmed',
  emits: ["click", "dblclick"],
  setup(__props, { emit: __emit }) {

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "cmc-dimmed",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event))),
    onDblclick: _cache[1] || (_cache[1] = ($event: any) => (emit('dblclick', $event)))
  }, null, 32))
}
}

})