import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import CmcIcon from '@/components/nextgen/misc/CmcIcon.vue';
import { defineComponent } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import { Size } from '../sizes';
interface Props {
    /**
     * Specifies if the loader dimms the background
     */
    withDimmed?: boolean;

    /**
     * Specifies if the loader blurs the background
     */
    withBlur?: boolean;

    /**
     * The padding to set at the top of the div, default is 3xl
     */
    paddingTop?: Size;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcLoader',
  props: {
    withDimmed: { type: Boolean, default: false },
    withBlur: { type: Boolean, default: false },
    paddingTop: { default: '3xl' }
  },
  setup(__props: any) {

defineComponent([
    CmcIcon,
    CmcStack,
    CmcAlign,
    CmcBlock,
])





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, {
    class: _normalizeClass(['spinner-wrapper',
             { 'spinner-wrapper-dimmed': _ctx.withDimmed},
             { 'spinner-wrapper-blur': _ctx.withBlur},
             { 'spinner-wrapper-bg': (!_ctx.withBlur && !_ctx.withDimmed)}]),
    "padding-top": _ctx.paddingTop
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, { "take-full-height": "" }, {
        default: _withCtx(() => [
          _createVNode(CmcAlign, { "at-horizontal-center": "" }, {
            default: _withCtx(() => [
              _createVNode(CmcIcon, {
                icon: "progress",
                svg: "",
                size: "3xl",
                color: "blue",
                spin: ""
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class", "padding-top"]))
}
}

})