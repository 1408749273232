import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import InlineSvg from 'vue-inline-svg';

export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
    icon: {}
  },
  setup(__props: any) {

const props = __props

const svgSrc = computed(() => {
  try {
    return require(`@/../public/static/icons/${props.icon}.svg`)
  } catch(_err) {
    return require(`@/../public/static/img/${props.icon}.svg`)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(InlineSvg), {
    src: svgSrc.value,
    "aria-label": props.icon
  }, null, 8, ["src", "aria-label"]))
}
}

})