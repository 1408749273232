<template>
  <cmc-block class="cmc-partial-checkbox">
    <cmc-pair 
      :stretch-lhs="!!label"
      :reversed="reversed"
      :spacing="pairSpacing"
    >
      <div
        v-if="label && asTitle"
        class="cmc-partial-checkbox-label"
        @click.stop="onClick"
      >
        <cmc-align
          v-if="!withCount"
          at-vertical-center
        >
          <cmc-title 
            :title="label"
            :heading="headingForTitle"
            :with-i18n="withLabelI18n"
            v-bind="props"
          >
          </cmc-title>
        </cmc-align>
        <cmc-block
          v-if="withCount"
          as-inline
          padding-right="2xs"
        >
          <cmc-align at-vertical-center>
            <cmc-title 
              :title="label"
              :with-i18n="withLabelI18n"
              :heading="headingForTitle"
            >
            </cmc-title>
          </cmc-align>
        </cmc-block>
        <!-- We wanted the tag in between the text and tooltip/warnings.-->
        <cmc-block
          v-if="withCount"
          padding-right="3xs"
          as-inline
        >
          <cmc-align at-vertical-center>
            <cmc-tag
              v-if="withCount"
              :text="countLabel"
              size="s"
              horizontal-padding="3xs"
            ></cmc-tag>
          </cmc-align>
        </cmc-block>
        <cmc-block 
          v-if="withCount"
          as-inline
        >
          <cmc-align at-vertical-center>
            <cmc-title 
              :heading="headingForTitle"
              v-bind="props"
              title=""
            ></cmc-title>
          </cmc-align>
        </cmc-block>        
      </div>
      <div 
        v-if="!asTitle"
        class="cmc-partial-checkbox-label"
        @click.stop="onClick"
      >
        <cmc-align
          v-if="!withCount"
          at-vertical-center
        >
          <cmc-label
            v-if="!withCount"
            v-bind="props"
          ></cmc-label>
        </cmc-align>
        <cmc-block
          v-if="withCount"
          as-inline
          padding-right="2xs"
        >
          <cmc-align at-vertical-center>
            <cmc-text
              v-if="label"
              size="m"
              :text="label"
            ></cmc-text>
          </cmc-align>
        </cmc-block>
        <!-- We wanted the tag in between the text and tooltip/warnings.-->
        <cmc-group
          v-if="withCount"
          spacing="none"
          with-vertical-align="center"
        >
          <cmc-tag
            v-if="!!withCount"
            :text="countLabel"
            size="m"
            horizontal-padding="3xs"
          ></cmc-tag>
          <cmc-text
            v-bind="props"
            text=""
          ></cmc-text>
        </cmc-group>
      </div>
      <div>
        <cmc-align at-vertical-center>
          <input 
            type="checkbox" 
            :checked="inputState"
          >
          <div
            :class="['cmc-partial-checkbox-impl', { 'cmc-partial-show-icon': showIcon } ]"
            tabindex="0"
            @click.stop="onClick"
            @keydown.enter="onClick" 
            @keydown.space="onClick"
          >
            <cmc-icon 
              v-if="showIcon"
              svg
              color="white"
              :icon="getIcon()" 
              :custom-size="0.625"
            ></cmc-icon>
          </div>
        </cmc-align>
      </div>
    </cmc-pair>
  </cmc-block>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcTag from '../display/CmcTag.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import { PartialCheckboxState } from './constants';
import { Heading } from '../typography/types';
import { Count } from './types';
import { Size } from '../sizes';

type Props = {
  /**
   * Checked value of the checkbox
   */
  checkedState: PartialCheckboxState;
  /**
   * Label to display beside the checkbox.
   */
  label?: string;
  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;
  /**
   * Should checkbox be displayed on opposite side of text.
   */
  reversed?: boolean;
  /**
   * Checkbox is beside a header.  Should not be true if asTitle is true.
   */
  asHeader?: boolean;
  /**
   * Checkbox is beside a title.  Should not be true if asHeader is true.
   */
  asTitle?: boolean;
  /**
   * The type of heading for the title, ex: 'h1'
   */
  headingForTitle?: Heading;
  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;
  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;
    /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;
  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
    /**
   * Show a tag containing a count (ie: # selected / total items)
   */
  withCount?: Count;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  /**
   * Emitted when toggled.
   * @arg boolean
   */
  (event: 'update:checkedStatus', checked: boolean): void
}>()

const inputState = ref<boolean>(props.checkedState == PartialCheckboxState.CHECKED ? true : false);

const isPartial = computed<boolean>(() => props.checkedState == PartialCheckboxState.PARTIAL);
const isChecked = computed<boolean>(() =>  props.checkedState == PartialCheckboxState.CHECKED);
const showIcon = computed<boolean>(() => isPartial.value || isChecked.value);
const pairSpacing = computed<Size>(() => {
  if (!props.label) {
    return 'none';
  } else
    return 'xs';
})  
const countLabel = computed<string>(() => `${props.withCount?.numSelected}/${props.withCount?.total}`)  

const onClick = () => {
  if (props.checkedState == PartialCheckboxState.CHECKED) {
    emit('update:checkedStatus', false)
  } else {
    emit('update:checkedStatus', true)
  }
}

const getIcon = () => {
  if (isPartial.value) {
    return 'minus-bold';
  }
  return 'checkmark';
}

</script>

<style>
:root {
  --ng-checkbox-bg: var(--ng-text-main);
}
</style>

<style scoped lang="scss">
.cmc-partial-checkbox {
  max-width: 24rem;
  .cmc-title-h3 {
    line-height: 1.125rem;
  }
  input {
    display: none;
  }
  input + .cmc-partial-checkbox-impl.cmc-partial-show-icon {
    background-color: var(--ng-checkbox-bg)
  }
  .cmc-partial-checkbox-impl {
    min-width: 1rem;
    min-height: 1rem;
    max-width: 1rem;
    max-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.075rem solid var(--ng-checkbox-bg);
    border-radius: 0.125rem;
    box-sizing: border-box;
    cursor: pointer;
  }
  :deep(.cmc-tag) {
    padding-top: 0.0625rem;
    padding-bottom: 0.0625rem;
  }
}
</style>