import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["checked"]

import { ref, computed } from 'vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcTag from '../display/CmcTag.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import { PartialCheckboxState } from './constants';
import { Heading } from '../typography/types';
import { Count } from './types';
import { Size } from '../sizes';

type Props = {
  /**
   * Checked value of the checkbox
   */
  checkedState: PartialCheckboxState;
  /**
   * Label to display beside the checkbox.
   */
  label?: string;
  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;
  /**
   * Should checkbox be displayed on opposite side of text.
   */
  reversed?: boolean;
  /**
   * Checkbox is beside a header.  Should not be true if asTitle is true.
   */
  asHeader?: boolean;
  /**
   * Checkbox is beside a title.  Should not be true if asHeader is true.
   */
  asTitle?: boolean;
  /**
   * The type of heading for the title, ex: 'h1'
   */
  headingForTitle?: Heading;
  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;
  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;
    /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;
  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
    /**
   * Show a tag containing a count (ie: # selected / total items)
   */
  withCount?: Count;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcPartialCheckbox',
  props: {
    checkedState: {},
    label: {},
    withLabelI18n: { type: Boolean },
    reversed: { type: Boolean },
    asHeader: { type: Boolean },
    asTitle: { type: Boolean },
    headingForTitle: {},
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    withCount: {}
  },
  emits: ["update:checkedStatus"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit

const inputState = ref<boolean>(props.checkedState == PartialCheckboxState.CHECKED ? true : false);

const isPartial = computed<boolean>(() => props.checkedState == PartialCheckboxState.PARTIAL);
const isChecked = computed<boolean>(() =>  props.checkedState == PartialCheckboxState.CHECKED);
const showIcon = computed<boolean>(() => isPartial.value || isChecked.value);
const pairSpacing = computed<Size>(() => {
  if (!props.label) {
    return 'none';
  } else
    return 'xs';
})  
const countLabel = computed<string>(() => `${props.withCount?.numSelected}/${props.withCount?.total}`)  

const onClick = () => {
  if (props.checkedState == PartialCheckboxState.CHECKED) {
    emit('update:checkedStatus', false)
  } else {
    emit('update:checkedStatus', true)
  }
}

const getIcon = () => {
  if (isPartial.value) {
    return 'minus-bold';
  }
  return 'checkmark';
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, { class: "cmc-partial-checkbox" }, {
    default: _withCtx(() => [
      _createVNode(CmcPair, {
        "stretch-lhs": !!_ctx.label,
        reversed: _ctx.reversed,
        spacing: pairSpacing.value
      }, {
        default: _withCtx(() => [
          (_ctx.label && _ctx.asTitle)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "cmc-partial-checkbox-label",
                onClick: _withModifiers(onClick, ["stop"])
              }, [
                (!_ctx.withCount)
                  ? (_openBlock(), _createBlock(CmcAlign, {
                      key: 0,
                      "at-vertical-center": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcTitle, _mergeProps({
                          title: _ctx.label,
                          heading: _ctx.headingForTitle,
                          "with-i18n": _ctx.withLabelI18n
                        }, props), null, 16, ["title", "heading", "with-i18n"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.withCount)
                  ? (_openBlock(), _createBlock(CmcBlock, {
                      key: 1,
                      "as-inline": "",
                      "padding-right": "2xs"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcAlign, { "at-vertical-center": "" }, {
                          default: _withCtx(() => [
                            _createVNode(CmcTitle, {
                              title: _ctx.label,
                              "with-i18n": _ctx.withLabelI18n,
                              heading: _ctx.headingForTitle
                            }, null, 8, ["title", "with-i18n", "heading"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.withCount)
                  ? (_openBlock(), _createBlock(CmcBlock, {
                      key: 2,
                      "padding-right": "3xs",
                      "as-inline": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcAlign, { "at-vertical-center": "" }, {
                          default: _withCtx(() => [
                            (_ctx.withCount)
                              ? (_openBlock(), _createBlock(CmcTag, {
                                  key: 0,
                                  text: countLabel.value,
                                  size: "s",
                                  "horizontal-padding": "3xs"
                                }, null, 8, ["text"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.withCount)
                  ? (_openBlock(), _createBlock(CmcBlock, {
                      key: 3,
                      "as-inline": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcAlign, { "at-vertical-center": "" }, {
                          default: _withCtx(() => [
                            _createVNode(CmcTitle, _mergeProps({ heading: _ctx.headingForTitle }, props, { title: "" }), null, 16, ["heading"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.asTitle)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "cmc-partial-checkbox-label",
                onClick: _withModifiers(onClick, ["stop"])
              }, [
                (!_ctx.withCount)
                  ? (_openBlock(), _createBlock(CmcAlign, {
                      key: 0,
                      "at-vertical-center": ""
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.withCount)
                          ? (_openBlock(), _createBlock(CmcLabel, _normalizeProps(_mergeProps({ key: 0 }, props)), null, 16))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.withCount)
                  ? (_openBlock(), _createBlock(CmcBlock, {
                      key: 1,
                      "as-inline": "",
                      "padding-right": "2xs"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcAlign, { "at-vertical-center": "" }, {
                          default: _withCtx(() => [
                            (_ctx.label)
                              ? (_openBlock(), _createBlock(CmcText, {
                                  key: 0,
                                  size: "m",
                                  text: _ctx.label
                                }, null, 8, ["text"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.withCount)
                  ? (_openBlock(), _createBlock(CmcGroup, {
                      key: 2,
                      spacing: "none",
                      "with-vertical-align": "center"
                    }, {
                      default: _withCtx(() => [
                        (!!_ctx.withCount)
                          ? (_openBlock(), _createBlock(CmcTag, {
                              key: 0,
                              text: countLabel.value,
                              size: "m",
                              "horizontal-padding": "3xs"
                            }, null, 8, ["text"]))
                          : _createCommentVNode("", true),
                        _createVNode(CmcText, _mergeProps(props, { text: "" }), null, 16)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createVNode(CmcAlign, { "at-vertical-center": "" }, {
              default: _withCtx(() => [
                _createElementVNode("input", {
                  type: "checkbox",
                  checked: inputState.value
                }, null, 8, _hoisted_1),
                _createElementVNode("div", {
                  class: _normalizeClass(['cmc-partial-checkbox-impl', { 'cmc-partial-show-icon': showIcon.value } ]),
                  tabindex: "0",
                  onClick: _withModifiers(onClick, ["stop"]),
                  onKeydown: [
                    _withKeys(onClick, ["enter"]),
                    _withKeys(onClick, ["space"])
                  ]
                }, [
                  (showIcon.value)
                    ? (_openBlock(), _createBlock(CmcIcon, {
                        key: 0,
                        svg: "",
                        color: "white",
                        icon: getIcon(),
                        "custom-size": 0.625
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ], 34)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["stretch-lhs", "reversed", "spacing"])
    ]),
    _: 1
  }))
}
}

})