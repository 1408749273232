import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

import { defineComponent } from 'vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcText from '../typography/CmcText.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import { TagColor } from './types';
import { Size } from '../sizes';

type textSize = 's' | 'm' | 'l'

type Props = {
  /**
   * Identifier of the tag
   */
  value?: string;

  /**
   * Text to display in tag
   */
  text: string;

  /**
   * Should text be i18n
   */
  withI18n?: boolean;

  /**
   * Color of the tag
   */
  color?: TagColor;

  /**
   * Make the tag removable
   */
  withRemove?: boolean;

  /**
   * Size of text
   */
  size?: textSize

  /** 
   * Select size of horizontal padding within tag
   */
  horizontalPadding?: Size;

  /**
   * Tooltip text to be displayed
   */
  withTooltip?: string;

  /**
   * Tooltip text is an i18n label key
   */
  withTooltipI18n?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcTag',
  props: {
    value: {},
    text: {},
    withI18n: { type: Boolean },
    color: { default: 'light-gray' },
    withRemove: { type: Boolean },
    size: {},
    horizontalPadding: { default: 'xs' },
    withTooltip: {},
    withTooltipI18n: { type: Boolean }
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcText,
  CmcBlock,
  CmcGroup,
  CmcIcon,
  CmcAlign,
})



const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, {
    class: _normalizeClass(['cmc-tag', `cmc-tag-${_ctx.color}`, `cmc-tag-${_ctx.size}`]),
    "padding-horizontal": _ctx.horizontalPadding
  }, {
    default: _withCtx(() => [
      _createVNode(CmcAlign, { "at-vertical-center": "" }, {
        default: _withCtx(() => [
          _createVNode(CmcGroup, {
            class: "cmc-tag-inner",
            spacing: "xs",
            "with-vertical-align": "center"
          }, {
            default: _withCtx(() => [
              _createVNode(CmcText, {
                text: _ctx.text,
                "with-i18n": _ctx.withI18n,
                "with-tooltip": _ctx.withTooltip,
                "with-tooltip-i18n": _ctx.withTooltipI18n,
                "without-wrap": "",
                size: _ctx.size
              }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "size"]),
              (_ctx.withRemove)
                ? (_openBlock(), _createBlock(CmcIcon, {
                    key: 0,
                    icon: "times-bold",
                    size: "xs",
                    svg: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('remove', _ctx.value))),
                    onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent","stop"]))
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class", "padding-horizontal"]))
}
}

})