<!--eslint-disable vue/no-v-html-->
<template>
  <component
    :is="asLabel ? 'label' : 'div'"
    ref="rootRef"
    :class="[
      'cmc-text',
      `cmc-text-${size}`,
      align && `cmc-text-align-${align}`,
      {
        'cmc-text-with-bold': withBold,
        'cmc-text-with-monospace': withMonospace,
        'cmc-text-without-wrap': withoutWrap,
        'cmc-text-as-error': asError,
        'cmc-text-as-description': asDescription,
        'cmc-text-as-disabled': asDisabled,
        'cmc-text-as-clickable': asClickable,
        'cmc-text-with-copyable-on-hover': withCopyableOnClick
      },
    ]"
    @click="doClick"
  >
    <cmc-block v-if="withSensitive || withCopyable">
      <cmc-group
        with-vertical-align="center"
        spacing="2xs"
        class="cmc-group-inline-patch"
        @click="emit('click', $event)"
      >
        <slot name="lhs" />
        <div
          ref="textContainerRef"
          class="cmc-text-container"
        >
          <div
            :class="cmcTextClass"
            @mouseover="hover"
            @mouseleave="leave"
          >
            <div 
              v-if="displayActual" 
              v-click-outside="doClickOutside"
              class="cmc-unwrapped-text-border"
            />
            <div
              ref="textRef"
              class="cmc-text-display"
              v-html="displayedText"
            />
            <cmc-icon
              v-if="showCopyIcon && displayActual"
              v-tooltip="copyTooltip"
              class="copy-icon"
              icon="copy"
              svg
              with-clickable
              @click="copyToClipboard"
            />
          </div>
        </div>
        <cmc-icon
          v-if="withTooltip"
          v-tooltip="tooltipProps"
          icon="info-filled-bold"
          :class="['cmc-text-tooltip-icon',
                   {
                     'cmc-text-tooltip-icon-status-report': tooltipAsStatusReport
                   }]"
          svg
          :size="withTooltipIconSize"
        />
        <cmc-icon
          v-if="withWarningTooltip"
          v-tooltip="warningTooltipProps"
          icon="exclamation-filled-bold"
          class="cmc-text-tooltip-warning-icon"
          svg
          :size="withTooltipIconSize"
        />
      </cmc-group>
      <div class="cmc-text-controls">
        <cmc-icon
          v-if="withSensitive"
          :icon="showSensitive ? 'eye' : 'eye-slash'"
          class="show-icon"
          with-clickable
          svg
          @click="showSensitive = !showSensitive"
        />

        <cmc-icon
          v-if="showCopyIcon && !maxCharsChopped"
          v-tooltip="copyTooltip"
          class="copy-icon"
          icon="copy"
          svg
          with-clickable
          @click="copyToClipboard"
        />
      </div>
    </cmc-block>
    <cmc-group
      v-else
      class="cmc-group-inline-patch"
      spacing="3xs"
      with-vertical-align="center"
    >
      <slot name="lhs" />

      <div
        ref="textContainerRef"
        class="cmc-text-container"
      >
        <div
          ref="cmcTextClassRef"
          :class="cmcTextClass"
          @mouseover="hover"
          @mouseleave="leave"
        >
          <div 
            v-if="displayActual"
            v-click-outside="doClickOutside"
            class="cmc-unwrapped-text-border"
          />
          <div
            ref="textRef"
            class="cmc-text-display"
            v-html="displayedText"
          />
        </div>
      </div>
      <span v-if="asOptional">
        ({{ t('optional') }})
      </span>
      <VTooltip
        v-if="withTooltipKeyValue && Object.keys(withTooltipKeyValue).length > 0"
        :placement="withTooltipPlacement || 'auto'"
        :popperClass="'cmc-tooltip-size-' + withTooltipSize"
        :distance="15"
      >
        <i>
          <cmc-icon
            icon="info-filled-bold"
            :class="['cmc-text-tooltip-icon',
                     {
                       'cmc-text-tooltip-icon-status-report': tooltipAsStatusReport
                     }]"
            svg
            size="m"
          />
        </i>
        <template #popper>
          <slot name="tooltip" />
        </template>
      </VTooltip>
      <cmc-icon 
        v-else-if="withTooltip"
        v-tooltip="tooltipProps"
        icon="info-filled-bold"
        :class="['cmc-text-tooltip-icon',
                 {
                   'cmc-text-tooltip-icon-status-report': tooltipAsStatusReport
                 }]"
        svg
        :size="withTooltipIconSize"
      />
      <cmc-icon
        v-if="withWarningTooltip"
        v-tooltip="warningTooltipProps"
        icon="exclamation-filled-bold"
        class="cmc-text-tooltip-warning-icon"
        svg
        :size="withTooltipIconSize"
      />
    </cmc-group>
    <div
      v-show="false"
      ref="tooltipHtmlRef"
    >
      <slot name="tooltip" />
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { boldify, isMobileMode, isTabletMode } from '@/utils';
import CmcPair from "../layout/CmcPair.vue";
import CmcBlock from "../layout/CmcBlock.vue";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcGroup from "../layout/CmcGroup.vue";
import { Size, Alignment, TooltipPlacement } from "./types";

defineComponent({
  CmcPair, CmcIcon, CmcGroup
})

const rootRef = ref();
const textRef = ref();
const textContainerRef = ref();
const cmcTextClassRef = ref();
const showSensitive = ref(false);
const tooltipHtmlRef = ref();
const hoverTimeout = ref();
const displayActual = ref(false);

type Props = {
  /**
   * Text to display
   */
  text: string;
  /**
   * Size of the text
   */
  size?: Size;

  /** To align the text if required.  Possible values are:
   * 'left',
   * 'right',
   * 'center',
   * 'justify'
   */
  align?: Alignment;

  /**
   * Interpolation for the label.
   */
  interpolation?: any;

  /**
   * True if the text is a label key.
   */
  withI18n?: boolean;

  /**
   * True if the text is bold.
   */
  withBold?: boolean;

  /**
   * True if the interpolation should be bold.
   */
  withBoldInterpolation?: boolean;

  /**
   * True if the text is monospace.
   */
  withMonospace?: boolean;

  /**
   * Prevent text from wrapping to next line. If the text is cutoff, a tooltip will be provided on hover.
   */
  withoutWrap?: boolean;

  /**
   * Make the text sensitive and hidden. Will show an eye icon that can toggle between seeing the value and hiding it.
   */
  withSensitive?: boolean;

  /**
   * Show the copyable icon.
   */
  withCopyable?: boolean;

  /**
   * Text should be copyable on hover.
   */
  withCopyableOnClick?: boolean;

  /**
   * Tooltip text.
   */
  withTooltip?: string;

  /**
   * A non-warning tooltip either represents 'information on demand', or a 'status report'. 
   * If 'information on demand' ie: this property is false, should render the info icon as grey.
   * If 'status report', shoulder render the info icon as blue.
   * See CloudOps Design System -> CloudOps Patterns -> Notifications Iconography and Colour for details.
   */
  tooltipAsStatusReport?: boolean;

  /**
   * Tooltip placement.
   * Possible values are:
   * 'auto-start'
   * 'auto-end'
   * 'top'
   * 'top-start'
   * 'top-end'
   * 'right'
   * 'right-start'
   * 'right-end'
   * 'bottom'
   * 'bottom-start'
   * 'bottom-end'
   * 'left'
   * 'left-start'
   * 'left-end'
   */
  withTooltipPlacement?: TooltipPlacement;

  /**
   * Key-value pairs displayed in tooltip.
   */
  withTooltipKeyValue?: {[key: string]: string};

  /**
   * Tooltip text is an i18n label.
   */
  withTooltipI18n?: boolean;

  /**
   * Tooltip content contains raw HTML.
   */
  withTooltipHtml?: boolean;

  /**
   * Tooltip width (m or l)
   */
  withTooltipSize?: string;

  /**
   * Tooltip icon size in t-shirt sizing
   */
  withTooltipIconSize?: Size;

  /**
   * Use a label instead of a div element.
   */
  asLabel?: boolean;

  /**
  /**
   * Display text as disabled text.
   */
  asDisabled?: boolean;

  /**
   * Display with light text.
   */
  asDescription?: boolean;

  /**
   * Display as an error.
   */
  asError?: boolean;

  /**
   * Show pointer on top of hover text.
   */
  asClickable?: boolean;

  /**
   * Show the text as optional.
   */
  asOptional?: boolean;

  /**
   * If the text overflows but the tooltip is not required.
   */
  withoutTooltipOnHover?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Maximum number of characters allowed in the text, after which an ellipsis will be displayed 
   */
  maxChars?: number;

  maxLineDisplay?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'l',
  interpolation: {},
  withI18n: false,
  withBold: false,
  withBoldInterpolation: false,
  withMonospace: false,
  withoutWrap: false,
  asLabel: false,
  asError: false,
  asOptional: false,
  withoutTooltipOnHover: false,
  withTooltipSize: 'm',
  withTooltipIconSize: 'm'
});

const cmcTextClass = computed(() => ({
    [`maxLine-${props.maxLineDisplay}`]: props.maxLineDisplay && !displayActual.value,
    'cmc-text-unwrapped': displayActual.value
  }
));

const isLineClamped = computed(() => {
  if (!props.maxLineDisplay) {
    return false;
  }
  if (cmcTextClassRef.value) {
    return cmcTextClassRef.value.scrollHeight > cmcTextClassRef.value.clientHeight || cmcTextClassRef.value.scrollWidth > cmcTextClassRef.value.clientWidth;
  }
  return false;
});

const tooLong = computed(() => {
  if (textRef.value) {
    // Although .cmc-text-controls have an absolute position, rendering it outside
    // context of the box model it sits within, javascript still counts this for scrollWidth.
    // Subtracting the width of visible icons stops weird jumpiness.
    let widthCorrection = (props.withCopyableOnClick || props.withCopyable) ? 22 : 0;
    widthCorrection += props.withSensitive ? 22 : 0;
    return textRef.value.offsetWidth < textRef.value.scrollWidth - widthCorrection;
  }
  return false;
})

const maxCharsChopped = computed(() => {
  if (props.withSensitive) {
    return false;
  }
  if (props.maxChars) {
    return actualText.value.length > props.maxChars;
  }
  return false;
});

const hover = () => {
  if ((tooLong.value || maxCharsChopped.value || isLineClamped.value) && !hoverTimeout.value) {
    hoverTimeout.value = setTimeout(() => {
      showUnchoppedText();
    }, 1500);
  }
}
const leave = () => {
  if (hoverTimeout.value) {
    clearTimeout(hoverTimeout.value);
    hoverTimeout.value = null;
    hideUnchoppedText();
  }
}

function textToDots(text: string): string {
  const maskedText = text.slice(0, 16).replace(/./g, '•');
  return maskedText + (text.length > 16 ? '...' : '');
}

const { t } = useI18n()

const actualText = computed(() => {
  const interpolation = props.withBoldInterpolation ? boldify(props.interpolation) : props.interpolation;
  const translated = props.withI18n ? t(props.text, interpolation) : props.text;
  if (props.withSensitive && !showSensitive.value) {
    return textToDots(translated);
  }
  
  return translated;
})

const displayedText = computed(() => {
  if (!displayActual.value && maxCharsChopped.value) {
    return actualText.value.slice(0, props.maxChars) + '…'
  }
  return actualText.value;
})

const showCopyTooltip = ref()
const copyToClipboard = () => {
  navigator.clipboard.writeText(actualText.value);
  clearTimeout(showCopyTooltip.value);
  showCopyTooltip.value = setTimeout(() => {
    showCopyTooltip.value = undefined;
  }, 1000);
}

const tooltipLabel = computed(() => {
  if (props.withTooltipHtml) {
    return props.withTooltip
  }
  return props.withTooltipI18n ? t(props.withTooltip || '') : props.withTooltip
})

const warningTooltipLabel = computed(() => {
  return props.withWarningTooltipI18n ? t(props.withWarningTooltip || '') : props.withWarningTooltip
});

const showCopyIcon = computed(() => {
  return (props.withCopyable || props.withCopyableOnClick) && (!props.withSensitive || showSensitive.value);
});

const copyTooltip = computed(() => ({
  placement: 'right',
  content: 'Copied!',
  shown: !!showCopyTooltip.value,
  autoHide: false,
  triggers: [],
}));

const tooltipProps = computed(() => ({
  content: tooltipLabel.value,
  html: props.withTooltipHtml,
  popperClass: 'cmc-tooltip-size-' + props.withTooltipSize,
  placement: props.withTooltipPlacement || 'auto',
}));

const warningTooltipProps = computed(() => ({
  content: warningTooltipLabel.value,
  popperClass: 'cmc-tooltip-size-' + props.withTooltipSize,
  placement: props.withTooltipPlacement || 'auto',
}));

const emit = defineEmits<{
  /**
   * Emitted when text was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()

const doClick = (event: any) => {
  // Check that you have clicked on the text or container block
  // to prevent clicks on sensitive eye icon propagating as copy clicks.
  if (props.withCopyableOnClick && (event.target.classList.contains("cmc-text-display") || event.target.classList.contains("cmc-block"))) {
    copyToClipboard();
  }
  if ((tooLong.value || maxCharsChopped.value || isLineClamped.value) && (isMobileMode() || isTabletMode())) {
    showUnchoppedText();
  }
  emit('click', event);
}

const doClickOutside = (event: any) => {
  if (rootRef.value.contains(event.target)) {
    emit("click", event);
    return;
  }
  if (tooLong.value || maxCharsChopped.value) {
    hideUnchoppedText();
  }
}

const showUnchoppedText = () => {
  if (displayActual.value) {
    return;
  }
  // dont modify the textContainerRef style if chopping text using text-clamp
  if (!props.maxLineDisplay && !isLineClamped.value) {
    // Has to be like this otherwise style.height gets set to 2x the value if done directly???
    const width = textRef.value.offsetWidth + 'px';
    const height = textRef.value.offsetHeight + 'px';
    textContainerRef.value.style.width = width;
    textContainerRef.value.style.height = height;
  }
  displayActual.value = true;
}

const hideUnchoppedText = () => {
  if (textContainerRef.value) {
    textContainerRef.value.style.width = null;
    textContainerRef.value.style.height = null;
  }
  displayActual.value = false;
}
</script>

<style>
:root {
  --ng-text: var(--ng-text-main);
  --ng-label-text: var(--ng-text-header);
}
</style>

<style scoped lang="scss">
@mixin text-clamp($line-count) {
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@for $i from 1 through 5 {
  .maxLine-#{$i} {
    @include text-clamp($i);
  }
}
.cmc-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: relative;
  color: var(--ng-text);

  &:not(.cmc-text-without-wrap) {
    overflow-wrap: anywhere;
  }

  &.cmc-text-without-wrap {
    .cmc-text-display {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.cmc-text-l {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &.cmc-text-m {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  &.cmc-text-s {
    font-size: 0.75rem;
    line-height: 0.938rem;
  }

  &.cmc-text-align-left {
    text-align: left;
  }

  &.cmc-text-align-center {
    text-align: center;
  }

  &.cmc-text-align-right {
    text-align: right;
  }

  &.cmc-text-align-justify {
    text-align: justify;
  }

  &.cmc-text-with-monospace {
    font-family: 'Roboto Mono', sans-serif;
    line-height: 1.3rem;
  }

  :deep(strong) {
    font-weight: 500;
  }

  &.cmc-text-with-bold {
    font-weight: 500;
  }

  &.cmc-text-as-error {
    font-size: 0.875rem;
    color: var(--ng-primary-red);
  }

  .cmc-text-tooltip-icon {
    color: var(--ng-text-description);
    &.cmc-text-tooltip-icon-status-report {
      color: var(--ng-primary-blue);
    }
  }

  .cmc-text-tooltip-warning-icon {
    color: var(--ng-primary-yellow);
  }

  &.cmc-text-as-description {
    color: var(--ng-text-description);
  }

  &.cmc-text-as-disabled {
    color: var(--ng-text-description);
  }

  &.cmc-text-as-clickable:hover {
    cursor: pointer;
  }

  .cmc-text-controls {
    display: inline-block;
    position: absolute;
    width: max-content;
  }

  &.cmc-text-s .cmc-text-controls {
    padding-top: 0;
  }

  &.cmc-text-m .cmc-text-controls {
    padding-top: 0.06rem;
  }

  &.cmc-text-l .cmc-text-controls {
    padding-top: 0.175rem;
  }

  .copy-icon, .show-icon {
    margin-left: 0.375rem;
    min-width: 22px;
  }

  &.cmc-text-with-copyable-on-hover {
    cursor: pointer;
    user-select: none;
    &:not(:hover) {
      .copy-icon {
        visibility: hidden;
      }
    }
  }

}

label {
  color: var(--ng-label-text);
  user-select: none;
}

.cmc-unwrapped-text-border {
  position: absolute;
  top: -0.5rem;
  left: -0.75rem;
  right: -0.75rem;
  bottom: -0.5rem;
  box-shadow: 0.063rem 0.125rem 0.625rem 0rem #00000012;
  border: 0.094rem solid var(--divider);
  background-color: var(--ng-card-bg);
  z-index: -1;
}

.cmc-text-unwrapped {
  position: absolute;
  max-width: 28.5rem;
  width: max-content;
  z-index: 5;
  display: flex;
}

.cmc-group-inline-patch {
  max-width: 100%;
}

.cmc-text-container {
  max-width: 100%;
}
</style>
