<template>
  <div 
    :title="item.tooltipLabel && !item.disabled ? $tn(item.tooltipLabel) : null" 
    :role="role" 
    class="base-item" 
    :class="{'has-short': item.short, 'with-description': item.descriptionLabel, 'disabled': item.disabled}" 
    @click="actionItem && !disabled ? $emit('click', $event) : null" 
    @keyup.enter="actionItem && !disabled ? $emit('click', $event) : null"
  >
    <div 
      v-if="item.short"
      class="short" 
    > 
      {{ item.short ? $tn(item.short) : item.value }}
    </div>
    <div class="long">
      <div>
        <div class="item-left-section">
          <div class="item-right-section">
            <base-icon
              v-if="item.subicon"
              :icon="item.subicon"
            />
            {{ item.sublabel ? $tn(item.sublabel, subinterpolation) : (item.subdisplay || item.subvalue) }}
          </div>
          <base-icon
            v-if="showSelectedIcon"
            icon="fa fa-check icon"
            :class="{blank: !selected}"
          />
          <base-icon
            v-if="item.icon"
            :icon="item.icon"
            class="icon"
          />
          <base-image
            v-if="item.image"
            class="image icon"
            :src="item.image"
            :altText="altText"
          />
          {{ item.label ? $tn(item.label, interpolationValue) : (item.display || item.value) }}
          <span
            v-if="item.required"
            class="required-item"
          >*</span>
          <slot />
          <svg-icon
            v-if="item.disabled"
            icon="information"
            class="icon-disabled"
            :title="item.tooltipLabel ? $tn(item.tooltipLabel) : null"
          />
        </div>
      </div>
      <div 
        v-if="item.descriptionLabel" 
        class="description"
      >
        {{ $tn(item.descriptionLabel) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: 'option',
    },
    showSelectedIcon: {
      type: Boolean,
      default: false,
    },
    actionItem: {
      type: Boolean,
      default: false,
    },
    interpolation: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    interpolationValue() {
      return this.item.interpolation ? this.item.interpolation : this.interpolation;
    },
    altText() {
      return this.item.label ? this.$tn(this.item.label, this.interpolationValue) :
        (this.item.display || this.item.value);
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/styles/mixins.scss';

.base-item {
  padding: 0.5rem 1rem;

  &.with-description {
    padding: 0.125rem 0.938rem;
  }

  text-align: left;
  white-space: nowrap;
  em {
      width: 0.938rem;
      text-align: center;
  }
  @include mixins.phone {
      text-align: center;
      padding: 0.625rem;
  }

  .description {
    margin-bottom: 0;
    @include mixins.phone {
      text-align: center;
    }
  }


  &.has-short {
      .short {
          @include mixins.phone {
              display: none;
          }
      }
      .long {
          @include mixins.not-phone {
              display: none;
          }
      }
  }
}

.icon-disabled {
  top: 0.125rem;
  margin-left: 0.125rem;
  height: 0.875rem;
  width: 0.875rem;
  fill: var(--ng-text-description);
  position: relative;
}

.disabled {
  background-color: var(--on-primary);
}

.item-right-section {
  float: right;
  padding-left: 1rem;
  font-size: 0.75rem;
}

.item-left-section {
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon {
  padding-right: 0.625rem;
}

.required-item {
  padding-left: 0.188rem;
}

.image {
    width: 1.125rem;
    height: 1.125rem;
    font-size: 1.125rem;
    vertical-align: middle;
}

.blank {
  visibility: hidden;
}

@include mixins.phone {
  .base-item .long {
    white-space: normal;
  }
}
</style>
