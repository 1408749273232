<template>
  <div
    :id="id ? `cmc-card-${id}` : undefined"
    :class="['cmc-card', { 'cmc-card-as-simple': asSimple}]"
  >
    <cmc-block
      v-if="includeHeader"
      :class="['cmc-card-header', {'cmc-card-header-with-collapse': withCollapse}]"
      :padding-left="withCollapse ? 's' : '2xl'"
      padding-right="2xl"
      :padding-vertical="category || description ? 'l' : 'm'"
      with-header-bg
      @click="onHeaderClick"
    >
      <cmc-pair stretch-lhs>
        <cmc-group
          spacing="3xs"
          with-vertical-align="center"
        >
          <cmc-stack 
            v-if="title || description"
            spacing="3xs"
          >
            <cmc-header-content
              :title="title"
              :with-title-i18n="withTitleI18n"
              :subtitle="category"
              :with-subtitle-i18n="withCategoryI18n"
              :with-collapse="withCollapse"
              :collapsed="collapsed"
              :count="count"
            />
            <cmc-pair
              v-if="description"
              stretch-rhs
              spacing="4xs"
            >
              <cmc-block
                v-if="withCollapse"
                padding-left="l"
              />
              <cmc-align at-left>
                <cmc-text
                  :text="description"
                  :with-i18n="withDescriptionI18n"
                  size="m"
                ></cmc-text>
              </cmc-align>
            </cmc-pair>
          </cmc-stack>
          <slot name="lhs-content"></slot>
        </cmc-group>

        <cmc-group
          take-full-width
          with-vertical-align="center"
          with-horizontal-align="right"
        >
          <slot name="rhs-content"></slot>
        </cmc-group>
      </cmc-pair>
    </cmc-block>
    <cmc-block
      :padding="padding"
      :padding-horizontal="paddingHorizontal ? paddingHorizontal : (padding || '2xl')"
      :padding-vertical="paddingVertical ? paddingVertical : (padding || 'xl')"
      :padding-top="paddingTop"
      :padding-bottom="paddingBottom"
      :class="['cmc-card-content', {'cmc-card-content-collapsed': collapsed}]"
    >
      <!--
        @slot This is will appear in the body of the card
      -->
      <slot></slot>
    </cmc-block>
  </div>
</template>

<script setup lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useSlots } from 'vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcHeaderContent from './CmcHeaderContent.vue';
import { Size } from '../sizes';

const collapsed = ref(false)

defineComponent({
  CmcTitle, CmcText, CmcStack, CmcGroup, CmcBlock, CmcGrid, CmcGridCol, CmcHeaderContent, CmcPair, CmcAlign,
})

type Props = {
  /**
   * HTML element id for the card element
   */
  id?: string;

  /**
   * Padding around the card body.
   */
  padding?: Size;

  /**
   * Vertical padding of the card body. Overrides `padding`.
   */
  paddingVertical?: Size;

  /**
   * Horizontal padding of the card body. Overrides `padding`.
   */
  paddingHorizontal?: Size;

  /**
   * Add padding on top of the block.
   */
  paddingTop?: Size;
  /**
   * Add padding on bottom of the block.
   */
  paddingBottom?: Size;
  
  /**
   * Header title of the card.
   */
  title?: string;
  /**
   * True if the header title is a label key to be translated.
   */
  withTitleI18n?: boolean;
  
  /**
   * Header category of the card.
   */
  category?: string;
  /**
   * True if the header category is a label key to be translated.
   */
  withCategoryI18n?: boolean;

  /**
   * Header description of the card.
   */
  description?: string;
  /**
   * True if the header description is a label key to be translated.
   */
  withDescriptionI18n?: boolean

  /**
   * True if card should be collapsible
   */
  withCollapse?: boolean;

  /**
   * Add a count badge in the header.
   */
  count?: number;

  /**
   * Simple look removes borders and box-shadows.
   */
  asSimple?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  withCollapse: false,
  count: 0,
});
const onHeaderClick = () => {
  if (props.withCollapse) {
    collapsed.value = !collapsed.value;
  }
};
const slots = useSlots();
const includeHeader = computed(() => {
  // Some Title might take a while to load, but we still want to include the header.
  if (props.title === '') {
    return true;
  }
  return !!props.title || !!slots['lhs-content'] || !!slots['rhs-content']
});
</script>

<style lang="scss">
:root {
  --ng-card-bg: var(--ng-primary-surface);
  --ng-card-border: var(--ng-secondary-divider);
  --ng-card-border-radius: 0.3125rem;
}
</style>

<style scoped lang="scss">
.cmc-card {
  background-color: var(--ng-card-bg);
  display: block;

  &:not(.cmc-card-as-simple) {
    border: 0.09375rem solid var(--ng-card-border);
    box-shadow:  0.5px 0.5px 3px 1px rgba(0, 0, 0, 0.05);
    border-radius: var(--ng-card-border-radius);
  }

  .cmc-card-header {
    /*
    * 4 min-height is to make sure the header is always ready to accept more content of the right-hand side without flickering.
    * The decimals value is to account for the border-bottom within this CSS class.
    */ 
    min-height: 4.09375rem;
    align-items: center;
    display: flex;
    border-bottom: 0.09375rem solid var(--ng-card-border);
    &.cmc-card-header-with-collapse {
      cursor: pointer;
      user-select: none;
    }
  }

  .cmc-card-content-collapsed {
    height: 0;
    overflow: hidden;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
