<template>
  <div class="menu-container">
    <cmc-stack
      class="menu-items-container"
      spacing="none"
    >
      <cmc-align
        v-if="withHeader !== ''"
        class="nav-menu-header"
        at-center
      >
        <cmc-title
          :title="withHeader"
          heading="h4"
          :with-i18n="withHeaderI18n"
        ></cmc-title>
      </cmc-align>
      <cmc-divider></cmc-divider>
      <cmc-nav-menu-item
        v-for="listItem in menuItems"
        v-bind="listItem"
        :key="listItem.text"
        :with-divider="withDivider"
        :spacing="spacing"
        :with-title-on-hover="withTitleOnHover"
        :with-ellipsis-on-text-overflow="withEllipsisOnTextOverflow"
        :custom-text-max-width="customTextMaxWidth"
        @click="emit('clickItem', listItem.transition)"
      >
      </cmc-nav-menu-item>
    </cmc-stack>
  </div>
</template>
  
<script setup lang="ts">
import { defineComponent } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcNavMenuItem from './CmcNavMenuItem.vue'
import { Size } from '../sizes';
import { NavigationMenuItem } from '@/app/Main/components/navigation/schemas';

defineComponent({
  CmcStack,
  CmcTitle,
  CmcDivider,
  CmcAlign,
  CmcNavMenuItem
})

interface Props {
  /**
  * The list of items to be displayed in the menu
  */
  menuItems: Array<NavigationMenuItem>;
  /**
  * The header text of the menu
  */
  withHeader?: string;
  /**
  * True if the text is a label key.
  */
  withHeaderI18n?: boolean;
  /**
  * True if each menu item is seperated by a divider
  */
  withDivider?: boolean;
  /**
  * The spacing between menu items
  */
  spacing?: Size;
  /**
  * True if the menu item text is to be displayed without wrap
  */
  withEllipsisOnTextOverflow?: boolean;
  /**
  * Max width of the text wrapper if ellipsis is to be shown
  */
  customTextMaxWidth?: string;
  /**
  * True if the title is to be displayed on hover instead of cmc-text tooltip
  */
  withTitleOnHover? : boolean;
}

withDefaults(defineProps<Props>(), {
  header: "",
  spacing: 'm',
  withDivider: false,
  withHeader: '',
  withHeaderI18n: false,
  withEllipsisOnTextOverflow: false,
  withTitleOnHover: false,
  customTextMaxWidth: '100%',
})

const emit = defineEmits<{
  (e: 'clickItem', transition?: string): void
}>()
</script>