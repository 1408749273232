<template>
  <cmc-stack padding-top="xl">
    <cmc-title
      :title="getTitle"
      withI18n
      withBold
    />
    <cmc-text
      :text="getMessage"
      withI18n
    />
    <cmc-block padding-top="3xl">
      <cmc-align at-horizontal-center>
        <cmc-button
          :text="$t('close')"
          callToAction
          @click="close"
        />
      </cmc-align>
    </cmc-block>
  </cmc-stack>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import {defineComponent, computed } from 'vue';
import CmcStack from '@/components/nextgen/layout/CmcStack.vue';
import CmcAlign from '@/components/nextgen/layout/CmcAlign.vue';
import CmcBlock from '@/components/nextgen/layout/CmcBlock.vue';
import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';
import CmcText from '@/components/nextgen/typography/CmcText.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';

defineComponent({
  CmcStack, 
  CmcAlign, 
  CmcBlock, 
  CmcTitle, 
  CmcText, 
  CmcButton 
});

const route = useRoute();

const getTitle = computed(() => {
  return route.query.title || '';
});

const getMessage = computed(() => {
  return route.query.message || '';
});

function close() {
    window.close('','_parent','');
}

</script>