import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

import { defineComponent } from 'vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcCount from './CmcCount.vue';

type Props = {
    /**
     * Title for the header.
     */
    title?: string;
    /**
     * True if the header title is a label key to be translated.
     */
    withTitleI18n?: boolean;

    /**
     * Subtitle for the header.
     */
    subtitle?: string;
    /**
     * True if the header subtitle is a label key to be translated.
     */
    withSubtitleI18n?: boolean;
    /**
     * True if card or sublist should be collapsible
     */
    withCollapse?: boolean;

    /**
     * True if card or sublist is collapsed
     */
    collapsed?: boolean;

    /**
     * Add a count badge in the header.
     */
    count?: number;

    /**
     * True if header is a part of a sublist.
     */
    asSublistHeader?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcHeaderContent',
  props: {
    title: {},
    withTitleI18n: { type: Boolean },
    subtitle: {},
    withSubtitleI18n: { type: Boolean },
    withCollapse: { type: Boolean },
    collapsed: { type: Boolean },
    count: {},
    asSublistHeader: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({
    CmcTitle, CmcText, CmcStack, CmcGroup, CmcBlock, CmcCount,
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcGroup, {
    spacing: "2xs",
    "with-vertical-align": "bottom",
    "with-horizontal-align": "left",
    class: "cmc-header-content"
  }, {
    default: _withCtx(() => [
      _createVNode(CmcGroup, {
        spacing: "2xs",
        class: "cmc-collapsible-title-wrapper",
        "with-baseline-align": ""
      }, {
        default: _withCtx(() => [
          (_ctx.withCollapse)
            ? (_openBlock(), _createBlock(CmcBlock, {
                key: 0,
                "padding-right": "4xs"
              }, {
                default: _withCtx(() => [
                  _createVNode(CmcIcon, {
                    icon: _ctx.collapsed ? 'chevron-bold-right' : 'chevron-bold-down',
                    svg: "",
                    size: "s"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.title)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 1,
                class: _normalizeClass(['cmc-header-content-title', {'cmc-sublist-header': _ctx.asSublistHeader}]),
                text: _ctx.title,
                "with-i18n": _ctx.withTitleI18n
              }, null, 8, ["class", "text", "with-i18n"]))
            : _createCommentVNode("", true),
          (_ctx.subtitle)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 2,
                class: "cmc-header-content-subtitle",
                text: _ctx.subtitle,
                "with-i18n": _ctx.withSubtitleI18n
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.count)
        ? (_openBlock(), _createBlock(CmcCount, {
            key: 0,
            count: _ctx.count
          }, null, 8, ["count"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})