<template>
  <cmc-block class="cmc-count-container">
    <cmc-text
      class="cmc-count"
      :text="formattedCount"
      size="m"
      with-bold
    />
  </cmc-block>
</template>


<script setup lang="ts">
import { computed, defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';

defineComponent({
  CmcText,
})

interface Props {
  count: number;
}

const props = defineProps<Props>()
const formattedCount = computed(() => {
  if (props.count < 10) {
    return `0${props.count}`;
  }
  return props.count.toString();
});
</script>

<style lang="scss">
  :root {
    --ng-count-bg: var(--ng-secondary-divider);
  }
</style>

<style scoped lang="scss">
  .cmc-count-container {
    background-color: var(--ng-secondary-divider);
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: -0.063rem;
    padding: 0.125rem 0.4375rem 0.125rem 0.375rem;
    border-radius: 0.125rem;
    min-width: 1rem;
    text-align: center;
    line-height: 1.063rem;
  }
</style>