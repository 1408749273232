<template>
  <Teleport 
    v-if="opened"
    :to="props.target || 'body'"
  >
    <cmc-stack
      ref="footerRef"
      class="cmc-footer"
      spacing="none"
      :style="{ 'max-height': props.height }"
    >
      <cmc-divider></cmc-divider>
      <cmc-block
        ref="innerRef" 
        padding-horizontal="2xl"
      >
        <cmc-align at-center>
          <cmc-pair 
            class="cmc-pair" 
            stretch-rhs
          >
            <cmc-block 
              v-if="canExpand" 
              padding-top="s"
              padding-right="xs"
              @click="toggle"
            >
              <cmc-icon 
                size="s"
                :icon="expanded ? 'chevron-bold-down' : 'chevron-bold-right'"
                class="chevron" 
                svg
              ></cmc-icon>
            </cmc-block>
            <div v-else></div>
            <component 
              :is="component" 
              v-bind="props.props"
              :expanded="expanded"
              @expand="toggle"
            ></component>
          </cmc-pair>
        </cmc-align>
      </cmc-block>
    </cmc-stack>
  </Teleport>
</template>

<script setup lang="ts">
import { Ref, inject, defineComponent, ref, ComponentPublicInstance, watch } from 'vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import { FooterProps } from './useFooter';

defineComponent({
  CmcStack,
  CmcBlock,
  CmcDivider,
  CmcIcon,
  CmcPair,
  CmcAlign,
})


const { opened, component, props } = inject('cmc-footer') as { component: Ref<any>, props: Ref<FooterProps>, opened: Ref<boolean> } 
  
const footerRef = ref<ComponentPublicInstance>();
const innerRef = ref<ComponentPublicInstance>();
const canExpand = ref(false);
const expanded = ref(false);

const doSizeCheck = () => {
  if (expanded.value) {
    return;
  }
  if (innerRef.value && footerRef.value) {
    canExpand.value = innerRef.value.$el.clientHeight > footerRef.value.$el.clientHeight;
  } else {
    canExpand.value = false;
  }
}

const observer = new MutationObserver(doSizeCheck);

watch([footerRef], async () => {
  try {
    if (footerRef.value?.$el) {
      doSizeCheck();
      observer.observe(footerRef.value?.$el, { childList: true, subtree: true });
    } else {
      observer.disconnect();
    }
  } catch(e) {
    console.error(e);
  }
})

const toggle = () => {
  if (innerRef.value && footerRef.value) {
    if (expanded.value) {
      footerRef.value.$el.style.maxHeight = props.value.height;
    } else {
      footerRef.value.$el.style.maxHeight = 'none';
    }
    expanded.value = !expanded.value;
  }
}
</script>

<style lang="scss">
:root {
  --ng-floating-footer-bg: var(--ng-primary-surface);
}
</style>

<style scoped lang="scss">
@use '@/styles/variables.scss';

.cmc-footer {
  background-color: var(--ng-floating-footer-bg);
  position: sticky;
  bottom: 0;
  width: 100%;
  min-height: 3.5rem;
  max-height: 3.5rem;
  max-width: variables.$main-max-width;
  border-top: 0.063rem solid var(--ng-divider);
  margin: auto;
  overflow: hidden;

}

.cmc-pair {
  max-width: 96rem;
}
  
.chevron {
  cursor: pointer;
  color: var(--ng-text-header);
}
</style>