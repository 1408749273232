<template>
  <div 
    class="language-indicators" 
    :class="[size]"
  >
    <base-indicator 
      v-if="requiredLanguages" 
      :currentItems="requiredLanguages" 
      :allItems="activeLanguagesList" 
      :size="size"
    />
    <div v-else>
      <span 
        v-for="l in languages" 
        :key="l.value" 
        class="language-badge"
        :class="{ 'green': isActive(l.value), 'gray': !isActive(l.value) }"
      >
        <base-icon :icon="isActive(l.value) ? 'fa fa-check green': 'fa fa-times gray'" />
        {{ $t(l.label) }}
      </span>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { getLanguages } from '@/i18n';

export default {
  name: 'LanguageIndicator',
  props: {
    requiredLanguages: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: 'md',
      validator: v => ['sm', 'md', 'lg'].includes(v),
    },
  },
  data() {
    const allLanguages = getLanguages();
    return {
      languages: allLanguages.map(l => ({
        value: l,
        label: `languages.${l}.short`,
      })),
    };
  },
  computed: {
    ...mapGetters(['orgBrandingInfo']),
    missingTranslation() {
      return lang => !this.requiredLanguages[lang];
    },
    isActive() {
      return lang => this.orgBrandingInfo.activeLanguages.includes(lang);
    },
    activeLanguagesList() {
      return this.orgBrandingInfo.activeLanguages.map(l => ({
        value: l,
        label: `languages.${l}.short`,
      }));
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/animations.scss';

.language-indicators {
  padding: 5px;
  padding-left:5px;

  .language-badge {
    padding-top: 10px;
    margin-right: 6px;
    font-size: 0.8rem
  }

  &.sm {
    .language-badge {
      font-size: 0.7rem;
    }
  }

  &.lg {
    .language-badge {
      font-size: 0.9rem;
    }
  }
}
</style>
