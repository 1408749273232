<template>
  <inline-svg
    v-if="svg"
    :src="iconSrc"
    :aria-label="icon"
    :aria-hidden="ariaHidden"
    :class="[
      'cmc-icon',
      'cmc-icon-svg',
      `cmc-icon-${color}`,
      { 'cmc-icon-with-clickable': withClickable, 'spinner': spin },
    ]"
    :width="remSize"
    :height="remSize"
    :keep-during-loading="true"
    @click="emit('click', $event)"
  />
  <img
    v-else-if="img"
    :alt="icon"
    :src="icon"
    :style="{ height: remSize, width: remSize }"
  />
  <em
    v-else
    :aria-label="ariaLabel"
    :aria-hidden="ariaHidden"
    :class="[
      brandedIcon,
      'cmc-icon',
      `cmc-icon-${color}`,
      { 'cmc-icon-with-clickable': withClickable, 'spinner': spin },
    ]"
    :style="{ fontSize: remSize, height: remSize, width: remSize }"
    @click="emit('click', $event)"
  ></em>
</template>

<script setup lang="ts">
import InlineSvg from 'vue-inline-svg';
import {computed} from 'vue';
import {useStore} from 'vuex';
import {getSizeInRem, Size} from '../sizes';

type Color = 'white' | 'green' | 'red' | 'blue' | 'yellow' | 'gray';

type Props = {
  /**
   * Icon name. This will be prefixed by "fa fa-" if not svg.
   */
  icon: string;
  /**
   * True if the icon is an svg icon. This is the name of the file in public/static/icons/{name}.svg.
   */
  svg?: boolean;
    /**
   * True if the icon is a URL that has the location of the icon.
   */
  img?: boolean;

  /**
   * Size of the icon. We use t-shirt sizing which is standard across the app.
   */
  size?: Size;
  /**
   * Custom size in rem of the icon. Overrides size prop.
   */
  customSize?: number;

  /**
   * Color of the icon. Built-in colors.
   */
  color?: Color;

  /**
   * Should cursor be a pointer on hover.
   */
  withClickable?: boolean;

  /**
   * Aria-label for accessibility
   */
  ariaLabel?: string;
  /**
   * Aria-hidden for accessibility
   */
  ariaHidden?: boolean;

  /**
   * Animate the icon with a spin animation
   */
  spin?: boolean;

  /**
   * To update the path to fetch the icon if it is a service plugin icon
   */
  asPluginIcon?: boolean;
}

interface OrgBrandingInfo {
  customIcons: Record<string, string>;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'm'
});

const orgBrandingInfo = computed(() => {
  const store = useStore();
  return store.getters.orgBrandingInfo as OrgBrandingInfo;
});

const brandedIcon = computed(() => {
  if (props.svg) {
    return props.icon;
  }
  const prefixedIcon = `fa fa-${props.icon}`;
  return (
    (orgBrandingInfo.value?.customIcons || {})[prefixedIcon] || prefixedIcon
  );
});

const remSize = computed(() => props.customSize ? `${props.customSize}rem` : getSizeInRem(props.size));

const iconSrc = computed(() => {
  try {
    if(props.asPluginIcon) {
      return require(`@/../public/static/icons/plugins/${props.icon}.svg`);
    }
    return require(`@/../public/static/icons/nextgen/${props.icon}.svg`);
  } catch(_err) {
    return undefined;
  }
})

const emit = defineEmits<{
  /**
   * Emitted when block was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()
</script>

<style lang="scss">
:root {
  --ng-icon-green: var(--ng-primary-green);
  --ng-icon-blue: var(--ng-primary-blue);
  --ng-icon-red: var(--ng-primary-red);
  --ng-icon-yellow: var(--ng-primary-yellow);
  --ng-icon-gray: var(--ng-text-description);
}
</style>

<style scoped lang="scss">
svg {
  fill: currentColor;
}

.cmc-icon {
  color: var(--ng-text-main);

  &.cmc-icon-with-clickable {
    cursor: pointer;
  }
  &.cmc-icon-white {
    color: white;
  }
  &.cmc-icon-green {
    color: var(--ng-icon-green);
  }

  &.cmc-icon-blue {
    color: var(--ng-icon-blue);
  }

  &.cmc-icon-yellow {
    color: var(--ng-icon-yellow);
  }
  &.cmc-icon-gray {
    color: var(--ng-icon-gray);
  }

  &.cmc-icon-red {
    color: var(--ng-icon-red);
  }

  &.cmc-icon-text-light {
    color: var(--ng-text-description);
  }

  &.cmc-icon-tooltip-enabled {
    pointer-events: auto;
  }
}


.spinner {
  animation: rotate-360 2s linear infinite;
  z-index: 2;
}

@keyframes rotate-360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
