import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import CmcChart from "@/components/nextgen/charts/CmcChart.vue";
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import { computed, getCurrentInstance, ref } from "vue";
import merge from "deepmerge";
import { BarSeriesOption, EChartsOption } from "echarts";
import { LegendOption, TooltipOption, GridOption } from "echarts/types/dist/shared";
import { ToolboxOption } from "echarts/types/src/component/toolbox/ToolboxModel";
import useCmcChartEventListeners from "@/components/nextgen/composables/useCmcChartEventListeners";
import {
  GraphTextStyle,
  EChartStyleOptions,
  GraphColor
} from '@/components/nextgen/charts/branding';

interface Props {
  /** THIS BATCH OF PROPS IS ORIGINALLY FROM CMCCHARTPROPS */

  /**
   * The default colors used for the chart.
   * Specific colors can be specified for each series
   */
  colors?: GraphColor[];

  /**
   * The default text style used for the chart.
   */
  textStyle?: GraphTextStyle;

  /**
   * The inherited html classes from custom implementation
   */
  class?: Record<string, any>,

  /**
   * The inherited css inline styles from custom implementation
   */
  style?: Record<string, any>
  /** END OF CMCCHARTPROPS */

  /** START OF CMCCHARTVISIBLEPROPS */
  /**
     * The chart tooltip options
     */
    tooltip?: Omit<TooltipOption, EChartStyleOptions>;

  /**
   * The chart legend options
   */
  legend? : Omit<LegendOption, EChartStyleOptions>;

  /**
   * The chart grid options
   */
  grid?   : Omit<GridOption, EChartStyleOptions>;

  /**
   * The chart toolbox options
   */
  toolbox?: Omit<ToolboxOption, EChartStyleOptions>;
  /** END OF CMCCHARTVISIBLEPROPS */

  /** START ACTUAL PROPS WHICH EXTEND ABOVE */
  
  /**
   * If we wish to swap the axes (x -> y) and (y -> x) and thus have horizontal bars
   */
  switchAxis?: boolean;

  /**
   * True if we wish to display the legend
   */
  showLegends?: boolean;

  /**
   * Custom legend, allows you to configure the display style and texts
   */
  customLegends?: any[],

  /**
   * The labels displayed on the x-axis
   */
  labels?: any[]

  /**
   * The series to display on the chart.
   * The style options are not supported, this is why they are omitted (EChartStyleOptions) from the base type of the series.
   * this is to forget to go through the colors and textStyles props and to reinforce the conformity of the design.
   * @see {@link https://echarts.apache.org/en/option.html#series-bar}
   */
  series: Omit<BarSeriesOption, EChartStyleOptions>[];

  /**
   * Additional set of various options that will be merged with the known ones
   * @see CmcChart.props.customOptions
   * @see {@link https://echarts.apache.org/en/option.html}
   */
  customOptions?: EChartsOption;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcBarChart',
  props: {
    colors: {},
    textStyle: {},
    class: {},
    style: {},
    tooltip: {},
    legend: {},
    grid: {},
    toolbox: {},
    switchAxis: { type: Boolean, default: false },
    showLegends: { type: Boolean, default: true },
    customLegends: {},
    labels: {},
    series: {},
    customOptions: {}
  },
  emits: ['click', 'legendSelectChanged'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

use([BarChart]);

/** 
 * THERE IS A BUG IN VUE3 with TYPESCRIPT in which we can chain several interfaces together if one is imported from another file
 * The solution is that we unfortunately need to list off this huge chain (Props + CmcChartVisibleProps + CmcChartProps), 
 * and this causes issues with Jest testing.  We should eventually retest this in mid 2025 to see if it Typescript support has improved. 
*/
const props = __props;

const tooltipOptions = computed(() => ({
  trigger: 'axis',
  confine: true,
  axisPointer: {
    type: 'shadow',
  }
}));

const legendOptions = computed(() => ({
  data: props.customLegends || props.series.map(s => ({ name: s.name})),
  show: props.showLegends,
  height: 100,
  left: '10%',
  padding: 0,
  itemGap: 50,
  textStyle: {
    maxWidth: 500,
    overflow: "break"
  }
}));

const toolboxOptions = computed(() => ({
  show: false,
  feature: {
    saveAsImage: {
      show: false,
      title: 'save',
    },
  }
}));

const seriesOptions = computed(() => props.series.map(s => merge({
    type: 'bar',
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    selectedMode: 'multiple'
  }, s))
);

const customOptionsMerged = computed(() => {
  const xAxis = {
    type: 'category',
    data: props.labels,
    axisLabel: {
      interval: '0',
    },
  };
  const yAxis = {
    type: 'value',
  };
  const optionsOverride = {
    xAxis: props.switchAxis ? yAxis : xAxis,
    yAxis: props.switchAxis ? xAxis : yAxis,
  };
  return props.customOptions ?
    merge(optionsOverride, props.customOptions) :
    optionsOverride;
});

const chartProps = computed(() => ({
  ...props,
  series : seriesOptions.value as BarSeriesOption[],
  tooltip: tooltipOptions.value as TooltipOption,
  legend : legendOptions.value as LegendOption,
  toolbox: toolboxOptions.value as ToolboxOption,
  customOptions: customOptionsMerged.value as EChartsOption
}));

const emit = __emit;
const eventListeners = useCmcChartEventListeners(getCurrentInstance(), emit);

const cmcChart = ref<InstanceType<typeof CmcChart>>();
__expose({
  updateLegends: (selectedLegends: any) => cmcChart.value?.updateLegends(selectedLegends),
  toggleAllLegends: (allLegends: string[], enable: boolean) => cmcChart.value?.toggleAllLegends(allLegends, enable)
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcChart, _mergeProps({
    ref_key: "cmcChart",
    ref: cmcChart
  }, chartProps.value, {
    onClick: _unref(eventListeners).handleClick,
    onLegendSelectChanged: _unref(eventListeners).handleLegendSelectChanged
  }), {
    "tooltip-template": _withCtx(() => [
      _renderSlot(_ctx.$slots, "tooltip-template")
    ]),
    _: 3
  }, 16, ["onClick", "onLegendSelectChanged"]))
}
}

})