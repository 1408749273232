import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useRoute } from 'vue-router';
import {defineComponent, computed } from 'vue';
import CmcStack from '@/components/nextgen/layout/CmcStack.vue';
import CmcAlign from '@/components/nextgen/layout/CmcAlign.vue';
import CmcBlock from '@/components/nextgen/layout/CmcBlock.vue';
import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';
import CmcText from '@/components/nextgen/typography/CmcText.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OAuthResult',
  setup(__props) {

defineComponent({
  CmcStack, 
  CmcAlign, 
  CmcBlock, 
  CmcTitle, 
  CmcText, 
  CmcButton 
});

const route = useRoute();

const getTitle = computed(() => {
  return route.query.title || '';
});

const getMessage = computed(() => {
  return route.query.message || '';
});

function close() {
    window.close('','_parent','');
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcStack, { "padding-top": "xl" }, {
    default: _withCtx(() => [
      _createVNode(CmcTitle, {
        title: getTitle.value,
        withI18n: "",
        withBold: ""
      }, null, 8, ["title"]),
      _createVNode(CmcText, {
        text: getMessage.value,
        withI18n: ""
      }, null, 8, ["text"]),
      _createVNode(CmcBlock, { "padding-top": "3xl" }, {
        default: _withCtx(() => [
          _createVNode(CmcAlign, { "at-horizontal-center": "" }, {
            default: _withCtx(() => [
              _createVNode(CmcButton, {
                text: _ctx.$t('close'),
                callToAction: "",
                onClick: close
              }, null, 8, ["text"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})