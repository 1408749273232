<template>
  <cmc-group
    spacing="2xs"
    with-vertical-align="bottom"
    with-horizontal-align="left"
    class="cmc-header-content"
  >
    <cmc-group
      spacing="2xs"
      class="cmc-collapsible-title-wrapper"
      with-baseline-align
    >
      <cmc-block
        v-if="withCollapse"
        padding-right="4xs"
      >
        <cmc-icon
          :icon="collapsed ? 'chevron-bold-right' : 'chevron-bold-down'"
          svg
          size="s"
        />
      </cmc-block>
      <cmc-text
        v-if="title"
        :class="['cmc-header-content-title', {'cmc-sublist-header': asSublistHeader}]"
        :text="title"
        :with-i18n="withTitleI18n"
      />
      <cmc-text
        v-if="subtitle"
        class="cmc-header-content-subtitle"
        :text="subtitle"
        :with-i18n="withSubtitleI18n"
      />
    </cmc-group>
    <cmc-count
      v-if="count"
      :count="count"
    />
  </cmc-group>
</template>
  
<script setup lang="ts">
import { defineComponent } from 'vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcCount from './CmcCount.vue';

defineComponent({
    CmcTitle, CmcText, CmcStack, CmcGroup, CmcBlock, CmcCount,
})

type Props = {
    /**
     * Title for the header.
     */
    title?: string;
    /**
     * True if the header title is a label key to be translated.
     */
    withTitleI18n?: boolean;

    /**
     * Subtitle for the header.
     */
    subtitle?: string;
    /**
     * True if the header subtitle is a label key to be translated.
     */
    withSubtitleI18n?: boolean;
    /**
     * True if card or sublist should be collapsible
     */
    withCollapse?: boolean;

    /**
     * True if card or sublist is collapsed
     */
    collapsed?: boolean;

    /**
     * Add a count badge in the header.
     */
    count?: number;

    /**
     * True if header is a part of a sublist.
     */
    asSublistHeader?: boolean;
}

defineProps<Props>()
</script>
  
<style scoped lang="scss">
.cmc-header-content {
  max-height: 1.125rem;
}
.cmc-header-content-title {
    font-size: 1.125rem !important;
    line-height: 1.375rem !important;
    font-weight: 400;
    font-family: "Inter";
    color: var(--ng-text-main);
}

.cmc-header-content-title.cmc-sublist-header {
    font-size: 1rem !important;
    line-height: 1.188rem !important;
    font-weight: 500; 
    font-family: "Inter";
    color: var(--ng-text-header);
}

.cmc-header-content-subtitle {
    font-size: 0.875rem !important;
    line-height: 1.063rem !important;
    font-weight: 400;
    font-family: "Inter";
    color: var(--ng-text-header);
}

.cmc-header-content-title,
.cmc-header-content-subtitle {
    :deep(.cmc-group-inline-patch) {
        display: inline;
    }
}
</style>
  