<template>
  <div 
    ref="fixed"
    class="fixed-element" 
    :class="{ fixed }" 
    :style="customTransform" 
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FixedElement',
  props: {
    containerSelector: undefined,
    startOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      listener: null,
      scrollParent: null,
      fixed: false,
      translateOffset: 0,
    };
  },
  computed: {
    customTransform() {
      if (this.fixed) {
        return { transform: `translateY(${this.translateOffset}px` };
      }
      return {};
    },
  },
  beforeUnmount() {
    this.scrollParent.removeEventListener('scroll', this.listener);
  },
  mounted() {
    let initialScrollParent = document.querySelector(this.containerSelector)  || undefined;
    this.scrollParent = this.getScrollElement(initialScrollParent);
    this.listener = () => {
      this.fixed = this.startOffset <= this.scrollParent.scrollTop;
      this.translateOffset = this.scrollParent.scrollTop - this.startOffset;
    };
    this.scrollParent.addEventListener('scroll', this.listener, { passive: true });
  },
  methods: {
    getScrollElement(elm = this.$el) {
      if (['scroll', 'auto'].indexOf(getComputedStyle(elm).overflowY) > -1) {
        return elm;
      } else if (elm.parentNode) {
        return this.getScrollElement(elm.parentNode);
      }
      return window;
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';

.fixed-element {
  @include mixins.not-phone {
    &.fixed {
        z-index: -1;
    }
  }
  @include mixins.phone {
    transform: translateY(0) !important;
  }
}
</style>
