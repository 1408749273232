<!-- eslint-disable vue/no-v-html -->
<template>
  <base-form
    v-if="organization"
    :executing="executing && !openCurrencyChangeConfirmModal"
    :defaultBack="{ name: 'organizationDetails' }"
    :disabled="invalid"
    @submit="confirmCurrencyChange"
    @cancel="cancel"
  >
    <form-row
      label="name"
      class="org-name"
      :error="$t(validation.name) || errors.name"
    >
      <base-input
        v-model="modifiedOrg.name"
        :maxLength="50"
        @blur="onBlurName"
      />
    </form-row>

    <form-row
      :label="isSingleEntrypointLogin ? 'url_friendly_name' : 'entrypoint'"
      :error="invalidEntryPoint ? $t('entry_point_not_available') : errors.entryPoint"
    >
      <div class="entrypoint">
        <base-input
          v-model="modifiedOrg.entryPoint"
          :maxLength="50"
          class="url-input"
          :modelValue="modifiedOrg.entryPoint"
          @blur="onBlurEntryPoint"
        />
        <span
          v-if="!emptyEntryPoint && !isSingleEntrypointLogin"
          class="url-line"
        >
          <base-icon icon="fa fa-arrow-circle-right" />
          <span v-html="orgUrl"></span>
        </span>
      </div>
    </form-row>

    <form-row
      label="logo"
      optional
    >
      <base-input v-model="modifiedOrg.image" />
    </form-row>

    <form-row
      v-if="!isNewOrganization && canManageCustomDomain && modifiedOrg.isReseller"
      label="custom_domain"
      descriptionLabel="custom_domain_description"
    >
      <base-select
        v-if="verifiedDomainOptions.length"
        v-model="customDomain"
        :items="verifiedDomainOptions"
        :placeholder="$t('select_verified_domain')"
        :clearable="true"
      />
      <alert-box
        v-else
        border
        alertType="INFO"
        label="must_have_verified_domains"
      />
    </form-row>

    <form-row v-if="!isNewOrganization && canManageCustomDomain && customDomain && modifiedOrg.isReseller">
      <alert-box
        v-if="ipAddressesConfigured"
        border
        alertType="INFO"
        label="configure_dns_record"
        :interpolation="interpolateIpAddresses"
      />
      <alert-box
        v-else
        border
        alertType="WARNING"
        label="cloudmc_ip_addresses_not_configured"
      />
    </form-row>

    <form-row
      v-if="hasBillingPermission && !organization.isTrial && !hideBillableToggle"
      label="billable"
    >
      <div class="billing">
        <base-toggle
          v-model="isBillable"
          class="toggle"
        ></base-toggle>
      </div>
    </form-row>
    <form-row
      v-if="canManageBilling"
      label="billable_start_date"
    >
      <date-input
        :modelValue="hasPricingPackages ? modifiedBillableOrgInfo.billableStart : modifiedOrg.billableStartDate"
        @update:modelValue="setBillableStart($event)"
      />
    </form-row>
    <form-row
      v-if="canManageBilling && hasPricingPackages"
      label="billable_end_date"
      :error="$t(validation.billableEnd)"
    >
      <date-input
        :modelValue="modifiedBillableOrgInfo.billableEnd"
        :minDate="minimumEndDate"
        allowEmpty
        :disabled="noEndDate"
        @update:modelValue="(value) => modifiedBillableOrgInfo.billableEnd = value"
      />
    </form-row>
    <form-row
      v-if="canManageBilling && hasPricingPackages"
      optional
    >
      <base-checkbox
        v-model="noEndDate"
        sm
        label="monetization.no_end_date"
      ></base-checkbox>
    </form-row>
    <form-row
      v-if="canManageBilling"
      label="billing_mode"
    >
      <base-select
        v-model="modifiedOrg.billingMode"
        :items="getBillingModes"
      ></base-select>
    </form-row>

    <form-row
      v-if="canManageBilling && hasPricingPackages"
      label="pricing_package"
    >
      <div class="select-package">
        <base-select
          v-model="orgPricingPackage"
          :disabled="isAssignedPackage"
          :items="getPricingPackages"
          @update:modelValue="pricingPackageChanged"
        />
        <action-icon
          v-if="isAssignedPackage"
          v-permission="'reseller:billing'"
          icon="fa fa-edit"
          expandOnHover
          tooltipLabel="monetization.edit_billing_cycle_package"
          :to="{ name: 'billable_org_info_edit', query: { s: sourcePage }}"
        />
      </div>
    </form-row>

    <form-row
      v-if="canManageBilling && hasPricingPackages"
      label="currency"
    >
      <base-select
        v-model="modifiedBillableOrgInfo.currency"
        :items="orgCurrencyOptions"
      ></base-select>
    </form-row>

    <form-row
      v-if="canManageTags"
      label="tags"
    >
      <tag-select
        :modelValue="tagValues"
        :options="tagOptions"
        @addTag="addTag"
        @removeTag="removeTag"
      ></tag-select>
    </form-row>

    <form-row
      v-if="canManageOrgMeta"
      label="notes"
    >
      <markdown-editor v-model="modifiedOrg.notes"></markdown-editor>
    </form-row>


    <CustomFieldComponent
      :customFields="customFields"
      :routeName="$route.name"
      :locale="locale"
      :model="modifiedOrg"
      :errors="errors.customFieldErrors"
      @update:modelValue="updateCustomField"
    />

    <OrgCurrencyChangeModal
      v-if="openCurrencyChangeConfirmModal"
      :open="openCurrencyChangeConfirmModal"
      :orgId="organization.id"
      :currency="modifiedBillableOrgInfo.currency"
      @submit="submit"
      @close="() => openCurrencyChangeConfirmModal = false"
    />
  </base-form>
</template>

<script>

import {mapGetters} from 'vuex';
import authz from '@/authz';
import validators from '@/utils/validators';
import apis from '@/utils/apis';
import notify from '@/utils/notify';

import {sortBy} from '@/utils';
import {getDate, getNow, isBefore, startOfDay} from '@/utils/dates';
import {pricingMixin} from "@/mixins/pricing";
import OrgCurrencyChangeModal from "@/app/Main/Organizations/currency-change/OrgCurrencyChangeModal.vue";
import {billingCycleMixin} from "@/mixins/billingCycleMixin";
import CustomFieldComponent from "@/app/Main/Administration/customfields/CustomFieldComponent.vue";


export default {
  name: 'OrganizationForm',
  components: {OrgCurrencyChangeModal,CustomFieldComponent},
  mixins: [pricingMixin, billingCycleMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
    executing: {
      type: Boolean,
    },
    errors: {
      type: Object,
    },
    hideBillableToggle: {
      type: Boolean,
    },
    sourcePage: {
      type: String,
      required: false
    },
  },
  emits: ['submit','cancel', 'removeTag'],
  data() {
    return {
      modifiedOrg: {
        ...this.organization,
        users: null,
        quotas: null,
        ldap: null,
        serviceConnections: this.organization.serviceConnections ?
          this.organization.serviceConnections : null,
        isBillable: this.organization.isBillable,
        name: this.organization.name,
        entryPoint: this.organization.entryPoint,
        image: this.organization.image,
        billingMode: this.organization.billingMode,
        tags: this.organization.tags || [],
        notes: this.organization.notes,
      },
      verifiedDomains: [],
      pricingPackage: null,
      effectivePricing: undefined,
      isAssignedPackage: false,
      originalCurrency: null,
      modifiedBillableOrgInfo: {
        pricingPackage: null,
        billableStart: null,
        billableEnd: null,
        currency: null
      },
      allTags: [],
      customFields: [],
      pricingPackages: [],
      dateFormat: 'YYYY-MM-DD',
      originalEntryPoint: this.organization.entryPoint,
      properties: {},
      invalidEntryPoint: null,
      authOptions: [{
        i18n: true,
        value: 'db',
        name: 'local_db_auth',
      },
        {
          i18n: true,
          value: 'ldap',
          name: 'ldap_auth',
        }],
      billingModes: [{
        label: 'manual',
        value: 'MANUAL',
      },
        {
          label: 'credit_card',
          value: 'CREDIT_CARD',
        },
      ],
      openCurrencyChangeConfirmModal: false,
      supportedBillingCurrencies: [],
      validation: {
        name: '',
        billableEnd: '',
      },
      noEndDate: false,
    };
  },
  computed: {
    displayDate() {
      return this.modifiedBillableOrgInfo.billingCycles
        .filter(b => b.state === 'IN_PROGRESS').reduce((acc, b) => {
          if (!acc) {
            return b.startDate;
          }
          return isBefore(acc, b.startDate) ? acc : b.startDate;
        }, null);
    },
    tagValues() {
      return [...this.modifiedOrg.tags].sort(sortBy(t => !t.system)).map(t => t.name);
    },
    tagOptions() {
      return (this.allTags || [])
        .map(t => ({
          value: t.name,
          disabled: t.system || this.pricingPackageTags.includes(t.name),
          remove: () => this.$emit('removeTag', t.name),
        })).sort(sortBy(t => !t.disabled));
    },
    canToggleBillable() {
      return !this.isUpdate && !this.modifiedOrg.isBillable;
    },
    systemTags() {
      return this.allTags.filter(t => t.system).map(t => t.name);
    },
    invalid() {
      return this.emptyName || this.emptyEntryPoint || (this.isBillable && (this.hasPricingPackages ? !this.modifiedBillableOrgInfo.billableStart : !this.modifiedOrg.billableStartDate)) || this.missingRequiredCustomField;
    },
    emptyName() {
      return !this.modifiedOrg.name || !this.modifiedOrg.name.trim();
    },
    missingRequiredCustomField() {
      return (this.customFields || [])
        .some(field => (field.mode === 'ALPHANUMERIC' && field.modeOptions?.isRequired) && (!this.modifiedOrg[field.field] || this.modifiedOrg[field.field] === ''));
    },
    emptyEntryPoint() {
      return !this.modifiedOrg.entryPoint || !this.modifiedOrg.entryPoint.trim();
    },
    minimumEndDate() {
      const start = new Date(this.modifiedBillableOrgInfo.billableStart);
      const today = startOfDay(getNow());
      return isBefore(start, today) ? today : start;
    },
    isBillable: {
      get() {
        return this.modifiedOrg.isBillable;
      },
      set(b) {
        if (b) {
          this.modifiedOrg.tags = [{ name: 'billable', system: true }, ...this.modifiedOrg.tags];
        } else {
          this.modifiedOrg.tags = this.modifiedOrg.tags.filter(t => t.name !== 'billable');
        }
        this.modifiedOrg.isBillable = b;
        if (b && !this.modifiedBillableOrgInfo.billableStart) {
          const currentDate = getNow();
          this.modifiedOrg.billableStartDate = currentDate;
          if (this.hasPricingPackages) {
            this.modifiedBillableOrgInfo.billableStart = currentDate;
          } else {
            this.modifiedBillableOrgInfo.billableStart = null;
          }
        }
      },
    },
    getBillingModes() {
      return this.billingModes;
    },
    orgPricingPackage: {
      get() {
        return this.pricingPackage;
      },
      set(pricingPackageId) {
        if (pricingPackageId) {
          this.pricingPackage = pricingPackageId;
        } else {
          this.pricingPackage = null;
        }
      },
    },
    hasPricingPackages() {
      return this.pricingPackages.length !== 0;
    },
    updatedOrg() {
      return {
        ...this.modifiedOrg,
        tags: this.canManageOrgMeta ? this.modifiedOrg.tags.filter(t => !t.system) : null,
        notes: this.canManageOrgMeta ? this.modifiedOrg.notes : null,
        ldap: this.canManageOrgMeta ? this.modifiedOrg.ldap : null,
        customDomain: (this.canManageCustomDomain && this.modifiedOrg.isReseller)
          ? this.modifiedOrg.customDomain : null,
      };
    },
    updatedBillableOrgInfo() {
      return !this.modifiedOrg.isBillable || !this.hasPricingPackages ? null :
        {
          id: this.modifiedBillableOrgInfo.id ? this.modifiedBillableOrgInfo.id : null,
          billableStart: this.modifiedOrg.isBillable
            ? this.$date(this.modifiedBillableOrgInfo.billableStart, this.dateFormat) : null,
          billableEnd: this.modifiedOrg.isBillable && this.modifiedBillableOrgInfo.billableEnd && !this.noEndDate
            ? this.$date(this.modifiedBillableOrgInfo.billableEnd, this.dateFormat) : null,
          pricingPackage: {
            id: this.pricingPackage,
          },
          billingMode: this.modifiedOrg.billingMode,
          currency: this.modifiedBillableOrgInfo.currency
        };
    },
    getPricingPackages() {
      return this.pricingPackages.length === 0 ? [] :
        this.pricingPackages.filter(pp => pp.status !== 'EXPIRED').map(pp => (
          {
            label: pp.name[this.locale],
            value: pp.id,
          }
        ));
    },
    ...mapGetters([
      'myOrganization',
      'globalProperties',
      'isSingleEntrypointLogin',
      'selectedOrganization',
      'locale',
      'userContext',
    ]),
    orgUrl() {
      // Careful where you break line, it adds space in the output.
      return `${
        this.globalProperties.protocol}://<strong>${
        this.modifiedOrg.entryPoint}</strong>.${
        this.globalProperties['public.host']}<span v-if="${
        this.globalProperties['public.port']}">:${
        this.globalProperties['public.port']}</span>`;
    },
    canManageOrgMeta() {
      return authz.hasPermission('reseller:orgsMeta');
    },
    canManageCustomDomain() {
      return authz.hasPermission('reseller:orgs');
    },
    hasBillingPermission() {
      return authz.hasPermission('reseller:organizationBilling') && (this.organization.id !== this.myOrganization.id
        || this.isRootOrg);
    },
    isOperator() {
      return this.userContext.primaryRole === 'operator';
    },
    canManageTags() {
      return this.canManageOrgMeta && (this.organization.id !== this.myOrganization.id
        || this.isRootOrg);
    },
    isRootOrg() {
      return !this.organization.parent;
    },
    canManageBilling() {
      return this.isBillable && this.hasBillingPermission;
    },
    customFieldsToDisplay() {
      const fields = this.customFields?.map(f => ({
          ...f,
          values: f.values?.map(t => ({
            value: t.value,
            translationMap: t.translationMap,
            label: t.translationMap[this.locale]
          })),
        })
      ) || [];
      if (this.$route.name === 'addOrganization') {
        return fields.filter(cf => !(cf.mode === 'AUTOGENERATED'));
      }
      return fields;
    },
    verifiedDomainOptions() {
      return Object.values(this.verifiedDomains)
        .map(d => ({
          value: d.id,
          label: d.domain,
        }));
    },
    customDomain: {
      get() {
        return this.modifiedOrg.customDomain ? this.modifiedOrg.customDomain.id : null;
      },
      set(newVal) {
        if (newVal) {
          this.modifiedOrg['customDomain'] = { id: newVal };
        } else {
          this.modifiedOrg['customDomain'] = null;
        }
      },
    },
    ipAddressesConfigured() {
      return this.ipAddresses && this.ipAddresses.length > 0;
    },
    interpolateIpAddresses() {
      return { ips: this.ipAddresses
          .reduce((list, ip) => list.concat(`\n- ${ip.trim()}`), ''),
      };
    },
    isNewOrganization() {
      return !this.organization.id;
    },
    ipAddresses() {
      if (!this.globalProperties['cloudmc.ip_addresses']) {
        return undefined;
      }
      return this.globalProperties['cloudmc.ip_addresses'].split(',');
    },
    pricingPackageTags() {
      const pricingPackageId = this.modifiedBillableOrgInfo.pricingPackage ?
        this.modifiedBillableOrgInfo.pricingPackage.id : '';
      const selectedPricingPackage = this.pricingPackages.filter(pp =>
        pp.id === pricingPackageId)[0];
      return (!selectedPricingPackage || !selectedPricingPackage.tags) ?
        [] : selectedPricingPackage.tags.map(t => t.name);
    },
    currencyChanged() {
      return this.modifiedBillableOrgInfo.currency !== this.originalCurrency;
    },
    orgCurrencyOptions() {
      let curr = []

      if (this.effectivePricing != undefined) {
        curr = this.supportedBillingCurrencies
          .filter(c => this.effectivePricing.supportedCurrencies.includes(c))
          .reduce((obj, code) => {
            obj.push({
              label: code,
              value: code
            });
            return obj;
          }, [])
      }

      // We have to ensure that the current currency is always an option even if it's not in effectivePricing...
      if (this.originalCurrency && !curr.find(c => c.value == this.originalCurrency)) {
        curr.push({
          label: this.originalCurrency,
          value: this.originalCurrency
        })
      }

      return curr;
    },
  },
  async created() {
    await this.fetchCustomFields();
    if (this.canManageOrgMeta) {
      const tagsResp = await apis.tags.list({
        qs: { organization_id: this.organization.id },
      });
      this.allTags = tagsResp.data;
    }
    if (!this.isNewOrganization && this.canManageCustomDomain && this.modifiedOrg.isReseller) {
      await this.fetchVerifiedDomains();
    }
    if (this.canManageBilling) {
      await this.fetchBillableOrganizationInfo();
    }
    if (this.hasBillingPermission && (this.isOperator || await this.isMonetizationEnabled())) {
      await Promise.all([
        this.fetchPricingPackages(),
        this.fetchSupportedBillingCurrencies()
      ])
    }
  },
  methods: {
    updateCustomField(fieldName, value) {
      if (value === undefined) {
        delete this.modifiedOrg[fieldName];
      } else {
        this.modifiedOrg[fieldName] = value;
      }
    },
    confirmCurrencyChange() {
      if (this.currencyChanged && this.originalCurrency) {
        this.openCurrencyChangeConfirmModal = true;
        return;
      }
      this.submit();
    },
    addTag(tag) {
      if (!this.systemTags.includes(tag.trim())) {
        this.modifiedOrg.tags = [...this.modifiedOrg.tags, {
          name: validators.cleanTag(tag),
          system: false,
        }];
      }
    },
    removeTag(tag) {
      if (!this.systemTags.includes(tag) && !this.pricingPackageTags.includes(tag)) {
        this.modifiedOrg.tags = this.modifiedOrg.tags.filter(t => t.name !== tag);
      }
    },
    setBillableStart(date) {
      if (this.hasPricingPackages) {
        this.modifiedBillableOrgInfo.billableStart = date;
        if (getDate(date) > getDate(this.modifiedBillableOrgInfo.billableEnd)) {
          this.modifiedBillableOrgInfo.billableEnd = null;
        }
      }
      this.modifiedOrg.billableStartDate = date;
    },
    validateOrgName(org) {
      if (org.name.length < 2 || org.name.length > 50) {
        return 'org_name_incorrect_length';
      } else if (!org.name.charAt(0).match(/^[0-9a-zA-Z]$/)) {
        return 'incorrect_first_character';
      }
      return null;
    },
    validateBillableInfo(info) {
      if (!!info && !!info.billableEnd) {
        if (isBefore(info.billableEnd, info.billableStart)) {
          return 'end_before_start_error';
        }
        if (isBefore(info.billableEnd, startOfDay(getNow()))) {
          return 'end_before_current';
        }
      }
      return null;
    },
    async submit() {
      if (this.modifiedOrg.isBillable && !this.pricingPackage && this.hasBillingPermission
        && this.hasPricingPackages) {
        notify.error(this.$t('invalid_billable_config_error'));
      } else {
        const orgNameErrors = this.validateOrgName(this.modifiedOrg);
        const billableOrgInfoErrors = this.validateBillableInfo(this.updatedBillableOrgInfo);
        if (orgNameErrors || billableOrgInfoErrors) {
          this.validation.name = orgNameErrors;
          this.validation.billableEnd = billableOrgInfoErrors;
        } else {
          this.$emit('submit', this.updatedOrg, this.updatedBillableOrgInfo);
        }
      }
      this.openCurrencyChangeConfirmModal = false
    },
    async callValidateEntryPoint() {
      const newEntryPoint = this.invalidEntryPoint !== this.modifiedOrg.entryPoint;
      if (newEntryPoint) {
        const checkEntryPointResp = await apis
          .organizations.validateEntryPoint(this.modifiedOrg.entryPoint);
        const valid = checkEntryPointResp.data;
        const entryPointValid = this.modifiedOrg.entryPoint === this.originalEntryPoint || valid;
        this.invalidEntryPoint = entryPointValid ? null : this.modifiedOrg.entryPoint;
      }
    },
    copyToEntryPoint() {
      this.modifiedOrg.entryPoint = validators.slugifyUrl(
        validators.cleanString(this.modifiedOrg.name));
    },
    onBlurName() {
      if (this.emptyEntryPoint) {
        this.copyToEntryPoint();
        this.callValidateEntryPoint();
      }
    },
    onBlurEntryPoint() {
      if (!this.emptyEntryPoint) {
        this.modifiedOrg.entryPoint = validators.slugifyUrl(
          validators.cleanString(this.modifiedOrg.entryPoint));
        this.callValidateEntryPoint();
      }
    },
    async fetchVerifiedDomains() {
      const resp = await apis.organizations.getVerifiedDomains(this.organization.id);
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.verifiedDomains = resp.data.filter(domain => domain.status === 'VERIFIED');
      }
    },
    async fetchCustomFields() {
      const parentId = this.organization.parent && this.organization.parent.id !== undefined ?
        this.organization.parent.id : this.selectedOrganization.id;
      const resp = await apis.organizations
        .getCustomFields(this.organization.id ? this.organization.id : parentId);
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.customFields = resp.data;
      }
      this.customFields.forEach(f => {
        if (!this.modifiedOrg[f.field] && f.values) {
          let defaultValue = f.values.find(v => v.isDefault === true );
          if (defaultValue) {
            this.modifiedOrg[f.field] = defaultValue.value;
          }
        }
      })
    },
    async fetchPricingPackages() {
      const parentId = this.organization.parent && this.organization.parent.id !== undefined ?
        this.organization.parent.id : this.selectedOrganization.id;
      const resp = await apis.pricingPackages.list({ qs: {
          organization_id: this.organization.id ? this.organization.id : parentId,
          applicable: true },
      });
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.pricingPackages = resp.data;
      }

      await this.pricingPackageChanged()
    },
    async fetchSupportedBillingCurrencies() {
      this.supportedBillingCurrencies = await this.fetchBillingSupportedCurrencies()
    },
    async fetchBillableOrganizationInfo() {
      const resp = await apis.billable.getBillableOrgInfo(this.modifiedOrg.id);
      if (resp.status !== 200) {
        if (resp.status !== 404) {
          notify.error(this.$t('unexpected_error'));
        } else {
          this.modifiedBillableOrgInfo = null;
        }
      } else if (resp.data) {
        this.modifiedBillableOrgInfo = { ...resp.data };
        this.originalCurrency = resp.data.currency;
        this.pricingPackage = (resp.data.pricingPackage || {}).id;
        this.isAssignedPackage = !!this.pricingPackage;
        this.noEndDate = this.modifiedBillableOrgInfo.billableEnd == undefined;
      }
    },
    async isMonetizationEnabled() {
      if (this.organization.reseller) {
        const resp = await apis.organizations.find(this.organization.reseller.id);
        if (resp.status !== 200 || !resp.data) {
          notify.error(this.$t('unexpected_error'));
        } else if (!resp.data.parent) {
          return true;
        } else if (resp.data.features) {
          const monetizationFeature = resp.data.features.find(featureMapping => featureMapping.feature === 'MONETIZATION');
          return monetizationFeature ? monetizationFeature.access !== 'NO_ACCESS' : false;
        }
        return false;
      }
      return true;
    },
    async pricingPackageChanged() {
      if (!this.pricingPackage) {
        this.effectivePricing = undefined;
        return;
      }

      const pricingPackageEntity = this.pricingPackages.find(p => p.id == this.pricingPackage)
      if (!pricingPackageEntity) {
        this.effectivePricing = undefined;
        return;
      }

      const effective = await apis.pricings.getEffectivePricing(
        pricingPackageEntity.pricingDefinition.id,
        this.getEffectiveCurrencyDate()
      );

      if (effective && effective.status !== 200) {
        notify.error(this.$t('monetization.error_fetching_pricing'));
        return null;
      }
      this.effectivePricing = effective.data
    },
    getEffectiveCurrencyDate() {
      // Now change in currency? => now!
      if (!this.originalCurrency) {
        return this.$date(this.modifiedBillableOrgInfo.billableStart, "YYYY-MM-DD", true);
      }

      // return now if no cycle at all
      if (!this.modifiedBillableOrgInfo.billingCycles || this.modifiedBillableOrgInfo.billingCycles.length == 0) {
        return this.$date(new Date(), "YYYY-MM-DD", true);
      }

      // find the last closed cycle and return its end date
      for (let i in this.modifiedBillableOrgInfo.billingCycles) {
        const cycle = this.modifiedBillableOrgInfo.billingCycles[i]
        if (!this.isOngoing(cycle)) {
          return cycle.endDate;
        }
      }

      // return the first open cycle start date (all are open!)
      return this.modifiedBillableOrgInfo.billingCycles[this.modifiedBillableOrgInfo.billingCycles.length - 1].startDate
    },
    cancel(){
      if (this.sourcePage) {
        this.$router.push({ name: this.sourcePage })
        return;
      }
      this.$router.navigateBackOrDefault("organizationDetails")
    }
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

.billing {
  display: flex;
  flex-direction: column;
  .toggle {
    margin-bottom: 10px;
  }
}
.entrypoint {
  display: flex;
  width: 600px;
  flex-wrap: wrap;
  :deep(.input-control) {
    max-width: 300px;
    min-width: 300px;
    margin-right: 10px;
  }
  justify-content: flex-start;
  .url-line {
    display: flex;
    align-items: center;
    .fa {
      margin-right: 10px;
    }
  }
}
.cal {
  max-width: 475px;
}
.select-package {
  display: flex;
  align-items: center;
  > div:last-child {
    padding-left: 5px;
  }
}
</style>
