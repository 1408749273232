<template>
  <div 
    ref="dropdown_listener"
    class="language-picker" 
  >
    <div 
      v-if="activeLanguagesList.length > 1"
      class="language" 
    >
      <div 
        role="button" 
        tabindex="0" 
        class="language-btn clickable" 
        @click="langDropdown = activeLanguagesList.length > 1" 
        @keydown.enter="langDropdown = activeLanguagesList.length > 1"
        @keydown.tab="langDropdown = false"
      >
        {{ $t(`languages.${locale}.short`) }} <base-icon icon="fa fa-caret-down" />
      </div>
      <items-dropdown 
        keydownRef="dropdown_listener" 
        :items="activeLanguagesList" 
        :selected="locale" 
        header="choose_language" 
        :opened="langDropdown"
        @close="closeLangDropdown"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { switchLanguage } from '@/i18n';

export default {
  name: 'LanguagePicker',
  emits: ['switchLanguage'],
  data() {
    return {
      langDropdown: false,
    };
  },

  computed: {
    ...mapGetters([
      'locale',
      'loggedIn',
      'userBrandingInfo',
    ]),
    activeLanguagesList() {
      return this.userBrandingInfo.activeLanguages.map(l => ({
        value: l,
        short: `languages.${l}.short`,
        label: `languages.${l}.long`,
      }));
    },
  },

  methods: {
    closeLangDropdown(lang) {
      this.langDropdown = false;
      if (lang) {
        this.$emit('switchLanguage', lang);
        switchLanguage(lang);
      }
      if (!this.loggedIn) {
        localStorage.setItem('localeBeforeLogin', lang);
      }
    },
  },
};

</script>

<style scoped lang="scss">
  @use '@/styles/mixins.scss';

  .language {
    display:inline-block;
    position: relative;
  @include mixins.not-phone {
    .language-btn {
      border: 1px solid;
      border-radius:4px;
      padding:5px;
    }
  }
  }

</style>
