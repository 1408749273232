import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useResellerOrgNavigationGuard } from '@/app/composables/useResellerOrgNavigationGuard';
import { useRouter, useRoute } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'LegalSection',
  setup(__props) {

const { t } = useI18n();
const { checkAccess } = useResellerOrgNavigationGuard();
const router = useRouter()
const route = useRoute()

onMounted(() => {
  checkAccess();
});

const header = computed(() => ({
  title: t('legal.name'),
  subtitle: t('legal.description'),
  logo: 'book',
}));

const sections = computed(() => ([
  {
    title: t('disclaimer.title'),
    to: { name: 'legalDisclaimer' },
  },
  {
    title: t('terms_of_service_tab_title'),
    to: { name: 'terms-of-service' },
  },
]));

const operations = computed(() => {
  return [  
        {
            route: 'terms-of-service',
            href: '/admin/legal/terms-of-service/add',
            action: () => router.push({ name: 'addTermOfService' }),
            label: 'terms_of_service.add',
            icon: 'fa fa-plus-circle'
        }
  ].filter(o => route.name === o.route);
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_base_menu_page = _resolveComponent("base-menu-page")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_menu_page, {
      sections: sections.value,
      header: header.value,
      operations: operations.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["sections", "header", "operations"])
  ]))
}
}

})