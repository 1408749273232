<template>
  <cmc-card
    :title="headerTitle"
    with-title-i18n
  >
    <div class="loading-wrapper">
      <!-- TODO: Remove style when text-align center removed from top level -->
      <cmc-form
        :disable-submit="!validData || !hasValueChanged" 
        :executing="executing"
        :submitLabel="isEdit ? $t('save') : $t('add')"
        @submit="saveConnection" 
        @cancel="goBack"
      >
        <cmc-select
          v-if="!isEdit" 
          v-model="assignmentBody.serviceConnectionId"
          label="service"
          with-label-i18n
          :options="connectionOpts"
          @update:modelValue="onChange"
        >
        </cmc-select>
        <cmc-loader 
          v-if="loading || !init" 
        >
        </cmc-loader>
        <template v-else>
          <cmc-select
            v-if="isEdit"
            v-model="assignmentBody.serviceConnectionId"
            label="service"
            with-label-i18n
            :options="connectionOpts"
            disabled
            @update:modelValue="onChange"
          >
          </cmc-select>
          <cmc-select
            v-if="selectedConnection?.quotas.length"
            v-model="assignmentBody.quotaId"
            label="quota"
            with-label-i18n
            :allow-empty="false"
            :options="selectedConnection?.quotas.map(q => ({ value: q.id, label: upperFirstCharOnly(q.name) }))"
            @update:modelValue="onChange"
          > 
          </cmc-select>
          <cmc-select
            v-if="selectedConnection?.supportsOrganizationLinking"
            v-model="assignmentBody.backendOrganizationId"
            label="linked_to_service_organization"
            with-label-i18n
            :allow-empty="false"
            :with-tooltip="isEdit ? 'assign_connection.link_to_existing' : ''"
            with-tooltip-i18n
            :options="isEdit ? assignedOrganizationOption : assignableOrganizationOptions"
            :disabled="isEdit"
            @update:modelValue="onChange"
          ></cmc-select>
          <template
            v-for="fe in assignmentFormElements"  
            :key="fe.field"
          >
            <template v-if="fe.disabled && !fe.sensitive">
              <cmc-stack
                spacing="3xs"
              >
                <cmc-title
                  :title="fe.label"
                  with-i18n
                  heading="h5"
                  :with-tooltip="fe.descriptionLabel"
                  with-tooltip-i18n
                >
                </cmc-title>
                <cmc-block
                  padding-top="3xs"
                >
                  <cmc-text
                    :text="assignmentBody.assignmentParameters[fe.field]"
                  >
                  </cmc-text>
                </cmc-block>
              </cmc-stack>
            </template>
            <template v-else-if="!fe.disabled">
              <cmc-form-element
                v-model="assignmentBody.assignmentParameters[fe.field]"
                :formElement="fe" 
                :error="fieldErrors[fe.field]"
                :disabled="fe.disabled"
                @change="onChange(fe)"
                @reload="fetchAssignmentFormElements(assignmentBody.serviceConnectionId, isEdit)"
              >
              </cmc-form-element>
            </template>
          </template>
        </template>
      </cmc-form>
    </div>
  </cmc-card>
</template>

<script setup lang="ts">
import apis from '@/utils/apis';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, watch, defineComponent, onBeforeUnmount } from 'vue';
import notify from '@/utils/notify';
import { useI18n } from "vue-i18n";
import { upperFirstCharOnly } from '@/utils/label';
import { waitForCompletion } from '@/utils/tasks';
import CmcLoader from '@/components/nextgen/display/CmcLoader.vue';
import CmcSelect from  '@/components/nextgen/inputs/CmcSelect.vue';
import CmcForm from '@/components/nextgen/form/CmcForm.vue';
import CmcFormElement from '@/components/nextgen/sdk/CmcFormElement.vue';

defineComponent({
  CmcSelect, CmcForm, CmcFormElement, CmcLoader
})

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const isEdit = computed(() => route.query.isEdit === 'true');
const quotaId = computed(() => route.query.quotaId);
const isActive = ref(true);

const hasValueChanged = ref(false);

type Quota = {
  id: string;
  name: string;
};
type ManageableConnection = {
  id: string;
  name: string;
  type: string;
  isAssignable: boolean;
  quotas: Quota[]
  assignableOrganizations: boolean;
  supportsOrganizationLinking: boolean;
  supportsOrganizationCreation: boolean;
};
type AssigneableOrganization = {
  id: string;
  name: string;
};
type ErrorContext = {
  field: string;
  labelKey: string;
};
type ApiError = {
  message: string;
  context: ErrorContext;
};
const init = ref(false)
const availableConnections = ref<ManageableConnection[]>([]);
const fieldErrors = ref<Record<string, ApiError | null>>({});
const fetchAvailableConnections  = async () => {
  const resp = await apis.organizations.findServiceConnections(route.params.id);
  if (resp.status !== 200 || !resp.data) {
    notify.error(t('unexpected_error'));
  } else {
    availableConnections.value = resp.data;
    loading.value = false
  }
  init.value = true;
};

const connectionOpts = computed(() => {
  if (isEdit.value) {
    return availableConnections.value
      .filter(ac => ac.id === assignmentBody.value.serviceConnectionId)
      .map(ac => ({
        label: ac.name,
        value: ac.id,
        isServiceConnection: true,
        type: ac.type,
      }));
  } else {
    return availableConnections.value
      .filter(ac => ac.isAssignable)
      .map(ac => ({
        label: ac.name,
        value: ac.id,
        isServiceConnection: true,
        type: ac.type,
      }));
  }
});

fetchAvailableConnections();

const assignmentBody = ref({
  serviceConnectionId: '',
  assignmentParameters: {
  } as { [key: string]: any },
  quotaId: undefined as string | undefined,
  backendOrganizationId: undefined,
});

const selectedConnection = computed(() => {
  if (isEdit.value) {
    return availableConnections.value.find(ac => ac.id === route.params.connectionId);
  }
  return availableConnections.value.find(sc => sc.id === assignmentBody.value.serviceConnectionId);
});

const assignmentFormElements = ref()

const fetchAssignmentFormElements  = async (connId: string, isEdit: boolean) => {
  const resp = await apis.organizations.getConnectionAssignmentParameters(route.params.id, connId, isEdit);
  if (resp.status !== 200 || !resp.data) {
    notify.error(t('unexpected_error'));
    throw Error("Cannot load form elements")
  } else {
    return resp.data;
  }
};

const fetchBackendOrganizations  = async (connId: string) => {
  const resp = await apis.organizations.getBackendOrganizations(route.params.id, connId);
  if (resp.status !== 200 || !resp.data) {
    notify.error(t('unexpected_error'));
    throw Error("Cannot load form elements")
  } else {
    return resp.data;
  }
};

const assignableOrganizations = ref<AssigneableOrganization[]>([])

const assignableOrganizationOptions = computed(() => {
  if (!selectedConnection?.value || !assignableOrganizations.value) {
    return []
  }
  const options = assignableOrganizations.value.map(o => ({
    value: o.id,
    label: o.name,
  }));
  const createOption = [{
    value: '',
    label: t('add_new_customer'),
  }];
  if (selectedConnection?.value.supportsOrganizationCreation) {
    return [...createOption, ...options];
  }
  return options;
})

const assignedOrganizationOption = computed(() => {
  if (isEdit.value && selectedConnection.value && selectedConnection.value.assignedOrganization) {
    return [{
      label: selectedConnection.value.assignedOrganization.name,
      value: selectedConnection.value.assignedOrganization.id,
    }];
  }
  return [];
});

const loading = ref(true)

const validData = computed(() => {
  return !loading.value 
    && !!assignmentBody.value.serviceConnectionId
    && (assignmentFormElements.value || [])
        .filter((fe: any) => !fe.optional)
        .every((fe: any) => assignmentBody.value.assignmentParameters[fe.field]);
})

onBeforeUnmount(() => {
  isActive.value = false; 
});

watch(selectedConnection, async (newValue) => {
  if (!newValue) {
    return
  }
  loading.value = true;
  assignmentBody.value.quotaId = undefined;
  assignmentBody.value.backendOrganizationId = undefined;
  assignmentBody.value.assignmentParameters = {};
  fieldErrors.value = {};
  assignableOrganizations.value = [];
  const elements = await fetchAssignmentFormElements(newValue.id, isEdit.value);
  let backendOrgs = [];
  if (newValue.supportsOrganizationLinking) {
    backendOrgs = await fetchBackendOrganizations(newValue.id);
  }

  let normalizedElements = [];
  if (isEdit.value) {
    assignmentBody.value.quotaId = quotaId.value as string || undefined;
    assignmentBody.value.serviceConnectionId = newValue.id;
    assignmentBody.value.backendOrganizationId = newValue.supportsOrganizationLinking ? newValue.assignedOrganization?.id : undefined;
    for (const key in elements) {
      const {formElement, value} = elements[key];
      assignmentBody.value.assignmentParameters[formElement.field] = value;
      normalizedElements.push(formElement);
    }
  } else {
    for (const key in elements) {
      const {formElement} = elements[key];
      normalizedElements.push(formElement);
    }
  }

  if (newValue.id === assignmentBody.value.serviceConnectionId) {
    assignmentFormElements.value = normalizedElements;
    assignableOrganizations.value = backendOrgs;
    loading.value = false;
  }
});

const goBack = () => router.push(`/admin/organizations/${route.params.id}/services`);

const onChange = (formElement?: any) => {
  // If there is no pointer event, return early, it is just the form initializing.
  if (event === undefined) {
    return;
  }

  hasValueChanged.value = true;

  // Reset any errors associated with the field
  if (formElement) {
    fieldErrors.value[formElement.field] = null;
  }
};

// To avoid the flickering of the header title when the component is loading
const headerTitle = computed(() => {
  if (loading.value !== true) {
    return isEdit.value ? t('edit_assigned_connection', { name: selectedConnection?.value?.name }) : t('assign_connection.label');
  }
  return '';
});

const emit = defineEmits<{
  (event: 'refreshOrg',): void
}>();

const executing = ref(false);
const saveConnection = async () => {
  if (executing.value) {
    return;
  }
  executing.value = true;
  let resp;
  if (isEdit.value) {
    resp = await apis.organizations.updateAssignedConnection(route.params.id, assignmentBody.value);
  } else {
    resp = await apis.organizations.assignConnection(route.params.id, assignmentBody.value);
  }
  if (resp.errors) {
    populateFieldErrors(resp.errors)
    executing.value = false;
    notify.error(t('connection_assignment_error'))
    return;
  }

  // Wait for the asynchronous task to complete
  const pollingStatus = await waitForCompletion(resp.data.id)
  executing.value = false;
  if (pollingStatus === 'FAILURE') {
    notify.error(t('unexpected_error'));
    throw Error("Cannot load form elements")
  } else {
    if (isEdit.value) {
      notify.success(t('connection_assignment_updated_success', { 'name': selectedConnection?.value?.name }));
    } else {
      notify.success(t('connection_assignment_assigned_success', { 'name': selectedConnection?.value?.name }));
    }

    if (isActive.value) {
      emit('refreshOrg')
      goBack();
    }
  }
};

const populateFieldErrors = (newErrors: ApiError[]) => {
  const fieldMap: { [key: string]: ApiError } = {};
  newErrors.filter(ok => ok.context && ok.context.field)
    .forEach((err) => { fieldMap[err.context.field] = err; });
  fieldErrors.value = fieldMap; 
}
</script>

<style scoped>
.loading-wrapper {
  text-align: left;
  position:relative;
  min-height: 9rem;
}
</style>
