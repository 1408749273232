<template>
  <cmc-block
    :id="id ? `cmc-tabs-${id}` : undefined" 
    class="cmc-tabs"
    :tabindex="0"
    as-inline
  >
    <cmc-group 
      spacing="none"
    >
      <template
        v-for="(tab, idx) in tabs"
        :key="tab.key"
      >
        <cmc-app-link
          v-if="toNavigation"
          :to="tab.to"
        >
          <div 
            :class="['cmc-tabs-tab', {
              'cmc-tabs-tab-active': tab.key === activeTab && !tab.disabled,
              'cmc-tabs-tab-disabled': tab.disabled,
              'cmc-tabs-tab-normal': tab.key !== activeTab && !tab.disabled,
            }]"
            @click="onChange(tab)"
            @keyup.enter="onChange(tab)"
            @mouseenter="onMouseEnter(tab)"
            @mouseleave="onMouseLeave()"
          >
            <cmc-text 
              :id="id ? `cmc-tabs-tab-${id}-${tab.key}` : undefined"
              size="l"
              :text="tab.label"
              :with-i18n="tab.withI18n"
              :as-disabled="tab.disabled"
              :with-tooltip="tab.withTooltip"
              :with-tooltip-i18n="tab.withTooltipI18n"
              :tabindex="0"
            ></cmc-text>
          </div>
        </cmc-app-link>
        <div
          v-else
          :class="['cmc-tabs-tab', {
            'cmc-tabs-tab-active': tab.key === activeTab && !tab.disabled,
            'cmc-tabs-tab-disabled': tab.disabled,
            'cmc-tabs-tab-normal': tab.key !== activeTab && !tab.disabled,
          }]"
          @click="onChange(tab)"
          @keyup.enter="onChange(tab)"
          @mouseenter="onMouseEnter(tab)"
          @mouseleave="onMouseLeave()"
        >
          <cmc-text 
            :id="id ? `cmc-tabs-tab-${id}-${tab.key}` : undefined"
            size="l"
            :text="tab.label"
            :with-i18n="tab.withI18n"
            :as-disabled="tab.disabled"
            :with-tooltip="tab.withTooltip"
            :with-tooltip-i18n="tab.withTooltipI18n"
            :tabindex="0"
          ></cmc-text>
        </div>
        <cmc-divider 
          v-if="idx !== tabs.length - 1"
          vertical
          :style="{
            visibility: 
              (tab.key === activeTab || tabs[idx + 1]?.key === activeTab
                || tab.key === hoveredTab 
                || tabs[idx + 1]?.key === hoveredTab) ? 'hidden' : undefined }"
        ></cmc-divider>
      </template>
    </cmc-group>
  </cmc-block>
  <slot :name="activeTab"></slot>
</template>

<script setup lang="ts">
import type { Tab } from './types';
import { defineComponent, ref, computed } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcAppLink from './CmcAppLink.vue';

defineComponent({
  CmcGroup,
  CmcText,
  CmcDivider,
  CmcBlock,
  CmcAppLink,
})

interface Props {
  /**
   * Id of the HTML element
   */
  id?: string;

  /**
   * Tabs to display
   */
  tabs: Tab[];

  /**
   * Key of the active tab. If undefined will default to the first non-disabled tab.
   */
  activeTab?: string;
}
const props = defineProps<Props>()
const emit = defineEmits<{
  /**
   * Emitted when tab was changed
   * @arg Tab key that was clicked
   */
  (event: 'change', tabKey: string): void
}>()

const onChange = (tab: Tab) => {
  if (tab.key !== props.activeTab && !tab.disabled) {
    emit('change', tab.key)
  }
}
const firstAvailableTab = props.tabs.find(t => !t.disabled);
if (!props.activeTab && firstAvailableTab) {
  onChange(firstAvailableTab);
}

const hoveredTab = ref<string>()
const onMouseEnter = (tab: Tab) => {
  hoveredTab.value = tab.key
}
const onMouseLeave = () => {
  hoveredTab.value = undefined
}

const toNavigation = computed(() => {
  return props.tabs?.every(tab => tab.to);
});

</script>

<style scoped lang="scss">
.cmc-tabs {
  box-sizing: border-box;
  min-height: 2rem;
  border-radius: 0.1875rem;
  padding: 0.0625rem;
  border: 1px solid var(--ng-secondary-border);
  .cmc-tabs-tab {
    padding: 0.375rem 0.75rem;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    border-radius: 0.125rem;
    height: 1rem;

    &.cmc-tabs-tab-active {
      background-color: var(--ng-primary)
    }
    &.cmc-tabs-tab-active .cmc-text {
      color: var(--ng-navigation-elements)
    }
    &.cmc-tabs-tab-normal {
      cursor: pointer;
      &:hover {
        background-color: var(--ng-secondary-grey);
      }
    }
  }
  :deep(.cmc-divider) {
    margin-top: 0.325rem;
    margin-bottom: 0.325rem;
  }
}
</style>