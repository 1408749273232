<template>
  <cmc-stack
    v-if="layout === 'stack'"
    :id="props.id"
    :spacing="props.spacing"
    :take-full-height="props.takeFullHeight"
    :reversed="props.reversed"
  >
    <slot></slot>
  </cmc-stack>
  <cmc-group
    v-else-if="layout === 'group'"
    :id="props.id"
    :spacing="props.spacing"
    :take-full-height="props.takeFullHeight"
    :reversed="props.reversed"
    :take-full-width="props.takeFullWidth"
    :with-vertical-align="props.withVerticalAlign"
    :with-horizontal-align="props.withHorizontalAlign"
    :with-baseline-align="props.withBaselineAlign"
  >
    <!--
      @slot Content to apply the dynamic layout
    -->
    <slot></slot>
  </cmc-group>
  <slot v-else-if="layout === 'none'"></slot>
</template>

<script setup lang="ts">
import { Size } from '../sizes';
import CmcGroup from '@/components/nextgen/layout/CmcGroup.vue';
import CmcStack from '@/components/nextgen/layout/CmcStack.vue';

type Layout = 'stack' | 'group' | 'none'
type VerticalAlign = 'top' | 'center' | 'bottom'
type HorizontalAlign = 'left' | 'center' | 'right'

type Props = {
  /**
 * HTML element id.
 */
  id?: string,

  /**
   * Spacing between each stack element.
   */
  spacing?: Size,

  /**
   * Take full height of the parent container.
   */
  takeFullHeight?: boolean,

  /**
   * Reverse the ordering of child elements
   */
  reversed?: boolean,
  
  /**
   * True if the group should take the full width. Each element will have equal width.
   */
  takeFullWidth?: boolean

  /**
   * Align the group vertically.
   */
  withVerticalAlign?: VerticalAlign
  /**
   * Align the group horizontally.
   */
  withHorizontalAlign?: HorizontalAlign,

  /**
   * If true, all items are aligned such that their flex container baselines align.
   */
  withBaselineAlign?: boolean

  /**
   * Whether it's a group, stack or 'none' type layout
   */
  layout: Layout
}

const props = defineProps<Props>()
</script>