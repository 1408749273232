<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { provide } from 'vue';


export type StepProviderProps = {
  /**
   * Step number of the step.
   */
  step: number;

  /**
   * Current step where the form is currently at.
   */
  currentStep: number;

  /**
   * True if this the first step
   */
  first: boolean;

  /**
   * True if this the currently active step
   */
  active: boolean;

  /**
   * The highest step number that has been visited
   */
  highestVisitedStep: number;

  /**
   * True if this the last step
   */
  last: boolean;

  /**
   * Label of the submit button
   */
  submitLabel?: string;

  /**
   * Submit label is an i18n label.
   */
  withSubmitLabelI18n?: boolean;

  /**
   * Disable the submit button.
   */
  disabledSubmit?: boolean;

  /**
   * Provide a function to be invoked before proceeding to the next step. If the function returns false, we should not change steps.
   */
  beforeNext?: () => Promise<boolean>;

}

const props = defineProps<StepProviderProps>()

const emit = defineEmits<{
  /**
   * Emitted when step is requested to be changed
   * @arg step to change to
   */
  (event: 'changeStep', step: number): void,

  /**
   * Cancel the form.
   */
  (event: 'cancel'): void,
}>()

provide('cmc-multi-form-step-props', props);
provide('cmc-multi-form-go-to-step', async () => {
  emit('changeStep', props.step);
})
provide('cmc-multi-form-go-to-step-number', async (stepNumber: number) => {
  emit('changeStep', stepNumber);
})
provide('cmc-multi-form-go-to-next-step', async () => {
  if(!props.beforeNext || await props.beforeNext()) {
    emit('changeStep', props.step + 1);
  }
})
provide('cmc-multi-form-cancel', () => emit('cancel'))

</script>
