<template>
  <cmc-list
    class="cmc-list-multi-select"
    :layout="layout"
  >
    <cmc-list-header>
      <cmc-list-col
        v-for="header in headers"
        :key="header.label"
      >
        <cmc-partial-checkbox
          v-if="header.withPartialCheckbox"
          :checkedState="checkedState"
          :label="header.label"
          :with-label-i18n="header.withI18n"
          :with-tooltip="header.withTooltip"
          :with-tooltip-i18n="header.withTooltipI18n"
          :with-count="header.withCount ? headerCount : undefined"
          reversed
          as-title
          heading-for-title="h5"
          @update:checkedStatus="headerChecked($event)"
        />
        <cmc-title
          v-else
          v-bind="header"
          :title="header.label"
          :with-tooltip="header.withTooltip"
          :with-tooltip-i18n="header.withTooltipI18n"
          with-tooltip-placement="right"
          heading="h5"
        ></cmc-title>
      </cmc-list-col>
    </cmc-list-header>
    <cmc-list-row
      v-for="row in rows"
      :key="row.id"
      :value="row.id"
    >
      <cmc-list-col>
        <cmc-checkbox
          v-if="row.value"
          :label="row.label"
          :with-label-i18n="row.withI18n"
          :model-value="checkedValues[row.value]"
          reversed
          :maxLineDisplay="1"
          :checkbox-color="row.color"
          @update:model-value="rowChecked(row.value, $event)"
        />
        <div
          v-else
          class="placeholder-col"
        />
      </cmc-list-col>
      <cmc-list-col
        v-for="col in row.columns"
        :key="col.text"
      >
        <cmc-text
          v-if="col.text"
          :text="col.text"
          :with-i18n="col.withTextI18n"
          :as-description="col.asDescription"
          without-wrap
        />
        <cmc-group
          v-else-if="col.amount || col.amount == 0"
          spacing="2xs"
          withBaselineAlign
        >
          <cmc-amount
            :amount="col.amount"
            :currency="col.currency"
            :locale="col.locale"
            :with-bold="col.withBold"
            :show-currency="col.showCurrency"
            :reserve-currency-space="!col.showCurrency"
            :max-fraction-digits="2"
            without-wrap
            hide-narrow-currency
          />
          <cmc-group
            spacing="3xs"
            with-baseline-align
          >
            <cmc-icon
              v-if="col.comparisonIcon"
              :icon="col.comparisonIcon"
              svg
              size="s"
            />
            <cmc-text
              v-if="col.comparisonAmount"
              :text="col.comparisonAmount"
              size="m"
            />
          </cmc-group>
        </cmc-group>
        <div
          v-else
          class="placeholder-col"
        />
      </cmc-list-col>
    </cmc-list-row>
  </cmc-list>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import CmcList from '../display/list/CmcList.vue';
import CmcListHeader from '../display/list/CmcListHeader.vue';
import CmcListRow from '../display/list/CmcListRow.vue';
import CmcListCol from '../display/list/CmcListCol.vue';
import CmcPartialCheckbox from './CmcPartialCheckbox.vue';
import CmcAmount from '../typography/CmcAmount.vue';
import { ColLayout } from '../display/list/CmcListCol.vue';
import { PartialCheckboxState } from './constants';

export type ListCheckboxSelectHeader = {
  /**
   * Label on top of the text area
   */
  label: string;

  /**
   * Is the label i18n.
   */
  withI18n?: boolean;

  /**
   * Whether or not the header should be rendered as a partial checkbox which can
   * select or deselect all checkboxes within the same column (limited to first header/column)
   */
  withPartialCheckbox?: boolean;

  /**
   * Whether or not to include the count in the header if it is a partial checkbox
   * 
   */
  withCount?: boolean;

  /**
   * Tooltip in the header,
   */
  withTooltip?: string;

  /**
   * Is tooltip i18n.
   */
  withTooltipI18n?: boolean;
}
export type ListCheckboxSelectCol = {
  /**
   * Text content to render in the column.
   */
  text?: string;

  /**
   * Amount to render in the column
   */
  amount?: number;

  /**
   * Currency if the column is an amount to display with a currency
   */
  currency?: string;

  /**
   * True if the column amount value should be bold
   */
  withBold?: boolean;

  /**
   * The locale :shrug:
   */
  locale?: string;

  /**
   * True if the currency should be displayed in the column
   */
  showCurrency?: boolean;

  /**
   * True if the text is 18n
   */
  withTextI18n?: boolean;

  /**
   * True if the text should be rendered as a description
   */
  asDescription?: boolean;

  /**
   * An icon to display next to the comparison amount, if desired.
   * Ex. 'arrow-down-bold', 'arrow-up-bold'
   */
  comparisonIcon?: string;

  /**
   * A comparison amount to be displayed next to the amount, with the comparisonIcon
   * in the format <amount> <comparisonIcon> <comparisonAmount>.
   */
  comparisonAmount?: string;
}
export type ListCheckboxSelectRow = {
  /**
   * HTML element id for the checkbox
   */
  id?: string;

  /**
   * Value of the row corresponding to the checkbox
   * Omit if the first column should be empty (no checkbox).
   */
  value?: any;

  /**
   * Label for the checkbox in column 1
   * Omit if the first column should be empty (no checkbox).
   */
  label?: string;

  /**
   * True if the label is i18n.
   */
  withI18n?: boolean;

  /**
   * Color of the checkbox in hexadecimal format.
   */
  color?: string;

  /**
   * Text or amount content to be displayed in columns 2-n
   */
  columns?: ListCheckboxSelectCol[];
}
type Props = {
  id?: string;

  /**
   * Model value of the component. This is a list of values that are checked
   */
  modelValue: any[];

  /**
   * Headers of the list.
   */
  headers: ListCheckboxSelectHeader[];

  /**
   * Rows/Content of the list.
   */
  rows: ListCheckboxSelectRow[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  /**
   * Emitted when toggled.
   * @arg any[]
   */
  (event: 'update:modelValue', values: any[]): void
}>()

/**
 * Computed value to determine state of partial checkbox header
 */
const checkedState = computed(() => {
  const allValues = props.rows.filter(row => row.value).map(row => row.value);
  const numSelected = Object.values(checkedValues.value).length;
  if (numSelected == 0) {
    return PartialCheckboxState.UNCHECKED;
  } else if (numSelected == allValues.length) {
    return PartialCheckboxState.CHECKED;
  } 
  return PartialCheckboxState.PARTIAL;
})

/**
 * Computed value to track values of selected checkboxes 
 */
const checkedValues = computed(() => {
  return (props.modelValue || []).reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {});
});

/**
 * Used to compute the Count for the partial checkbox header
 */
const headerCount = computed(() => {
  return {
    numSelected: Object.values(checkedValues.value).length,
    total: props.rows.filter(row => row.value).map(row => row.value).length
  }
})

const rowChecked = (value: any, checked: boolean) => {
  if (checked) {
    return emit('update:modelValue', [...new Set([...(props.modelValue || []), value])])
  } else {
    return emit('update:modelValue', [...(props.modelValue || [])].filter(v => v !== value))
  }
}
const headerChecked = (checked: boolean) => {
  if (checked) {
    return emit('update:modelValue', props.rows.filter(row => row.value).map(row => row.value));
  } else {
    return emit('update:modelValue', []);
  }
}
</script>

<style lang="scss" scoped>
.placeholder {
  width: auto
}
.cmc-list-multi-select {
  :deep(.cmc-list-col) {
    margin: 0rem;
    padding-top: 0rem;
    padding-bottom: 0.219rem;
  }
  :deep(.cmc-list-header-col) {
    padding-top: 0rem;
    padding-bottom: 0.469rem;
  }
  :deep(.cmc-list-header), :deep(.cmc-list-row) {
    border-bottom: none;
    min-height: 0rem;
  }
  :deep(.cmc-list-row:hover) {
    background-color: transparent;
  }
  :deep(.cmc-checkbox-label .cmc-text) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  :deep(.cmc-block-padding-left-2xl) {
    padding-left: 0rem;
  }
  :deep(.cmc-block-padding-right-2xl) {
    padding-right: 0rem;
  }
}

</style>