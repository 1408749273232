<template>
  <div
    class="cmc-list"
  >
    <component 
      :is="isRadio ? CmcRadioGroup : 'div'" 
      v-bind="$attrs"
      as-custom
      :modelValue="modelValue"
      :disabled="disabled"
      @update:modelValue="emit('update:modelValue', $event)"
    >
      <slot></slot>
    </component>
    <cmc-pagination
      v-if="paginable && exceedMin"
      :total-records="withNumberOfRecords"
      @pageSelected="emit('pageSelected', $event)"
      @pageSizeSelected="emit('pageSizeSelected', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { provide, defineComponent, computed } from 'vue';
import { ColLayout } from './CmcListCol.vue';
import CmcRadioGroup from '../../inputs/CmcRadioGroup.vue';
import CmcPagination from '../../pagination/CmcPagination.vue';

defineComponent({
  CmcRadioGroup, CmcPagination
})

export type ListInputType = 'checkbox' | 'radio';

type Props = {
  /**
   * Model value. This is only used when in radio or checkbox mode.
   */
  modelValue?: string | string[];

  /**
   * Layout of the columns.
   */
  layout: ColLayout[];

  /**
   * Adds input on the left-hand side of the list.
   */
  withInput?: ListInputType;

  /**
   * Disable the list.
   */
  disabled?: boolean;

  /**
   * Make the list paginable. Defaults to false
   */
  paginable?: boolean;

  /**
   * Total number of records to display. Required if list is paginable
   */
  withNumberOfRecords?: number;

  /**
   * Adds actions column on right-hand side of list
   */
  withActions?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  paginable: false,
  withNumberOfRecords: 10,
})

provide('cmc-list-layout', props.layout)
provide('cmc-list-with-input', props.withInput)
provide('cmc-list-with-actions', props.withActions)

const isRadio = computed(() => props.withInput === 'radio')

const exceedMin = computed(() => props.withNumberOfRecords > 5)

const emit = defineEmits<{
  /**
   * Emitted when option is selected
   */
  (event: 'update:modelValue', value: any): void
  (event: 'pageSelected', value: any): void
  (event: 'pageSizeSelected', value: any): void
}>()
</script>

<style scoped lang="scss">
.cmc-list {
  --ng-list-header-bg: var(--on-primary);
  --ng-list-header-bg-highlight: var(--ng-primary-background);
  --ng-list-border: var(--ng-secondary-border);
}
</style>