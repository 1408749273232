<template>
  <div 
    ref="dropdown_listener" 
    tabindex="0" 
    class="selection-card" 
    :class="{ active: isActive }"
    @click="$emit('update:modelValue', defaultOption.value)" 
    @keydown.enter="$emit('update:modelValue', defaultOption.value)"
  >
    <div 
      :class="{ active: isActive }" 
      class="selection-card-details"
    >
      <base-image 
        v-if="tile.imageUrl" 
        :altText="altText" 
        :src="tile.imageUrl"
      />
      <base-icon 
        v-else-if="tile.icon" 
        class="icon" 
        :icon="tile.icon"
      />
      <div 
        v-if="!tile.titles || !tile.titles.length" 
        class="title"
      >
        {{ tile.name }}
      </div>
      <div 
        v-for="title in tile.titles" 
        :key="title.label" 
        :class="{ title: !title.subtitle, subtitle: title.subtitle }"
      >
        {{ title.is18n ? $t(title.label, title.interpolation): title.label }}
      </div>
      <div 
        v-if="tile.options && tile.options.length" 
        class="subtitle tile-select-options"
        :tabindex="tile.options.length > 1 ? 0 : -1" 
        role="listbox" 
        :aria-label="$t('show_tile_options')"
        @keydown.self.enter="showOptions = true" 
        @click="showOptions = true"
        @click.stop="hasMultipleOptions && (showOptions = true)"
      >
        {{ defaultOption.label }} <base-icon 
          v-if="tile.options.length > 1" 
          icon="caret-down"
        />
      </div>
    </div>
    <div 
      class="selection-dropdown" 
      @click.stop
    >
      <items-dropdown 
        v-if="hasMultipleOptions" 
        keydownRef="dropdown_listener" 
        :items="options" 
        :selected="modelValue" 
        :opened="showOptions" 
        :aria-label="$t('hide_tile_options')"
        @update:modelValue="selectOption" 
        @close="showOptions = false" 
      />
    </div>
  </div>
</template>

<script>
import { isAbsoluteUrl } from '@/utils';
import BaseIcon from '@/components/BaseIcon';

export default {
  name: 'TileOption',
  components: { BaseIcon },
  props: {
    tile: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: String,
    },
    imageResolver: {
      type: Function,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    hasMultipleOptions() {
      return this.options.length > 1;
    },
    options() {
      return this.tile.options.map(o => ({
        value: o.value,
        label: o.name,
      }));
    },
    isActive() {
      if (this.options.length) {
        return this.options.find(opt => opt.value === this.modelValue);
      }
      return this.tile.value === this.modelValue;
    },
    defaultOption() {
      const opt = this.options.find(o => o.value === this.modelValue);
      if (opt) {
        return opt;
      } else if (this.options.length) {
        return this.options[0];
      }
      return { value: this.tile.value };
    },
    imagUrl() {
      const url = this.tile.imagUrl;
      if (isAbsoluteUrl(url) || !this.imageResolver) {
        return url;
      }
      return this.imageResolver(url);
    },
    altText() {
      if (!this.tile.titles || !this.tile.titles.length) {
        return this.tile.name;
      }
      return this.title.is18n ? this.$t(this.title.label, this.title.interpolation)
        : this.title.label;
    },
  },
  methods: {
    selectOption(v) {
      this.showOptions = false;
      this.$emit('update:modelValue', v);
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';

.selection-card {
  display:flex;
  align-items: center;
  flex-direction:column;
  border-radius: 7px;
  margin-top: 5px;
  width: 200px;
  padding: 5px;
  user-select: none;
  cursor: pointer;
  &.active {
    background: #f0f0f0;
  }
  .selection-card-details {
    transition: transform 0.2s;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    padding: 10px 20px;

    .title {
      font-size: 16px;
    }

    .subtitle {
      font-size: 12px;
    }

    .title, .subtitle {
      margin-top: 4px;
      text-align: center;
    }

    em.icon, img {
      margin-bottom: 5px;
    }

    em.icon {
       font-size: 60px;
    }

    img {
      height: 60px;
      width: 60px;
    }

    &:not(.active):not(:hover) {
      transform: scale(.9);
    }

    &.active, &:hover {
      transform: scale(1);
    }
  }
}

@include mixins.not-phone {
  .selection-dropdown {
    position: relative;
    width: 100%;
  }
}

</style>
