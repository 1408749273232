import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { computed, defineComponent } from "vue";
import CmcPair from "../layout/CmcPair.vue";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcText from "../typography/CmcText.vue";
import CmcTitle from "../typography/CmcTitle.vue";
import { ServiceConnectionType } from "./types";

type Props = {
  /**
   * Service connection name.
   */
  name: string;

  /**
   * Service connection type. Possible values are: 'aws', 'azure', 'cloudca', 'cloudstack', 'constellation', 'default', 'gcp', 'kubernetes', 'openstack', 'purestorage', 'showcase', 'swift', 'swiftstack', 'tanzu', 'vmware-cloud-director'.
   */
  type: ServiceConnectionType;

  /**
   * Use as element in a select group (size of logo is 16 instead of 24, and spacing is 4px instead of 6px). Defaults to false.
   */
  asSelectOption?: boolean;

  /**
   * Use as category title in a select group (text typestyle is h5 instead of body-1). Defaults to false.
   */
  asCategoryTitle?: boolean;

  /**
   * Text is an i18n label key
   */
  withI18n?: boolean;

  /**
   * Tooltip text to be displayed
   */
  withTooltip?: string;

  /**
   * Tooltip text is an i18n label key
   */
  withTooltipI18n?: boolean;

  /**
   * Whether the rendered CmcText's tooltip content may contain html (ex: line breaks via <br>)
   */
  withTooltipHtml?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcServiceConnection',
  props: {
    name: { default: '' },
    type: { default: 'default' },
    asSelectOption: { type: Boolean, default: false },
    asCategoryTitle: { type: Boolean, default: false },
    withI18n: { type: Boolean, default: false },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withTooltipHtml: { type: Boolean, default: false }
  },
  setup(__props: any) {

defineComponent({
  CmcIcon, CmcText, CmcTitle, CmcPair
})

const serviceConnectionTypes: ServiceConnectionType[] = [
  'aws', 'azure', 'cloudca', 'cloudstack', 'constellation', 'default', 'fake', 'gcp', 'kubernetes',
  'openstack', 'purestorage', 'showcase', 'swift', 'swiftstack', 'tanzu', 'vmware-cloud-director'
];

function isValidServiceConnectionType(value: string): value is ServiceConnectionType {
  return serviceConnectionTypes.includes(value as ServiceConnectionType);
}

const props = __props;

const serviceConnectionIcon = computed(() => {
  return isValidServiceConnectionType(props.type) ? props.type : 'no-image';
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcPair, {
    class: "cmc-service-connection",
    "stretch-rhs": "",
    spacing: _ctx.asSelectOption ? '3xs' : '2xs'
  }, {
    default: _withCtx(() => [
      _createVNode(CmcIcon, {
        icon: serviceConnectionIcon.value,
        size: _ctx.asSelectOption ? 'm' : 'xl',
        "as-plugin-icon": "",
        svg: "",
        class: _normalizeClass({
        'cmc-service-connection-icon-normal' : !_ctx.asSelectOption,
        'cmc-service-connection-icon-option' : _ctx.asSelectOption && !_ctx.asCategoryTitle,
        'cmc-service-connection-icon-option-header' : _ctx.asSelectOption && _ctx.asCategoryTitle
      })
      }, null, 8, ["icon", "size", "class"]),
      (_ctx.asCategoryTitle)
        ? (_openBlock(), _createBlock(CmcTitle, {
            key: 0,
            class: _normalizeClass(_ctx.asSelectOption? 'cmc-service-connnection-header-option': 'cmc-service-connection-header-normal'),
            title: _ctx.name,
            "with-tooltip": _ctx.withTooltip,
            "with-tooltip-i18n": _ctx.withTooltipI18n,
            heading: "h5"
          }, null, 8, ["class", "title", "with-tooltip", "with-tooltip-i18n"]))
        : (_openBlock(), _createBlock(CmcText, {
            key: 1,
            class: _normalizeClass(_ctx.asSelectOption? 'cmc-service-connnection-text-option': 'cmc-service-connection-text-normal'),
            text: _ctx.name,
            "with-i18n": _ctx.withI18n,
            "with-tooltip": _ctx.withTooltip,
            "with-tooltip-i18n": _ctx.withTooltipI18n,
            "with-tooltip-html": _ctx.withTooltipHtml,
            size: "l"
          }, null, 8, ["class", "text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
    ]),
    _: 1
  }, 8, ["spacing"]))
}
}

})