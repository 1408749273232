<template>
  <div 
    ref="dropdown_listener"
    class="base-select" 
    :class="size" 
  >
    <div 
      :id="comboboxId" 
      :tabindex="disabled ? -1 : 0" 
      class="select clickable" 
      :class="{ disabled, searchable, open, 'no-border': noBorder, 'soft-border': softBorder, 'error-border': !!error}"
      :value="modelValue" 
      role="combobox" 
      aria-haspopup="listbox" 
      :aria-label="$t('select_value')"
      :aria-expanded="open" 
      :aria-controls="listboxId"
      @click="focus" 
      @keydown.self.enter="focus" 
      @keydown.tab="open=false" 
    >
      <div 
        v-show="searchable ? !open : true"
        class="search-content" 
        :class="{ 'no-border': noBorder }" 
      >
        <span 
          v-if="item"
          class="selected-item"
          :title="getDisplayLabel(item)"
        >
          <base-image 
            v-if="item.image" 
            class="label-image" 
            :src="item.image" 
            :altText="getDisplayLabel(item)"
          />
          <base-icon 
            v-if="icon" 
            :icon="icon" 
            class="input-icon"
          />
          <span :class="{'with-icon': !!icon}">
            {{ getDisplayLabel(item) }}
            <span 
              v-if="item.required" 
              class="required-indicator"
            >*</span>
          </span>
        </span>
        <span 
          v-else 
          class="placeholder"
        >{{ $tn(placeholder, interpolation) }}</span>
        <base-icon 
          v-if="!clearableValue" 
          :icon="loading ? 'fa fa-cog fa-spin' : 'fa fa-caret-down'"
        />
        <action-icon 
          v-if="clearableValue" 
          :icon="loading ? 'fa fa-cog fa-spin ' :'fa fa-times right'" 
          class="cancelAction" 
          size="small" 
          tooltipLabel="clear"
          @click="clearValue" 
        />
      </div>
      <input 
        v-show="searchable ? open : false" 
        :id="inputId" 
        ref="searchInput" 
        :class="{ 'no-border': noBorder }" 
        :aria-activedescendant="activeDescendant" 
        :disabled="disabled"
        :aria-controls="listboxId" 
        aria-autocomplete="both"
        type="text" 
        :placeholder="$tn('search')"
        :value="!open && item ? getDisplayLabel(item) : search"
        @keydown.enter="enterSearchInput" 
        @blur="blur" 
        @input="search = $event.target.value"
      />
    </div>
    <items-dropdown 
      :id="listboxId" 
      :parentId="inputId" 
      :focusDropdownElements="!searchable" 
      keydownRef="dropdown_listener" 
      role="listbox" 
      :selected="modelValue" 
      :items="filteredItems" 
      direction="down" 
      :opened="open" 
      :interpolation="interpolation" 
      :emptyListLabel="emptyListLabel" 
      @update:modelValue="input" 
      @close="close" 
      @search="$emit('search', $event)" 
      @item="handleItem" 
      @active="handleActiveChange"
    >
      <template 
        v-if="useSlot" 
        #default="slotProps"
      >
        <slot :item="slotProps.item"></slot>
      </template>
    </items-dropdown>
    <div 
      v-if="typeof error === 'string' && error"
      class="error-label red" 
    >
      {{ $tn(error) }}&nbsp;
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import {searchFilter, isMobileMode} from '@/utils';
import BaseIcon from './BaseIcon';
import ActionIcon from './ActionIcon';

const MIN_FOR_SEARCHABLE = 5;

export default {
  name: 'BaseSelect',
  components: { BaseIcon, ActionIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [String, Number]
    },
    icon: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    interpolation: {
      type: Object,
    },
    useSlot: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: v => ['sm', 'md'].includes(v),
    },
    emptyListLabel: {
      type: String,
    },
    clearable: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    noBorder: {
      type: Boolean,
    },
    softBorder: {
      type: Boolean,
      default: false
    },
    error: {
      type: [String, Boolean],
    },
    // should be object mapping item value (as key) to item field (as value) to be searched against
    additionalSearchFields: {
      type: Object,
      required: false,
    },
  },
  emits: ['update:modelValue', 'item','search'],
  data() {
    return {
      open: false,
      search: '',
      mobileMode: false,
      comboboxId: `combobox-label-${uuidv4()}`,
      listboxId: `listbox-label-${uuidv4()}`,
      inputId: `input-label-${uuidv4()}`,
      activeDescendant: '',
    };
  },
  computed: {
    clearableValue() {
      return this.clearable && this.modelValue;
    },
    item() {
      return this.items.find(i => i.value === this.modelValue);
    },
    labeledItems() {
      return this.items.map(i => ({
        ...i,
        display: this.getDisplayLabel(i),
      }));
    },
    filteredItems() {
      return this.labeledItems.filter(searchFilter(this.search,
        item => [item.name, item.display, item.value, this.$tn(item.label),
          ...this.additionalSearchFieldItems(item)]));
    },
    searchable() {
      return this.items.length > MIN_FOR_SEARCHABLE;
    },
  },
  watch: {
    modelValue() {
      this.checkValue();
    },
    open() {
      if (this.open) {
        this.focus();
      }
    },
  },
  created() {
    this.checkValue();
  },
  mounted() {
    this.$nextTick(() =>
      window.addEventListener('resize', () => {
        this.mobileMode = isMobileMode();
      }));
  },
  methods: {
    additionalSearchFieldItems(item) {
      return (this.additionalSearchFields &&
          this.additionalSearchFields.constructor === Object &&
          Object.keys(this.additionalSearchFields).length !== 0) ?
        Object.values(this.additionalSearchFields[item.value]) : [];
    },
    clearValue() {
      this.$emit('update:modelValue', '');
    },
    interpolationValue(item) {
      return item.interpolation ? item.interpolation : this.interpolation;
    },
    getDisplayLabel(item) {
      return item.label ? this.$tn(item.label, this.interpolationValue(item)) : item.display;
    },
    focus() {
      if (!this.disabled) {
        this.open = true;
        this.activeDescendant = '';
        if (this.$refs.searchInput) {
          this.$nextTick(() => this.$refs.searchInput.focus());
        }
      }
    },
    enterSearchInput(event) {
      if (this.searchable) {
        event.preventDefault();
      }
    },
    blur() {
      if (this.searchable) {
        this.open = false;
        this.search = '';
      }
    },
    input(value) {
      this.$emit('update:modelValue', value);
      this.open = false;
      this.activeDescendant = '';
    },
    close() {
      this.open = false;
      this.search = '';
      this.activeDescendant = '';
    },
    checkValue() {
      if (!this.item && this.items.length && !this.placeholder && !this.clearable) {
        this.$emit('update:modelValue', this.items[0].value);
      }
    },
    handleItem(item) {
      this.$emit('item', item);
    },
    handleActiveChange(elementId) {
      this.activeDescendant = elementId;
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';
@use '@/styles/variables.scss';

.base-select {
  position: relative;
  text-align: left;
  margin-left: 0px;
  border-radius: 3px;
  .icon {
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 15px;
  }

  .with-icon {
    padding-left: 5px;
  }

  input {
    width: 100%;
    box-sizing:border-box;
  }

  .select, .search-input {
    em.fa-caret-down, em.fa-times {
      font-size: 15px;
    }

  }

  .search-content {
    &:not(.no-border) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.no-border {
      text-align: right;
    }

    .fa {
      padding-left: 5px;
    }

  }

  font-size: 1rem;

  .select, input {
    height: 40px;
    min-width: 200px;

    &:not(.no-border) {
      border-width: 1px;
      border-style: solid;
    }

    &.soft-border {
      border-color: var(--plain-dark);
    }

    &:not(.soft-border) {
      border-color: var(--input-placeholder-text);
    }

    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;

    &.searchable.open {
      padding: 0px;
    }
    &.searchable input {
      border-width: 0px;
    }
    &.error-border {
      border-color: var(--input-error-border);
    }
  }

  .select, .placeholder, input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected-item {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .placeholder {
    opacity: 0.5;
    padding-right: 0.5em;
  }

  :deep(.base-dropdown), :deep(.dropdown) {
    width: 100%;
    display: fixed;
    .item-list {
      @include mixins.not-phone {
        max-height: 300px;
      }
    }
    .item {
      padding: 10px;
      text-align: left;
    }
  }

  &.sm {
    font-size: variables.$form-element-font-size-sm;

    .select, .search-input {
      min-width: variables.$form-element-width-sm;
      height: variables.$form-element-height-sm;
      em.fa-caret-down {
        top: 9px;
        font-size: 13px;
      }
    }

    .select, input {
        padding: 5px 10px;
        &.searchable input {
          font-size:  variables.$form-element-font-size-sm;
        }
    }

    :deep(.base-dropdown), :deep(.dropdown) {
      .item {
        padding: 0px;
      }
      .group {
        padding: 5px;
      }
    }
  }

  .error-label {
    margin-top: 4px;
  }

}

.label-image {
  width: 20px;
  height: 20px;
  margin-bottom:-5px;
  padding-right:5px;
}
.cancelAction {
  padding-left: 0.25em;
  display:inline-block;
}
</style>
