import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { computed } from 'vue';
import { Size } from '../sizes';

type Props = {
  id?: string;
  /**
   * Make the left-hand side take as much space as possible
   */
  stretchLhs?: boolean;
  /**
   * Make the right-hand side take as much space as possible
   */
  stretchRhs?: boolean;
  /**
   * Spacing between left and right side.
   */
  spacing?: Size;
  /**
   * Should the ordering of the pair be reversed.
   */
  reversed?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcPair',
  props: {
    id: {},
    stretchLhs: { type: Boolean },
    stretchRhs: { type: Boolean },
    spacing: { default: 'none' },
    reversed: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const noneStretch = computed(() => !props.stretchLhs && !props.stretchRhs);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass([
      'cmc-pair',
      `cmc-pair-spacing-${_ctx.spacing}`,
      {
        'cmc-pair-stretch-lhs': noneStretch.value || _ctx.stretchLhs,
        'cmc-pair-stretch-rhs': noneStretch.value || _ctx.stretchRhs,
        'cmc-pair-reversed': _ctx.reversed,
      }
    ])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})