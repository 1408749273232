import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "cmc-counter" }

import { computed, defineComponent } from 'vue';
import CmcTextInput from './CmcTextInput.vue';
import CmcButton from '../buttons/CmcButton.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcReadOnly from './CmcReadOnly.vue';

export type CmcCounterProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Value of the input
   */
  modelValue?: string;

  /**
   * Label on top of the input
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * True if the label should be centered above the input, defaults to false
   */
  withLabelCentered?: boolean;

  /**
   * Will show an error text under the input.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Make the input read-only.
   */
  readOnly?: boolean;
  
  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean;
  
  /**
   * Minimum value (inclusive) of the input before the decrement button is disabled
   * Defaults to 0;
   */
  min?: number;

  /**
   * Maximum value (inclusive) of the input before the increment button is disabled
   */
  max?: number;

  /**
   * True if the input should allow for the user to type within the input component.
   * Defaults to true.
   */
  allowTyping?: boolean;

  /**
   * Disable the input
   */
  disabled?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcCounter',
  props: {
    id: {},
    modelValue: {},
    label: {},
    withLabelI18n: { type: Boolean },
    withLabelCentered: { type: Boolean, default: false },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    min: { default: 0 },
    max: {},
    allowTyping: { type: Boolean, default: true },
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue", "click", "focus", "blur"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcTextInput,
  CmcButton,
  CmcGroup,
  CmcReadOnly,
})

const props = __props;

const disableTextInput = computed(() => {
  return props.disabled || !props.allowTyping;
})
const disableIncrement = computed(() => {
  const currentValue = Number(props.modelValue);
  if (!isNaN(currentValue) && (props.max !== undefined)) {
    return (currentValue >= props.max) || props.disabled;
  }
  return props.disabled;
})
const disableDecrement = computed(() => {
  const currentValue = Number(props.modelValue);
  if (!isNaN(currentValue) && (props.min !== undefined)) {
    return (currentValue <= props.min) || props.disabled;
  }
  return props.disabled;
})

const emit = __emit

const updateValue = (v: string) => {
  emit('update:modelValue', v);
}
const incrementCounter = (v: number) => {
  const updatedCount = Number(props.modelValue) + v;
  emit('update:modelValue', String(updatedCount));
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_label = _resolveComponent("cmc-label")!
  const _component_cmc_align = _resolveComponent("cmc-align")!
  const _component_cmc_text = _resolveComponent("cmc-text")!
  const _component_cmc_stack = _resolveComponent("cmc-stack")!

  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-counter-${_ctx.id}` : undefined,
    label: _ctx.label,
    "model-value": _ctx.modelValue,
    "with-label-i18n": _ctx.withLabelI18n,
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cmc_stack, { spacing: "3xs" }, {
          default: _withCtx(() => [
            _createVNode(_component_cmc_align, { "at-horizontal-center": _ctx.withLabelCentered }, {
              default: _withCtx(() => [
                _createVNode(_component_cmc_label, {
                  label: _ctx.label,
                  "with-label-i18n": _ctx.withLabelI18n
                }, null, 8, ["label", "with-label-i18n"])
              ]),
              _: 1
            }, 8, ["at-horizontal-center"]),
            _createVNode(CmcGroup, {
              class: _normalizeClass(['cmc-counter-inputs', {'cmc-counter-disabled': _ctx.disabled}]),
              spacing: "none",
              "with-vertical-align": "bottom"
            }, {
              default: _withCtx(() => [
                _createVNode(CmcButton, {
                  id: _ctx.id ? `cmc-counter-button-up-${_ctx.id}` : undefined,
                  disabled: disableDecrement.value,
                  "lhs-icon": "chevron-bold-down",
                  "lhs-icon-svg": "",
                  "icon-size": "s",
                  "aria-label": _ctx.$t('decrease'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (incrementCounter(-1)))
                }, null, 8, ["id", "disabled", "aria-label"]),
                _createVNode(CmcTextInput, {
                  id: _ctx.id ? `cmc-counter-input-${_ctx.id}` : undefined,
                  type: "number",
                  decimal: false,
                  "model-value": _ctx.modelValue,
                  disabled: disableTextInput.value,
                  "read-only": _ctx.readOnly,
                  "inherit-read-only": _ctx.inheritReadOnly,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (updateValue($event))),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('click', $event))),
                  onFocus: _cache[3] || (_cache[3] = ($event: any) => (emit('focus', $event))),
                  onBlur: _cache[4] || (_cache[4] = ($event: any) => (emit('blur', $event)))
                }, null, 8, ["id", "model-value", "disabled", "read-only", "inherit-read-only"]),
                _createVNode(CmcButton, {
                  id: _ctx.id ? `cmc-counter-button-down-${_ctx.id}` : undefined,
                  disabled: disableIncrement.value,
                  "lhs-icon": "chevron-bold-up",
                  "lhs-icon-svg": "",
                  "icon-size": "s",
                  "aria-label": _ctx.$t('increase'),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (incrementCounter(1)))
                }, null, 8, ["id", "disabled", "aria-label"])
              ]),
              _: 1
            }, 8, ["class"]),
            (_ctx.withErrorText)
              ? (_openBlock(), _createBlock(_component_cmc_text, {
                  key: 0,
                  text: _ctx.withErrorText,
                  "with-i18n": _ctx.withErrorTextI18n,
                  "as-error": "",
                  size: "m"
                }, null, 8, ["text", "with-i18n"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["id", "label", "model-value", "with-label-i18n", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n"]))
}
}

})