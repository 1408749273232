<template>
  <base-input
    v-if="shouldDisplaySearchBar"
    id="search-bar"
    ref="search-bar"
    tabindex="0"
    clearable
    softBorder
    noBorder
    :placeholder="$t('search')"
    :modelValue="searchInput"
    @update:modelValue="onSearchInput"
  >
  </base-input>
  <div class="table">
    <div v-if="shouldDisplaySearchBar">
      <div
        v-if="hasSelectedTags"
        class="label selected-tags"
      >
        <span>
          {{ $t(selectedPlaceholder) }}
        </span>
      </div>
      <div
        v-for="(option, index) in availableOptions"
        id="selected-container"
        ref="selected-container"
        :key="option.value"
        tabindex="0"
        :aria-label="option.value"
        :class="{ 'active' : isActiveOption(index, 'selected-container') }"
        @keyup.enter="onCheckboxEnter"
      >
        <div
          v-if="option.selectedFromAppliedFilter"
          class="row"
        >
          <base-checkbox
            id="checkbox"
            class="checkbox"
            :modelValue="option.selected"
            :label="getLabel(option)"
            :toolTip="shouldDisplayTooltip(getLabel(option))"
            :maxCharacters="maxCharacters"
            @update:modelValue="onCheckboxSelection($event, option)"
          >
          </base-checkbox>
        </div>
      </div>
      <hr
        v-if="hasSelectedTags"
        class="divider"
      >
    </div>
    <div
      v-for="(option, index) in availableOptions"
      id="options-container"
      ref="options-container"
      :key="option.value"
      tabindex="0"
      :aria-label="option.value"
      :class="{ 'active' : isActiveOption(index, 'options-container') }"
      @keyup.enter="onCheckboxEnter"
    >
      <div
        v-if="(!option.filtered && !option.selectedFromAppliedFilter) || !shouldDisplaySearchBar"
        class="row"
      >
        <base-checkbox
          id="checkbox"
          class="checkbox"
          :modelValue="option.selected"
          :label="getLabel(option)"
          :toolTip="shouldDisplayTooltip(getLabel(option))"
          :maxCharacters="maxCharacters"
          @update:modelValue="onCheckboxSelection($event, option)"
        >
        </base-checkbox>
      </div>
    </div>
    <div
      v-if="emptyResults"
      class="no-search-result"
    >
      <span class="center">
        {{ $t('search_results_empty') }}
      </span>
    </div>
  </div>
  <hr class="divider">
  <div class="button-container">
    <span
      id="clear-button"
      ref="clear-button"
      class="button left"
      :class="{ 'disabled': !shouldEnableButtons }"
      tabindex="0"
      :aria-label="$t('clear')"
      @click="clear"
      @keyup.enter="clear"
    >{{ $t('clear') }}</span>
    <span
      id="apply-button"
      ref="apply-button"
      class="button right"
      :class="{ 'disabled': !shouldEnableButtons }"
      tabindex="0"
      :aria-label="$t('apply')"
      @click="apply"
      @keyup.enter="apply"
    >{{ $t('apply') }}</span>
  </div>
</template>
<script>

const MAX_CHARACTERS = 14;

export default {
  name: 'CheckboxDropdown',
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true,
    },
    selection: {
      type: Set,
      default: new Set()
    },
    mustSelectedValue: {
      type: Boolean,
      default: false
    },
    shouldDisplaySearchBar: {
      type: Boolean,
      default: true
    },
    singleOption: {
      type: Boolean,
      default: false
    },
    // Indicates if the dropdown has been opened with the keyboard
    withKeyboard: {
      type: Boolean,
      default: false
    },
    selectedPlaceholder : {
      type: String,
      default: 'selected_tags',
    },
    maxCharacters : {
      type: Number,
      default: MAX_CHARACTERS
    }
  },
  emits: ['update:modelValue', 'clear', 'apply'],
  data() {
    return {
      MAX_CHARACTERS,
      relativeIndex: -1,
      searchInput: '',
      emptyResults: false,
      availableOptions: [],
      snapshotOptions: [],
    }
  },
  computed: {
    shouldEnableButtons() {
      if (this.mustSelectedValue) {
        // At least 1 item should be selected in order to enable the buttons.
        return this.hasSelectedTags;
      }
      return true;
    },
    hasSelectedTags() {
      return this.availableOptions.some(x => x.selectedFromAppliedFilter);
    }
  },
  // Initialize the checkbox dropdown with the initial selection, if any.
  created() {
    this.availableOptions = []

    if (this.items.length === 0) {
        this.emptyResults = true;
        return;
    }

    const selectedValues = new Set(Array.from(this.selection.values(), x => x.value));

    // From the Set of values, populate the list of selected items!
    for (let item of this.items) {
      const isSelected = selectedValues.has(item.value);
      this.availableOptions.push({...item, filtered: false, selectedFromAppliedFilter: isSelected, selected: isSelected});
    }

    // Sort the available options alphabetically
    this.sortAlphabetically();

    // Take a snapshot of the initial array
    this.snapshotOptions = JSON.parse(JSON.stringify(this.availableOptions));
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyboardEvent, false);

    if (this.shouldDisplaySearchBar && this.withKeyboard) {
      this.setFocusOnSearchBar();
    } else {
      // Initial the focus to the first element
      this.relativeIndex = 0;
    }
  },
  unmounted() {
    window.removeEventListener("keydown", this.handleKeyboardEvent, false);
  },
  methods: {
    /**
     * Method triggered whenever a keyboard event is raised. Handles the keyboard navigation
     * 
     * @param {event} $event The event
     */
    handleKeyboardEvent($event) {
      // Only handle supported keyboard event code
      if (!['ArrowDown', 'ArrowUp'].includes($event.code)) {
        return;
      }

      // On Arrow down, move the current selection in the dropdown down.
      if ($event.code === 'ArrowDown') {
        this.relativeIndex += 1;
      }

      // On Arrow up, move the current selection in the dropdown up.
      if ($event.code === 'ArrowUp') {
        this.relativeIndex -= 1;
      }

      // Calculate the length of the filtered list of available options
      const length = this.availableOptions.filter(x => !x.filtered).length;

      // Calculate the maximum index bound.
      let max = length + 1;
      if (this.relativeIndex > max) {
        this.relativeIndex = max;
      }

      // Calculate the minimum index bound.
      let min = this.shouldDisplaySearchBar? -2 : -1;
      if (this.relativeIndex < min) {
        this.relativeIndex = min;
      }

      // Handle the State machine.
      this.handleRelativeIndexState(length, max, min);
    },
    /**
     * State machine to determine what should be done based on the current relative index.
     * 
     * @param {Number} length The length of the options shown to the user
     * @param {Number} max The maximum value of the relative index
     * @param {Number} min The minimum value of the relative index
     */
    handleRelativeIndexState(length, max, min) {
      switch(true) {
        case (this.relativeIndex == length):
          this.$refs['clear-button']?.focus();
          break;
        case (this.relativeIndex == max): 
          this.$refs['apply-button']?.focus();
          break;
        case (this.relativeIndex == min + 1 && this.shouldDisplaySearchBar):
          this.setFocusOnSearchBar();
          break;
        case (this.relativeIndex <= min):
          this.focusMultiSelect();
          break;
      }
    },
    /**
     * Set the focus on the search bar
     */
    setFocusOnSearchBar() {
      if (!this.shouldDisplaySearchBar) {
        return;
      }
      this.$refs['search-bar'].toggleFocusInput();
    },
    /**
     * Validate if the current option is active or not based on an index.
     * 
     * @param {Number} index The index of the options
     */
    isActiveOption(index, key) {
      // If the user has not opened the dropdown with the keyboard, then do not highlight the row.
      if (!this.withKeyboard) {
        return false;
      }

      // Check if the row is active by comparing relative index.
      const active = this.relativeIndex == index;
      if (active) {
        // If the row is active, set the focus on it at the absolute index
        this.$refs[key][this.calculateAbsoluteIndex()].focus({ focusVisible: true});
      }

      return active;
    },
    onSearchInput(input) {
        this.searchInput = input;

        let count = 0;
        this.emptyResults = false;

        this.availableOptions.forEach(item => {
            const includesValue = item.value.toLowerCase().includes((input || '').toLowerCase());
            const includesLabel = item.label.toLowerCase().includes((input || '').toLowerCase());
            const includesDisplay = item.display?.toLowerCase().includes((input || '').toLowerCase());
            item.filtered = !includesValue && !includesLabel && !includesDisplay;
            if (item.filtered) {
                count++;
            }
        });

        // If you've filtered out every rows, then there is no results.
        if (count === this.availableOptions.length) {
          this.emptyResults = true;
        }
    },
    /**
     * Event triggered when the user presses Enter on a checkbox row using the keyboard
     */
    onCheckboxEnter() {
      this.onCheckboxSelection(!this.availableOptions[this.relativeIndex].selected, this.availableOptions[this.relativeIndex]);
    },
    /**
     * Event triggered when the user selects a checkbox by clicking on it.
     * 
     * This method emits the current selection as a Set.
     * 
     * @param {Boolean} $event The checkbox state
     * @param {Object} item The row representing an item
     */
    onCheckboxSelection($event, item) {
        // If this checkbox only supports a single option at once:
        if (this.singleOption) {
          this.availableOptions.forEach(x => x.selected = false);
        }

        item.selected = $event;

        // Sort the available options alphabetically
        this.sortAlphabetically();

        const selectedOptions = this.availableOptions.filter(x => x.selected).map(x => {
          return {
            value: x.value,
            label: x.label? x.label : '',
            display: !x.label? x.display : '',
          }
        });
        this.$emit('update:modelValue', new Set(selectedOptions));

        this.focusMultiSelect();
    },
    /*
    * Apply takes the current selection and return a Set.
    */
    apply() {
        if (!this.shouldEnableButtons) {
          return;
        }

        const selectedOptions = this.availableOptions.filter(x => x.selected).map(x => {
          return {
            value: x.value,
            label: x.label? x.label : '',
            display: !x.label? x.display : '',
          }
        });
        this.availableOptions.forEach(function(part, index) {
          this[index].selectedFromAppliedFilter = this[index].selected;
        }, this.availableOptions);

        this.$emit('apply', new Set(selectedOptions));
    },
    /*
    * Clear returns an empty set
    */
    clear() {
        if (!this.shouldEnableButtons) {
          return;
        }

        // Clear the search result
        this.onSearchInput('');

        this.availableOptions.forEach(x => x.selected = false);
        this.$emit('clear', new Set());
    },
    /**
     * Method to focus the multi select (the parent)
     */
    focusMultiSelect() {
      this.$parent.$refs['base-multi-select']?.focus();
    },
    /**
     * Since the search bar filters "in place" by turning the "filtered" state ON/OFF, we need
     * to calculate an absolute index based on the current active index.
     *
     * Below is an example: Abs <=> Relative
     * Abs Index | Name     | Filtered | Relative Index
     * ----------|----------|----------|------
     *   0       | APPLE    |          | 0
     *   1       | ORANGE   | true     | (Skipped)
     *   2       | TOMATO   | true     | (Skipped)
     *   3       | CUCUMBER |          | 1
     * 
     * @return {Number} The absolute index
     */ 
    calculateAbsoluteIndex() {
      // From the list of all options which aren't filtered
      const filteredOptions = this.availableOptions.filter(x => !x.filtered);

      // Find what is the absolute index where this relative index is located
      return this.snapshotOptions.findIndex(x => x?.value === filteredOptions[this.relativeIndex]?.value);
    },
    /**
     * Sort the available options (in place) by the following order:
     * 
     *  - Conditionally, sort selected first
     *  - Then, sort alphabetically
     */
    sortAlphabetically() {
      if (!this.shouldDisplaySearchBar) {
        return;
      }

      this.availableOptions.sort((x, y) => {
        const isSelected = this.shouldDisplaySearchBar? (+y.selected) - (+x.selected) : false;
        return isSelected || x.value.localeCompare(y.value);
      });
    },
    /**
     * Determine if the tooltip should be displayed or not
     * 
     * @param {string} value The value to display
     * @return {Boolean} Whether to display the tooltip or not
     */
     shouldDisplayTooltip(value) {
      // Do not display tooltip if you are showing presets
      if (this.singleOption) {
        return false;
      }

      return value.length > this.maxCharacters;
    },
    /**
     * Get the truncated value
     * 
     * @param {string} value The value to truncate
     * @returns The truncated value
     */
    getTruncatedValue(value) {
      if (!this.shouldDisplayTooltip(value)) {
        return this.$t(value);
      }
      return this.$t(value).substring(0, this.maxCharacters) + "...";
    },
    /**
     * 
     * @param {Object} option 
     * @returns The i18n label or display label
     */
    getLabel(option) {
      return option.label? this.$t(option.label) : (option.display || option.value)
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/variables.scss';

.table {
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    display: inline-block;

    .no-search-result {
        width: 100%;

        .center {
            margin: auto;
            width: 50%;
            display: block;
            padding: 25px;
            text-align: center;
        }
    }

    &::-webkit-scrollbar {
      width: 12px; /* for vertical scrollbars */
    }

    &::-webkit-scrollbar-track {
      opacity: 0%;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.3)
    }
}

.active-row {
  background-color: var(--primary-light);
}

.selected-tags {
  font-size: 0.85em;
  padding-left: 12px;
  margin-bottom: 6px;
  color: var(--list-header-text);
}
    
.row {
    height: 40px;
        
    .checkbox {
        margin-left: 12px;
        margin-top: 12px;
    }
    .text {
        position: relative;
        margin-top: 14px;
    }

    .truncate {
      text-align: left;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}

.divider {
    color: var(--button-inactive-text);
    margin-top: 12px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.button-container {
    height: 45px;

    .button {
        padding: 12px 12px 12px 0px;
        cursor: pointer;
    }

    .button:hover{
      color: var(--button-submit-bg)
    }

    .disabled {
      opacity: 0.7;
      cursor: default;
    }

    .left {
        float: left;
        margin-left: 12px;
    }
    .right {
        float: right;
        margin-right: 12px;
    }
}

.input {
  border-radius: 0px;
}

</style>
