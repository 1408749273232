
<template>
  <div>
    <section-header :searchEnabled="false">
      <template #logo>
        <base-icon icon="fa fa-commenting" />
      </template>
      <template #title>
        <div>{{ $t('feedback_and_support.name') }}</div>
      </template>
      <template #subtitle>
        <div>{{ $t('feedback_and_support.description') }}</div>
      </template>
    </section-header>
    <base-loader v-if="loading"></base-loader>
    <div v-else>
      <div class="edit">
        <action-icon 
          v-protected="toSettings"
          icon="fa fa-edit" 
          :expandOnHover="true" 
          tooltipLabel="edit"
          :to="toSettings"
        />
      </div>
      <div class="rows">
        <form-row>
          <div class="label">
            {{ $t("feedback_and_support.feedback_slack_webhook") }}
          </div>
          <pre v-if="settings.feedbackSlackWebhookUrl">{{ settings.feedbackSlackWebhookUrl }}</pre>
          <pre 
            v-else
            wrap 
          >
            {{ $t('feedback_and_support.no_slack_webhook_configured') }} 
          </pre>
        </form-row>
        <form-row>
          <div class="label">
            {{ $t("feedback_and_support.feedback_email") }}
          </div>
          <pre v-if="settings.feedbackEmail">{{ settings.feedbackEmail }}</pre>
          <pre 
            v-else
            wrap 
          >
            {{ $t('feedback_and_support.no_feedback_email_configured') }} 
          </pre>
        </form-row>
        <form-row v-if="support === 'email'">
          <div class="label">
            {{ $t("feedback_and_support.support_email") }}
          </div>
          <pre v-if="settings.supportEmail">{{ settings.supportEmail }}</pre>
          <pre 
            v-else
            wrap 
          >
            {{ $t('feedback_and_support.no_support_email_configured') }} 
          </pre>
        </form-row>
        <div v-else>
          <h4 class="zendesk-title">
            {{ $t('feedback_and_support.zendesk_widget') }}
          </h4>
          <form-row>
            <div class="label">
              {{ $t("feedback_and_support.widget_key") }}
            </div>
            <pre>{{ settings.zendeskWidgetKey }}</pre>
          </form-row>
          <form-row>
            <div class="label">
              {{ $t("feedback_and_support.window_zesettings") }}
            </div>
            <pre v-highlightjs><code class="json">{{ settings.widgetZesettings }}</code></pre>
          </form-row>
        </div>
        <form-row>
          <div class="label">
            {{ $t("feedback_and_support.help_center_url") }}
          </div>
          <pre v-if="settings.helpCenterUrl">{{ settings.helpCenterUrl }}</pre>
          <pre 
            v-else
            wrap 
          >
            {{ $t('feedback_and_support.no_help_center_url_configured') }} 
          </pre>
        </form-row>
        <form-row>
          <div class="label">
            {{ $t("feedback_and_support.matomo_host") }}
          </div>
          <pre v-if="settings.matomoHost">{{ settings.matomoHost }}</pre>
          <pre 
            v-else
            wrap 
          > 
            {{ $t('feedback_and_support.no_matomo_host_configured') }} 
          </pre>
        </form-row>
        <form-row>
          <div class="label">
            {{ $t("feedback_and_support.matomo_website_id") }}
          </div>
          <pre v-if="settings.matomoWebsiteId">{{ settings.matomoWebsiteId }}</pre>
          <pre 
            v-else
            wrap 
          >
            {{ $t('feedback_and_support.no_matomo_website_id_configured') }} 
          </pre>
        </form-row>
        <form-row>
          <div class="label">
            {{ $t("feedback_and_support.google_tag_manager_container_id") }}
          </div>
          <pre v-if="settings.googleTagManagerContainerId">{{ settings.googleTagManagerContainerId }}</pre>
          <pre 
            v-else
            wrap 
          >
            {{ $t('feedback_and_support.no_google_tag_manager_container_id_configured') }} 
          </pre>
        </form-row>
      </div>
    </div>
    <system-activity-listener 
      :codes="codes" 
      @receive="load" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import { resellerOrgNavigationMixin } from '@/mixins/resellerOrgNavigationGuard';
import SystemActivityListener from '@/events/SystemActivityListener';

export default {
  name: 'FeedbackSupportView',
  components: { SystemActivityListener },
  mixins: [resellerOrgNavigationMixin],
  data() {
    return {
      loading: false,
      settings: {
        feedbackSlackWebhookUrl: undefined,
        feedbackEmail: undefined,
        supportEmail: undefined,
        zendeskWidgetKey: undefined,
        widgetZesettings: undefined,
        helpCenterUrl: undefined,
        matomoHost: undefined,
        matomoWebsiteId: undefined,
        googleTagManagerContainerId: undefined,
      },
      recentChange: false,
      support: 'email',
    };
  },
  computed: {
    ...mapGetters([
      'selectedOrganization',
    ]),
    toSettings() {
      return { name: 'feedbackSupportSettings' };
    },
    codes() {
      return [
        'feedback_settings.create',
        'feedback_settings.update',
        'feedback_settings.delete',
      ];
    },
  },
  async created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const resp = await apis.feedbackSettings
        .getOrganizationFeedbackSettings(this.selectedOrganization.id);
      if (resp.ok && resp.data) {
        this.settings = resp.data;
        if (this.settings.zendeskWidgetKey && this.settings.zendeskWidgetKey !== '') {
          this.support = 'widget';
        } else {
          this.support = 'email';
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

.edit {
  position: relative;
  float: right;
  padding: 15px 35px;
}

.alert-box {
  padding-top: 5px;
  padding-bottom: 10px;
}

.zendesk-title {
  margin-bottom: 5px;
}

.rows {
  padding: 15px 35px;
  flex-direction: column;
  @include mixins.phone {
    padding: 15px 10px;
  }
}

</style>
