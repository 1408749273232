<template>
  <div :class="['elements-ui', { 'mobile-mode': mobile }]">
    <base-modal :open="!!error">
      <div class="error-modal">
        <div class="main-title title col-xs-12 col-md-12">
          Error parsing: {{ serviceType || 'Core' }}
        </div>
        <markdown-viewer :modelValue="error">
        </markdown-viewer>
        <div class="disclaimer">
          This modal will not appear in production
        </div>
        <div class="btns">
          <base-button @click="error = ''">
            Close
          </base-button>
        </div>
      </div>
    </base-modal>
    <elements-api
      id="docs"
      router="hash"
      :layout="mobile ? 'stacked' : 'sidebar'"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const SCHEMA_URL_PREFIX = '/schemas/';
const OPERATION_URL_PREFIX = '/operations/';

export default {
  props: {
    docs: {
      type: Array,
      default: () => [],
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: '',
      openApiStr: undefined,
      openApi: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'userBrandingInfo',
    ]),
    serviceType() {
      return this.$route.params.serviceType;
    }, 
    url() {
      return this.serviceType ? `/rest/docs/${this.serviceType}/api/v3/openapi.json` : `/rest/docs/api/v3/openapi.json`;
    },
    validate() {
      return process.env.VUE_APP_VERSION.includes('SNAPSHOT');
    },
    apiTitle() {
      let title = 'Core';
      if (this.serviceType) {
        title = (this.docs.find(d => d.serviceType === this.serviceType) || {}).display || this.serviceType;
      }
      return `${title} API`;
    },
    isSchemaSelected() {
      return this.$route.hash.includes(SCHEMA_URL_PREFIX);
    },
    opId() {
      return (this.$route.hash.replace('#', '') || '/').replace(OPERATION_URL_PREFIX, '');
    },
    selectedOp() {
      if (!this.openApi) {
        return null;
      }
      return Object.values(this.openApi.paths)
        .map(p => Object.values(p)
          .find(e => e.operationId === this.opId))
        .filter(e => e)[0];
    },
    entityTitle() {
      if (this.isSchemaSelected) {
        return this.$route.hash.split(SCHEMA_URL_PREFIX)[1];
      }
      if (this.selectedOp) {
        return this.selectedOp.summary;
      }
      return null;
    },
    endpointTitle() {
      if (this.isSchemaSelected) {
        return 'Schemas';
      }
      if (this.selectedOp) {
        return (this.selectedOp.tags || [])[0];
      }
      return null;
    },
    htmlTitle() {
      return [this.entityTitle, this.endpointTitle, this.apiTitle]
        .filter(t => t)
        .join(' - ');
    },
  },
  watch: {
    async $route() {
      await this.waitForDoc();
    },
    async htmlTitle() {
      this.setTitle();
    },
  },
  async mounted() {
    await Promise.all([
      import("@/../node_modules/@cloudops/elements/styles.min.css"),
      import("@/../node_modules/@cloudops/elements/web-components.min.js"),
      this.loadOpenApi(),
    ]);
    await this.setOpenApi();

    await this.waitForDoc();
    await this.setTitle();
  },
  methods: {
    async loadOpenApi() {
      this.openApiStr = await fetch(`${this.url}?validate=${this.validate}`).then(res => res.text());
      this.openApi = JSON.parse(this.openApiStr);
    },
    async setOpenApi() {
      this.error = '';
      const docs = document.getElementById('docs');
      if (docs) {
        docs.apiDescriptionDocument = this.openApiStr;
      }
      if (this.validate) {
        this.error = this.openApi['x-cmc-unknown-fields'];
      }
      if (this.error) {
        console.warn(this.error);
      }
    },
    waitForDoc() {
      return new Promise((resolve) => {
        let isLoaded = () => !!document.querySelector('.ElementsTableOfContentsItem');
        if (isLoaded()) {
          resolve();
          return;
        }
        // Observe the html. On load, an ElementsTableOfContentsItem element should appear.
        // Stop observing and set title.
        let observer = new MutationObserver((_, observer) => {
          if (isLoaded()) {
            resolve();
            observer && observer.disconnect();
          }
        });
        const docs = document.getElementById('docs');
        if (docs) {
          observer.observe(docs, { childList: true, subtree: true });
        }
      });
    },
    setTitle() {
      document.title = this.htmlTitle;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/variables.scss';

elements-api {
  position: absolute;
  overflow-y: auto;
  height: calc(100% - 50px);
  top: 50px;
  width: 100%;
}

:deep(.sl-bg-canvas) {
  position: relative;

  .sl-mb-10 {
    margin-top: 20px;
  }
  .sl-py-16 > div > input {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    font-size: 16px;
    padding-left: 24px;
    border-bottom: 1px solid #f0f0f0;
  }

}

:deep(#bundled) {
  display: none;
}

.mobile-mode {
  elements-api {
    padding: 20px;
  }
  :deep(.sl-mb-10 a.sl-flex.sl-justify-end) {
    display: none;
  }
}

.error-modal {
  width: 85%;
  padding-bottom: 20px;
  .main-title {
    margin-bottom: 10px;
  }

  .btns {
    display: flex;
    justify-content: flex-end;
  }

  .disclaimer {
    font-size: 0.7rem;
    font-style: italic;
  }

}
</style>