<template>
  <cmc-block
    withBorder
    paddingVertical="3xl"
    paddingHorizontal="2xl"
  >
    <cmc-group
      withVerticalAlign="center"
      spacing="3xl"
      takeFullHeight
    >
      <ColorCard
        v-for="(color, idx) in modelValue"
        :key="idx"
        :title="'#' + (idx + 1)"
        :color="color"
        :readonly="readonly"
        @update:model-value="updateColor($event, idx)"
      />
    </cmc-group>
  </cmc-block>
</template>


<script setup lang="ts">
import { defineComponent } from 'vue';
import CmcBlock from "@/components/nextgen/layout/CmcBlock.vue";
import CmcGroup from "@/components/nextgen/layout/CmcGroup.vue";
import ColorCard from "./ColorCard.vue";

defineComponent({
  CmcBlock,
  CmcGroup,
  ColorCard,
})

export type ColorSwatchProps = {
  /**
   * Array of #hex color values
   */
  modelValue?: string[];

  /**
   * Whether the colors can be edited.
   */
  readonly?: boolean;
}

withDefaults(defineProps<ColorSwatchProps>(), {
  modelValue: () => [],
  readonly: false
});

const updateColor = (v: string, index: number) => {
  emit('update:modelValue', v, index);
}

const emit = defineEmits<{
  /**
   * Emitted when color is changed.
   * @arg color - the hexadecimal color including #
   * @arg index - the index of the color in the list
   */
  (event: 'update:modelValue', color: string, index: number): void

}>()

</script>

<style scoped lang="scss">
</style>
