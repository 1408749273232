<template>
  <cmc-list-select
    :label="label"
    with-label-i18n
    :with-tooltip="description"
    with-tooltip-i18n
    :headers="listSelectHeaders"
    :categories="listSelectCategories"
    :options="listSelectOptions"
    :layout="listSelectLayout"
    :modelValue="modelValue"
    :category="category"
    :config="config"
    :disabled="disabled"
    @update:modelValue="inputChange"
    @update:category="categoryChange"
    @update:config="configChange"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

type FormOption = {
  interpolation: any;
  type: string;
  name: string;
  is18n?: boolean;
  disabled?: boolean;
  isLeaf?: boolean;
  children?: Object[];
  value: string;
  imageUrl?: string;
  icon?: string;
  titles?: Object[];
  options: FormOption[];
  group?: string;
  detail?: string;
  withDetailI18n?: boolean;
  color?: string;
}
type HeadersMetadata = {
  label: string;
  type: string;
  tooltip: string;
}
type Selection = {
  label: string;
  options: FormOption[];
  disabled: boolean;
  optional: boolean;
  required: boolean;
  field: string;
  reloadOnChange: boolean;
  sectionsToReload: string[];
  type: string;
}
type Entry = {
  label: string;
  value: string;
  tooltip: string;
  tooltipKeyValue: {[key: string]: string};
  withTooltipI18n: boolean;
  selections: Selection[];
  imgUrl: string;
}
type Category = {
  label: string;
  value: string;
  disabled: boolean;
  headersMetadata: HeadersMetadata[];
  entries: Entry[];
}
type Props = {
  label?: string;
  description?: String;
  formElement: Record<string, any>;
  modelValue: any;
  category?: string;
  disabled?: boolean;
  config: {[key: string]: any };
}

const props = withDefaults(defineProps<Props>(), {})

const listSelectHeaders = computed(() => {
  return [{
    label: props.formElement.firstColumnHeader.label,
    type: props.formElement.firstColumnHeader.type,
    tooltip: ""
  }].concat(props.formElement.categories[0].headersMetadata || []).map((h: HeadersMetadata)  => {
    return ({
        label: h.label,
        type: h.type,
        withI18n: true,
        withTooltip: h.tooltip,
        withTooltipI18n: true,
      });
  })
})
const listSelectCategories = computed(() => {
  return (props.formElement.categories ||[]).map((c: Category) => {
    return ({
      key: c.value,
      label: c.label,
      withI18n: true
    })
  })
})
const listSelectOptions = computed(() => {
  return (props.formElement.categories ||[]).map((c: Category) => {
    return (c.entries || []).map((e: Entry) => {
      return ({
        key: e.value,
        value: e.value,
        label: e.label,
        withI18n: false,
        imgUrl: e.imgUrl,
        category: c.value,
        withTooltip: e.tooltip,
        withTooltipKeyValue: e.tooltipKeyValue,
        withTooltipI18n: true,
        configs: (e.selections || []).map((s: Selection) => {
          return ({
            key: s.field,
            options: (s.options || []).map((o: FormOption) => {
              return ({
                withLabelI18n: o.is18n,
                value: o.value,
                label: o.name,
                detail: o.detail,
                interpolation: o.interpolation,
                withDetailI18n: true
              })
            })
          })
        })
      })
    })
  }).reduce((acc: [], g: []) => acc.concat(g), [])
})
const listSelectLayout = computed(() => {
  return (listSelectHeaders.value || []).map(header => {
    return ({
      u: `1-${listSelectHeaders.value.length}`,
      asColType: header.type
    })
  })
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: any): void,
  (event: 'update:config', value: any): void,
  (event: 'update:category', category: string | undefined): void,
}>()

function inputChange(v: Object | Date | String | Number | Boolean | Array<Object>) {
  emit('update:modelValue', v);
}
function configChange(config: any) {
  emit('update:config', config);
}
function categoryChange(category: any) {
  emit('update:category', category);
}

</script>