<template>
  <div class="successful-registration-message-container">
    <div class="message-container">
      <h1 class="title">
        {{ $t('successful_registration_title') }}
      </h1>
      <div class="body">
        {{ $t('successful_registration_body') }}
      </div>
    </div>
    <div class="sign-in-button">
      <base-button
        tabindex="0"
        type="submit"
        @keyup.enter="returnToLogin"
        @click="returnToLogin"
      >
        {{
          $t('sign_in') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'SuccessfulRegistrationMessage',
    emits: ['signIn'],
    computed: {
        ...mapGetters([
            'locale',
        ]),
    },
    methods: {
        returnToLogin() {
            this.$router.replace('');
            this.$emit('signIn');
        }
    },
};
</script>


<style scoped lang="scss">
@use '@/styles/login-form.scss';

.successful-registration-message-container {
    margin: -20px;
}

.sign-in-button {
    padding-top: 10px;
}
</style>
