import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "before-list" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "clear" }
const _hoisted_5 = { class: "after-list" }

import { defineComponent, computed, ref } from 'vue';
import VueMultiselect from 'vue-multiselect';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import CmcAlert from '../display/CmcAlert.vue';
import CmcTag from '../display/CmcTag.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcTextInput from './CmcTextInput.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcServiceConnection from '../display/CmcServiceConnection.vue';
import type { SelectOption, SingleSelectOption, GroupedSelectOption, SelectAction } from './types';

import 'vue-multiselect/dist/vue-multiselect.css';
import { useI18n } from 'vue-i18n';
import authz from '@/authz';

export type SelectProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Label of the select.
   */
  label?: string;
  /**
   * Is the label i18n.
   */
  withLabelI18n?: boolean;

  /**
   * Description of the select.
   */
  description?: string;

  /**
   * Is the description i18n.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Model value of the select
   */
  modelValue?: any;

  /**
   * Options of the select.
   */
  options: SelectOption[];

  /**
   * Actions of the select.
   */
  actions?: SelectAction[];

  /**
   * Add a placeholder to the select
   */
  withPlaceholder?: string

  /**
   * The placeholder is an i18n label key.
   */
  withPlaceholderI18n?: boolean

  /**
   * Label to show when empty select.
   */
  withEmptyLabel?: string

  /**
   * The empty label is an i18n label key.
   */
  withEmptyLabelI18n?: boolean

  /**
   * Whether there is a custom label to display when no option is picked in a Step-based form
   */
  withReadOnlyEmptyLabel?: string;

  /**
   * Tooltip next to the label
   */
  withTooltip?: string;
  /**
   * Is the tooltip i18n.
   */
  withTooltipI18n?: boolean;

  /**
   * Error text under the select.
   */
  withErrorText?: string;

  /**
   * Error text is i18n label.
   */
  withErrorTextI18n?: boolean;

  /**
   * Set the select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Is allowed to be empty
   */
  allowEmpty?: boolean;

  /**
   * Allow new options to be added that are not in the list. Only works with multiselect.
   */
  allowNew?: boolean;

  /**
   * Disable the select.
   */
  disabled?: boolean;

  /**
   * Support multiselect.
   */
  asMulti?: boolean;

  /**
   * Display as tags
   */
  asTag?: boolean;

  /**
   * Select for numbers.
   */
  asNumber?: boolean;
  asOptional?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcSelect',
  props: {
    id: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    modelValue: {},
    options: {},
    actions: {},
    withPlaceholder: {},
    withPlaceholderI18n: { type: Boolean },
    withEmptyLabel: {},
    withEmptyLabelI18n: { type: Boolean },
    withReadOnlyEmptyLabel: {},
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    allowEmpty: { type: Boolean, default: true },
    allowNew: { type: Boolean },
    disabled: { type: Boolean },
    asMulti: { type: Boolean },
    asTag: { type: Boolean },
    asNumber: { type: Boolean },
    asOptional: { type: Boolean, default: false }
  },
  emits: ["update:modelValue", "action"],
  setup(__props: any, { emit: __emit }) {

const elemRef = ref()

defineComponent({
  VueMultiselect,
  CmcText,
  CmcStack,
  CmcBlock,
  CmcPair,
  CmcGroup,
  CmcReadOnly,
  CmcIcon,
  CmcAlert,
  CmcTag,
  CmcDivider,
  CmcTextInput,
  CmcAlign,
})

const props = __props

const hasGroup = computed(() => {
  return !!actualOptions.value.find(o => !!(o as GroupedSelectOption).options)
})

const actualValue = computed(() => {
  if (props.modelValue === null || props.modelValue === undefined) {
    return undefined;
  }

  const allOptions = getAllFlattenedOptions(props.options);
  const optionsMap = createOptionsMap(allOptions);

  const selectedOptions = props.asMulti 
    ? handleMultiSelect(props.modelValue, optionsMap)
    : handleSingleSelect(props.modelValue, optionsMap);
  
  if (Array.isArray(selectedOptions)) {
    return sortOptions(selectedOptions);
  }

  return selectedOptions;
});

function sortOptions(options: SingleSelectOption[]): SingleSelectOption[] {
  return options.sort((a, b) => {
    // First sort by group label
    const groupComparison = (a.groupLabel || '').localeCompare(b.groupLabel || '');
    if (groupComparison !== 0) {
      return groupComparison;
    }
    // Then sort by option label within the same group
    return a.label.localeCompare(b.label);
  });
}

function getAllFlattenedOptions(options: (GroupedSelectOption | SingleSelectOption)[]): SingleSelectOption[] {
  const groupedOptions = options
    .filter((opt): opt is GroupedSelectOption => 'options' in opt && Array.isArray(opt.options))
    .flatMap(group => group.options!.map(opt => ({
      ...opt,
      groupLabel: group.label
    })));

  const singleOptions = options
    .filter((opt): opt is SingleSelectOption => !('options' in opt))
    .map(opt => ({
      ...opt,
      groupLabel: ''
    }));

  return [...groupedOptions, ...singleOptions];
}

function createOptionsMap(options: SingleSelectOption[]): Record<string, SingleSelectOption> {
  return options.reduce((acc, option) => {
    acc[JSON.stringify(option.value)] = option;
    return acc;
  }, {} as Record<string, SingleSelectOption>);
}

function handleMultiSelect(values: any[], optionsMap: Record<string, SingleSelectOption>): SingleSelectOption[] {
  return (values || []).map(value => {
    const option = optionsMap[JSON.stringify(value)] || {
      value,
      label: value,
      groupLabel: ''
    };

    return option;
  });
}

function handleSingleSelect(value: any, optionsMap: Record<string, SingleSelectOption>): SingleSelectOption | null {
  const selectedOption = Object.values(optionsMap).find(opt => opt.value === value);
  
  if (!selectedOption) {
    return null;
  }

  return {
    ...selectedOption,
    label: selectedOption.label,
  };
}

const { t } = useI18n()

const actualOptions = computed<SelectOption[]>(() => {
  if (props.asMulti) {
    const selected: SingleSelectOption[] = Array.isArray(actualValue.value) ? actualValue.value : []

    const selectedValues = new Set(selected.map(s => s.value))
    if (!selected.length) {
      return props.options
    }
    const emptyGroup: GroupedSelectOption = {
      label: '',
      options: [],
    }

    return [{
      label: t('selected_items'),
      options: selected,
    }, ...props.options.map((opt: SelectOption): GroupedSelectOption | null => {
      if ('options' in opt) {
        const options = opt.options.filter(c => !selectedValues.has(c.value))
        if (!options.length) {
          return null
        }
        return {
          ...opt,
          options,
        }
      } else if (!selectedValues.has(opt.value)) {
        emptyGroup.options.push(opt)
      }
      return null
    }).filter((opt): opt is GroupedSelectOption => !!opt)].concat([emptyGroup])
  }
  return props.options;
})

const permittedActions = computed(() => {
  return props.actions?.filter(a => authz.hasPermission(a.permission));
})

const emit = __emit

const onSelect = (opt: any) => {
  if ((!opt || Object.keys(opt).length === 0) && props.allowEmpty) {
    emit('update:modelValue', undefined)
  } else {
    if (!props.asMulti) {
      opt && emit('update:modelValue', (opt as SingleSelectOption).value);
    } else { 
      emit('update:modelValue', (opt as SingleSelectOption[]).map(o => o.value));
    }
  }
}

if (!props.allowEmpty && !actualValue.value && actualOptions.value.length) {
  const firstVal: SelectOption = actualOptions.value[0];
  if (!props.asMulti) {
    onSelect(firstVal && 'options' in firstVal ? firstVal.options[0] : firstVal)
  } else {
    onSelect([firstVal])
  }
}

const doClose = () => {
  elemRef.value.deactivate()
}

const doClear = () => {
  if (props.asMulti) {
    onSelect([])
  } else {
    onSelect(undefined);
  }
}

const doAction = (action: SelectAction) => {
  if(!action.isDisabled) {
    doClose();
    emit('action', action.value);
  }
}

const search = ref('')

const doFilter = (opt: SingleSelectOption) => {
  if (opt.value === actualValue.value?.value) {
    return undefined;
  }
  const label = opt.withLabelI18n ? t(opt.label) : opt.label;
  return label?.toLowerCase().includes(search.value.toLowerCase())
}

const filteredOptions = computed(() => {
  return actualOptions.value
    .map(opt => {
      if ((opt as GroupedSelectOption).options) {
        const gopt = opt as GroupedSelectOption;
        return {
          ...gopt,
          options: gopt.options.filter(doFilter).filter(o => o),
        }
      }
      if (doFilter(opt as SingleSelectOption)) {
        return opt;
      }
      return null;
    }).filter(opt => opt)
});

const atLeastOneOptionHasIcon = computed(() => {
  return actualOptions.value.some(opt => (opt as SingleSelectOption).hasIcon || (opt as SingleSelectOption).isServiceConnection)
});

const onSearchClick = () => {
  if (!elemRef.value) {
    return
  }
  elemRef.value.$el.querySelector('.before-list input')?.focus()
  setTimeout(() => {
    elemRef.value.isOpen = true;
  })
  elemRef.value.isOpen = true;
}

const onSearchBlur = () => {
  if (!elemRef.value) {
    return
  }
  elemRef.value.deactivate()
}

const opened = ref(false)
const trackOpen = () => {
  opened.value = true;
}
const trackClose = () => {
  opened.value = false;
  search.value = '';
}

const searchable = computed(() => {
  return (!props.asNumber && actualOptions.value.length > 10 && !allNumeric()) || props.asTag;
})

const allNumeric = () => {
  return actualOptions.value.every(o => !isNaN(o.label));
}

const onTag = () => {
  if (props.asMulti && props.allowNew && search.value) {
    if (!new Set(props.modelValue).has(search.value)) {
      emit('update:modelValue', [...props.modelValue, search.value]);
    }
  }
}

const optionsValueSet = computed(() => {
  return new Set(props.options.flatMap((opt) => {
    if ((opt as GroupedSelectOption).options) {
      return (opt as GroupedSelectOption).options.map(o => o.value)
    }
    return [(opt as SingleSelectOption).value]
  }))
})

const searchValueExists = computed(() => {
  return new Set(props.modelValue).has(search.value) || optionsValueSet.value.has(search.value)
})

const readOnlyValue = computed(() => {
  if (!actualValue.value) {
    return '';
  } else if (Array.isArray(actualValue.value)) {
    return actualValue.value.map(v => v.withLabelI18n ? t(v.label) : v.label).join(", ")
  }
  return actualValue.value.withLabelI18n ? t(actualValue.value.label) : actualValue.value.label
})

const hasValue = computed(() => {
  if (actualValue.value && Array.isArray(actualValue.value)) {
    return actualValue.value.length > 0;
  }
  return !!actualValue.value;
})

return (_ctx: any,_cache: any) => {
  const _component_cmc_label = _resolveComponent("cmc-label")!

  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-select-${_ctx.id}` : undefined,
    label: _ctx.label,
    "with-label-i18n": _ctx.withLabelI18n,
    "model-value": readOnlyValue.value + '' || _unref(t)(_ctx.withReadOnlyEmptyLabel ? _ctx.withReadOnlyEmptyLabel : 'none'),
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        class: _normalizeClass(['cmc-select', {
        'cmc-select-no-options': !actualOptions.value.length && !_ctx.disabled,
        'cmc-select-as-number': _ctx.asNumber,
        'cmc-select-as-tag': _ctx.asTag,
        'cmc-select-opened': opened.value,
        'cmc-select-has-value': !!actualValue.value,
        'cmc-select-has-many-options': actualOptions.value.length > 1,
        'cmc-select-as-multi': _ctx.asMulti,
        'cmc-select-has-group': hasGroup.value,
      }]),
        spacing: "3xs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cmc_label, _mergeProps(props, { "as-header": "" }), null, 16),
          _createElementVNode("div", {
            class: "cmc-select-wrapper",
            onKeydown: _withKeys(onTag, ["enter"])
          }, [
            _createVNode(_unref(VueMultiselect), {
              ref_key: "elemRef",
              ref: elemRef,
              label: "label",
              options: filteredOptions.value,
              "model-value": actualValue.value,
              placeholder: _ctx.$t('search'),
              searchable: (!actualValue.value || _ctx.asMulti) && searchable.value,
              "group-values": hasGroup.value ? 'options': undefined,
              "group-label": hasGroup.value ? 'label' : undefined,
              "track-by": "value",
              multiple: _ctx.asMulti,
              taggable: _ctx.asMulti && _ctx.allowNew,
              "show-labels": false,
              "allow-empty": _ctx.allowEmpty,
              disabled: _ctx.disabled,
              "internal-search": false,
              closeOnSelect: !_ctx.asMulti,
              onSearchChange: _cache[1] || (_cache[1] = ($event: any) => (search.value = $event)),
              "onUpdate:modelValue": onSelect,
              onOpen: trackOpen,
              onClose: trackClose
            }, {
              beforeList: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "beforeList"),
                  (actualValue.value && searchable.value)
                    ? (_openBlock(), _createBlock(CmcTextInput, {
                        key: 0,
                        modelValue: search.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                        "as-search": "",
                        "inherit-read-only": false,
                        onClick: onSearchClick,
                        onBlur: onSearchBlur
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true),
                  (search.value && _ctx.asMulti && _ctx.allowNew && !searchValueExists.value)
                    ? (_openBlock(), _createBlock(CmcAlert, {
                        key: 1,
                        class: "cmc-select-press-enter",
                        text: "Press Enter to create a new tag with this name",
                        "with-outline": false
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              singleLabel: _withCtx(() => [
                _createVNode(CmcPair, { "stretch-lhs": "" }, {
                  default: _withCtx(() => [
                    _createVNode(CmcGroup, { spacing: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(CmcGroup, {
                          "with-vertical-align": "top",
                          spacing: "3xs"
                        }, {
                          default: _withCtx(() => [
                            (actualValue.value && actualValue.value.isServiceConnection)
                              ? (_openBlock(), _createBlock(CmcIcon, {
                                  key: 0,
                                  icon: actualValue.value.type,
                                  size: "m",
                                  "as-plugin-icon": "",
                                  svg: "",
                                  class: "cmc-select-option-with-icon-margin"
                                }, null, 8, ["icon"]))
                              : (actualValue.value && actualValue.value.hasIcon)
                                ? (_openBlock(), _createBlock(CmcIcon, {
                                    key: 1,
                                    icon: actualValue.value.icon,
                                    size: "m",
                                    svg: "",
                                    class: "cmc-select-option-with-icon-margin"
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                            _createVNode(CmcGroup, {
                              "with-horizontal-align": _ctx.asNumber ? 'center' : undefined,
                              "with-vertical-align": "center",
                              spacing: "3xs"
                            }, {
                              default: _withCtx(() => [
                                (actualValue.value)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 0,
                                      class: _normalizeClass([actualValue.value.withTooltip && !actualValue.value.detail ? 'actual-value-with-tooltip' : '']),
                                      text: actualValue.value.label,
                                      "with-i18n": actualValue.value.withLabelI18n,
                                      size: "l",
                                      "without-wrap": "",
                                      "with-tooltip": actualValue.value.detail ? '' : actualValue.value.withTooltip,
                                      "with-tooltip-i18n": actualValue.value.withTooltipI18n ?? !!actualValue.value.withTooltip,
                                      "with-tooltip-placement": "bottom",
                                      "with-tooltip-html": actualValue.value.withTooltipHtml
                                    }, null, 8, ["class", "text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
                                  : _createCommentVNode("", true),
                                (actualValue.value && actualValue.value.detail)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 1,
                                      "with-i18n": actualValue.value.withDetailI18n,
                                      text: actualValue.value.withDetailI18n ? _unref(t)(actualValue.value.detail, actualValue.value.interpolation) : actualValue.value.detail,
                                      size: "m",
                                      "as-description": ""
                                    }, null, 8, ["with-i18n", "text"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["with-horizontal-align"])
                          ]),
                          _: 1
                        }),
                        (actualValue.value && actualValue.value.withTooltip && actualValue.value.detail)
                          ? (_openBlock(), _createBlock(CmcText, {
                              key: 0,
                              class: "actual-value-with-tooltip",
                              text: "",
                              "with-tooltip": actualValue.value.withTooltip,
                              "with-tooltip-i18n": actualValue.value.withTooltipI18n ?? !!actualValue.value.withTooltip,
                              "with-tooltip-placement": "bottom",
                              "with-tooltip-html": actualValue.value.withTooltipHtml
                            }, null, 8, ["with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    (actualValue.value)
                      ? _renderSlot(_ctx.$slots, "rhs", {
                          key: 0,
                          option: actualValue.value
                        })
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                })
              ]),
              option: _withCtx(({ option }) => [
                _createVNode(CmcBlock, {
                  class: _normalizeClass(['option', !option.$isLabel ? 'cmc-select-option' : '', {'option-as-disabled': option.isDisabled} ])
                }, {
                  default: _withCtx(() => [
                    _createVNode(CmcAlign, {
                      "at-vertical-center": "",
                      "at-horizontal-center": _ctx.asNumber
                    }, {
                      default: _withCtx(() => [
                        (option.$isLabel)
                          ? (_openBlock(), _createBlock(CmcStack, {
                              key: 0,
                              class: _normalizeClass(['cmc-select-group-label', {'empty': !option.$groupLabel}]),
                              spacing: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcDivider, { class: "group-separator" }),
                                (option.$groupLabel)
                                  ? (_openBlock(), _createBlock(CmcTitle, {
                                      key: 0,
                                      title: option.$groupLabel,
                                      "with-i18n": "",
                                      heading: "h5"
                                    }, null, 8, ["title"]))
                                  : _createCommentVNode("", true),
                                (option.detail && !option.hideDetailInOptions)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 1,
                                      "with-i18n": option.withDetailI18n,
                                      text: option.withDetailI18n ? _unref(t)(option.detail, option.interpolation): option.detail,
                                      size: "m",
                                      "as-description": ""
                                    }, null, 8, ["with-i18n", "text"]))
                                  : _createCommentVNode("", true),
                                (option.withTooltip)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 2,
                                      text: "",
                                      "with-tooltip": option.withTooltip,
                                      "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                    }, null, 8, ["with-tooltip", "with-tooltip-i18n"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["class"]))
                          : (option.isServiceConnection)
                            ? (_openBlock(), _createBlock(CmcServiceConnection, {
                                key: 1,
                                name: option.label,
                                type: option.type,
                                "as-select-option": true,
                                "as-category-title": option.isCategoryTitle,
                                "with-i18n": option.withLabelI18n,
                                "with-tooltip": option.withTooltip,
                                "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                "with-tooltip-html": option.withTooltipHtml
                              }, null, 8, ["name", "type", "as-category-title", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
                            : (option.hasIcon || atLeastOneOptionHasIcon.value)
                              ? (_openBlock(), _createBlock(CmcPair, {
                                  key: 2,
                                  class: _normalizeClass(['cmc-select-option-with-icon']),
                                  "stretch-rhs": "",
                                  spacing: "3xs"
                                }, {
                                  default: _withCtx(() => [
                                    (option.hasIcon)
                                      ? (_openBlock(), _createBlock(CmcIcon, {
                                          key: 0,
                                          icon: option.icon,
                                          size: "m",
                                          svg: "",
                                          class: "cmc-select-option-with-icon-margin"
                                        }, null, 8, ["icon"]))
                                      : (atLeastOneOptionHasIcon.value)
                                        ? (_openBlock(), _createBlock(CmcIcon, {
                                            key: 1,
                                            icon: "no-image",
                                            size: "m",
                                            svg: "",
                                            class: "cmc-select-option-with-icon-margin"
                                          }))
                                        : _createCommentVNode("", true),
                                    _createVNode(CmcText, {
                                      text: option.label,
                                      "with-i18n": option.withLabelI18n,
                                      "with-tooltip": option.withTooltip,
                                      "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                      size: "l"
                                    }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_ctx.asMulti)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                    (_ctx.asTag)
                                      ? (_openBlock(), _createBlock(CmcTag, {
                                          key: 0,
                                          value: option.value,
                                          text: option.label,
                                          "with-i18n": option.withLabelI18n,
                                          color: option.color,
                                          "with-tooltip": option.withTooltip,
                                          "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                        }, null, 8, ["value", "text", "with-i18n", "color", "with-tooltip", "with-tooltip-i18n"]))
                                      : (_openBlock(), _createBlock(CmcGroup, {
                                          key: 1,
                                          class: "cmc-multiselect__options-label-container",
                                          spacing: "none"
                                        }, {
                                          default: _withCtx(() => [
                                            (option.groupLabel)
                                              ? (_openBlock(), _createBlock(CmcBlock, {
                                                  key: 0,
                                                  paddingTop: "4xs",
                                                  paddingRight: "3xs"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(CmcText, {
                                                      size: "m",
                                                      text: option.groupLabel,
                                                      "with-i18n": option.withLabelI18n,
                                                      "with-tooltip": option.withTooltip,
                                                      "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                                      asDescription: !!option.groupLabel
                                                    }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "asDescription"])
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              : _createCommentVNode("", true),
                                            _createVNode(CmcBlock, null, {
                                              default: _withCtx(() => [
                                                _createVNode(CmcText, {
                                                  size: "l",
                                                  text: option.label,
                                                  "with-i18n": option.withLabelI18n,
                                                  "with-tooltip": option.withTooltip,
                                                  "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                                }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024))
                                  ], 64))
                                : (_openBlock(), _createBlock(CmcPair, {
                                    key: 4,
                                    "stretch-lhs": ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(CmcGroup, {
                                        "with-horizontal-align": _ctx.asNumber ? 'center' : undefined,
                                        "with-vertical-align": "center",
                                        spacing: "4xs"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(CmcText, {
                                            size: "l",
                                            text: option.label,
                                            "with-i18n": option.withLabelI18n
                                          }, null, 8, ["text", "with-i18n"]),
                                          _createElementVNode("div", {
                                            class: _normalizeClass({ 'cmc-text-details': _ctx.asNumber })
                                          }, [
                                            (option.detail && !option.hideDetailInOptions)
                                              ? (_openBlock(), _createBlock(CmcText, {
                                                  key: 0,
                                                  "with-i18n": option.withDetailI18n,
                                                  text: option.withDetailI18n ? _unref(t)(option.detail, option.interpolation) : option.detail,
                                                  size: "m",
                                                  "as-description": ""
                                                }, null, 8, ["with-i18n", "text"]))
                                              : _createCommentVNode("", true)
                                          ], 2),
                                          (option.withTooltip)
                                            ? (_openBlock(), _createBlock(CmcText, {
                                                key: 0,
                                                text: "",
                                                "with-tooltip": option.withTooltip,
                                                "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                              }, null, 8, ["with-tooltip", "with-tooltip-i18n"]))
                                            : _createCommentVNode("", true),
                                          _renderSlot(_ctx.$slots, "rhs", { option: option })
                                        ]),
                                        _: 2
                                      }, 1032, ["with-horizontal-align"])
                                    ]),
                                    _: 2
                                  }, 1024))
                      ]),
                      _: 2
                    }, 1032, ["at-horizontal-center"])
                  ]),
                  _: 2
                }, 1032, ["class"])
              ]),
              placeholder: _withCtx(() => [
                (_ctx.withPlaceholder && (!actualValue.value || (Array.isArray(actualValue.value) && !actualValue.value.length)) && (actualOptions.value.length || _ctx.disabled))
                  ? (_openBlock(), _createBlock(CmcText, {
                      key: 0,
                      text: _ctx.withPlaceholder,
                      "with-i18n": _ctx.withPlaceholderI18n,
                      size: "l",
                      "as-description": ""
                    }, null, 8, ["text", "with-i18n"]))
                  : (!actualOptions.value.length && !_ctx.disabled)
                    ? (_openBlock(), _createBlock(CmcAlert, {
                        key: 1,
                        text: _ctx.withEmptyLabel || _ctx.$t('no_options'),
                        "with-i18n": _ctx.withEmptyLabelI18n,
                        color: "blue",
                        "with-outline": false
                      }, null, 8, ["text", "with-i18n"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2))
              ]),
              caret: _withCtx(() => [
                (hasValue.value && _ctx.allowEmpty)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", {
                          onMousedown: _withModifiers(doClear, ["stop"])
                        }, [
                          _createVNode(CmcIcon, {
                            icon: "times-bold",
                            size: "xs",
                            svg: ""
                          })
                        ], 32),
                        _createVNode(CmcIcon, {
                          class: "caret",
                          icon: "triangle-down",
                          size: "xs",
                          svg: ""
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              tag: _withCtx(({option,remove}) => [
                (_ctx.asTag)
                  ? (_openBlock(), _createBlock(CmcTag, {
                      key: 0,
                      value: option.value,
                      text: option.label,
                      "with-i18n": option.withLabelI18n,
                      "with-remove": "",
                      color: option.color,
                      onRemove: ($event: any) => (remove(option))
                    }, null, 8, ["value", "text", "with-i18n", "color", "onRemove"]))
                  : (_openBlock(), _createBlock(CmcGroup, {
                      key: 1,
                      class: "cmc-multiselect__options-label-container",
                      spacing: "none"
                    }, {
                      default: _withCtx(() => [
                        (option.groupLabel)
                          ? (_openBlock(), _createBlock(CmcBlock, {
                              key: 0,
                              paddingTop: "4xs"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcText, {
                                  size: "m",
                                  text: option.groupLabel,
                                  "with-i18n": option.withLabelI18n,
                                  "with-tooltip": option.withTooltip,
                                  "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                  asDescription: !!option.groupLabel
                                }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "asDescription"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createVNode(CmcBlock, null, {
                          default: _withCtx(() => [
                            _createVNode(CmcText, {
                              size: "l",
                              text: option.label,
                              "with-i18n": option.withLabelI18n,
                              "with-tooltip": option.withTooltip,
                              "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                            }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
              ]),
              noOptions: _withCtx(() => [
                (search.value)
                  ? (_openBlock(), _createBlock(CmcAlert, {
                      key: 0,
                      text: 'search_results_empty',
                      "with-i18n": "",
                      "with-outline": false,
                      "as-warning": ""
                    }))
                  : _createCommentVNode("", true)
              ]),
              noResult: _withCtx(() => [
                _createVNode(CmcAlert, {
                  text: 'search_results_empty',
                  "with-i18n": "",
                  "with-outline": false,
                  "as-warning": ""
                })
              ]),
              afterList: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (permittedActions.value)
                    ? (_openBlock(), _createBlock(CmcBlock, {
                        key: 0,
                        class: "cmc-select-after-list",
                        "padding-horizontal": "s",
                        "padding-vertical": "2xs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcStack, { spacing: "4xs" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(permittedActions.value, (action) => {
                                return (_openBlock(), _createBlock(CmcText, {
                                  key: action.value,
                                  class: _normalizeClass(!action.isDisabled ? 'select-actions' : 'select-actions-disabled'),
                                  text: action.label,
                                  "with-tooltip": action.withTooltip,
                                  "with-i18n": "",
                                  "with-tooltip-i18n": action.withTooltipI18n ?? !!action.withTooltip,
                                  "tooltip-as-status-report": "",
                                  "as-clickable": "",
                                  size: "l",
                                  onClick: ($event: any) => (doAction(action))
                                }, null, 8, ["class", "text", "with-tooltip", "with-tooltip-i18n", "onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.asMulti)
                    ? (_openBlock(), _createBlock(CmcBlock, {
                        key: 1,
                        class: "cmc-select-after-list",
                        "padding-horizontal": "s",
                        "padding-vertical": "2xs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcPair, { "stretch-lhs": "" }, {
                            default: _withCtx(() => [
                              _createVNode(CmcText, {
                                text: "clear",
                                "with-i18n": "",
                                "as-clickable": "",
                                size: "m",
                                onClick: doClear
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 3
            }, 8, ["options", "model-value", "placeholder", "searchable", "group-values", "group-label", "multiple", "taggable", "allow-empty", "disabled", "closeOnSelect"]),
            ((!actualValue.value || _ctx.asMulti) && searchable.value)
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 0,
                  icon: "search",
                  class: "cmc-search-icon-lhs",
                  svg: ""
                }))
              : _createCommentVNode("", true)
          ], 32),
          (_ctx.withErrorText)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 0,
                text: _ctx.withErrorText,
                "with-i18n": _ctx.withErrorTextI18n,
                "as-error": "",
                size: "m"
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["id", "label", "with-label-i18n", "model-value", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n"]))
}
}

})