import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "aria-label", "type", "disabled", "aria-disabled"]
const _hoisted_2 = {
  key: 0,
  class: "cmc-button-loading-icon"
}
const _hoisted_3 = {
  key: 1,
  class: "cmc-button-lhs"
}

import { useI18n } from "vue-i18n";
import { computed, defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';
import CmcIcon from '../misc/CmcIcon.vue';

type Size = 's' | 'm' | 'l';
type ButtonType = 'submit' | 'button' | 'reset'

interface Props {
  /**
   * HTML Element Id
   */
  id?: string;
  /**
   * Text to use for the contents of the Button. This is can be a label key with the use of the i18n flag.
   */
  text?: string;
  /**
   * Renders the button with a background color so that users know that it's an action button.
   */
  callToAction?: boolean;
  /**
   * The name of the icon to display on the left-hand side. This can be an svg icon with the use of the lhsIconSvg flag.
   */
  lhsIcon?: string;
  /**
   * If true, then the lhsIcon refers to an svg icon.
   */
  lhsIconSvg?: boolean;
  /**
   * If true, then the text is a label key that will be translated.
   */
  withI18n?: boolean;
  /**
   * Button type. This can be button, submit or reset.
   */
  type?: ButtonType;
  /**
   * Button will take the full-width of the parent container. 
   */
  takeFullWidth?: boolean;
  /**
   * Button will take the width of the child contents.
   */
  takeTextWidth?: boolean,
  /**
   * Button will be disabled and won't emit clicks anymore.
   */
  disabled?: boolean;
  /**
   * Size of icon, can be 's', 'm', or 'l'.
   */
  iconSize?: Size;

  loading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcButton',
  props: {
    id: {},
    text: {},
    callToAction: { type: Boolean, default: false },
    lhsIcon: {},
    lhsIconSvg: { type: Boolean },
    withI18n: { type: Boolean, default: false },
    type: { default: 'button' },
    takeFullWidth: { type: Boolean, default: false },
    takeTextWidth: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    iconSize: {},
    loading: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

defineComponent({
  CmcIcon,
  CmcText,
})

const emit = __emit

const handleClick = (event: Event) => {
  if (!props.disabled) {
    emit('click', event);
  }
  event?.target?.blur()
}
const { t } = useI18n();
const iconOnly = computed(() => {
  return props.lhsIcon && !props.text
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    id: _ctx.id ? `cmc-button-${_ctx.id}` : undefined,
    tabindex: "0",
    "aria-label": _ctx.withI18n ? _unref(t)(_ctx.text || '') : _ctx.text,
    type: _ctx.type,
    disabled: _ctx.disabled || _ctx.loading,
    "aria-disabled": _ctx.disabled || _ctx.loading,
    class: _normalizeClass(
      ['cmc-button', {
        'cmc-button-icon-only': iconOnly.value,
        'cmc-button-call-to-action': _ctx.callToAction || _ctx.type === 'submit',
        'cmc-button-std-width': !_ctx.takeFullWidth && !_ctx.takeTextWidth && !iconOnly.value,
        'cmc-button-full-width': _ctx.takeFullWidth,
        'cmc-button-text-width': _ctx.takeTextWidth,
        'cmc-button-disabled': _ctx.disabled || _ctx.loading,
      }]),
    onClick: handleClick
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(CmcIcon, {
            icon: "progress",
            color: "blue",
            spin: "",
            svg: ""
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.lhsIcon && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(CmcIcon, {
            id: _ctx.id ? `${_ctx.id}-lhs-icon` : undefined,
            class: "cmc-button-lhs-icon",
            icon: _ctx.lhsIcon,
            svg: _ctx.lhsIconSvg,
            size: _ctx.iconSize
          }, null, 8, ["id", "icon", "svg", "size"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createBlock(CmcText, {
          key: 2,
          text: _ctx.text,
          "with-i18n": _ctx.withI18n,
          size: "l",
          class: _normalizeClass(['cmc-button-text', {'has-lhs-icon': _ctx.lhsIcon}])
        }, null, 8, ["text", "with-i18n", "class"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})