import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent, provide, ref } from 'vue';
import CmcModal from './CmcModal.vue';
import CmcDrawer from './CmcDrawer.vue';
import CmcFooter from './CmcFooter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcOverlayProvider',
  setup(__props) {

defineComponent({
  CmcModal,
  CmcDrawer,
  CmcFooter,
})

const initRefs = () => { 
  const component = ref()
  const props = ref()
  const parentProps = ref()
  const opened = ref(false)
  return {
    component,
    props,
    parentProps,
    opened,
    close: () => {
      opened.value = false;
      setTimeout(() => {
        component.value = null;
        props.value = undefined;  
      }, 100)
    },
  } 
}
const modal = initRefs()
const drawer = initRefs()
const footer = initRefs()

provide('cmc-modal', modal)
provide('cmc-drawer', drawer)
provide('cmc-footer', footer)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(CmcModal),
    _createVNode(CmcDrawer),
    _createVNode(CmcFooter)
  ], 64))
}
}

})