<template>
  <cmc-chart
    ref="cmcChart"
    v-bind="chartProps"
    @click="eventListeners.handleClick"
    @legend-select-changed="eventListeners.handleLegendSelectChanged"
  >
    <template #tooltip-template>
      <slot name="tooltip-template"></slot>
    </template>
  </cmc-chart>
</template>

<script setup lang="ts">
import CmcChart from "@/components/nextgen/charts/CmcChart.vue";
import { use } from "echarts/core";
import { LineChart } from "echarts/charts";
import { computed, getCurrentInstance, ref } from "vue";
import merge from "deepmerge";
import { EChartsOption, LineSeriesOption } from "echarts";
import { ToolboxOption } from "echarts/types/src/component/toolbox/ToolboxModel";
import {
  LegendOption,
  TooltipOption,
  XAXisOption,
  YAXisOption,
  GridOption
} from "echarts/types/dist/shared";
import {
  EChartStyleOptions,
  GraphColor,
  GraphTextStyle
} from "@/components/nextgen/charts/branding";
import useCmcChartEventListeners from "@/components/nextgen/composables/useCmcChartEventListeners";

use([LineChart]);

/** 
 * THERE IS A BUG IN VUE3 with TYPESCRIPT in which we can chain several interfaces together if one is imported from another file
 * The solution is that we unfortunately need to list off this huge chain (Props + CmcChartVisibleProps + CmcChartProps), 
 * and this causes issues with Jest testing.  We should eventually retest this in mid 2025 to see if it Typescript support has improved. 
*/

/**
 * The LineChart props type
 * The style options are not supported, this is why they are omitted (EChartStyleOptions) from props and
 *     this is to forget to go through the colors and textStyles props and to reinforce the conformity of the design.
 */
interface Props {
  /** THIS BATCH OF PROPS IS ORIGINALLY FROM CMCCHARTPROPS */

  /**
   * The default colors used for the chart.
   * Specific colors can be specified for each series
   */
   colors?: GraphColor[];

  /**
   * The default text style used for the chart.
   */
  textStyle?: GraphTextStyle;

  /**
   * The inherited html classes from custom implementation
   */
  class?: Record<string, any>,

  /**
   * The inherited css inline styles from custom implementation
   */
  style?: Record<string, any>
  /** END OF CMCCHARTPROPS */

  /** START OF CMCCHARTVISIBLEPROPS */
  /**
     * The chart tooltip options
     */
    tooltip?: Omit<TooltipOption, EChartStyleOptions>;

  /**
   * The chart legend options
   */
  legend? : Omit<LegendOption, EChartStyleOptions>;

  /**
   * The chart grid options
   */
  grid?   : Omit<GridOption, EChartStyleOptions>;

  /**
   * The chart toolbox options
   */
  toolbox?: Omit<ToolboxOption, EChartStyleOptions>;
  /** END OF CMCCHARTVISIBLEPROPS */

  /** START ACTUAL PROPS WHICH EXTEND ABOVE */
  
  /**
   * Line chart x-axis options.
   * @see {@link https://echarts.apache.org/en/option.html#xAxis}
   */
  xAxis?: Omit<XAXisOption, EChartStyleOptions>[];
  /**
   * Line chart y-axis options.
   * @see {@link https://echarts.apache.org/en/option.html#yAxis}
   */
  yAxis?: Omit<YAXisOption, EChartStyleOptions>[];

  /**
   * The line-chart series options
   * @see {@link https://echarts.apache.org/en/option.html#series-line}
   */
  series: Omit<LineSeriesOption, EChartStyleOptions>[];

  /**
   * Additional set of various options that will be merged with the known ones
   * @see CmcChart.props.customOptions
   * @see {@link https://echarts.apache.org/en/option.html}
   */
  customOptions?: EChartsOption;
}

const props = defineProps<Props>();

const CHART_MARGIN_TOP = '14%';
const AXIS_NAME_TO_LINE_SPACING = 40;

const tooltipOptions = computed( () => ({ trigger: 'axis', confine: true }));
const legendOptions = computed(() => {
  return props.series.length > 1 ? { data: props.series.map(s => s.name), top: CHART_MARGIN_TOP } : null;
});

const seriesOptions = computed(() => {
  return props.series.map((s, idx) => ({
    ...s,
    type: 'line',
    symbol: s.symbol ? s.symbol : 'emptyCircle',
    smooth: false,
    ...(props.xAxis ? { xAxisIndex: Math.min(idx, props.xAxis.length > 0 ? props.xAxis.length - 1 : 0) } : {}),
    ...(props.yAxis ? { yAxisIndex: Math.min(idx, props.yAxis.length > 0 ? props.yAxis.length - 1 : 0) } : {}),
  }));
});

const customOptionsMerged = computed(() => {
  let xAxisOption;
  let yAxisOption;

  if (!props.xAxis) {
    xAxisOption = props.series.map(s => ({
      type: 'category',
      data: s.data,
      boundaryGap: false,
    }));
  } else {
    xAxisOption = props.xAxis.map(axis => ({
      type: 'category',
      boundaryGap: false,
      ...axis,
    }));
  }

  if (!props.yAxis) {
    yAxisOption = [{type: 'value'}]
  } else {
    yAxisOption = props.yAxis.map(axis => ({
      type: 'value',
      nameLocation: 'middle',
      nameGap: AXIS_NAME_TO_LINE_SPACING,
      ...axis,
    }));
  }

  const optionsOverride = {
    xAxis: xAxisOption,
    yAxis: yAxisOption,
  };
  return props.customOptions ?
    merge(optionsOverride, props.customOptions) :
    optionsOverride;
});

const chartProps = computed(() => ({
  ...props,
  series : seriesOptions.value as LineSeriesOption[],
  tooltip: tooltipOptions.value as TooltipOption,
  legend : legendOptions.value as LegendOption,
  customOptions: customOptionsMerged.value as EChartsOption
}));

const emit = defineEmits(['click', 'legendSelectChanged']);
const eventListeners = useCmcChartEventListeners(getCurrentInstance(), emit);

const cmcChart = ref<InstanceType<typeof CmcChart>>();
defineExpose({
  updateLegends: (selectedLegends: any) => cmcChart.value?.updateLegends(selectedLegends),
  toggleAllLegends: (allLegends: string[], enable: boolean) => cmcChart.value?.toggleAllLegends(allLegends, enable)
});

</script>