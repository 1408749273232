import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, normalizeClass as _normalizeClass, withCtx as _withCtx, Teleport as _Teleport } from "vue"

import { Ref, defineComponent, inject } from 'vue';
import CmcDimmed from './CmcDimmed.vue';
import { ModalProps } from './useModal';
import CmcBlock from "@/components/nextgen/layout/CmcBlock.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcModal',
  setup(__props) {

defineComponent({
  CmcDimmed,
})

const { opened, component, props, parentProps, close } = inject('cmc-modal') as {
  component: Ref<any>,
  props: Ref<any>,
  parentProps: Ref<ModalProps>,
  opened: Ref<boolean>,
  close: () => void
}

const dimmedDbHandler = () => {
  if (!parentProps.value?.preventClose) {
    close();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_unref(opened))
      ? (_openBlock(), _createBlock(CmcDimmed, {
          key: 0,
          onDblclick: dimmedDbHandler
        }))
      : _createCommentVNode("", true),
    (_unref(opened))
      ? (_openBlock(), _createBlock(CmcBlock, {
          key: 1,
          class: _normalizeClass([
        'cmc-modal',
        { 'cmc-modal-as-card': _unref(parentProps)?.asCardModal }
      ])
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component)), _mergeProps(_unref(props), { close: _unref(close) }), null, 16, ["close"]))
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}
}

})