import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';

type Color = 'green' | 'red' | 'blue' | 'yellow' | 'grey';

interface Props {
  /**
   * HTML Element id
   */
  id?: string;
  /**
   * Text to add in the badge
   */
  text: string;
  /**
   * Color of the badge
   */
  color?: Color;
  /**
   * Text is an i18n label key
   */
  withI18n?: boolean;
  /**
   * Add an outline around badge
   */
  withOutline?: boolean;
  /**
   * Make the background transparent instead of default.
   */
  withTransparent?: boolean;
  /**
   * Make the badge color animate
   */
  animate?:boolean;
  /**
   * Interpolation for the text.
   */
  interpolation?: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcBadge',
  props: {
    id: {},
    text: {},
    color: {},
    withI18n: { type: Boolean },
    withOutline: { type: Boolean },
    withTransparent: { type: Boolean },
    animate: { type: Boolean },
    interpolation: {}
  },
  setup(__props: any) {

defineComponent({
  CmcText,
})




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id ? `cmc-badge-${_ctx.id}` : undefined,
    class: _normalizeClass([
      'cmc-badge', 
      { 
        'cmc-badge-with-outline' : _ctx.withOutline,
        'cmc-badge-with-transparent' : _ctx.withTransparent,
      }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['cmc-badge-circle', _ctx.color, { 'cmc-badge-animate' : _ctx.animate}])
    }, null, 2),
    _createVNode(CmcText, {
      ref: "badgeTextRef",
      class: "cmc-badge-text",
      size: "l",
      "with-i18n": _ctx.withI18n,
      text: _ctx.text,
      interpolation: _ctx.interpolation,
      "without-wrap": ""
    }, null, 8, ["with-i18n", "text", "interpolation"])
  ], 10, _hoisted_1))
}
}

})