import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, vShow as _vShow, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cmc-unwrapped-text-border"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "cmc-text-controls" }
const _hoisted_4 = {
  key: 0,
  class: "cmc-unwrapped-text-border"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }

import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { boldify, isMobileMode, isTabletMode } from '@/utils';
import CmcPair from "../layout/CmcPair.vue";
import CmcBlock from "../layout/CmcBlock.vue";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcGroup from "../layout/CmcGroup.vue";
import { Size, Alignment, TooltipPlacement } from "./types";

type Props = {
  /**
   * Text to display
   */
  text: string;
  /**
   * Size of the text
   */
  size?: Size;

  /** To align the text if required.  Possible values are:
   * 'left',
   * 'right',
   * 'center',
   * 'justify'
   */
  align?: Alignment;

  /**
   * Interpolation for the label.
   */
  interpolation?: any;

  /**
   * True if the text is a label key.
   */
  withI18n?: boolean;

  /**
   * True if the text is bold.
   */
  withBold?: boolean;

  /**
   * True if the interpolation should be bold.
   */
  withBoldInterpolation?: boolean;

  /**
   * True if the text is monospace.
   */
  withMonospace?: boolean;

  /**
   * Prevent text from wrapping to next line. If the text is cutoff, a tooltip will be provided on hover.
   */
  withoutWrap?: boolean;

  /**
   * Make the text sensitive and hidden. Will show an eye icon that can toggle between seeing the value and hiding it.
   */
  withSensitive?: boolean;

  /**
   * Show the copyable icon.
   */
  withCopyable?: boolean;

  /**
   * Text should be copyable on hover.
   */
  withCopyableOnClick?: boolean;

  /**
   * Tooltip text.
   */
  withTooltip?: string;

  /**
   * A non-warning tooltip either represents 'information on demand', or a 'status report'. 
   * If 'information on demand' ie: this property is false, should render the info icon as grey.
   * If 'status report', shoulder render the info icon as blue.
   * See CloudOps Design System -> CloudOps Patterns -> Notifications Iconography and Colour for details.
   */
  tooltipAsStatusReport?: boolean;

  /**
   * Tooltip placement.
   * Possible values are:
   * 'auto-start'
   * 'auto-end'
   * 'top'
   * 'top-start'
   * 'top-end'
   * 'right'
   * 'right-start'
   * 'right-end'
   * 'bottom'
   * 'bottom-start'
   * 'bottom-end'
   * 'left'
   * 'left-start'
   * 'left-end'
   */
  withTooltipPlacement?: TooltipPlacement;

  /**
   * Key-value pairs displayed in tooltip.
   */
  withTooltipKeyValue?: {[key: string]: string};

  /**
   * Tooltip text is an i18n label.
   */
  withTooltipI18n?: boolean;

  /**
   * Tooltip content contains raw HTML.
   */
  withTooltipHtml?: boolean;

  /**
   * Tooltip width (m or l)
   */
  withTooltipSize?: string;

  /**
   * Tooltip icon size in t-shirt sizing
   */
  withTooltipIconSize?: Size;

  /**
   * Use a label instead of a div element.
   */
  asLabel?: boolean;

  /**
  /**
   * Display text as disabled text.
   */
  asDisabled?: boolean;

  /**
   * Display with light text.
   */
  asDescription?: boolean;

  /**
   * Display as an error.
   */
  asError?: boolean;

  /**
   * Show pointer on top of hover text.
   */
  asClickable?: boolean;

  /**
   * Show the text as optional.
   */
  asOptional?: boolean;

  /**
   * If the text overflows but the tooltip is not required.
   */
  withoutTooltipOnHover?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Maximum number of characters allowed in the text, after which an ellipsis will be displayed 
   */
  maxChars?: number;

  maxLineDisplay?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcText',
  props: {
    text: {},
    size: { default: 'l' },
    align: {},
    interpolation: { default: {} },
    withI18n: { type: Boolean, default: false },
    withBold: { type: Boolean, default: false },
    withBoldInterpolation: { type: Boolean, default: false },
    withMonospace: { type: Boolean, default: false },
    withoutWrap: { type: Boolean, default: false },
    withSensitive: { type: Boolean },
    withCopyable: { type: Boolean },
    withCopyableOnClick: { type: Boolean },
    withTooltip: {},
    tooltipAsStatusReport: { type: Boolean },
    withTooltipPlacement: {},
    withTooltipKeyValue: {},
    withTooltipI18n: { type: Boolean },
    withTooltipHtml: { type: Boolean },
    withTooltipSize: { default: 'm' },
    withTooltipIconSize: { default: 'm' },
    asLabel: { type: Boolean, default: false },
    asDisabled: { type: Boolean },
    asDescription: { type: Boolean },
    asError: { type: Boolean, default: false },
    asClickable: { type: Boolean },
    asOptional: { type: Boolean, default: false },
    withoutTooltipOnHover: { type: Boolean, default: false },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    maxChars: {},
    maxLineDisplay: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcPair, CmcIcon, CmcGroup
})

const rootRef = ref();
const textRef = ref();
const textContainerRef = ref();
const cmcTextClassRef = ref();
const showSensitive = ref(false);
const tooltipHtmlRef = ref();
const hoverTimeout = ref();
const displayActual = ref(false);

const props = __props;

const cmcTextClass = computed(() => ({
    [`maxLine-${props.maxLineDisplay}`]: props.maxLineDisplay && !displayActual.value,
    'cmc-text-unwrapped': displayActual.value
  }
));

const isLineClamped = computed(() => {
  if (!props.maxLineDisplay) {
    return false;
  }
  if (cmcTextClassRef.value) {
    return cmcTextClassRef.value.scrollHeight > cmcTextClassRef.value.clientHeight || cmcTextClassRef.value.scrollWidth > cmcTextClassRef.value.clientWidth;
  }
  return false;
});

const tooLong = computed(() => {
  if (textRef.value) {
    // Although .cmc-text-controls have an absolute position, rendering it outside
    // context of the box model it sits within, javascript still counts this for scrollWidth.
    // Subtracting the width of visible icons stops weird jumpiness.
    let widthCorrection = (props.withCopyableOnClick || props.withCopyable) ? 22 : 0;
    widthCorrection += props.withSensitive ? 22 : 0;
    return textRef.value.offsetWidth < textRef.value.scrollWidth - widthCorrection;
  }
  return false;
})

const maxCharsChopped = computed(() => {
  if (props.withSensitive) {
    return false;
  }
  if (props.maxChars) {
    return actualText.value.length > props.maxChars;
  }
  return false;
});

const hover = () => {
  if ((tooLong.value || maxCharsChopped.value || isLineClamped.value) && !hoverTimeout.value) {
    hoverTimeout.value = setTimeout(() => {
      showUnchoppedText();
    }, 1500);
  }
}
const leave = () => {
  if (hoverTimeout.value) {
    clearTimeout(hoverTimeout.value);
    hoverTimeout.value = null;
    hideUnchoppedText();
  }
}

function textToDots(text: string): string {
  const maskedText = text.slice(0, 16).replace(/./g, '•');
  return maskedText + (text.length > 16 ? '...' : '');
}

const { t } = useI18n()

const actualText = computed(() => {
  const interpolation = props.withBoldInterpolation ? boldify(props.interpolation) : props.interpolation;
  const translated = props.withI18n ? t(props.text, interpolation) : props.text;
  if (props.withSensitive && !showSensitive.value) {
    return textToDots(translated);
  }
  
  return translated;
})

const displayedText = computed(() => {
  if (!displayActual.value && maxCharsChopped.value) {
    return actualText.value.slice(0, props.maxChars) + '…'
  }
  return actualText.value;
})

const showCopyTooltip = ref()
const copyToClipboard = () => {
  navigator.clipboard.writeText(actualText.value);
  clearTimeout(showCopyTooltip.value);
  showCopyTooltip.value = setTimeout(() => {
    showCopyTooltip.value = undefined;
  }, 1000);
}

const tooltipLabel = computed(() => {
  if (props.withTooltipHtml) {
    return props.withTooltip
  }
  return props.withTooltipI18n ? t(props.withTooltip || '') : props.withTooltip
})

const warningTooltipLabel = computed(() => {
  return props.withWarningTooltipI18n ? t(props.withWarningTooltip || '') : props.withWarningTooltip
});

const showCopyIcon = computed(() => {
  return (props.withCopyable || props.withCopyableOnClick) && (!props.withSensitive || showSensitive.value);
});

const copyTooltip = computed(() => ({
  placement: 'right',
  content: 'Copied!',
  shown: !!showCopyTooltip.value,
  autoHide: false,
  triggers: [],
}));

const tooltipProps = computed(() => ({
  content: tooltipLabel.value,
  html: props.withTooltipHtml,
  popperClass: 'cmc-tooltip-size-' + props.withTooltipSize,
  placement: props.withTooltipPlacement || 'auto',
}));

const warningTooltipProps = computed(() => ({
  content: warningTooltipLabel.value,
  popperClass: 'cmc-tooltip-size-' + props.withTooltipSize,
  placement: props.withTooltipPlacement || 'auto',
}));

const emit = __emit

const doClick = (event: any) => {
  // Check that you have clicked on the text or container block
  // to prevent clicks on sensitive eye icon propagating as copy clicks.
  if (props.withCopyableOnClick && (event.target.classList.contains("cmc-text-display") || event.target.classList.contains("cmc-block"))) {
    copyToClipboard();
  }
  if ((tooLong.value || maxCharsChopped.value || isLineClamped.value) && (isMobileMode() || isTabletMode())) {
    showUnchoppedText();
  }
  emit('click', event);
}

const doClickOutside = (event: any) => {
  if (rootRef.value.contains(event.target)) {
    emit("click", event);
    return;
  }
  if (tooLong.value || maxCharsChopped.value) {
    hideUnchoppedText();
  }
}

const showUnchoppedText = () => {
  if (displayActual.value) {
    return;
  }
  // dont modify the textContainerRef style if chopping text using text-clamp
  if (!props.maxLineDisplay && !isLineClamped.value) {
    // Has to be like this otherwise style.height gets set to 2x the value if done directly???
    const width = textRef.value.offsetWidth + 'px';
    const height = textRef.value.offsetHeight + 'px';
    textContainerRef.value.style.width = width;
    textContainerRef.value.style.height = height;
  }
  displayActual.value = true;
}

const hideUnchoppedText = () => {
  if (textContainerRef.value) {
    textContainerRef.value.style.width = null;
    textContainerRef.value.style.height = null;
  }
  displayActual.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_VTooltip = _resolveComponent("VTooltip")!
  const _directive_click_outside = _resolveDirective("click-outside")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.asLabel ? 'label' : 'div'), {
    ref_key: "rootRef",
    ref: rootRef,
    class: _normalizeClass([
      'cmc-text',
      `cmc-text-${_ctx.size}`,
      _ctx.align && `cmc-text-align-${_ctx.align}`,
      {
        'cmc-text-with-bold': _ctx.withBold,
        'cmc-text-with-monospace': _ctx.withMonospace,
        'cmc-text-without-wrap': _ctx.withoutWrap,
        'cmc-text-as-error': _ctx.asError,
        'cmc-text-as-description': _ctx.asDescription,
        'cmc-text-as-disabled': _ctx.asDisabled,
        'cmc-text-as-clickable': _ctx.asClickable,
        'cmc-text-with-copyable-on-hover': _ctx.withCopyableOnClick
      },
    ]),
    onClick: doClick
  }, {
    default: _withCtx(() => [
      (_ctx.withSensitive || _ctx.withCopyable)
        ? (_openBlock(), _createBlock(CmcBlock, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(CmcGroup, {
                "with-vertical-align": "center",
                spacing: "2xs",
                class: "cmc-group-inline-patch",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "lhs"),
                  _createElementVNode("div", {
                    ref_key: "textContainerRef",
                    ref: textContainerRef,
                    class: "cmc-text-container"
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(cmcTextClass.value),
                      onMouseover: hover,
                      onMouseleave: leave
                    }, [
                      (displayActual.value)
                        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
                            [_directive_click_outside, doClickOutside]
                          ])
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        ref_key: "textRef",
                        ref: textRef,
                        class: "cmc-text-display",
                        innerHTML: displayedText.value
                      }, null, 8, _hoisted_2),
                      (showCopyIcon.value && displayActual.value)
                        ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                            key: 1,
                            class: "copy-icon",
                            icon: "copy",
                            svg: "",
                            "with-clickable": "",
                            onClick: copyToClipboard
                          }, null, 512)), [
                            [_directive_tooltip, copyTooltip.value]
                          ])
                        : _createCommentVNode("", true)
                    ], 34)
                  ], 512),
                  (_ctx.withTooltip)
                    ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                        key: 0,
                        icon: "info-filled-bold",
                        class: _normalizeClass(['cmc-text-tooltip-icon',
                   {
                     'cmc-text-tooltip-icon-status-report': _ctx.tooltipAsStatusReport
                   }]),
                        svg: "",
                        size: _ctx.withTooltipIconSize
                      }, null, 8, ["class", "size"])), [
                        [_directive_tooltip, tooltipProps.value]
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.withWarningTooltip)
                    ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                        key: 1,
                        icon: "exclamation-filled-bold",
                        class: "cmc-text-tooltip-warning-icon",
                        svg: "",
                        size: _ctx.withTooltipIconSize
                      }, null, 8, ["size"])), [
                        [_directive_tooltip, warningTooltipProps.value]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _: 3
              }),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.withSensitive)
                  ? (_openBlock(), _createBlock(CmcIcon, {
                      key: 0,
                      icon: showSensitive.value ? 'eye' : 'eye-slash',
                      class: "show-icon",
                      "with-clickable": "",
                      svg: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (showSensitive.value = !showSensitive.value))
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
                (showCopyIcon.value && !maxCharsChopped.value)
                  ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                      key: 1,
                      class: "copy-icon",
                      icon: "copy",
                      svg: "",
                      "with-clickable": "",
                      onClick: copyToClipboard
                    }, null, 512)), [
                      [_directive_tooltip, copyTooltip.value]
                    ])
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 3
          }))
        : (_openBlock(), _createBlock(CmcGroup, {
            key: 1,
            class: "cmc-group-inline-patch",
            spacing: "3xs",
            "with-vertical-align": "center"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "lhs"),
              _createElementVNode("div", {
                ref_key: "textContainerRef",
                ref: textContainerRef,
                class: "cmc-text-container"
              }, [
                _createElementVNode("div", {
                  ref_key: "cmcTextClassRef",
                  ref: cmcTextClassRef,
                  class: _normalizeClass(cmcTextClass.value),
                  onMouseover: hover,
                  onMouseleave: leave
                }, [
                  (displayActual.value)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, null, 512)), [
                        [_directive_click_outside, doClickOutside]
                      ])
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    ref_key: "textRef",
                    ref: textRef,
                    class: "cmc-text-display",
                    innerHTML: displayedText.value
                  }, null, 8, _hoisted_5)
                ], 34)
              ], 512),
              (_ctx.asOptional)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " (" + _toDisplayString(_unref(t)('optional')) + ") ", 1))
                : _createCommentVNode("", true),
              (_ctx.withTooltipKeyValue && Object.keys(_ctx.withTooltipKeyValue).length > 0)
                ? (_openBlock(), _createBlock(_component_VTooltip, {
                    key: 1,
                    placement: _ctx.withTooltipPlacement || 'auto',
                    popperClass: 'cmc-tooltip-size-' + _ctx.withTooltipSize,
                    distance: 15
                  }, {
                    popper: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "tooltip")
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("i", null, [
                        _createVNode(CmcIcon, {
                          icon: "info-filled-bold",
                          class: _normalizeClass(['cmc-text-tooltip-icon',
                     {
                       'cmc-text-tooltip-icon-status-report': _ctx.tooltipAsStatusReport
                     }]),
                          svg: "",
                          size: "m"
                        }, null, 8, ["class"])
                      ])
                    ]),
                    _: 3
                  }, 8, ["placement", "popperClass"]))
                : (_ctx.withTooltip)
                  ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                      key: 2,
                      icon: "info-filled-bold",
                      class: _normalizeClass(['cmc-text-tooltip-icon',
                 {
                   'cmc-text-tooltip-icon-status-report': _ctx.tooltipAsStatusReport
                 }]),
                      svg: "",
                      size: _ctx.withTooltipIconSize
                    }, null, 8, ["class", "size"])), [
                      [_directive_tooltip, tooltipProps.value]
                    ])
                  : _createCommentVNode("", true),
              (_ctx.withWarningTooltip)
                ? _withDirectives((_openBlock(), _createBlock(CmcIcon, {
                    key: 3,
                    icon: "exclamation-filled-bold",
                    class: "cmc-text-tooltip-warning-icon",
                    svg: "",
                    size: _ctx.withTooltipIconSize
                  }, null, 8, ["size"])), [
                    [_directive_tooltip, warningTooltipProps.value]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 3
          })),
      _withDirectives(_createElementVNode("div", {
        ref_key: "tooltipHtmlRef",
        ref: tooltipHtmlRef
      }, [
        _renderSlot(_ctx.$slots, "tooltip")
      ], 512), [
        [_vShow, false]
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})