import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { provide } from 'vue';
import { CMC_CONTEXTUAL_GRID } from '../constants';

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  atVerticalCenter?: boolean;
  /**
   * Should be sized based on the parent container.
   */
  contextual?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcGrid',
  props: {
    id: {},
    atVerticalCenter: { type: Boolean },
    contextual: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {




const emit = __emit

provide(CMC_CONTEXTUAL_GRID, true);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(['cmc-grid pure-g', { 'cmc-grid-at-vertical-center': _ctx.atVerticalCenter}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})