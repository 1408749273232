<template>
  <div 
    class="state" 
    :class="[color]"
  >
    <base-icon 
      :icon="`icon fa ${icon}`" 
    />
  </div>
</template>
<script>
export default {
  name: 'MobileStatus',
  props: {
    color: {
      type: String,
      required: true,
      validator: c => ['blue', 'green', 'yellow', 'red', 'gray', 'grey'].includes(c),
    },
    icon: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/styles/mixins.scss';

@include mixins.phone {
  .state {
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
  }
}

</style>
