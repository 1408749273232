<template>
  <cmc-stack
    spacing="3xs"
    :class="['cmc-radio', { 'cmc-radio-disabled': disabled }]"
  >
    <cmc-pair 
      stretch-rhs
      spacing="2xs"
    >
      <div>
        <input 
          type="radio" 
          :checked="value === groupProps.modelValue"
          :for="groupName"
          :value="value"
          :disabled="disabled"
          @input="onClick"
        >
        <div
          class="cmc-radio-impl" 
          tabindex="0"
          @click="onClick"
          @keydown.enter="onClick" 
          @keydown.space="onClick"
        >
          <div 
            v-if="value === groupProps.modelValue"
            class="dot"
          ></div>
        </div>
      </div>
      <div 
        class="cmc-radio-label"
        @click="onClick"
      >
        <cmc-align at-vertical-center>
          <cmc-label v-bind="props"></cmc-label>
        </cmc-align>
      </div>
    </cmc-pair>
    <cmc-text
      v-if="withErrorText"
      size="m"
      :text="withErrorText"
      :with-i18n="withErrorTextI18n"
      as-error
    ></cmc-text>
  </cmc-stack>
</template>

<script setup lang="ts">
import { defineComponent, inject } from 'vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import { CmcRadioGroupProps } from './CmcRadioGroup.vue';

defineComponent({
  CmcAlign, CmcText, CmcPair, CmcReadOnly, CmcStack, CmcIcon, CmcLabel
})

type Props = {
  /**
   * HTML element id
   */
   id?: string;
  /**
   * Checked value of the radio
   */
  value?: any;

  /**
   * Label to display on the left-hand side of the radio.
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the radio.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Should the radio be disabled.
   */
  disabled?: boolean
}
const props = defineProps<Props>()
const init = inject('cmc-radio-group-init', (() => {
}) as ((v: any) => void));
const setValue = inject('cmc-radio-group-set-value', (() => {
}) as ((v: any) => void));
const groupProps = inject('cmc-radio-group-props', {} as CmcRadioGroupProps)
const groupName = inject('cmc-radio-group-name', '')

const onClick = () => {
  if (props.disabled) {
    return;
  }
  setValue(props.value)
}
if (!groupProps.modelValue) {
  init(props.value);
}
</script>

<style>
:root {
  --ng-radio-bg: var(--ng-text-main);
  --ng-radio-disabled-bg: var(--ng-primary-surface);
}
</style>

<style scoped lang="scss">
.cmc-radio {
  max-width: 28rem;

  input {
    display: none;
  }
  .cmc-radio-impl {
    min-width: 1rem;
    min-height: 1rem;
    max-width: 1rem;
    max-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.09375rem solid var(--ng-radio-bg);
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    .dot {
      border-radius: 50%;
      min-width: 0.625rem;
      min-height: 0.625rem;
      max-width: 0.625rem;
      max-height: 0.625rem;
      background-color: var(--ng-radio-bg);
    }
  }
  &.cmc-radio-disabled {
    opacity: 0.6;
    .cmc-radio-impl {
      background-color: var(--ng-radio-disabled-bg);
      border-color: var(--ng-text-description);
      .dot {
        background-color: var(--ng-text-description);
      }
    }
  }
}
</style>