<template>
  <div class="card">
    <div 
      class="state" 
      :class="{green: 'running' == state, red: 'stopped' == state, 'blue animate': 'starting' == state}"
    >
      <base-image 
        class="template-logo" 
        :src="data.logo" 
        :altText="data.template"
      />
      <span class="detail">{{ data.cpu }} vCPU</span>
      <span 
        class="detail" 
        style="margin-bottom: 8px;"
      >{{ data.ram }} GB</span>
      <span class="detail">{{ data.state }}</span>
    </div>
    <div class="card-info">
      <div class="name">
        {{ data.name }}
      </div>
      <div class="subtitle">
        {{ data.vpc }} / {{ data.network }}
      </div>
      <div class="extra">
        {{ data.ipAddress }}
      </div>
      <div class="extra">
        {{ data.template }}
      </div>
    </div>
    <div class="action-slice">
      <base-icon 
        icon="fa fa-ellipsis-v" 
        class="action" 
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourceCard',
  props: {
    state: {
      type: String,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/animations.scss';

.card {
    border: 2px solid #f0f0f0;
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    display: flex;
}

.state .fa {
    font-size: 2.3rem;
    padding: 3px 5px;
}

a {
    text-decoration: none;
    color: #838990;
    font-weight: bold;
}

a:hover {
    color: #afb4bb;
}

.template-logo {
    width: 2.3rem;
    height: 2.3rem;
    padding: 3px 5px;
    filter: brightness(0) invert(100%);
    margin-bottom: 10px;
}

.name {
    font-size: 20px;
    margin-bottom: 8px;
}

.subtitle {
    font-size: 14px;
    margin-top: 5px;
    color: #696969;
    font-weight: bold;
}

.extra {
    font-size: 12px;
    margin-top: 8px;
    color: #b1b1b1;
}

.state {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    color: white;
 }

.animate {
  animation: flash 1.5s infinite;
}

.card-info {
    padding: 10px 15px;
    flex: 1;
    text-align: left;
}

.detail {
    font-size: 12px;
    font-weight: bold;
}

.action {
    color: #dfdfdf;
    font-size: 25px;
    padding-top: 5px;
    margin-left: auto;
    margin-right: 5px;
}

.action-list {
    display: flex;
    flex-direction: column;
}

.action {
    font-size: 22px;
    margin-left: 3px;
    margin-top: 3px;
    color: #aeaeae;
}

.action:hover {
    cursor: pointer;
    color: #dedede;
}

.action-slice {
    padding-right: 5px;
}


</style>
