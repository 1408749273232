import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["checked", "for", "value", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "dot"
}

import { defineComponent, inject } from 'vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import { CmcRadioGroupProps } from './CmcRadioGroup.vue';

type Props = {
  /**
   * HTML element id
   */
   id?: string;
  /**
   * Checked value of the radio
   */
  value?: any;

  /**
   * Label to display on the left-hand side of the radio.
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the radio.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Should the radio be disabled.
   */
  disabled?: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcRadio',
  props: {
    id: {},
    value: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    disabled: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({
  CmcAlign, CmcText, CmcPair, CmcReadOnly, CmcStack, CmcIcon, CmcLabel
})

const props = __props
const init = inject('cmc-radio-group-init', (() => {
}) as ((v: any) => void));
const setValue = inject('cmc-radio-group-set-value', (() => {
}) as ((v: any) => void));
const groupProps = inject('cmc-radio-group-props', {} as CmcRadioGroupProps)
const groupName = inject('cmc-radio-group-name', '')

const onClick = () => {
  if (props.disabled) {
    return;
  }
  setValue(props.value)
}
if (!groupProps.modelValue) {
  init(props.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcStack, {
    spacing: "3xs",
    class: _normalizeClass(['cmc-radio', { 'cmc-radio-disabled': _ctx.disabled }])
  }, {
    default: _withCtx(() => [
      _createVNode(CmcPair, {
        "stretch-rhs": "",
        spacing: "2xs"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("input", {
              type: "radio",
              checked: _ctx.value === _unref(groupProps).modelValue,
              for: _unref(groupName),
              value: _ctx.value,
              disabled: _ctx.disabled,
              onInput: onClick
            }, null, 40, _hoisted_1),
            _createElementVNode("div", {
              class: "cmc-radio-impl",
              tabindex: "0",
              onClick: onClick,
              onKeydown: [
                _withKeys(onClick, ["enter"]),
                _withKeys(onClick, ["space"])
              ]
            }, [
              (_ctx.value === _unref(groupProps).modelValue)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true)
            ], 32)
          ]),
          _createElementVNode("div", {
            class: "cmc-radio-label",
            onClick: onClick
          }, [
            _createVNode(CmcAlign, { "at-vertical-center": "" }, {
              default: _withCtx(() => [
                _createVNode(CmcLabel, _normalizeProps(_guardReactiveProps(props)), null, 16)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      (_ctx.withErrorText)
        ? (_openBlock(), _createBlock(CmcText, {
            key: 0,
            size: "m",
            text: _ctx.withErrorText,
            "with-i18n": _ctx.withErrorTextI18n,
            "as-error": ""
          }, null, 8, ["text", "with-i18n"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})