<template>
  <cmc-grid-col 
    :class="[
      'cmc-list-col',
      'cmc-list-col-' + idx,
      `cmc-list-col-${colLayout?.asColType}`,
      {
        'cmc-list-col-select' : asListSelectItemWithoutLabel,
      },
      {
        'cmc-list-header-col': header,
      },
      {
        'cmc-list-content-col': !header,
      }]"
    v-bind="{ ...props, ...colLayout }" 
    :data-col-id="colId"
  >
    <cmc-pair 
      spacing="s"
      stretch-rhs
      :style="{...colOffsetStyle}"
      class="cmc-list-col-collapse-icon-container"
    >
      <cmc-block 
        v-if="withCollapse && idx == 0"
        padding-top="4xs"
      >
        <cmc-icon 
          :icon="collapsed ? 'chevron-bold-right' : 'chevron-bold-down'" 
          svg      
          size="s"       
          class="cmc-list-col-collapse-icon" 
        />
      </cmc-block>
      <cmc-block :class="[`cmc-list-col-${colLayout?.asColType}`]">
        <div 
          :class="[
            {
              'pointer': sortable
            },
            {
              'cmc-list-col-with-sort-icon': sortable || header
            }]"
          @mouseenter="mouseOver = true"
          @mouseleave="mouseOver = false"
          @click="emitSortInstructions(sortedDirection)"
        >
          <div :class="{'no-click': sortable}">
            <slot />
          </div>
          <div 
            v-if="sortable" 
            class="sorting-icon"
          >
            <div v-if="isActiveSort || mouseOver">
              <cmc-icon 
                v-if="sortedDirection == Direction.ASCENDING" 
                icon="sort-ascending" 
                svg 
                :custom-size="0.9"
              />
              <cmc-icon 
                v-if="sortedDirection == Direction.DESCENDING" 
                icon="sort-descending" 
                svg 
                :custom-size="0.9"
              />
            </div>
          </div>
        </div>
      </cmc-block>
    </cmc-pair>
  </cmc-grid-col>
</template>

<script setup lang="ts">
import { computed, defineComponent, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import CmcGridCol from '../../layout/CmcGridCol.vue';
import CmcBlock from '../../layout/CmcBlock.vue';
import { Direction, DirectionType, Sortable } from '../../composables/useListSorting'
import { v4 as uuidv4 } from 'uuid';
import { getCurrentInstance } from "vue";
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";
import CmcPair from "@/components/nextgen/layout/CmcPair.vue";
import { getColOffsetStyle } from "@/components/nextgen/display/list/offsetHelper";
import { ColLayout } from './types';

const colId = uuidv4()

defineComponent({
  CmcGridCol,
  CmcBlock,
})

type CmcListColProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * The sorting instructions for the column, only used if this column is a header that needs sorting
   */
  sortable?: Sortable

  /**
   * Allows this sorting column to watch when other sorting columns have emitted their sorting events 
   * with lastSortedBy in useListSorting.ts
   */
  otherColumnsHook?: Sortable;

  /**
   * True if this column cell renders a select element without a label on top of it. 
   */
  asListSelectItemWithoutLabel? : boolean
}

const props = defineProps<CmcListColProps>()

const layout       = inject('cmc-list-layout') as ColLayout[]
const header       = inject('cmc-list-header') as boolean
const nestedLevel  = inject('cmc-list-nested-level', 0) as number
const withCollapse = inject('cmc-list-with-collapse', false) as boolean
const collapsed    = inject('cmc-list-collapsed', false) as boolean

const emit = defineEmits<{
  (e: 'sort', toSort: Sortable): void
}>()

const mouseOver = ref(false)

const sortedDirection = ref<DirectionType | undefined>(undefined)
const isActiveSort = ref(false)

const idx = ref(0)
const findAndSetIndex = (parentEl: HTMLElement) => {
  idx.value = [...parentEl.children].findIndex(e => {
    return e.getAttribute('data-col-id') === colId
  })
}
const colLayout = computed(() => {
  return layout[idx.value]
})
const colOffsetStyle = computed(() => idx.value == 0 ? getColOffsetStyle(nestedLevel, withCollapse) : {})


const hook = computed(() => props.otherColumnsHook)
watch(hook, () => {
  if (hook.value && props.sortable && hook.value.tableId === props.sortable.tableId) {
    isActiveSort.value = hook.value?.columnId == props.sortable.columnId
  }
})

onMounted(() => {
  sortedDirection.value = props.sortable?.sortOrder
  if (props.sortable?.default) {
    emitSortInstructions(props.sortable?.sortOrder)
  }
  
  const parentComponent = getCurrentInstance()!!.parent!!
  if (parentComponent && parentComponent.vnode.el instanceof HTMLElement) {
    findAndSetIndex(parentComponent.vnode.el as HTMLElement)

    const observer = new MutationObserver(() => {
      const parentEl = parentComponent.vnode.el as HTMLElement
      findAndSetIndex(parentEl)
    });

    const observerConfig = { childList: true };
    observer.observe(parentComponent.vnode.el, observerConfig);
    onBeforeUnmount(() => {
      observer.disconnect()
    })
  }
})

function emitSortInstructions(order: DirectionType | undefined) {
  if (props.sortable != undefined && order != undefined) {
    const sortingInstructions = props.sortable

    if (isActiveSort.value) {
      sortedDirection.value = !order
      sortingInstructions.sortOrder = !order
    }
    emit('sort', sortingInstructions)
  }
}
</script>

<style scoped lang="scss">
.cmc-list-col {
  align-self: auto;
  margin: 0.75rem 0 0.75rem 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2rem;
  &:last-child {
    padding-right: 0rem;
  }
  
  &.cmc-list-header-col {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .cmc-list-col-badge {
      padding-left: 1.625rem;
    }

    .cmc-list-col-text-with-icon {
      margin-left: 1.25rem;
    }

    .cmc-list-col-with-sort-icon {
      display: flex;
    }
  }
  .cmc-list-col-collapse-icon-container {
    box-sizing: border-box;
  }

  .cmc-list-col-number, .cmc-list-col-date {
    text-align: center;
  }
  .cmc-list-col-number .cmc-list-col-with-sort-icon,
  .cmc-list-col-date .cmc-list-col-with-sort-icon {
    justify-content: center;
  }
 
  &.cmc-list-content-col.cmc-list-col-avatar,
  &.cmc-list-content-col.cmc-list-col-select {
    padding-top: 0.125rem;
  }

  &.cmc-list-header-col.cmc-list-col-chart,
  &.cmc-list-header-col.cmc-list-col-status-icon {
    text-align: center;
  }

  &.cmc-list-header-col.cmc-list-col-chart .cmc-list-col-with-sort-icon,
  &.cmc-list-header-col.cmc-list-col-status-icon .cmc-list-col-with-sort-icon {
    justify-content: center;
  }

  &.cmc-list-content-col.cmc-list-col-chart {
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    display:inline-grid;
  }

  &.cmc-list-content-col.cmc-list-col-status-icon {
    justify-content: center;
    display:inline-grid;
    padding-top: 0.75rem;
  }

  .cmc-list-col-amount {
    text-align: right;
  }

  .cmc-list-col-amount .cmc-list-col-with-sort-icon {
    justify-content: flex-end;
  }

  &.cmc-list-col-none {
    align-self: flex-start;
  }

  &.cmc-list-col-text-with-icon {
    align-self: auto;
  }
}

:deep(.cmc-badge) {
  padding-top: 0;
  height: auto;
}

.sorting-icon {
  width: 0;
  height: 0.875rem;
  margin-left: 0.25rem;
  margin-top: auto;
  margin-bottom: 0.17rem;
  color: var(--text-header);
}

.pointer {
  cursor: pointer;
}

.no-click {
  pointer-events: none;
}
</style>