<template>
  <cmc-group
    take-full-width
  >
    <cmc-card
      class="cmc-card-modal-card"
      :with-collapse="false"
      :title="title"
      :with-title-i18n="withTitleI18n"
      :category="withCategoryI18n ? category : $t(category)"
      :description="withDescriptionI18n ? description : $t(description)"
      :count="count"
      :padding="padding"
      :padding-horizontal="paddingHorizontal"
      :padding-vertical="paddingVertical"
      :padding-top="paddingTop"
      :padding-bottom="paddingBottom"
    >
      <template #rhs-content>
        <slot name="rhs"></slot>
      </template>
      <slot></slot>
    </cmc-card>
  </cmc-group>
</template>

<script setup lang="ts">
import { defineComponent } from 'vue';
import CmcCard from "../display/CmcCard.vue";
import CmcDimmed from './CmcDimmed.vue';
import CmcResponsive from '../misc/CmcResponsive.vue';
import CmcGroup from "@/components/nextgen/layout/CmcGroup.vue";
import CmcAlign from "@/components/nextgen/layout/CmcAlign.vue";
import CmcButton from "@/components/nextgen/buttons/CmcButton.vue";
import { Size } from '../sizes';

type Props = {
  /**
   * Title of the modal
   */
  title : string;

  /**
   * True if the title should be translated
   */
  withTitleI18n?: boolean;

  /**
   * Category of the modal
   */
  category?: string;

  /**
   * True if the category should be translated
   */
  withCategoryI18n?: boolean;

  /**
   * Description of the modal
   */
  description?: string;

  /**
   * True if the description should be translated
   */
  withDescriptionI18n?: boolean;

  /**
   * Will display a count badge on the card if count > 0
   */
  count?: number;

  /**
   * Padding around the card body.
   */
  padding?: Size;

  /**
   * Vertical padding of the card body. Overrides `padding`.
   */
  paddingVertical?: Size;

  /**
   * Horizontal padding of the card body. Overrides `padding`.
   */
  paddingHorizontal?: Size;

  /**
   * Add padding on top of the block.
   */
  paddingTop?: Size;

  /**
   * Add padding on bottom of the block.
   */
  paddingBottom?: Size;
};

defineComponent({
  CmcDimmed,
  CmcCard,
  CmcResponsive,
  CmcGroup,
  CmcAlign,
  CmcButton
});

withDefaults(defineProps<Props>(), {
  description: "",
  withDescriptionI18n: false,
  category: "",
  withCategoryI18n: false,
  withTitleI18n: false,
  count: 0
});
</script>

<style lang="scss" scoped>

</style>