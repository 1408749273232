<template>
  <div>
    <base-list
      :ariaLabel="type.toLowerCase()"
      noActions
      bordered
      class="adjustment-list"
      :ariaRowCount="totalRows"
    >
      <list-header>
        <list-column :size="3">
          {{ $t('name') }}
        </list-column>
        <list-column alignRight>
          {{ isDiscountType ? $t('discount') : $t('applied') }}
        </list-column>
        <list-column alignRight>
          {{ isDiscountType ? $t('applied') : $t('remaining') }}
        </list-column>
      </list-header>
      <template
        v-for="adjustmentGroup in groupedAdjustment"
        :key="adjustmentGroup.id"
      >
        <list-row :noBorder="adjustmentGroup.scope !== 'ALL_PRODUCTS'">
          <list-column :size="3">
            <div class="nowrap">
              <div>{{ (adjustmentGroup.name || {})[locale] }}</div>
              <base-tooltip
                v-if="showDiscountRange(adjustmentGroup, billingCycle)"
                :message="getDiscountRangeForInvoice(adjustmentGroup, billingCycle)"
                direction="bottom"
                class="padding-left"
                overflow
              >
                <base-icon
                  class="description"
                  icon="info-circle"
                  :customizable="false"
                />
              </base-tooltip>
            </div>
          </list-column>
          <list-column
            v-if="adjustmentGroup.scope === 'ALL_PRODUCTS'"
            alignRight
          >
            <div>{{ !isDiscountType ? getFormattedAmount(adjustmentGroup.children[0].amount) : adjustmentGroup.children[0].discount }}</div>
          </list-column>
          <list-column
            v-if="adjustmentGroup.scope === 'ALL_PRODUCTS'"
            alignRight
          >
            <div>{{ !isDiscountType ? getFormattedAmount(adjustmentGroup.children[0].remaining) : getFormattedAmount(adjustmentGroup.children[0].amount) }}</div>
          </list-column>
        </list-row>
        <template v-if="adjustmentGroup.scope !== 'ALL_PRODUCTS'">
          <list-row
            v-for="adjustment in adjustmentGroup.children"
            :key="adjustment.itemId"
            class="child"
          >
            <list-column
              class="child-first-col"
              :size="3"
            >
              <div>{{ (adjustment.itemName || {})[locale] }}</div>
              <div
                v-if="adjustment.sku"
                class="description"
              >
                {{ $t('reports.sku') }}: {{ adjustment.sku }}
              </div>
            </list-column>
            <list-column
              alignRight
              :size="1"
            >
              <div>{{ !isDiscountType ? getFormattedAmount(adjustment.amount) : adjustment.discount }}</div>
            </list-column>
            <list-column
              alignRight
              :size="1"
            >
              <div>{{ !isDiscountType ? getFormattedAmount(adjustment.remaining) : getFormattedAmount(adjustment.amount) }}</div>
            </list-column>
          </list-row>
        </template>
      </template>
    </base-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { currencyMixin } from '@/mixins/currencyMixin';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import BaseTooltip from '@/components/BaseTooltip';

const CREDITS = 'credits';
const DISCOUNTS = 'discounts';

export default {
  name: 'AdjustmentList',
  components: { BaseTooltip },
  mixins: [currencyMixin, billingCycleMixin],
  props: {
    adjustmentList: {
      type: Array,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    billingCycle: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: CREDITS,
      validator: t => [CREDITS, DISCOUNTS].includes(t.toLowerCase()),
    },
  },
  computed: {
    ...mapGetters(['locale']),
    groupedAdjustment() {
      const groupedAdjustments = {};

      this.adjustmentList.forEach((c) => {
        if (!groupedAdjustments[c.id]) {
          groupedAdjustments[c.id] = {
            id: c.id,
            name: c.name,
            scope: c.scope,
            startDate: c.startDate,
            endDate: c.endDate,
            children: []
          };
        }
        if (!groupedAdjustments[c.id].children.find(child => child.itemId == c.itemId)) {
          groupedAdjustments[c.id].children.push({
            itemId: c.itemId,
            sku: c.sku,
            itemName: c.itemName,
            amount: 0,
            discount: c.discount,
            remaining: c.available
          });
        }

        const targetChild = groupedAdjustments[c.id].children.find(child => child.itemId == c.itemId)
        targetChild.amount += c.amount;
        targetChild.remaining += c.amount;
      });

      return groupedAdjustments;
    },
    totalRows() {
      return Object.values(this.groupedAdjustment)
        .map(c => c.children.length + (c.scope !== 'ALL_PRODUCTS' ? 1 : 0))
        .reduce((a, b) => a + b, 0);
    },
    isDiscountType() {
      return this.type === DISCOUNTS;
    },
  },
  methods: {
    getFormattedAmount(amount) {
      if (amount === 0 || amount) {
        return this.formatShortCurrency(amount, this.currency);
      }
      return '-';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/mixins.scss';

  .child-first-col {
    text-indent: 15px;
  }
  .list.adjustment-list {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .nowrap {
    display: flex;
    flex-wrap: nowrap;
  }
  .padding-left {
    padding-left: 5px;
  }
  .description {
    margin-bottom: 0px;
  }
  .child {
    margin-top: -15px;
  }
</style>
