import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["id"]

import { computed, defineComponent, ref, useSlots, provide } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcButton from '../buttons/CmcButton.vue';
import CmcStepProvider from './CmcStepProvider.vue';
import CmcStep from './CmcStep.vue';
import { CMC_CONTAINED_IN, CMC_FORM } from '../constants';

type Props = {

  /**
   * HTML element id.
   */
  id?: string;

  /**
   * Change value of submit label.
   */
  submitLabel?: string;

  /**
   * True if the label provided to submit is i18n.
   */
  withSubmitLabelI18n?: boolean;

  /**
   * Disable the submit button.
   */
  disabledSubmit?: boolean;

  /**
   * General errors to display.
   */
  generalErrors?: String[];

  /**
   * Provide a function to be invoked before proceeding to the next step. If the function returns false, we should not change steps.
   */
  beforeNext?: () => Promise<boolean>;

}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcMultiForm',
  props: {
    id: {},
    submitLabel: {},
    withSubmitLabelI18n: { type: Boolean },
    disabledSubmit: { type: Boolean },
    generalErrors: {},
    beforeNext: {}
  },
  emits: ["submit", "cancel", "change"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const slots = useSlots()
const curStep = ref(1);
const highestVisitedStep = ref(1);

defineComponent({
  CmcGroup,
  CmcButton,
  CmcStack,
  CmcStepProvider,
  CmcAlign,
  CmcStep,
})


const totalSteps = computed(() => {
  return Object.keys(slots).length + 1
})

const changeStep = (step: number) => {
  curStep.value = step;
  if (step > highestVisitedStep.value) {
    highestVisitedStep.value = step;
  }
  emit('change', step);
}

__expose({
  changeStep
})

const emit = __emit

provide(CMC_CONTAINED_IN, CMC_FORM);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    id: _ctx.id,
    class: "cmc-multi-form",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"]))
  }, [
    _createVNode(CmcStack, { spacing: "none" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(new Array(totalSteps.value), (_, idx) => {
          return (_openBlock(), _createBlock(CmcStepProvider, {
            key: idx,
            first: idx === 0,
            last: idx + 1 === totalSteps.value,
            step: idx + 1,
            active: curStep.value === idx + 1,
            "highest-visited-step": highestVisitedStep.value,
            "current-step": curStep.value,
            "disabled-submit": _ctx.disabledSubmit,
            "submit-label": _ctx.submitLabel,
            "with-submit-label-i18n": _ctx.withSubmitLabelI18n,
            "before-next": _ctx.beforeNext,
            onChangeStep: changeStep,
            onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
          }, {
            default: _withCtx(() => [
              _createVNode(CmcStack, {
                spacing: "none",
                class: _normalizeClass(`step-index-${idx}`)
              }, {
                default: _withCtx(() => [
                  (idx < totalSteps.value - 1)
                    ? _renderSlot(_ctx.$slots, idx, { key: 0 })
                    : (_openBlock(), _createBlock(CmcStep, {
                        key: 1,
                        title: "finish",
                        "disabled-next": _ctx.disabledSubmit,
                        "general-errors": _ctx.generalErrors,
                        "with-title-i18n": ""
                      }, null, 8, ["disabled-next", "general-errors"]))
                ]),
                _: 2
              }, 1032, ["class"])
            ]),
            _: 2
          }, 1032, ["first", "last", "step", "active", "highest-visited-step", "current-step", "disabled-submit", "submit-label", "with-submit-label-i18n", "before-next"]))
        }), 128))
      ]),
      _: 3
    })
  ], 40, _hoisted_1))
}
}

})