<template>
  <div v-if="!loading && !hasError">
    <advanced-details
      :titleLabel="cycleDate(cycle)"
      :state="cycleState(cycle)"
    />

    <alert-box
      v-if="cycle.testMode"
      border
      alert-type="INFO"
      headerLabel="billing_settings.testing_mode"
      :label="isIssuedInvoice ? 'monetization.billing_cycle_test_mode_closed' :'monetization.billing_cycle_test_mode_current'"
      class="alert-box-top"
    />

    <BillingPricingPackage
      :pricingPackage="pricingPackage"
      :isEditMode="!isIssuedInvoice"
      class="pricing-package-detail"
    >
      <template #actions>
        <action-icon
          expandOnHover
          icon="fa fa-edit"
          tooltipLabel="monetization.edit_billing_cycle_package"
          :to="{ name: 'billable_org_info_edit', params: { cycleId: cycle.id } }"
        />
      </template>
    </BillingPricingPackage>

    <div class="invoice-container">
      <span class="invoice-header">{{ $t("reports.invoice") }}</span>
      <div
        v-if="invoiceId"
        class="invoice-details"
      >
        <InvoiceSummaryFields :invoice="invoice" />
        <InvoiceDetailsSummary
          v-if="invoice.id"
          :billingCycle="cycle"
          :invoice="invoice"
        />
      </div>
      <div
        v-else
        class="invoice-missing"
      >
        <alert-box
          label="monetization.no_invoice"
          alertType="INFO"
          border
        />
      </div>
    </div>
    <commitment-list
      :commitments="commitments"
      :organization="organization"
      :billingCycles="billingCycles"
      :disableCreate="isIssuedInvoice"
    />
    <DiscountList
      :discounts="applicableDiscounts"
      :organization="organization"
      :disableCreate="isIssuedInvoice"
    />
    <CreditList
      :credits="applicableCredits"
      :currency="cycle.currency"
      :organization="organization"
      :disableCreate="isIssuedInvoice"
    />
  </div>
  <div v-else-if="!loading">
    <alert-box
      label="monetization.no_billing_cycle"
      alertType="INFO"
      border
    />
  </div>
  <base-loader v-else></base-loader>
</template>
<script>
import apis from '@/utils/apis';
import authz from '@/authz';
import { mapGetters } from 'vuex';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import { organizationFeatures } from '@/mixins/organizationFeatures';
import { cycleDate } from "@/utils/billing_cycles";

import DiscountList from '@/app/Main/components/discounts/DiscountList';
import CreditList from '@/app/Main/components/discounts/CreditList';

import CommitmentList from '@/app/Main/components/commitments/CommitmentList';

import InvoiceDetailsSummary from '@/app/Main/Reporting/ResellerReport/InvoiceDetailsSummary';
import InvoiceSummaryFields from '@/app/Main/Reporting/ResellerReport/InvoiceSummaryFields';

import BillingPricingPackage from './BillingPricingPackage';

export default {
  name: 'BillingCycleDetails',
  components: {
    BillingPricingPackage,
    CommitmentList,
    CreditList,
    DiscountList,
    InvoiceDetailsSummary,
    InvoiceSummaryFields,
  },
  mixins: [billingCycleMixin, organizationFeatures],
  beforeRouteLeave(_to, _from, next) {
    this.$emit('addToBreadcrumb', []);
    next();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  emits: ['addToBreadcrumb'],
  data() {
    return {
      discounts: [],
      discountMetadata: [],
      packages: [],
      cycle: {},
      invoice: {},
      loading: true,
      hasError: false,
      commitments: [],
    };
  },
  computed: {
    ...mapGetters(['locale']),
    billingCycleDiscounts() {
      return this.cycle.discounts;
    },
    mergedDiscounts() {
      return this.billingCycleDiscounts.map(d => ({
        ...d,
        appliedPricing: this.getAppliedPricing(d),
        ...this.discounts[d.discountId],
      }));
    },
    applicableDiscounts() {
      return this.mergedDiscounts.filter(d => d.type === 'PERCENTAGE');
    },
    applicableCredits() {
      return this.mergedDiscounts.filter(d => d.type === 'CREDIT');
    },
    pricingPackageName() {
      return this.cycle.pricingPackages
        .map(pp => pp.name[this.locale])
        .join(', ');
    },
    pricingPackage() {
      return this.cycle.pricingPackages[0];
    },
    invoiceId() {
      // there is only one non obsoleted invoice.
      return this.cycle && (this.cycle.invoices.find(i => i.status !== 'OBSOLETE') || {}).id;
    },
    hasInvoice() {
      return !!this.invoiceId;
    },
    isIssuedInvoice() {
      const statuses = ['ISSUED', 'OVERDUE', 'PAID'];
      return authz.hasPermission('reseller:billing') && this.hasInvoice && statuses.includes(this.invoice.status);
    },
    billingCycles() {
      return [this.cycle];
    },
  },
  async created() {
    await Promise.all([
      this.fetchCycles(),
      this.fetchDiscounts(),
      this.fetchPackages(),
    ]);

    if (this.hasError) {
      this.loading = false;
      return;
    }
    
    await Promise.all([
      this.fetchCommitments(),
      this.fetchDiscountMetadata(),
      this.fetchInvoice(),
    ]);

    // add to breadcrumb
    this.$emit("addToBreadcrumb", [
      {
        label: "billing",
        withI18n: true,
        path: '/admin/organizations/' + this.organization.id + '/billing/overview'
      },
      {
        label: cycleDate(this.cycle),
        withI18n: false
      },
    ]);

    this.loading = false;
  },
  methods: {
    getAppliedPricing(d) {
      if (d && d.discountId) {
        const meta = this.discountMetadata[d.discountId];
        if (meta) {
          const pid = meta.packageId;
          return this.packages[pid];
        }
      }
      return null;
    },
    async fetchCycles() {
      const resp = await apis.billable.getBillingCycles(this.organization.id, true);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.cycle = resp.data.filter(bc => bc.id === this.id)[0];
    },
    async fetchDiscounts() {
      const resp = await apis.discounts.list({ qs: { organization_id: this.organization.id } });
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.discounts = resp.data.reduce((acc, d) => ({ ...acc, [d.id]: d }), {});
    },
    async fetchPackages() {
      const resp = await apis.organizations.fetchApplicablePricingPackages(this.organization.id);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.packages = resp.data.reduce((acc, d) => ({ ...acc, [d.id]: d }), {});
    },
    async fetchDiscountMetadata() {
      const resp = await apis.discounts.fetchDiscountMetadata(this.organization.id);
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.discountMetadata = resp.data;
    },
    async fetchInvoice() {
      if (!this.invoiceId) {
        return;
      }
      const resp = await apis.invoices.findInvoice(this.invoiceId);
      if (!resp || resp.status !== 200) {
        return;
      }
      this.invoice = resp.data;
    },
    async fetchCommitments() {
      const resp = await apis.commitments.list({
        qs: {
          organization_id: this.organization.id,
          from: this.cycle.startDate,
          to: this.cycle.endDate,
        }
      });
      if (!resp || resp.status !== 200) {
        this.hasError = true;
        return;
      }
      this.commitments = resp.data;
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/styles/mixins.scss';

.pricing-package-detail {
  padding-top: 10px;
}

.invoice-container {
  display: flex;
  flex-direction: column;

  .invoice-header {
    text-align: left;
    padding-bottom: 10px;
  }

  .invoice-details {
    display: flex;
    text-align: left;

    >div {
      @include mixins.not-phone {
        width: 50%;
      }
    }

    :deep(.invoice-info) {
      font-size: 14px;

      .detail-text {
        color: var(--detail-text);
      }
    }

    :deep(&:not(.description)) {
      font-size: 14px;
    }

    :deep(.invoice-details-summary-body) {
      .list-row {
        .list-column:first-child {
          color: var(--detail-text);
        }
      }
    }
  }
}
</style>
