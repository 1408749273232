<template>
  <p>
    {{ $t('organization_currency_change.currency_change_modal.credits.description') }}
  </p>

  <base-loader v-if="!credits"></base-loader>
  <p v-else-if="remainingCredits.length == 0">
    <b>{{ $t('organization_currency_change.currency_change_modal.credits.no_credit_affected') }}</b>
  </p>
  <base-list
    v-else
    aria-labelledby="credits"
    :ariaRowCount="remainingCredits.length"
    :list="remainingCredits"
  >
    <list-header>
      <list-column>{{ $t('credit') }}</list-column>
      <list-column>{{ $t('organization_currency_change.currency_change_modal.credits.target') }}</list-column>
      <list-column>{{ $t('monetization.start_date') }}</list-column>
      <list-column>{{ $t('organization_currency_change.currency_change_modal.credits.amount_initial') }} ({{ currency }})</list-column>
      <list-column>{{ $t('organization_currency_change.currency_change_modal.credits.amount_remaining') }} ({{ currency }})</list-column>
    </list-header>
    <list-row
      v-for="credit in remainingCredits"
      :key="credit.id"
    >
      <list-column>{{ credit.name }}</list-column>
      <list-column>{{ credit.catOrProd }}</list-column>
      <list-column>{{ $date((credit || {}).startDate, 'YYYY-MM-DD', true) }}</list-column>
      <list-column>{{ creditInitial(credit) }}</list-column>
      <list-column>{{ creditRemaining(credit) }}</list-column>
    </list-row>
  </base-list>
</template>
<script>

import { mapGetters } from 'vuex';
import BaseLoader from "@/components/BaseLoader.vue";
import apis from "@/utils/apis";
import {currencyMixin} from "@/mixins/currencyMixin";

export default {
  name: 'OrgCurrencyChangeCreditList',
  components: {BaseLoader},
  mixins: [currencyMixin],
  props: {
    orgId: {
      type: String,
      required: true
    },
    currentOpenCycle: {
      type: Object,
      required: false,
      default: undefined
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      credits: undefined
    };
  },
  computed: {
    ...mapGetters(['userBrandingInfo', 'locale', 'selectedOrganization']),
    remainingCredits() {
      return this.credits.filter(c => c.amountRemaining == undefined || c.amountRemaining > 0)
    }
  },
  async created() {
      await this.loadCredits();
  },
  methods: {
      async loadCredits() {
        this.credits = undefined
        const resp = await apis.discounts.list({ qs: { organization_id: this.orgId } });
        if (resp.data) {
          this.credits = []
          resp.data
            .filter(c => c.type === 'CREDIT')
            .filter(c => !c.isDeactivated)
            .forEach(c => {
              switch (c.scope) {
                case 'ALL_PRODUCTS':
                  this.credits.push({
                    id: c.id,
                    name: c.name[this.locale],
                    scope: c.scope,
                    status: c.status,
                    catOrProd: '*',
                    catOrProdId: undefined,
                    startDate: c.startDate,
                    amountInitial: c.packageDiscount
                  })
                  break;
                case 'PRODUCTS':
                  Object.keys(c.discountedProducts).forEach(prodId => {
                    this.credits.push({
                      id: c.id,
                      name: c.name[this.locale],
                      scope: c.scope,
                      status: c.status,
                      catOrProd: c.discountedLabels[prodId][this.locale],
                      catOrProdId: prodId,
                      startDate: c.startDate,
                      amountInitial: c.discountedProducts[prodId]
                    })
                  })
                  break;
                case 'CATEGORIES':
                  Object.keys(c.discountedCategories).forEach(catId => {
                    this.credits.push({
                      id: c.id,
                      name: c.name[this.locale],
                      scope: c.scope,
                      status: c.status,
                      catOrProd: c.discountedLabels[catId][this.locale],
                      catOrProdId: catId,
                      startDate: c.startDate,
                      amountInitial: c.discountedCategories[catId]
                    })
                  })
                  break;
              }
            })

          this.calculateCreditRemaining()
        }
      },
      calculateCreditRemaining() {
        this.credits.forEach(credit => {
          // We only consider CURRENT, if UPCOMING, "remaining" is undefined and will be set to initial
          if (credit.status === 'CURRENT') {
            // We have to find the most actual remaining in CLOSED cycles
            let discount = this.currentOpenCycle.discounts.find(d => d.discountId === credit.id)
            if (discount && discount.remaining) {
              switch (credit.scope) {
                case 'ALL_PRODUCTS':
                  credit.amountRemaining = discount.remaining.packageDiscount;
                  break;
                case 'PRODUCTS':
                  credit.amountRemaining = discount.remaining.discountedProducts[credit.catOrProdId];
                  break;
                case 'CATEGORIES':
                  credit.amountRemaining = discount.remaining.discountedCategories[credit.catOrProdId];
                  break;
              }
            }
          }
        })
      },
      creditRemaining(credit) {
          const remaining = credit.amountRemaining == undefined ? credit.amountInitial : credit.amountRemaining
          return this.formatShortCurrency(remaining, this.currency)
      },
      creditInitial(credit) {
        return this.formatShortCurrency(credit.amountInitial, this.currency)
      }
    },
  };
</script>

<style lang="scss" scoped>
  @use '@/styles/mixins.scss';
</style>
