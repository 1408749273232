import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { computed, ref, onMounted } from 'vue';
import CmcSelect, { SelectOption, GroupedSelectOption, SingleSelectOption } from '../inputs/CmcSelect.vue';
import CmcTextArea from '../inputs/CmcTextArea.vue';
import i18n from '@/i18n';

interface ServiceKey {
  value: string;
  type: string;
  name: string;
  is18n: boolean;
}

interface sshKey {
  sshKeyType: string,
  sshKeyLength?: number,
  sshKeyName: string,
  sshKeyValue: string
}

interface Props {
  modelValue?: Record<string, any>;
  disabled?: boolean;
  formElement: Record<string, any>;
  errors: Record<string, any>;
  asOptional?: boolean;
}

const SERVICE_PREFIX = 'service#'
const PROFILE_PREFIX = 'profile#'
const NONE_OPTION = 'none'
const NEW_OPTION = 'new'

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcSshKeyInput',
  props: {
    modelValue: { default: () => { return {} as Record<string, any> } },
    disabled: { type: Boolean, default: false },
    formElement: {},
    errors: { default: () => { return { } as Record<string, any> } },
    asOptional: { type: Boolean, default: false }
  },
  emits: ['update:modelValue', 'reload'],
  setup(__props: any, { emit: __emit }) {

const props = __props

onMounted(async() => {
  await loadProfileKeys();
  await loadWithProvidedValues();
  loading.value = false;
})

const emits = __emit;

const loading = ref(false)
const selectValue = ref<string | undefined>(undefined)
const nameValue = ref<string | undefined>(undefined)
const keyValue = ref<string | undefined>(undefined)
const otherValues = ref <string | undefined>(undefined)
const profileKeys = ref<sshKey[]>([])

const newKeyValueLabel = computed<string>(() =>
  props.formElement?.newKeyValueLabel || "ssh_keys.new_key_value"
)
const newKeyNameLabel = computed<string>(() =>
  props.formElement?.newKeyNameLabel || "ssh_keys.new_key_name"
)
const newKeyValuePlaceholder = computed<string>(() =>
  props.formElement?.newKeyValuePlaceholder || "ssh_keys.key_input_box_message"
)
const otherKeyLabel = computed<string>(() =>
  props.formElement?.otherKeyLabel || "ssh_keys.other_values"
)
const otherKeyPlaceholder = computed<string>(() =>
  props.formElement?.otherKeyPlaceholder || ""
)
const keyFieldIsList = computed<boolean>(() =>
  props.formElement?.keyFieldIsList
)
const valueSeparator = computed<string>(() =>
  props.formElement?.valueSeparator || "CSV"
)
const isNativeList = computed<boolean>(() =>
  props.formElement?.isNativeList
)
const keyValueField = computed<string>(() =>
  props.formElement?.field
)
const keyNameField = computed<string>(() =>
  props.formElement?.keyNameField
)
const displayCaptureKey = computed<boolean>(() =>
  selectValue.value === NEW_OPTION
)
const captureKeyName = computed<string>(() =>
  props.formElement?.captureKeyName
)
const displayCaptureKeyName = computed<boolean>(() =>
  !!captureKeyName.value && (!!displayCaptureKey.value || !!isProfileSelected(selectValue.value))
)
const isRequired = computed<boolean>(() =>
  props.formElement?.required
)
const allowNewKey = computed<boolean>(() =>
  props.formElement?.captureNewKey
)
const serviceKeys = computed<ServiceKey[]>(() =>
  props.formElement?.serviceKeys || []
)
const disableProfileKeys = computed<boolean>(() =>
  !!props.formElement?.disableProfileKeys
)
const onlyNameForServiceKeys = computed<boolean>(() =>
  !!props.formElement?.onlyNameForServiceKeys
)
const separator = computed<string>(() =>
  valueSeparator.value === "NEW_LINE" ? "\n" : ","
)

const selectOptions = computed(() => {
  let options: SelectOption[] = [];
  let ungroupedOptions: SingleSelectOption[] = [];
  if (!isRequired.value) {
    ungroupedOptions.push({
      value: NONE_OPTION,
      label: "ssh_keys.none",
      withLabelI18n: true
    } as SingleSelectOption);
  }
  if (allowNewKey.value) {
    ungroupedOptions.push({
      value: NEW_OPTION,
      label: "ssh_keys.new_key",
      $isDisabled: props.formElement?.enableOnlyServiceKeys,
      withLabelI18n: true
    } as SingleSelectOption);
  }
  if (ungroupedOptions.length !== 0) {
    options.push({
      label: "",
      options: ungroupedOptions
    } as GroupedSelectOption);
  }
  if (!disableProfileKeys.value && filteredProfileKeys.value) {
    options.push(filteredProfileKeys.value);
  }
  if (serviceKeys.value) {
    options.push({
      label: "ssh_keys.service_keys",
      withLabelI18n: true,
      options: serviceKeys.value.map(key => ({ value: SERVICE_PREFIX + key.name, label: key.name})) as SingleSelectOption[]
    } as GroupedSelectOption)
  }
  return options;
})
const filteredProfileKeys = computed<GroupedSelectOption>(() => {
  const keyFilters = props.formElement?.supportedKeyTypes;
  if (keyFilters && keyFilters.length !== 0) {
    return getProfileOptionValues(profileKeys.value.filter(key => (keyFilters.includes(getProfileEncodingValue(key)) || keyFilters.includes(key.sshKeyType))));
  }
  return getProfileOptionValues(profileKeys.value);
})
const nameFieldErrors = computed<string|undefined>(() => {
  if (!keyNameField.value || !captureKeyName.value || !props.errors) {
    return undefined;
  }
  return getErrorLabelValue(props.errors[keyNameField.value]);
})
const keyFieldErrors = computed<string|undefined>(() => {
  if (!keyValueField.value || !props.errors) {
    return undefined;
  }
  return getErrorLabelValue(props.errors[keyValueField.value]);
})
const valueFieldErrorSelectKeys = computed<boolean[]>(() =>
  !displayCaptureKey.value
  && !keyFieldIsList.value
  && !!props.errors && props.errors[keyValueField.value]
)
const nameFieldErrorSelectKeys = computed<boolean>(() =>
  !displayCaptureKeyName.value && !!props.errors && props.errors[keyNameField.value]
)
const selectFieldError = computed<string|undefined>(() => {
  if (nameFieldErrorSelectKeys.value) {
    return getErrorLabelValue(props.errors[keyNameField.value]);
  } else if (valueFieldErrorSelectKeys.value) {
    return getErrorLabelValue(props.errors[keyValueField.value]);
  }
  return "";
})

async function loadProfileKeys() {
  const qs = { withKeyValue: true };
  const resp = await apis.sshkeys.list({ qs });
  if (resp?.ok) {
    profileKeys.value = resp.data;
  }
  else {
    notify.error(i18n.global.t("ssh_keys.cannot_fetch_profile_keys"));
  }
}
async function loadWithProvidedValues() {
  if (!!keyNameField.value && !keyFieldIsList.value && props.modelValue[keyNameField.value]) {
    nameValue.value = props.modelValue[keyNameField.value];
    loadSelectValueFromNameField();
  }
  if (!!keyValueField.value && props.modelValue[keyValueField.value]) {
    if (keyFieldIsList.value) {
      updateKeyValuesFromArray();
    } else {
      keyValue.value = props.modelValue[keyValueField.value];
    }
    loadSelectValueFromKeyField();
  }
}
function getProfileEncodingValue(key: sshKey) {
  let value = key.sshKeyType;
  if (key.sshKeyLength) {
    value += '_' + key.sshKeyLength;
  }
  return value;
}
function loadSelectValueFromKeyField() {
  if (serviceKeys.value) {
    let matchKey = serviceKeys.value.find(key => key.value === keyValue.value);
    updateWithMatchSelectValue(matchKey, SERVICE_PREFIX);
  }
  if (profileKeys.value) {
    let matchKey = profileKeys.value.find(key => key.sshKeyValue === keyValue.value);
    updateWithMatchSelectValue(matchKey, PROFILE_PREFIX);
  }
  if (!selectValue.value && keyValue.value) {
    selectValue.value = NEW_OPTION;
  }
}
function loadSelectValueFromNameField() {
  if (serviceKeys.value) {
    const matchKey = serviceKeys.value.find(key => key.name === nameValue.value);
    if (matchKey) {
      selectValue.value = SERVICE_PREFIX + nameValue.value;
    }
  }
  if (!selectValue.value && props.formElement?.captureNewKey) {
    selectValue.value = NEW_OPTION;
  }
}
function updateKeyValuesFromArray() {
  let rawValue = props.modelValue[keyValueField.value];
  let arrayValues;

  if (!Array.isArray(rawValue)) {
    arrayValues = (rawValue !== "") ? rawValue.split(separator.value) : [];
  } else {
    arrayValues = [...rawValue];
  }

  if (arrayValues && arrayValues.length !== 0) {
    keyValue.value = arrayValues.shift();
    otherValues.value = arrayValues.join(separator.value);
  } else if (!isKeyPresentInSelectValues()) {
    otherValues.value = keyValue.value;
    keyValue.value = '';
  }
}
function isKeyPresentInSelectValues() {
  let matchKey;
  if (serviceKeys.value && keyValue.value) {
    matchKey = serviceKeys.value.find(key => key.value === keyValue.value);
  }
  if (profileKeys.value && keyValue.value) {
    matchKey = profileKeys.value.find(key => key.sshKeyValue === keyValue.value);
  }
  return !!matchKey;
}
function updateWithMatchSelectValue(matchKey: sshKey|ServiceKey|undefined, prefix: string) {
  if (matchKey !== undefined && matchKey) {
    if (selectValue.value && allowNewKey.value && selectValue.value !== prefix + props.formElement?.keyName) {
      selectValue.value = NEW_OPTION;
    } else if (props.formElement?.keyName) {
      selectValue.value = prefix + props.formElement?.keyName;
    } else if ('sshKeyName' in matchKey && matchKey.sshKeyName) {
      selectValue.value = prefix + matchKey.sshKeyName;
    }
  }
}
function isServiceSelected(value: string) {
  return value?.startsWith(SERVICE_PREFIX);
}
function selectChange(newValue: string) {
  if (selectValue.value === newValue) {
    return;
  }
  selectValue.value = newValue;
  if (newValue === NONE_OPTION || newValue === NEW_OPTION) {
    selectChangeNoneOrNew();
  }
  if (isServiceSelected(newValue)) {
    selectChangeServiceSSHKey(newValue);
  }
  if (isProfileSelected(newValue)) {
    selectChangeProfileSSHKey(newValue);
  }
  sshKeyChange();
}
function selectChangeNoneOrNew() {
  if (props.formElement?.keyNameField) {
    nameValue.value = undefined;
  }
  if (keyValueField.value) {
    keyValue.value = undefined;
  }
}
function selectChangeServiceSSHKey(newValue: string) {
  const matchingValue = serviceKeys.value.find(k => newValue.slice(SERVICE_PREFIX.length) === k.name);
  if (props.formElement?.keyNameField) {
    nameValue.value = matchingValue?.name;
  } else {
    nameValue.value = undefined;
  }
  if (!onlyNameForServiceKeys.value && keyValue.value) {
    keyValue.value = matchingValue?.value;
  } else {
    keyValue.value = undefined;
  }
}
function selectChangeProfileSSHKey(newValue: string) {
  const matchingValue = profileKeys.value.find(k => newValue.slice(PROFILE_PREFIX.length) === k.sshKeyName);
  if (props.formElement?.keyNameField && displayCaptureKeyName.value) {
    nameValue.value = matchingValue?.sshKeyName;
  } else {
    nameValue.value = undefined;
  }
  if (keyValueField.value) {
    keyValue.value = matchingValue?.sshKeyValue;
  } else {
    keyValue.value = undefined;
  }
}
function sshKeyChange() {
  if (keyFieldIsList.value) {
    const arrayValue = getArrayValue();
    emits("update:modelValue", {
      ...props.modelValue,
      "ui-selectValue": selectValue.value,
      [keyValueField.value]: arrayValue
    });
  }
  else {
    let newValueObject: Record<string, any> = {
      ...props.modelValue,
      "ui-selectValue": selectValue.value,
    };
    if (keyValueField.value) {
      newValueObject[keyValueField.value] = keyValue.value;
    }
    if (keyNameField.value) {
      newValueObject[keyNameField.value] = nameValue.value;
    }
    emits("update:modelValue", newValueObject);
    emits('reload',
          {
            oldValue: props.modelValue,
            field: props.formElement?.field,
            formElement: props.formElement,
            value: newValueObject,
            sections: props.formElement?.sectionsToReload,
            selectedConfig: {},
          });
  }
}
function getArrayValue() {
  let arrayValue: string|string[]|undefined = "";
  if (selectValue.value !== NONE_OPTION) {
    arrayValue = keyValue.value;
  }
  if (otherValues.value) {
    if (keyValue.value) {
      arrayValue += separator.value;
    }
    arrayValue += otherValues.value;
  }
  if (isNativeList.value) {
    arrayValue = (arrayValue !== "" && arrayValue !== undefined) ? arrayValue.split(separator.value) : [];
  }
  return arrayValue;
}
function isProfileSelected(value: undefined|string) {
  return value?.startsWith(PROFILE_PREFIX)
}
function getProfileOptionValues(arrayValues: sshKey[]) {
  return {
    label: "ssh_keys.profile_keys",
    withLabelI18n: true,
    options: arrayValues.map(key => ({
        value: PROFILE_PREFIX + key.sshKeyName,
        label: key.sshKeyName,
        $isDisabled: props.formElement?.enableOnlyServiceKeys,
      })) as SingleSelectOption[]
  } as GroupedSelectOption
}
function getErrorLabelValue(error: Record<string, any>) {
  if (!error) {
    return undefined;
  }
  return i18n.global.t(error[0].context.labelKey, (error[0].context || {}));
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_icon = _resolveComponent("cmc-icon")!
  const _component_cmc_text_input = _resolveComponent("cmc-text-input")!
  const _component_cmc_stack = _resolveComponent("cmc-stack")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_cmc_stack, { spacing: "2xl" }, {
    default: _withCtx(() => [
      (!loading.value)
        ? (_openBlock(), _createBlock(CmcSelect, {
            key: 0,
            modelValue: selectValue.value,
            label: _ctx.formElement?.label,
            "as-optional": _ctx.asOptional,
            "with-label-i18n": "",
            description: _ctx.formElement?.description,
            "with-description-i18n": "",
            options: selectOptions.value,
            disabled: _ctx.disabled,
            allowEmpty: false,
            withErrorText: selectFieldError.value,
            withErrorTextI18n: true,
            "with-warning-tooltip": _ctx.disabled ? _ctx.formElement?.disabledDescriptionLabel : '',
            "with-warning-tooltip-i18n": "",
            "onUpdate:modelValue": selectChange
          }, {
            rhs: _withCtx(({ option }: { option: SingleSelectOption }) => [
              (option && option.$isDisabled)
                ? _withDirectives((_openBlock(), _createBlock(_component_cmc_icon, {
                    key: 0,
                    icon: "info-filled-bold",
                    class: "cmc-icon cmc-icon-blue cmc-icon-tooltip-enabled",
                    svg: "",
                    size: "m"
                  }, null, 512)), [
                    [_directive_tooltip, { content: _ctx.$t(props.formElement?.limitedOptionsDescLabel), popperClass: 'cmc-tooltip-size-s', placement: 'auto' }]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "label", "as-optional", "description", "options", "disabled", "withErrorText", "with-warning-tooltip"]))
        : _createCommentVNode("", true),
      (displayCaptureKeyName.value)
        ? (_openBlock(), _createBlock(_component_cmc_text_input, {
            key: 1,
            modelValue: nameValue.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((nameValue).value = $event)),
              sshKeyChange
            ],
            label: newKeyNameLabel.value,
            "as-optional": _ctx.asOptional,
            withLabelI18n: true,
            withErrorText: nameFieldErrors.value,
            disabled: _ctx.disabled
          }, null, 8, ["modelValue", "label", "as-optional", "withErrorText", "disabled"]))
        : _createCommentVNode("", true),
      (displayCaptureKey.value)
        ? (_openBlock(), _createBlock(CmcTextArea, {
            key: 2,
            modelValue: keyValue.value,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => ((keyValue).value = $event)),
              sshKeyChange
            ],
            label: newKeyValueLabel.value,
            "as-optional": _ctx.asOptional,
            withLabelI18n: true,
            withErrorText: keyFieldErrors.value,
            withErrorTextI18n: true,
            disabled: _ctx.disabled,
            withPlaceholder: newKeyValuePlaceholder.value,
            "with-placeholder-i18n": "",
            "as-monospace": ""
          }, null, 8, ["modelValue", "label", "as-optional", "withErrorText", "disabled", "withPlaceholder"]))
        : _createCommentVNode("", true),
      (keyFieldIsList.value)
        ? (_openBlock(), _createBlock(CmcTextArea, {
            key: 3,
            modelValue: otherValues.value,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((otherValues).value = $event)),
              sshKeyChange
            ],
            label: otherKeyLabel.value,
            "as-optional": _ctx.asOptional,
            withLabelI18n: true,
            withErrorText: keyFieldErrors.value,
            withErrorTextI18n: true,
            disabled: _ctx.disabled,
            withPlaceholder: otherKeyPlaceholder.value
          }, null, 8, ["modelValue", "label", "as-optional", "withErrorText", "disabled", "withPlaceholder"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})