<template>
  <l-map
    v-if="mapProvider && ready"
    ref="theMap"
    :bounds="mapBounds"
    :maxBounds="maxBounds"
    :minZoom="minZoom"
    :maxZoom="16"
    :maxBoundsViscosity="1.0"
    :zoomAnimation="true"
    :options="{ zoomSnap: 0.5, zoomDelta: 0.5 }"
    :style="styleObject"
    @ready="updateBound"
  >
    <l-tile-layer
      :noWrap="false"
      :url="url"
      :attribution="attribution"
    />
    <l-control
      v-if="legend"
      position="bottomleft"
      class="legend"
    >
      <slot name="legend"></slot>
    </l-control>
    <div :class="loading && loadDelay === 0 ? 'loader-container' : undefined">
      <base-loader
        v-if="loading"
        :delay="loadDelay"
      ></base-loader>
    </div>
    <slot></slot>
  </l-map>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import {LControl, LMap, LTileLayer} from '@vue-leaflet/vue-leaflet';
import {mapGetters} from 'vuex';
import mapProviders from './map.providers';

export default {
  name: 'BaseMap',
  components: { LMap, LTileLayer, LControl },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadDelay: {
      type: Number,
      required: false,
      default: undefined,
    },
    bounds: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '100%',
    },
    legend: {
      type: Boolean,
    },
  },
  data() {
    return {
      mapBounds: null,
      maxBounds: null,
      minZoom: 1,
      ready: false,
    };
  },
  computed: {
    ...mapGetters(['globalProperties']),
    mapProvider() {
      return mapProviders[this.provider];
    },
    provider() {
      return this.globalProperties['map_provider.name'];
    },
    providerConfig() {
      const configStr = this.globalProperties['map_provider.config'] || '{}';
      return JSON.parse(configStr);
    },
    attribution() {
      return this.mapProvider.attribution();
    },
    url() {
      return this.mapProvider.url(this.providerConfig);
    },
    styleObject() {
      return {
        height: this.height,
        width: '100%',
        'min-height': 'max(50vh,600px)',
        filter: this.loading && this.loadDelay === 0 ? 'blur(4px)' : '',
      };
    },
  },
  watch: {
    async bounds(){
      this.setBounds();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const element = document.querySelector('.map-container');
      const rect = element && element.getBoundingClientRect();
      const width = rect && rect.width;
      if (width < 1400) {
        this.minZoom = 2;
      } else if (width < 1800) {
        this.minZoom = 2.5;
      } else {
        this.minZoom = 3;
      }
      await this.setMaxBounds();
      await this.setBounds();
      this.ready = true;
    });
  },
  methods: {
    async setBounds(){
      const  { featureGroup, marker } = await import ('leaflet/dist/leaflet-src.esm');
      if (!this.bounds.length) {
        this.mapBounds = await this.maxBounds;
        return;
      }
      const createBounds = point => [
        [point[0] - 1, point[1] - 1],
        [point[0] + 1, point[1] + 1],
      ];
      /* eslint-disable new-cap */
      const group =  featureGroup(this.bounds
        .map(p => createBounds(p))
        .reduce((acc, cur) => [...acc, ...cur])
        .map(p => marker(p)));
      this.mapBounds = group.getBounds();
    },

    async setMaxBounds() {
      const  { latLng, latLngBounds } = await import ('leaflet/dist/leaflet-src.esm');

      const southWest = latLng(-90, -180);
      const northEast = latLng(90, 180);
      this.maxBounds = latLngBounds(southWest, northEast);
    },
    updateBound(){
      this.$refs.theMap.leafletObject.fitBounds(this.mapBounds);
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/animations.scss';
@use '@/styles/mixins.scss';

.legend {
  min-width: 140px;

  padding: 10px 16px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 2px 13px 0px var(--map-widget-legend-shadow);
}

:deep(.leaflet-top, .leaflet-bottom) {
  z-index: 8;
}

:deep(.leaflet-pane) {
  z-index: 7;
}

:deep(.light-map-marker:not(:hover)) {
  z-index: 0 !important;
}

:deep(.map-marker) {
  &.light {

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    .map-marker-icon {
      font-size: 34px;
      text-shadow: 1.5px 1px 2px var(--icon-shadow);
    }

    &:hover {
      .map-marker-icon {
        font-size: 42px;
        transition: font-size 0.2s;
      }

      .map-marker-label {
        display: block;
        font-size: 16px;
        margin-left: 3px;
        padding: 0px 10px;
        opacity: 0.8;
        background-color: var(--section-header-bg);
        border-radius: 10px;
        white-space: nowrap;
        transition: display 0.2s;
        position: relative;
      }
    }
    .map-marker-label {
      display: none;
      transition: display 0.1s;
    }
  }
}

:deep(.map-marker:not(.light)) {
  position: relative;
  padding: 3px 15px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  opacity: 0.9;
  transition: opacity 0.1s;
  &.animated {
    animation: light-flash 1.5s infinite;
  }
  &:hover {
    opacity: 1;
  }

  &.map-marker-no-text {
    padding: unset;
  }

  .map-marker-state {
    font-size: 12px;
    position: absolute;
    top: -6px;
    left: -6px;
    transition: font-size 0.1s;
    &.map-marker-no-text {
      font-size: 10px;
    }
  }

  &:hover {
    .map-marker-icon {
      font-size: 24px;
      height: 24px;
      width: 24px;
    }
    .map-marker-label {
      font-size: 12px;
    }
    .map-marker-state {
      font-size: 14px;
    }
  }
  .map-marker-icon {
    font-size: 22px;
    height: 22px;
    width: 22px;
    transition: font-size 0.1s, height 0.1s, width 0.1s;
  }

  .map-marker-label {
    font-size: 11px;
    transition: font-size 0.1s;
    font-weight: bold;
  }
}

:deep(.map-marker-popup) {
  display: flex;
  flex-direction: row;
  @include mixins.not-phone {
      min-width: 380px;
  }

  .lhs {
    flex: 2;
    padding-right: 20px;

    table {
      tr {
        td:first-child {
          padding-right: 8px;
        }
      }
    }
    .title {
      font-size: 16px;
      padding-bottom: 10px;
    }
    .description {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }
  }

  .rhs {
    @include mixins.phone {
      display: none;
    }
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    flex-direction: column;
    border-left: 1px solid #f0f0f0;
    button {
      margin-top: 10px;
    }
  }
}

:deep(.map-marker-tooltip) {

  @include mixins.not-phone {
    min-width: 300px;
  }

  @include mixins.phone {
    max-width: 220px;
  }

  .title {
    font-size: 16px;
    padding-left: 8px;
    padding-bottom: 10px;
  }
  .description {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .map-marker-metadata-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .map-marker-metadata {
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;

    .field-group {
      &.horizontal {
        @include mixins.phone {
          flex-direction: column;
        }
      }
    }
  }
}
.map-container {
  :deep(.leaflet-tooltip) {
    border-radius:5px;
  }

}
.popup-group-resource {
  padding-bottom: 20px;
}

.loader-container {
    position: absolute;
    top: 50%;
    opacity: 1;
    z-index: 700;
    width: 100%;
    transform: translate(0%, -50%);
}
</style>
