import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

import { defineComponent, computed } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import { useRoute, useRouter, RouteLocationRaw, RouteLocationPathRaw } from 'vue-router';
import { useAttrs } from 'vue';
import { useStore } from 'vuex'

type Props = {
  /**
   * The path to navigate to, with parameters like name, query, params. It can also be just a path string.
   */
  to: RouteLocationRaw;

  /**
   * True if the link is to be opened in a new tab. Defaults to false.
   */
  withBlankTarget?: boolean;

  /**
   * Whether the slot content is clickable or needs to be wrapped in a div. Defaults to false.
   */
  plain?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcAppLink',
  props: {
    to: {},
    withBlankTarget: { type: Boolean, default: false },
    plain: { type: Boolean, default: false }
  },
  setup(__props: any) {

const router = useRouter();
const route = useRoute();
const attrs = useAttrs();
const store = useStore();

defineComponent({
  CmcGroup,
  CmcText,
  CmcDivider,
  CmcBlock,
})

const props = __props;

const selectedOrgCode = computed(() => store.getters.selectedOrganization?.entryPoint);

const workspaceQuery = computed(() => route.query.workspace);

const routeObject = computed(() => {
  return typeof props.to === 'object' ? { ...props.to, query: adaptedQuery.value } : { path: props.to, query: adaptedQuery.value }
});

const adaptedQuery = computed(() => {
  let query = typeof props.to === 'object' ? props.to.query || {} : extractQueryParams(props.to);
  if (shouldAddOrgQueryParam.value && !query.org) {
    query = { ...query, org: selectedOrgCode.value };
  }
  if (workspaceQuery.value && inheritWorkspaceQuery(props.to)) {
    query = { ...query, workspace: workspaceQuery.value };
  }
  return query;
});

const shouldAddOrgQueryParam = computed(() => {
  const selectedOrg = store.getters.selectedOrganization;
  const myOrg = store.getters.myOrganization;
  if (!selectedOrg) {
    return false;
  }
  return !(myOrg && myOrg.id === selectedOrg.id);
});

const inheritWorkspaceQuery = (toRoute: RouteLocationRaw) => {
  if (toService(toRoute) === false) {
    return false;
  }
  const record = router.resolve(toRoute);
  return record.path.startsWith('/services') && !(record.meta || {}).requiresWorkspaceQuery;
};

const ariaLabelledBy = computed(() => attrs['aria-labelledby']);

const extractQueryParams = (path: string) => {
  const urlParts = path.split('?');
  if (urlParts.length >= 2) {
    const urlSearchParams = new URLSearchParams(urlParts[1]);
    const result: Record<string, string> = {};
    urlSearchParams.forEach((value, key) => { result[key] = value; });
    return result;
  }
  return {};
};

const toService = (toRoute: RouteLocationRaw): boolean | null => {
  if (typeof toRoute === 'string') {
    return toRoute.startsWith('/services')
  }

  return (toRoute as RouteLocationPathRaw).path?.startsWith('/services') || null
};

const goTo = (route: RouteLocationRaw) => { router.push(route); };


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.plain)
    ? (_openBlock(), _createBlock(CmcBlock, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: routeObject.value,
            target: _ctx.withBlankTarget ? '_blank' : '_self',
            "aria-labelledby": ariaLabelledBy.value,
            onKeypress: _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (goTo(routeObject.value)), ["self"]), ["enter"]))
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["to", "target", "aria-labelledby"])
        ]),
        _: 3
      }))
    : (_openBlock(), _createBlock(CmcBlock, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            custom: "",
            to: routeObject.value
          }, {
            default: _withCtx(({ navigate }) => [
              _createVNode(CmcBlock, {
                class: _normalizeClass([_ctx.$attrs.class]),
                "aria-labelledby": ariaLabelledBy.value,
                onKeypress: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (goTo(routeObject.value)), ["self"]), ["enter"])),
                onClick: navigate
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 2
              }, 1032, ["class", "aria-labelledby", "onClick"])
            ]),
            _: 3
          }, 8, ["to"])
        ]),
        _: 3
      }))
}
}

})