<template>
  <div 
    v-if="!hideIfOne || tabs.length > 1" 
    ref="dropdown_listener" 
    :class="{ 'tabs': !light, 'light-tabs': light }"
  >
    <transition-route 
      v-for="tab in tabs" 
      :key="tab.value"
      class="tab multi fake-a" 
      :role="tab.to ? 'link' : 'button'" 
      :tabindex="0"
      :to="tab.to"
      :name="tab.value"
      :class="{active: selectedTab && selectedTab.value === tab.value, 'tab': !light, 'multi': !light, 'light-tab': light}"
      @click="clicked(tab)" 
    >
      {{ tabLabel(tab) }}
      <slot 
        name="tab-icons" 
        :tab="tab.value"
      ></slot>
    </transition-route>
    <div 
      v-if="allowModification" 
      class="tab multi clickable fake-a" 
      :class="{'tab': !light, 'multi': !light, 'light-tab': light}"
    >
      <div 
        v-if="!addingTab" 
        @click="addingTab = true"
      >
        {{ $t('monetization.add_category') }}
      </div>
      <div v-else>
        <input 
          v-model="newTab" 
          v-focus 
          class="tab-input" 
          type="text" 
          :maxlength="maxNewTabLength" 
          @keydown.enter.prevent="addTab" 
          @blur="addTab" 
        />
      </div>
      <action-icon 
        icon="fa fa-plus" 
        class="addCategory" 
        size="small" 
        color="unset" 
        @click="addingTab = true"
      />
    </div>
    <div 
      v-if="selectedTab && !light" 
      :tabindex="0" 
      class="tab single clickable active" 
      @keydown.enter="dropdownOpened = true" 
      @click="dropdownOpened = true"
    >
      <div>{{ tabLabel(selectedTab) }}</div> &nbsp;<base-icon icon="fa fa-caret-down" />
    </div>
    <items-dropdown 
      keydownRef="dropdown_listener" 
      header="select_tab" 
      :opened="dropdownOpened" 
      :items="tabs" 
      :selected="selected"
      @close="closeDropdown" 
    />
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    selected: {
      type: String,
    },
    tabs: {
      type: Array,
      required: true,
    },
    light: {
      type: Boolean,
    },
    allowModification: {
      type: Boolean,
    },
    hideIfOne: {
      type: Boolean,
      default: false,
    },
    maxNewTabLength: {
      type: Number,
    },
  },
  emits: ['tabChange', 'addTab'],
  data() {
    return {
      dropdownOpened: false,
      addingTab: false,
      newTab: '',
    };
  },
  computed: {
    selectedTab() {
      return this.tabs.find((t) => {
        if (t.to) {
          return this.selected.includes(t.value);
        }
        return this.selected === t.value;
      });
    },
  },
  methods: {
    enableAddTab() {
      this.addingTab = true;
    },
    clicked(tab) {
      this.$emit('tabChange', tab);
    },
    addTab() {
      this.addingTab = false;
      if (this.newTab) {
        this.$emit('addTab', this.newTab);
        this.newTab = '';
      }
    },
    closeDropdown(tab) {
      this.dropdownOpened = false;
      if (tab) {
        const selected = this.tabs.find(t => t.value === tab);
        this.clicked(selected);
        if (selected.to) {
          this.$router.push(selected.to);
        }
      }
    },
    tabLabel(tab) {
      const found = this.$te(tab.label, tab.interpolation);
      return found ? this.$t(tab.label, tab.interpolation): tab.label;
    }
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

input {
  font-size: 14px;
}

.tabs {
  display: flex;
  flex-direction: row;
  font-size: 13px;

  .tab {
    flex: 1;
    padding: 15px 10px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    display: flex;
    white-space: nowrap;
  }

  @include mixins.not-phone {
    .single, .dropdown {
      display: none;
    }
  }

  @include mixins.phone {
    .multi {
      display: none;
    }
  }
}

.light-tabs {
  display: flex;
  margin-bottom: 20px;

  .light-tab {
    font-size: 14px;
    padding: 12px 15px;
    cursor: pointer;
    text-align: left;
  }
}
.fa-plus {
  margin-left: 10px;
}

.tab {
  display: flex;
  align-items: center;
}

.tab-input {
  border: 0px;
  width: 80px;
  padding:0;
}
.addCategory {
  margin-left: 5px;
}
</style>
