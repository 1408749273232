import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["checked"]

import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';

type Props = {
  /**
   * HTML element id
   */
   id?: string;
   
  /**
   * Checked value of the checkbox
   */
  modelValue?: boolean;

  /**
   * Label to display on the left-hand side of the checkbox.
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * Is the description i18n.
   */
  withDescriptionI18n?: boolean;
  
  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the checkbox.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean

  /**
   * Should be display in read-only mode
   */
  readOnly?: boolean;

  /**
   * Should the checkbox be disabled
   */
  disabled?: boolean;

  /**
   * Should display as a toggle.
   */
  asToggle?: boolean;

  /**
   * Should checkbox be displayed in reversed mode.
   */
  reversed?: boolean;

  /**
   * Make it bigger.
   */
  large?: boolean;

  /**
   * Checkbox is inside a checkbox group.
   */
  asGroup?: boolean;

  /**
   * Checkbox is a header
   */
  asHeader?: boolean;

  /**
   * Color of the checkbox.
   */
  checkboxColor?: string;

  /**
   * The number of lines to display before we truncated with ellipsis rather than wrapping text.
   */
  maxLineDisplay?: number;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcCheckbox',
  props: {
    id: {},
    modelValue: { type: Boolean },
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    readOnly: { type: Boolean },
    disabled: { type: Boolean },
    asToggle: { type: Boolean },
    reversed: { type: Boolean },
    large: { type: Boolean },
    asGroup: { type: Boolean },
    asHeader: { type: Boolean },
    checkboxColor: { default: 'var(--ng-checkbox-bg)' },
    maxLineDisplay: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "fdb8626c": (_ctx.$props.checkboxColor)
}))

const props = __props

const emit = __emit

const onClick = () => {
  if (props.disabled) {
    return;
  }
  emit('update:modelValue', !props.modelValue)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-checkbox-${_ctx.id}` : undefined,
    label: _ctx.label,
    modelValue: _ctx.modelValue + '',
    "with-label-i18n": _ctx.withLabelI18n,
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        spacing: "3xs",
        class: _normalizeClass(['cmc-checkbox', { 'cmc-checkbox-disabled': _ctx.disabled}])
      }, {
        default: _withCtx(() => [
          _createVNode(CmcPair, {
            "stretch-lhs": !!_ctx.label,
            reversed: _ctx.reversed,
            spacing: _ctx.label ? 'xs' : 'none'
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "cmc-checkbox-label",
                onClick: onClick
              }, [
                _createVNode(CmcAlign, { "at-vertical-center": "" }, {
                  default: _withCtx(() => [
                    _createVNode(CmcLabel, _normalizeProps(_guardReactiveProps(props)), null, 16)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createVNode(CmcAlign, {
                  "at-vertical-center": "",
                  "at-bottom": _ctx.asHeader
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("input", {
                      type: "checkbox",
                      checked: _ctx.modelValue
                    }, null, 8, _hoisted_1),
                    (_ctx.asToggle)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "cmc-checkbox-as-toggle",
                          tabindex: "0",
                          onClick: onClick,
                          onKeydown: [
                            _withKeys(onClick, ["enter"]),
                            _withKeys(onClick, ["space"])
                          ]
                        }, _cache[0] || (_cache[0] = [
                          _createElementVNode("div", { class: "cmc-checkbox-as-toggle-dot" }, null, -1)
                        ]), 32))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "cmc-checkbox-impl",
                          tabindex: "0",
                          onClick: onClick,
                          onKeydown: [
                            _withKeys(onClick, ["enter"]),
                            _withKeys(onClick, ["space"])
                          ]
                        }, [
                          (_ctx.modelValue)
                            ? (_openBlock(), _createBlock(CmcIcon, {
                                key: 0,
                                svg: "",
                                color: "white",
                                icon: "checkmark",
                                "custom-size": 0.625
                              }))
                            : _createCommentVNode("", true)
                        ], 32))
                  ]),
                  _: 1
                }, 8, ["at-bottom"])
              ])
            ]),
            _: 1
          }, 8, ["stretch-lhs", "reversed", "spacing"]),
          (_ctx.withErrorText)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 0,
                size: "m",
                text: _ctx.withErrorText,
                "with-i18n": _ctx.withErrorTextI18n,
                "as-error": ""
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["id", "label", "modelValue", "with-label-i18n", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n"]))
}
}

})