import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';

interface Props {
  count: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcCount',
  props: {
    count: {}
  },
  setup(__props: any) {

defineComponent({
  CmcText,
})

const props = __props
const formattedCount = computed(() => {
  if (props.count < 10) {
    return `0${props.count}`;
  }
  return props.count.toString();
});

return (_ctx: any,_cache: any) => {
  const _component_cmc_block = _resolveComponent("cmc-block")!

  return (_openBlock(), _createBlock(_component_cmc_block, { class: "cmc-count-container" }, {
    default: _withCtx(() => [
      _createVNode(CmcText, {
        class: "cmc-count",
        text: formattedCount.value,
        size: "m",
        "with-bold": ""
      }, null, 8, ["text"])
    ]),
    _: 1
  }))
}
}

})