import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots, unref as _unref, resolveComponent as _resolveComponent } from "vue"

import { computed, defineComponent, reactive, watch, ref } from 'vue';
import type { Ref } from 'vue'
import CmcList from '../display/list/CmcList.vue';
import CmcListHeader from '../display/list/CmcListHeader.vue';
import CmcListRow from '../display/list/CmcListRow.vue';
import CmcKeyValue from '../display/CmcKeyValue.vue';
import CmcListCol from '../display/list/CmcListCol.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';

import CmcTabs from '../navigation/CmcTabs.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcSelect, { SelectProps } from '../inputs/CmcSelect.vue';
import CmcPagination from '../pagination/CmcPagination.vue'
import { useI18n } from 'vue-i18n';
import { SingleSelectOption } from './types';
import { ColLayout } from '../display/list/types';
import { Tab } from '../navigation/types';

export type ListSelectConfig = {
  key: string;
} & SelectProps

export type ListSelectHeader = {
  /**
   * Label on top of the text area
   */
  label: string;

  /**
   * Is the label i18n.
   */
  withI18n?: boolean;

  /**
   * Tooltip in the header,
   */
  withTooltip?: string;

  /**
   * Is tooltip i18n.
   */
  withTooltipI18n?: boolean;
}

export type ListSelectOption = {
  /**
   * Value of the row.
   */
  value: string;

  /**
   * Label of the select option.
   */
  label: string;

  /**
   * Label is i18n or not.
   */
  withI18n?: boolean;

  /**
   * Tooltip content
   */
  withTooltip?: string;

  /**
   * Tooltip content
   * Keys are i18n
   */
  withTooltipKeyValue?: {[key: string]: string}

  /**
   * True if tooltip label is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * True if the tooltip content is html
   */
  withTooltipHtml?: boolean;

  /**
   * Image icon to show next to the option.
   */
  imgUrl?: string;

  /**
   * Category of the option.
   */
  category?: string;

  /**
   * Specific configuration for the option.
   */
  configs: ListSelectConfig[]
}

type Props = {

  id?: string;

  /**
   * Add a label on top of the select
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Model value of the list select. This is the value of the select row
   */
  modelValue: any;

  /**
   * Config selected for the current row.
   */
  config: {[key: string]: any };

  /**
   * Selected category
   */
  category?: string;

  /**
   * Categories available as tabs
   */
  categories?: Tab[];

  /**
   * Headers of the list.
   */
  headers: ListSelectHeader[];

  /**
   * Options of the list.
   */
  options: ListSelectOption[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];

  /**
   * Disable the list select.
   */
  disabled?: boolean;

  /**
   * Set the list select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListSelect',
  props: {
    id: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    modelValue: {},
    config: {},
    category: {},
    categories: {},
    headers: {},
    options: {},
    layout: {},
    disabled: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean }
  },
  emits: ["update:modelValue", "update:config", "update:category"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcList,
  CmcListCol,
  CmcListHeader,
  CmcTabs,
  CmcSelect,
  CmcBlock,
  CmcText,
  CmcTitle,
  CmcLabel,
  CmcStack,
  CmcReadOnly,
  CmcIcon,
  CmcKeyValue,
  CmcPagination
})

const props = __props


const selectedPage: Ref<number> = ref(1);
const pageSize: Ref<number> = ref(5);

  const getCategoryLabel = (): string | number | undefined => {
  if (props.categories?.length === 1) {
    return '';
  }
  return props.categories?.find(c => c.key === props.category)?.label || '';
};

const selectedCategory = computed<string | undefined>({
  get: () => {
    return props.categories?.length === 1 ? props.categories[0].key : props.category;
  },
  set: (newCategory) => {
    emit('update:category', newCategory);
  },
});

const actualOptions = computed(() => {
  return props.options.filter(opt => {
    return opt.category === selectedCategory.value
  });
});

const shouldBePaginated = computed(() => {
  return actualOptions.value.length > 5;
})

const optionsToDisplay = computed(() => {
  return shouldBePaginated.value ? actualOptions.value.slice((selectedPage.value - 1 ) * pageSize.value, Math.min(actualOptions.value.length, selectedPage.value * pageSize.value)) :  actualOptions.value;
});

let allConfigs: { [key: string]: any } = reactive(buildAllConfig());

function buildAllConfig() {
  const configMap = props.options.reduce((acc, cur) => {
    acc[cur.value] = {};
    return acc;
  }, {} as { [key: string]: any });
  if (props.modelValue) {
    configMap[props.modelValue] = { ...configMap[props.modelValue], ...(props.config || {}) };
  }
  return configMap;
}
const emit = __emit

const selectRow = (v: string) => {
  let config;

  if (Object.entries(allConfigs[v]).length > 0) {
    config = allConfigs[v];
  } else {
    const configs = props.options.find(opt => opt.value === v)?.configs;
    config = configs?.reduce((acc, cur) => {
      acc[cur.key] = (cur.options[0] as SingleSelectOption).value;
      return acc;
    }, {} as {[key: string]: any});
  }
  emit('update:category', selectedCategory.value);
  emit('update:config', config);
  emit('update:modelValue', v);
};

const isSelectWithoutLabel = (selectLabel: string | undefined) => {
  return selectLabel == null ||  selectLabel == undefined ||  selectLabel == '';
}

const selectPage = (v: string) => {
  selectedPage.value = parseInt(v)
}

const selectPageSize = (v: string) => {
  pageSize.value = parseInt(v)
}

const changeTab = (v: string) => {
  selectedCategory.value = v;
}

const updateSelectValue = () => {
  if (allConfigs[props.modelValue]) {
    emit('update:config', allConfigs[props.modelValue]);
  }
};

// reassign allConfig when props options change on section reload
watch(() => props.options, () => {
  selectedCategory.value = props.category;
  const curModelVal = props.options.find(opt => opt.value === props.modelValue)
  if (!curModelVal) {
    selectRow(props.options[0]?.value);
  } else {
    reassignAllConfig();
  }
})

/**
 * If the props.options change it is possible that a selected value in the allConfigs is no longer a valid selectable option
 * In this case, we should reassign the allConfig value to the first option in the list
 */
function reassignAllConfig() {
  props.options.forEach(option => {
    option.configs.forEach((config) => {
      var currentOption;
      if (option.value === props.modelValue) {
        currentOption = config.options.find(opt => (opt as SingleSelectOption).value == props.config[config.key]);
      } else {
        currentOption = config.options.find(opt => (opt as SingleSelectOption).value == allConfigs[option.value][config.key]);
      }
      if (currentOption) {
        // sometimes there is a type mismatch between the value from the backend + frontend (1 vs '1.0')
        // which causes the select to appear empty (not recognize the selected value as one of the options)
        allConfigs[option.value][config.key] = (currentOption as SingleSelectOption).value;
      } else {
        // if the current selected value doesnt exist in the list of options anymore (due to reload)
        // then fall back to the first option in the list
        allConfigs[option.value][config.key] = (config.options[0] as SingleSelectOption).value;
      }
    })
  })
}

// this is for when confirm on change happens and the model value is set to null
watch(() => props.modelValue, () => {
  if (!props.modelValue) {
    allConfigs = buildAllConfig()
  }
});

const { t } = useI18n()

const readOnlyValue = computed(() => {
  const opt = props.options.find(opt => opt.value === props.modelValue);
  if (opt) {
    return opt.withI18n ? t(opt.label) : opt.label;
  }
  return '';
});

const readOnlyConfig = computed(() => {
  const config = [];
  if (props.categories && props.categories.length >= 1) {
    config.push({
        label: props.headers[0].label,
        withLabelI18n: true,
        value: readOnlyValue.value,
      });
  }
  const opt = props.options.find(opt => opt.value === props.modelValue);
  if (opt && props.config) {
    config.push(...opt.configs.map((c, idx) => ({
      label: props.headers[idx + 1].label,
      withLabelI18n: props.headers[idx + 1].withI18n,
      value: c.options.find((option: any) => option.value == props.config[c.key])?.label,
    })));
  }
  return config;
})

return (_ctx: any,_cache: any) => {
  const _component_cmc_align = _resolveComponent("cmc-align")!

  return (_openBlock(), _createBlock(CmcStack, { class: "cmc-list-select" }, {
    default: _withCtx(() => [
      _createVNode(CmcReadOnly, {
        id: _ctx.id ? `cmc-list-select-${_ctx.id}` : undefined,
        label: _ctx.label,
        "with-label-i18n": "",
        "model-value": getCategoryLabel(),
        "read-only": _ctx.readOnly,
        "inherit-read-only": _ctx.inheritReadOnly,
        hide: (_ctx.categories && _ctx.categories.length < 1) || _ctx.readOnly
      }, {
        default: _withCtx(() => [
          _createVNode(CmcStack, { spacing: "none" }, {
            default: _withCtx(() => [
              _createVNode(CmcLabel, _mergeProps(props, { "as-header": "" }), null, 16),
              (_ctx.categories && _ctx.categories.length > 1)
                ? (_openBlock(), _createBlock(CmcBlock, {
                    key: 0,
                    "padding-top": "3xs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcTabs, {
                        tabs: _ctx.categories === undefined ? [] : _ctx.categories,
                        activeTab: selectedCategory.value,
                        onChange: changeTab
                      }, null, 8, ["tabs", "activeTab"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(CmcBlock, { "padding-top": "xl" }, {
                default: _withCtx(() => [
                  _createVNode(CmcList, {
                    layout: _ctx.layout,
                    "with-input": "radio",
                    disabled: _ctx.disabled,
                    "model-value": _ctx.modelValue,
                    paginable: shouldBePaginated.value,
                    "with-number-of-records": actualOptions.value.length,
                    onPageSelected: selectPage,
                    onPageSizeSelected: selectPageSize,
                    "onUpdate:modelValue": selectRow
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcListHeader, { asHighlight: "" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                            return (_openBlock(), _createBlock(CmcListCol, {
                              key: header.label
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcTitle, _mergeProps({ ref_for: true }, header, {
                                  title: header.label,
                                  "with-tooltip": header.withTooltip,
                                  "with-tooltip-i18n": header.withTooltipI18n,
                                  "with-tooltip-placement": "right",
                                  heading: "h5",
                                  "with-bold": ""
                                }), null, 16, ["title", "with-tooltip", "with-tooltip-i18n"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsToDisplay.value, (opt) => {
                        return (_openBlock(), _createBlock(CmcListRow, {
                          key: opt.value,
                          value: opt.value
                        }, {
                          default: _withCtx(() => [
                            _createVNode(CmcListCol, null, {
                              default: _withCtx(() => [
                                _createVNode(CmcText, _mergeProps({
                                  class: "cmc-clickable-text",
                                  ref_for: true
                                }, opt, {
                                  text: opt.label,
                                  "with-tooltip-placement": opt.withTooltipKeyValue? 'right' : 'auto',
                                  onClick: ($event: any) => (selectRow(opt.value))
                                }), _createSlots({
                                  lhs: _withCtx(() => [
                                    (opt.imgUrl && _ctx.layout[0].asColType === 'text-with-icon')
                                      ? (_openBlock(), _createBlock(CmcIcon, {
                                          key: 0,
                                          icon: opt.imgUrl,
                                          size: "l",
                                          img: ""
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, [
                                  (opt.withTooltipKeyValue && Object.keys(opt.withTooltipKeyValue).length > 0)
                                    ? {
                                        name: "tooltip",
                                        fn: _withCtx(() => [
                                          _createElementVNode("div", null, [
                                            _createVNode(CmcKeyValue, {
                                              "key-values": opt.withTooltipKeyValue,
                                              "with-narrow": ""
                                            }, null, 8, ["key-values"])
                                          ])
                                        ]),
                                        key: "0"
                                      }
                                    : undefined
                                ]), 1040, ["text", "with-tooltip-placement", "onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opt.configs, (conf, idx) => {
                              return (_openBlock(), _createBlock(CmcListCol, {
                                key: conf.label,
                                "as-list-select-item-without-label": conf.options.length > 1 && isSelectWithoutLabel(conf.label)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(CmcBlock, {
                                    "padding-horizontal": conf.options.length > 1 ? 'l' : 's',
                                    "padding-right": conf.options.length > 1 ? 'none' : 's',
                                    "padding-left": _ctx.layout[idx + 1].asColType !== 'number' ? 'none' : undefined,
                                    "padding-vertical": "none"
                                  }, {
                                    default: _withCtx(() => [
                                      (conf.options.length > 1)
                                        ? (_openBlock(), _createBlock(_component_cmc_align, {
                                            key: 0,
                                            "at-horizontal-center": _ctx.layout[idx + 1].asColType === 'number'
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", null, [
                                                _createVNode(CmcSelect, _mergeProps({ ref_for: true }, conf, {
                                                  modelValue: _unref(allConfigs)[opt.value][conf.key],
                                                  "onUpdate:modelValue": [($event: any) => ((_unref(allConfigs)[opt.value][conf.key]) = $event), updateSelectValue],
                                                  "allow-empty": false,
                                                  "as-number": _ctx.layout[idx + 1].asColType === 'number',
                                                  "inherit-read-only": false,
                                                  disabled: _ctx.disabled || opt.value !== _ctx.modelValue
                                                }), null, 16, ["modelValue", "onUpdate:modelValue", "as-number", "disabled"])
                                              ])
                                            ]),
                                            _: 2
                                          }, 1032, ["at-horizontal-center"]))
                                        : (_openBlock(), _createBlock(CmcText, _mergeProps({
                                            key: 1,
                                            ref_for: true
                                          }, conf.options[0], {
                                            text: conf.options[0].label,
                                            "with-i18n": conf.options[0].withLabelI18n
                                          }), null, 16, ["text", "with-i18n"]))
                                    ]),
                                    _: 2
                                  }, 1032, ["padding-horizontal", "padding-right", "padding-left"])
                                ]),
                                _: 2
                              }, 1032, ["as-list-select-item-without-label"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["layout", "disabled", "model-value", "paginable", "with-number-of-records"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["id", "label", "model-value", "read-only", "inherit-read-only", "hide"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(readOnlyConfig.value, (roc) => {
        return (_openBlock(), _createBlock(CmcReadOnly, {
          key: roc.label,
          label: roc.label,
          "with-label-i18n": roc.withLabelI18n,
          "model-value": roc.value,
          "read-only": _ctx.readOnly,
          "inherit-read-only": _ctx.inheritReadOnly
        }, null, 8, ["label", "with-label-i18n", "model-value", "read-only", "inherit-read-only"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})