import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "cmc-drawer"
}

import { Ref, defineComponent, inject } from 'vue';
import CmcDimmed from './CmcDimmed.vue';
import CmcCard from '../display/CmcCard.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import { DrawerProps } from './useDrawer';


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcDrawer',
  setup(__props) {

defineComponent({
  CmcDimmed,
  CmcCard,
  CmcStack,
  CmcDivider,
})

const { opened, props, component, close } = inject('cmc-drawer') as { component: Ref<any>, props: Ref<DrawerProps>, opened: Ref<boolean>, close: () => void } 

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_unref(opened))
      ? (_openBlock(), _createBlock(CmcDimmed, {
          key: 0,
          onDblclick: _unref(close)
        }, null, 8, ["onDblclick"]))
      : _createCommentVNode("", true),
    (_unref(opened))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(CmcStack, null, {
            default: _withCtx(() => [
              _createVNode(CmcCard, { "as-simple": "" }, {
                default: _withCtx(() => [
                  _createVNode(CmcStack, null, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createElementVNode("div", null, "Header", -1)),
                      _createVNode(CmcDivider)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component)), _mergeProps(_unref(props), { close: _unref(close) }), null, 16, ["close"]))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})