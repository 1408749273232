<template>
  <div
    :class="[
      'cmc-sensitive-text-input-wrapper',
      {'cmc-sensitive-text-input-disabled': inputDisabled}
    ]"
  >
    <cmc-text-input
      :type="sensitive ? 'password' : 'text'"
      :modelValue="actualValue"
      :label="label"
      :with-label-i18n="withLabelI18n"
      :description="description"
      :with-description-i18n="withDescriptionI18n"
      :with-warning-tooltip="withWarningTooltip"
      :with-warning-tooltip-i18n="withWarningTooltipI18n"
      :placeholder="adaptedPlaceholder"
      :with-placeholder-i18n="withPlaceholderI18n"
      :with-tooltip="withTooltip"
      :with-tooltip-i18n="withTooltipI18n"
      :read-only="readOnly"
      :inherit-read-only="inheritReadOnly"
      :with-error-text="withErrorText"
      :with-error-text-i18n="withErrorTextI18n"
      :disabled="inputDisabled"
      hide-error-status-icon
      @update:model-value="emit('update:modelValue', $event)"
      @click="emit('click', $event)"
      @focus="emit('focus', $event)"
      @blur="emit('blur', $event)"
    >
      <template #rhs>
        <cmc-icon
          v-if="showEditIcon"
          class="rhs-icon"
          icon="edit"
          size="m"
          svg
          :with-clickable="!disabled"
          @click="edit"
        ></cmc-icon>
        <cmc-icon
          v-else-if="!sensitive && showEyeIcon"
          class="rhs-icon"
          icon="eye"
          size="m"
          svg
          with-clickable
          @click="reveal"
        ></cmc-icon>
        <cmc-icon
          v-else-if="sensitive && showEyeIcon"
          class="rhs-icon"
          icon="eye-slash"
          size="m"
          svg
          with-clickable
          @click="reveal"
        ></cmc-icon>
      </template> 
    </cmc-text-input>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useI18n } from 'vue-i18n';
import CmcTextInput from "../inputs/CmcTextInput.vue";

defineComponent({
  CmcTextInput
})

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Value of the input
   */
  modelValue?: string;

  /**
   * Label on top of the input
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Set a placeholder in the input.
   */
  placeholder?: string;

  /**
   * True if the placeholder is an i18n label.
   */
  withPlaceholderI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Make the input read-only.
   */
  readOnly?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Will show an error text under the input.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Disable the input, this will also disabled the edit button
   */
  disabled?: boolean;

  /**
   * If true, the user will not be allowed to view the initial modelValue the component is rendered with.
   * Defaults to true.
   */
  obscureInitialValue?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  inheritReadOnly: true,
  obscureInitialValue: true,
})

const emit = defineEmits<{
  /**
   * Emitted when input was changed.
   * @arg text value
   */
  (event: 'update:modelValue', str: string): void

  /**
   * On click of the input
   */
  (event: 'click', e: Event): void

  /**
   * On focus of the input
   */
   (event: 'focus', e: Event): void

  /**
   * On blur of the input
   */
   (event: 'blur', e: Event): void
}>()

const hadInitialValue = ref(!!props.modelValue);
const sensitive = ref(true);
const editing = ref(!props.modelValue || !props.obscureInitialValue);

const { t } = useI18n();
const actualValue = computed(() => {
  if (props.obscureInitialValue && hadInitialValue.value && !editing.value) {
    return '[' + t('redacted') + ']';
  }
  return props.modelValue;
})
const adaptedPlaceholder = computed(() => {
  if (props.placeholder) {
    return props.withPlaceholderI18n ? t(props.placeholder) : props.placeholder;
  }
  if (editing.value && hadInitialValue.value) {
    return t('input_new_value');
  }
  return undefined;
})
const inputDisabled = computed(() => {
  return props.disabled || !editing.value;
})
const showEditIcon = computed(() => {
  return !editing.value && !props.disabled;
})
const showEyeIcon = computed(() => {
  return editing.value || !props.obscureInitialValue;
})

const edit = () => {
  editing.value = true;
  if (props.obscureInitialValue) {
    emit('update:modelValue', '');
  }
}
const reveal = () => {
  sensitive.value = !sensitive.value;
}
</script>

<style scoped lang="scss">
.cmc-sensitive-text-input-wrapper {
  .rhs-icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
  }

  &.cmc-sensitive-text-input-disabled {
    :deep(input) {
      color: var(--ng-text-description) !important;
    }
  }
}
</style>