<!-- eslint-disable vue/no-v-html -->
<template>
  <div 
    class="form-row" 
    :class="{ error: !!error, 'remove-row-padding': !addPadding }"
  >
    <div class="label-row">
      <div 
        v-if="label" 
        :id="labelId"
        class="label fullsize" 
      >
        <span :class="{'capitalize-text': capitalizeLabel}">{{ effectiveLabel }}</span>
        <span 
          v-if="optional" 
          class="optional"
        >({{ $t('optional') }})</span>
        <slot name="titleInfo"></slot>
        <base-tooltip
          v-if="tooltip"
          toggleOnFocus 
          :message="effectiveTooltip"
          overflow
          @click.prevent
        >
          <svg-icon 
            class="info-icon" 
            icon="information" 
          />
        </base-tooltip>
      </div>
      <slot name="titleRight"></slot>
    </div>
    <div 
      v-if="descriptionLabelValue && (descriptionLabel || {}).isHtml" 
      class="description" 
      v-html="effectiveDescriptionLabel"
    ></div>
    <div 
      v-else-if="descriptionLabelValue" 
      :id="descriptionLabel"
      class="description" 
    >
      {{ effectiveDescriptionLabel }}
    </div>
    <div :class="{ horizontal, vertical }">
      <slot></slot>
    </div>
    <div 
      v-if="error" 
      class="error-label red"
      v-html="formattedError"
    ></div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'FormRow',
  props: {
    label: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    interpolation: {
      type: Object,
      default: null,
      required: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    descriptionLabel: {
      type: [String, Object],
    },
    error: {
      type: String,
    },
    direction: {
      type: String,
      validator: d => ['horizontal', 'vertical'].includes(d),
      default: 'vertical',
    },
    addPadding: {
      type: Boolean,
      default: true,
    },
    capitalizeLabel: {
      type: Boolean,
    }
  },
  data() {
    return {
      labelId: `label-${uuidv4()}`,
      observer: null,
    };
  },
  computed: {
    descriptionLabelValue() {
      if (typeof this.descriptionLabel === 'object') {
        return this.descriptionLabel.label;
      }
      return this.descriptionLabel;
    },
    horizontal() {
      return this.direction === 'horizontal';
    },
    vertical() {
      return this.direction === 'vertical';
    },
    effectiveLabel() {
      return this.interpolation ? this.$t(this.label, this.interpolation) : this.$t(this.label);
    },
    effectiveTooltip() {
      return this.interpolation ? this.$t(this.tooltip, this.interpolation) : this.$t(this.tooltip);
    },
    effectiveDescriptionLabel() {
      return this.interpolation ?
        this.$t(this.descriptionLabelValue, this.interpolation)
        : this.$t(this.descriptionLabelValue);
    },
    formattedError() {
      return this.error?.replaceAll("\n", "<br>");
    }
  },
  mounted() {
    this.addLabelledByToSlot();
    const observer = new MutationObserver(this.addLabelledByToSlot);
    observer.observe(this.$el, {
      childList: true,
      subtree: true,
    });
    this.observer = observer;
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    getRowControl() {
      return [
        ...this.$el.querySelectorAll('input'),
        ...this.$el.querySelectorAll('div.select'),
        ...this.$el.querySelectorAll('div.multiselect'),
        ...this.$el.querySelectorAll('div.radio-group'),
        ...this.$el.querySelectorAll('div.base-checkboxes'),
        ...this.$el.querySelectorAll('div.toggle'),
        ...this.$el.querySelectorAll('textarea'),
        ...this.$el.querySelectorAll('div.tile-select'),
        ...this.$el.querySelectorAll('div.base-slider'),
      ];
    },
    addLabelledByToSlot() {
      const controls = this.getRowControl();
      controls.forEach((c) => {
        if (!c.getAttribute('aria-labelledby') && !c.getAttribute('aria-label')) {
          c.setAttribute('aria-labelledby', this.labelId);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/styles/variables.scss';

.label-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: variables.$max-form-width;
}

.info-icon {
  margin-left: 0.25rem;
  height: 0.875rem;
  width: 0.875rem;
  fill: var(--text);
}

.base-tooltip {
  padding-right: 0.5rem;
}

.fullsize {
  width: 100%;
  display: flex;
  flex-direction: row;
  .optional {
    padding-left: 0.3rem;
    align-self: center;
  }
}

.form-row {
  padding-bottom: 0.5rem;
  text-align: left;
}

.description {
  margin-top: -0.3rem;
}

.horizontal {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.vertical {
  width: 100%;
}

.error-label {
  margin-top: 0.3rem;
  font-size: 0.75rem;
  text-align: left;
}
.capitalize-text {
  text-transform: capitalize;
}

// remove padding on composite elements that have a single row
.remove-row-padding {
  .form-row {
    padding-bottom: 0rem;
  }
}

:deep(.form-wrapper) {
  width: 100%;
}
</style>
