<template>
  <cmc-read-only
    :id="id ? `cmc-select-${id}` : undefined"
    :label="label"
    :with-label-i18n="withLabelI18n"
    :model-value="readOnlyValue + '' || t(withReadOnlyEmptyLabel ? withReadOnlyEmptyLabel : 'none')"
    :read-only="readOnly"
    :inherit-read-only="inheritReadOnly"
    :with-error-text="withErrorText"
    :with-error-text-i18n="withErrorTextI18n"
  >
    <cmc-stack
      :class="['cmc-select', {
        'cmc-select-no-options': !actualOptions.length && !disabled,
        'cmc-select-as-number': asNumber,
        'cmc-select-as-tag': asTag,
        'cmc-select-opened': opened,
        'cmc-select-has-value': !!actualValue,
        'cmc-select-has-many-options': actualOptions.length > 1,
        'cmc-select-as-multi': asMulti,
        'cmc-select-has-group': hasGroup,
      }]"
      spacing="3xs"
    >
      <cmc-label
        v-bind="props"
        as-header
      />
      <div
        class="cmc-select-wrapper"
        @keydown.enter="onTag"
      >
        <vue-multiselect
          ref="elemRef"
          label="label"
          :options="filteredOptions"
          :model-value="actualValue"
          :placeholder="$t('search')"
          :searchable="(!actualValue || asMulti) && searchable"
          :group-values="hasGroup ? 'options': undefined"
          :group-label="hasGroup ? 'label' : undefined"
          track-by="value"
          :multiple="asMulti"
          :taggable="asMulti && allowNew"
          :show-labels="false"
          :allow-empty="allowEmpty"
          :disabled="disabled"
          :internal-search="false"
          :closeOnSelect="!asMulti"
          @search-change="search = $event"
          @update:model-value="onSelect"
          @open="trackOpen"
          @close="trackClose"
        >
          <template #beforeList>
            <div class="before-list">
              <!--
                @slot Content to put at the beginning of the list of options.
              -->
              <slot name="beforeList"></slot>
              <cmc-text-input
                v-if="actualValue && searchable"
                v-model="search"
                as-search
                :inherit-read-only="false"
                @click="onSearchClick"
                @blur="onSearchBlur"
              />
              <cmc-alert
                v-if="search && asMulti && allowNew && !searchValueExists"
                class="cmc-select-press-enter"
                text="Press Enter to create a new tag with this name"
                :with-outline="false"
              />
            </div>
          </template>
          <template #singleLabel>
            <cmc-pair stretch-lhs>
              <cmc-group spacing="none">
                <!-- The above group with spacing 'none' allows correct spacing between a description and a tooltip associated with the text before the description, 
                 since a tooltip always has encapsulated space to its left when the text is empty-->
                <cmc-group
                  with-vertical-align="top"
                  spacing="3xs"
                >
                  <cmc-icon
                    v-if="actualValue && actualValue.isServiceConnection"
                    :icon="actualValue.type"
                    size="m"
                    as-plugin-icon
                    svg
                    class="cmc-select-option-with-icon-margin"
                  />
                  <cmc-icon
                    v-else-if="actualValue && actualValue.hasIcon"
                    :icon="actualValue.icon"
                    size="m"
                    svg
                    class="cmc-select-option-with-icon-margin"
                  />
                  <cmc-group
                    :with-horizontal-align="asNumber ? 'center' : undefined"
                    with-vertical-align="center"
                    spacing="3xs"
                  >
                    <cmc-text
                      v-if="actualValue"
                      :class="[actualValue.withTooltip && !actualValue.detail ? 'actual-value-with-tooltip' : '']"
                      :text="actualValue.label"
                      :with-i18n="actualValue.withLabelI18n"
                      size="l"
                      without-wrap
                      :with-tooltip="actualValue.detail ? '' : actualValue.withTooltip"
                      :with-tooltip-i18n="actualValue.withTooltipI18n ?? !!actualValue.withTooltip"
                      with-tooltip-placement="bottom"
                      :with-tooltip-html="actualValue.withTooltipHtml"
                    />
                    <cmc-text
                      v-if="actualValue && actualValue.detail"
                      :with-i18n="actualValue.withDetailI18n"
                      :text="actualValue.withDetailI18n ? t(actualValue.detail, actualValue.interpolation) : actualValue.detail"
                      size="m"
                      as-description
                    />
                  </cmc-group>
                </cmc-group>
                <!-- CmcText below has empty text prop because it is a solo tooltip. This allows [text1][description][tooltip associated with text1] pattern
                 However, it can't be a member of a group unless spacing=none because CmcText encapsulates the right amount of space to the left of the tooltip already. -->
                <cmc-text
                  v-if="actualValue && actualValue.withTooltip && actualValue.detail"
                  class="actual-value-with-tooltip"
                  text=""
                  :with-tooltip="actualValue.withTooltip"
                  :with-tooltip-i18n="actualValue.withTooltipI18n ?? !!actualValue.withTooltip"
                  with-tooltip-placement="bottom"
                  :with-tooltip-html="actualValue.withTooltipHtml"
                />
              </cmc-group>
              <!--
                @slot Add something to the right-hand side of the select
              -->
              <slot
                v-if="actualValue"
                name="rhs"
                :option="actualValue"
              />
            </cmc-pair>
          </template>
          <template #option="{ option }">
            <cmc-block
              :class="['option', !option.$isLabel ? 'cmc-select-option' : '', {'option-as-disabled': option.isDisabled} ]"
            >
              <cmc-align
                at-vertical-center
                :at-horizontal-center="asNumber"
              >
                <template v-if="option.$isLabel">
                  <cmc-stack
                    :class="['cmc-select-group-label', {'empty': !option.$groupLabel}]"
                    spacing="none"
                  >
                    <cmc-divider class="group-separator" />
                    <cmc-title
                      v-if="option.$groupLabel"
                      :title="option.$groupLabel"
                      with-i18n
                      heading="h5"
                    />
                    <cmc-text
                      v-if="option.detail && !option.hideDetailInOptions"
                      :with-i18n="option.withDetailI18n"
                      :text="option.withDetailI18n ? t(option.detail, option.interpolation): option.detail"
                      size="m"
                      as-description
                    />
                    <!-- Text is required for cmc-text, but it's intentionally left blank to leverage the tooltip functionality -->
                    <cmc-text
                      v-if="option.withTooltip"
                      text=""
                      :with-tooltip="option.withTooltip"
                      :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                    />
                  </cmc-stack>
                </template>
                <template v-else-if="option.isServiceConnection">
                  <cmc-service-connection
                    :name="option.label"
                    :type="option.type"
                    :as-select-option="true"
                    :as-category-title="option.isCategoryTitle"
                    :with-i18n="option.withLabelI18n"
                    :with-tooltip="option.withTooltip"
                    :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                    :with-tooltip-html="option.withTooltipHtml"
                  />
                </template>
                <template v-else-if="option.hasIcon || atLeastOneOptionHasIcon">
                  <cmc-pair
                    :class="['cmc-select-option-with-icon']"
                    stretch-rhs
                    spacing="3xs"
                  >
                    <cmc-icon
                      v-if="option.hasIcon"
                      :icon="option.icon"
                      size="m"
                      svg
                      class="cmc-select-option-with-icon-margin"
                    />
                    <cmc-icon
                      v-else-if="atLeastOneOptionHasIcon"
                      icon="no-image"
                      size="m"
                      svg
                      class="cmc-select-option-with-icon-margin"
                    />
                    <cmc-text
                      :text="option.label"
                      :with-i18n="option.withLabelI18n"
                      :with-tooltip="option.withTooltip"
                      :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                      size="l"
                    />
                  </cmc-pair>
                </template>
                <template v-else-if="asMulti">
                  <cmc-tag
                    v-if="asTag"
                    :value="option.value"
                    :text="option.label"
                    :with-i18n="option.withLabelI18n"
                    :color="option.color"
                    :with-tooltip="option.withTooltip"
                    :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                  />
                  <cmc-group 
                    v-else
                    class="cmc-multiselect__options-label-container"
                    spacing="none"
                  >
                    <cmc-block 
                      v-if="option.groupLabel"
                      paddingTop="4xs"
                      paddingRight="3xs"
                    >
                      <cmc-text
                        size="m"
                        :text="option.groupLabel"
                        :with-i18n="option.withLabelI18n"
                        :with-tooltip="option.withTooltip"
                        :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                        :asDescription="!!option.groupLabel"
                      />
                    </cmc-block>

                    <cmc-block>
                      <cmc-text
                        size="l"
                        :text="option.label"
                        :with-i18n="option.withLabelI18n"
                        :with-tooltip="option.withTooltip"
                        :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                      />
                    </cmc-block>
                  </cmc-group>
                </template>
                <cmc-pair
                  v-else
                  stretch-lhs
                >
                  <cmc-group
                    :with-horizontal-align="asNumber ? 'center' : undefined"
                    with-vertical-align="center"
                    spacing="4xs"
                  >
                    <cmc-text
                      size="l"
                      :text="option.label"
                      :with-i18n="option.withLabelI18n"
                    />
                    <div :class="{ 'cmc-text-details': asNumber }">
                      <cmc-text
                        v-if="option.detail && !option.hideDetailInOptions"
                        :with-i18n="option.withDetailI18n"
                        :text="option.withDetailI18n ? t(option.detail, option.interpolation) : option.detail"
                        size="m"
                        as-description
                      />
                    </div>
                    <!-- Text is required for cmc-text, but it's intentionally left blank to leverage the tooltip functionality -->
                    <cmc-text
                      v-if="option.withTooltip"
                      text=""
                      :with-tooltip="option.withTooltip"
                      :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                    />
                    <!--
                      @slot Content on the right-hand size of the options of the select
                    -->
                    <slot
                      name="rhs"
                      :option="option"
                    />
                  </cmc-group>
                </cmc-pair>
              </cmc-align>
            </cmc-block>
          </template>
          <template #placeholder>
            <cmc-text
              v-if="withPlaceholder && (!actualValue || (Array.isArray(actualValue) && !actualValue.length)) && (actualOptions.length || disabled)"
              :text="withPlaceholder"
              :with-i18n="withPlaceholderI18n"
              size="l"
              as-description
            />
            <cmc-alert
              v-else-if="!actualOptions.length && !disabled"
              :text="withEmptyLabel || $t('no_options')"
              :with-i18n="withEmptyLabelI18n"
              color="blue"
              :with-outline="false"
            />
            <div v-else />
          </template>
          <template #caret>
            <div v-if="hasValue && allowEmpty">
              <div class="clear">
                <div @mousedown.stop="doClear">
                  <cmc-icon
                    icon="times-bold"
                    size="xs"
                    svg
                  />
                </div>
                <cmc-icon
                  class="caret"
                  icon="triangle-down"
                  size="xs"
                  svg
                />
              </div>
            </div>
          </template>
          <template #tag="{option,remove}">
            <cmc-tag
              v-if="asTag"
              :value="option.value"
              :text="option.label"
              :with-i18n="option.withLabelI18n"
              with-remove
              :color="option.color"
              @remove="remove(option)"
            />
            <cmc-group 
              v-else 
              class="cmc-multiselect__options-label-container"
              spacing="none"
            >
              <cmc-block 
                v-if="option.groupLabel"
                paddingTop="4xs"
              >
                <cmc-text
                  size="m"
                  :text="option.groupLabel"
                  :with-i18n="option.withLabelI18n"
                  :with-tooltip="option.withTooltip"
                  :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                  :asDescription="!!option.groupLabel"
                />
              </cmc-block>

              <cmc-block>
                <cmc-text
                  size="l"
                  :text="option.label"
                  :with-i18n="option.withLabelI18n"
                  :with-tooltip="option.withTooltip"
                  :with-tooltip-i18n="option.withTooltipI18n ?? !!option.withTooltip"
                />
              </cmc-block>
            </cmc-group>
          </template>
          <template #noOptions>
            <cmc-alert
              v-if="search"
              :text="'search_results_empty'"
              with-i18n
              :with-outline="false"
              as-warning
            />
          </template>
          <template #noResult>
            <cmc-alert
              :text="'search_results_empty'"
              with-i18n
              :with-outline="false"
              as-warning
            />
          </template>
          <template #afterList>
            <div class="after-list">
              <cmc-block
                v-if="permittedActions"
                class="cmc-select-after-list"
                padding-horizontal="s"
                padding-vertical="2xs"
              >
                <cmc-stack spacing="4xs">
                  <cmc-text
                    v-for="action in permittedActions"
                    :key="action.value"
                    :class="!action.isDisabled ? 'select-actions' : 'select-actions-disabled'"
                    :text="action.label"
                    :with-tooltip="action.withTooltip"
                    with-i18n
                    :with-tooltip-i18n="action.withTooltipI18n ?? !!action.withTooltip"
                    tooltip-as-status-report
                    as-clickable
                    size="l"
                    @click="doAction(action)"
                  />
                </cmc-stack>
              </cmc-block>
              <cmc-block
                v-if="asMulti"
                class="cmc-select-after-list"
                padding-horizontal="s"
                padding-vertical="2xs"
              >
                <cmc-pair stretch-lhs>
                  <cmc-text
                    text="clear"
                    with-i18n
                    as-clickable
                    size="m"
                    @click="doClear"
                  />
                </cmc-pair>
              </cmc-block>
            </div>
          </template>
        </vue-multiselect>
        <cmc-icon
          v-if="(!actualValue || asMulti) && searchable"
          icon="search"
          class="cmc-search-icon-lhs"
          svg
        />
      </div>
      <cmc-text
        v-if="withErrorText"
        :text="withErrorText"
        :with-i18n="withErrorTextI18n"
        as-error
        size="m"
      />
    </cmc-stack>
  </cmc-read-only>
</template>

<script setup lang="ts">
import { defineComponent, computed, ref } from 'vue';
import VueMultiselect from 'vue-multiselect';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import CmcAlert from '../display/CmcAlert.vue';
import CmcTag from '../display/CmcTag.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcTextInput from './CmcTextInput.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcServiceConnection from '../display/CmcServiceConnection.vue';
import type { SelectOption, SingleSelectOption, GroupedSelectOption, SelectAction } from './types';

import 'vue-multiselect/dist/vue-multiselect.css';
import { useI18n } from 'vue-i18n';
import authz from '@/authz';

const elemRef = ref()

defineComponent({
  VueMultiselect,
  CmcText,
  CmcStack,
  CmcBlock,
  CmcPair,
  CmcGroup,
  CmcReadOnly,
  CmcIcon,
  CmcAlert,
  CmcTag,
  CmcDivider,
  CmcTextInput,
  CmcAlign,
})

export type SelectProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Label of the select.
   */
  label?: string;
  /**
   * Is the label i18n.
   */
  withLabelI18n?: boolean;

  /**
   * Description of the select.
   */
  description?: string;

  /**
   * Is the description i18n.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Model value of the select
   */
  modelValue?: any;

  /**
   * Options of the select.
   */
  options: SelectOption[];

  /**
   * Actions of the select.
   */
  actions?: SelectAction[];

  /**
   * Add a placeholder to the select
   */
  withPlaceholder?: string

  /**
   * The placeholder is an i18n label key.
   */
  withPlaceholderI18n?: boolean

  /**
   * Label to show when empty select.
   */
  withEmptyLabel?: string

  /**
   * The empty label is an i18n label key.
   */
  withEmptyLabelI18n?: boolean

  /**
   * Whether there is a custom label to display when no option is picked in a Step-based form
   */
  withReadOnlyEmptyLabel?: string;

  /**
   * Tooltip next to the label
   */
  withTooltip?: string;
  /**
   * Is the tooltip i18n.
   */
  withTooltipI18n?: boolean;

  /**
   * Error text under the select.
   */
  withErrorText?: string;

  /**
   * Error text is i18n label.
   */
  withErrorTextI18n?: boolean;

  /**
   * Set the select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Is allowed to be empty
   */
  allowEmpty?: boolean;

  /**
   * Allow new options to be added that are not in the list. Only works with multiselect.
   */
  allowNew?: boolean;

  /**
   * Disable the select.
   */
  disabled?: boolean;

  /**
   * Support multiselect.
   */
  asMulti?: boolean;

  /**
   * Display as tags
   */
  asTag?: boolean;

  /**
   * Select for numbers.
   */
  asNumber?: boolean;
  asOptional?: boolean;
}

const props = withDefaults(defineProps<SelectProps>(), {
  allowEmpty: true,
  inheritReadOnly: true,
  asOptional: false
})

const hasGroup = computed(() => {
  return !!actualOptions.value.find(o => !!(o as GroupedSelectOption).options)
})

const actualValue = computed(() => {
  if (props.modelValue === null || props.modelValue === undefined) {
    return undefined;
  }

  const allOptions = getAllFlattenedOptions(props.options);
  const optionsMap = createOptionsMap(allOptions);

  const selectedOptions = props.asMulti 
    ? handleMultiSelect(props.modelValue, optionsMap)
    : handleSingleSelect(props.modelValue, optionsMap);
  
  if (Array.isArray(selectedOptions)) {
    return sortOptions(selectedOptions);
  }

  return selectedOptions;
});

function sortOptions(options: SingleSelectOption[]): SingleSelectOption[] {
  return options.sort((a, b) => {
    // First sort by group label
    const groupComparison = (a.groupLabel || '').localeCompare(b.groupLabel || '');
    if (groupComparison !== 0) {
      return groupComparison;
    }
    // Then sort by option label within the same group
    return a.label.localeCompare(b.label);
  });
}

function getAllFlattenedOptions(options: (GroupedSelectOption | SingleSelectOption)[]): SingleSelectOption[] {
  const groupedOptions = options
    .filter((opt): opt is GroupedSelectOption => 'options' in opt && Array.isArray(opt.options))
    .flatMap(group => group.options!.map(opt => ({
      ...opt,
      groupLabel: group.label
    })));

  const singleOptions = options
    .filter((opt): opt is SingleSelectOption => !('options' in opt))
    .map(opt => ({
      ...opt,
      groupLabel: ''
    }));

  return [...groupedOptions, ...singleOptions];
}

function createOptionsMap(options: SingleSelectOption[]): Record<string, SingleSelectOption> {
  return options.reduce((acc, option) => {
    acc[JSON.stringify(option.value)] = option;
    return acc;
  }, {} as Record<string, SingleSelectOption>);
}

function handleMultiSelect(values: any[], optionsMap: Record<string, SingleSelectOption>): SingleSelectOption[] {
  return (values || []).map(value => {
    const option = optionsMap[JSON.stringify(value)] || {
      value,
      label: value,
      groupLabel: ''
    };

    return option;
  });
}

function handleSingleSelect(value: any, optionsMap: Record<string, SingleSelectOption>): SingleSelectOption | null {
  const selectedOption = Object.values(optionsMap).find(opt => opt.value === value);
  
  if (!selectedOption) {
    return null;
  }

  return {
    ...selectedOption,
    label: selectedOption.label,
  };
}

const { t } = useI18n()

const actualOptions = computed<SelectOption[]>(() => {
  if (props.asMulti) {
    const selected: SingleSelectOption[] = Array.isArray(actualValue.value) ? actualValue.value : []

    const selectedValues = new Set(selected.map(s => s.value))
    if (!selected.length) {
      return props.options
    }
    const emptyGroup: GroupedSelectOption = {
      label: '',
      options: [],
    }

    return [{
      label: t('selected_items'),
      options: selected,
    }, ...props.options.map((opt: SelectOption): GroupedSelectOption | null => {
      if ('options' in opt) {
        const options = opt.options.filter(c => !selectedValues.has(c.value))
        if (!options.length) {
          return null
        }
        return {
          ...opt,
          options,
        }
      } else if (!selectedValues.has(opt.value)) {
        emptyGroup.options.push(opt)
      }
      return null
    }).filter((opt): opt is GroupedSelectOption => !!opt)].concat([emptyGroup])
  }
  return props.options;
})

const permittedActions = computed(() => {
  return props.actions?.filter(a => authz.hasPermission(a.permission));
})

const emit = defineEmits<{
  /**
   * Emitted when option is selected
   */
  (event: 'update:modelValue', value: any): void,

  /**
   * Emitted when action is selected
   */
   (event: 'action', value: any): void,
}>()

const onSelect = (opt: any) => {
  if ((!opt || Object.keys(opt).length === 0) && props.allowEmpty) {
    emit('update:modelValue', undefined)
  } else {
    if (!props.asMulti) {
      opt && emit('update:modelValue', (opt as SingleSelectOption).value);
    } else { 
      emit('update:modelValue', (opt as SingleSelectOption[]).map(o => o.value));
    }
  }
}

if (!props.allowEmpty && !actualValue.value && actualOptions.value.length) {
  const firstVal: SelectOption = actualOptions.value[0];
  if (!props.asMulti) {
    onSelect(firstVal && 'options' in firstVal ? firstVal.options[0] : firstVal)
  } else {
    onSelect([firstVal])
  }
}

const doClose = () => {
  elemRef.value.deactivate()
}

const doClear = () => {
  if (props.asMulti) {
    onSelect([])
  } else {
    onSelect(undefined);
  }
}

const doAction = (action: SelectAction) => {
  if(!action.isDisabled) {
    doClose();
    emit('action', action.value);
  }
}

const search = ref('')

const doFilter = (opt: SingleSelectOption) => {
  if (opt.value === actualValue.value?.value) {
    return undefined;
  }
  const label = opt.withLabelI18n ? t(opt.label) : opt.label;
  return label?.toLowerCase().includes(search.value.toLowerCase())
}

const filteredOptions = computed(() => {
  return actualOptions.value
    .map(opt => {
      if ((opt as GroupedSelectOption).options) {
        const gopt = opt as GroupedSelectOption;
        return {
          ...gopt,
          options: gopt.options.filter(doFilter).filter(o => o),
        }
      }
      if (doFilter(opt as SingleSelectOption)) {
        return opt;
      }
      return null;
    }).filter(opt => opt)
});

const atLeastOneOptionHasIcon = computed(() => {
  return actualOptions.value.some(opt => (opt as SingleSelectOption).hasIcon || (opt as SingleSelectOption).isServiceConnection)
});

const onSearchClick = () => {
  if (!elemRef.value) {
    return
  }
  elemRef.value.$el.querySelector('.before-list input')?.focus()
  setTimeout(() => {
    elemRef.value.isOpen = true;
  })
  elemRef.value.isOpen = true;
}

const onSearchBlur = () => {
  if (!elemRef.value) {
    return
  }
  elemRef.value.deactivate()
}

const opened = ref(false)
const trackOpen = () => {
  opened.value = true;
}
const trackClose = () => {
  opened.value = false;
  search.value = '';
}

const searchable = computed(() => {
  return (!props.asNumber && actualOptions.value.length > 10 && !allNumeric()) || props.asTag;
})

const allNumeric = () => {
  return actualOptions.value.every(o => !isNaN(o.label));
}

const onTag = () => {
  if (props.asMulti && props.allowNew && search.value) {
    if (!new Set(props.modelValue).has(search.value)) {
      emit('update:modelValue', [...props.modelValue, search.value]);
    }
  }
}

const optionsValueSet = computed(() => {
  return new Set(props.options.flatMap((opt) => {
    if ((opt as GroupedSelectOption).options) {
      return (opt as GroupedSelectOption).options.map(o => o.value)
    }
    return [(opt as SingleSelectOption).value]
  }))
})

const searchValueExists = computed(() => {
  return new Set(props.modelValue).has(search.value) || optionsValueSet.value.has(search.value)
})

const readOnlyValue = computed(() => {
  if (!actualValue.value) {
    return '';
  } else if (Array.isArray(actualValue.value)) {
    return actualValue.value.map(v => v.withLabelI18n ? t(v.label) : v.label).join(", ")
  }
  return actualValue.value.withLabelI18n ? t(actualValue.value.label) : actualValue.value.label
})

const hasValue = computed(() => {
  if (actualValue.value && Array.isArray(actualValue.value)) {
    return actualValue.value.length > 0;
  }
  return !!actualValue.value;
})
</script>

<style>
:root {
  --ng-select-border: var(--ng-secondary-border);
  --ng-select-text: var(--ng-text-main);
  --ng-select-hover: var(--ng-secondary-main);
  --ng-select-hover-text: var(--ng-text-main);
  --ng-select-arrow-color: var(--ng-text-main);
  --ng-select-clear-icon: var(--ng-text-main);
}
</style>

<style scoped lang="scss">
.cmc-select {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  .cmc-select-wrapper {
    position: relative;
    .cmc-search-icon-lhs {
      position: absolute;
      left: 0.5rem;
      top: 0.5rem;
      z-index: 51;
      color: var(--ng-text-description);
      display: none;
    }

    :deep(.multiselect__input) {
      margin-top: 0.125rem;
      margin-bottom: 0rem;
      &::placeholder {
        color: var(--ng-text-description);
        opacity: 1; /* Firefox compatability */
      }
    }
  }

  &.cmc-select-opened {
    :deep(.multiselect__input) {
      padding-left: 1.5rem;
    }
    &.cmc-select-as-multi {

      :deep(.multiselect__tags-wrap) {
        display: none;
      }
      :deep(.before-list) {
        .cmc-text-input {
          display: none;
        }
      }
      :deep(.after-list) {
        .cmc-text-input {
          background-color: white;
          color: var(--ng-select-text);

          &:hover {
            background-color: var(--ng-select-hover);
            color: var(--ng-select-hover-text);
          }
        }
      }
    }
    :deep(.multiselect--active.multiselect--above) {
      .multiselect__tags {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    :deep(.multiselect--active:not(.multiselect--above)) {
      .multiselect__tags {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:not(.cmc-select-has-value), &.cmc-select-as-multi {
      .cmc-select-wrapper .cmc-search-icon-lhs {
        display: block;
      }
    }
  }

  &.cmc-select-as-tag {
    :deep(.multiselect) {

      li[role="option"] + li[role="option"] {
        .multiselect__option:not(.multiselect__option--group) {
          margin-top: 0.25rem;
        }
      }
    }
  }

  :deep(.before-list) {
    padding-bottom: 0.5rem;
  }

  :deep(.after-list) {
    padding-top: 0.5rem;
    .cmc-select-after-list {
      border-top: 0.0625rem solid var(--ng-select-border);
    }

    .select-actions {
      &:hover {
        background-color: var(--ng-select-hover);
        color: var(--ng-select-hover-text);
      }
    }

    .select-actions-disabled {
      color: var(--inactive);

      &:hover {
        cursor: default;
      }
    }

    .cmc-text .cmc-text-tooltip-icon {
      color: var(--ng-primary-blue);
    }

  }

  :deep(.cmc-text-input) {
    input {
      border-radius: 0;
      border: 0;
      border-bottom: 0.0625rem solid var(--ng-select-border);
      &:focus{
        outline: none;
      }
    }
  }


  &.cmc-select-as-tag {
    :deep(.multiselect__tags) {
      padding: calc(0.25rem - 0.0625rem) 0.25rem;
    }
  }

  :deep(.multiselect) {
    font-family: 'Roboto', sans-serif;
    max-width: 24rem;
    user-select: none;
    min-height: 2rem;

    .multiselect__single {
      padding-left: 0;
    }

    .cmc-select-press-enter {
      border-bottom: 0.0625rem solid var(--ng-select-border);
      border-radius: 0;
    }

    .multiselect__option--highlight::after {
      display: none;
    }
    .empty {
      margin-bottom: -0.5rem;
    }

    .multiselect__tags {
      &:not(:first-of-type) {
        .cmc-multiselect__options-label-container:before {
          content: ',';
          margin-left: -0.25rem;
          margin-right: 0.1rem;
          margin-top: 0.1rem;
        }
      }
    }

    .cmc-text-details {
      display: flex;
      position: absolute;
      justify-content: flex-start;
      left: 50%;
    }
    .cmc-single-select-details {
      margin-left: 0.5rem;
    }

    .multiselect__content-wrapper {
      border-color: var(--ng-select-border);
    }
    .multiselect__option--group.multiselect__option {
      padding-top: 0rem;
      padding-bottom: 0rem;
      padding-right: 1rem;
      box-sizing: content-box;
    }
    .multiselect__option {
      padding-right: 2rem;
      > .cmc-block {
        flex: 1;
      }
    }
    .multiselect__option:not(.multiselect__option--group) {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }

    &.multiselect--disabled {
      opacity: 1;
    }



    &.multiselect--disabled .multiselect__current, &.multiselect--disabled .multiselect__select {
      background-color: transparent;
    }

    li.multiselect__element:first-of-type {
      .group-separator {
        display: none;
      }
    }
    .group-separator {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
    }

    .group-separator-actions {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
      margin-left: -3rem;
      margin-right: -1rem;
    }
    .multiselect__option--disabled {
      background-color: white !important;
    }
    .multiselect__content-wrapper {
      color: var(--ng-select-text);
    }
    .multiselect__tags-wrap {
      :not(:first-of-type) {
        margin-left: 0.25rem;
      }
    }
    .multiselect__select {
      height: 2rem;
      min-height: 2rem;
    }

    .multiselect__select::before {
      color: var(--ng-select-arrow-color);
      border-radius: 0.188rem;
      border-top-color: var(--ng-select-arrow-color);
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

    .multiselect__option {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-height: 1.5rem;
      display: flex;
      align-items: center;
    }
    .multiselect__option {
      background-color: white;
      color: var(--ng-select-text);

      &:hover {
        background-color: var(--ng-select-hover);
        color: var(--ng-select-hover-text);
      }
    }

    .multiselect__option:has(> div.option-as-disabled) {
      background-color: transparent;
      cursor: default;

      .cmc-text {
        color: var(--inactive);
      }
    }

    .multiselect__option:has(> div.option-as-disabled):active {
      pointer-events: none;
    }
  }

  :deep(.multiselect--disabled) {
    .multiselect__select, .clear {
      display: none;
    }
  }

  &.cmc-select-has-value:not(.cmc-select-has-many-options) {
    :deep(.multiselect--disabled .multiselect__tags) {
      padding-left: 0;
      border: none;
    }
  }

  &.cmc-select-no-options {
    :deep(.multiselect__tags) {
      padding: 0;
    }
    :deep(.multiselect__tags .multiselect__placeholder) {
      color: var(--ng-select-text);
    }

    :deep(.multiselect--active .multiselect__tags .multiselect__placeholder) {
      display: block;
    }

    :deep(.multiselect) {
      .multiselect__option {
        &:hover {
          background-color: inherit;
          cursor: default;
        }
      }
    }
  }

  :deep(.multiselect__tags) {
    font-family: 'Roboto', sans-serif;
    color: var(--ng-select-text);
    border: 0.0625rem solid var(--ng-select-border);
    font-size: 1rem;
    border-radius: 0.1875rem;
    padding: 0.25rem 0.75rem;
    padding-right: 2rem;
    height: 2rem;
    min-height: 2rem;
    box-sizing: border-box;

    .multiselect__tags-wrap {
      display: block;
      max-width: calc(100% - 2rem);
      white-space: nowrap;
      overflow: hidden;
    }
    .multiselect__placeholder {
      color: var(--ng-text-description);
      margin-bottom: 0;
      padding-top: 0;
    }
  }

  :deep(.multiselect__tags .multiselect__placeholder .cmc-alert) {
    height: 1.87rem;
  }

  &.cmc-select-as-number {
    :deep(.multiselect__single) {
      text-align: center;
    }
    :deep(.multiselect__option) {
      justify-content: center;
    }
  }
  .clear {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 1rem;
    top: 50%;
    transform: translateY(-51%);
    color: var(--ng-select-clear-icon);
    cursor: pointer;

    .caret {
      margin: auto auto auto 0.5rem;
      transform: translateX(-0.063rem);
    }
  }

  .cmc-select-group-label {
    width: 100%;
  }

  .cmc-select-option-with-icon {
    flex-wrap: nowrap;
    align-items: top;
  }

  .cmc-select-option-with-icon-margin {
    margin-top:0.21875rem;
  }

  /**
    Remove header that has no label
    Update: not sure what intended use for this was, causes bug
  */
  // &.cmc-select-has-group :deep(#null-0) {
  //   display: none;
  // }
}
</style>
