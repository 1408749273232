import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import useResponsive from './useResponsive';

type Props = {
  onlySmScreen?: boolean;
  onlyMdScreen?: boolean;
  onlyLgScreen?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcResponsive',
  props: {
    onlySmScreen: { type: Boolean },
    onlyMdScreen: { type: Boolean },
    onlyLgScreen: { type: Boolean }
  },
  setup(__props: any) {

const { screenSize } = useResponsive();

const props = __props;

const show = computed(() => {
  if (!props.onlyLgScreen 
    && !props.onlyMdScreen 
    && !props.onlySmScreen) {
    return true;
  }
  return (props.onlySmScreen && screenSize.value === 'sm') ||
         (props.onlyMdScreen && screenSize.value === 'md') ||
         (props.onlyLgScreen && screenSize.value === 'lg');
});

return (_ctx: any,_cache: any) => {
  return (show.value)
    ? _renderSlot(_ctx.$slots, "default", {
        key: 0,
        screenSize: _unref(screenSize)
      })
    : _createCommentVNode("", true)
}
}

})