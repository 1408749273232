<template>
  <div
    :id="id"
    :class="[
      'cmc-group', 
      { 
        'cmc-group-take-full-width': takeFullWidth, 
        'cmc-group-reversed': reversed,
      }, 
      `cmc-group-spacing-${spacing}`, 
      { 
        [`cmc-group-vertical-align-${withVerticalAlign}`] : !takeFullHeight,
        [`cmc-group-horizontal-align-${withHorizontalAlign}`]: !takeFullHeight,
        [`cmc-group-align-baseline`]: withBaselineAlign
      },
    ]"
    @click="emit('click', $event)"
  >
    <!--
      @slot Content to be grouped horizontally
    -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { Size } from '../sizes';

type VerticalAlign = 'top' | 'center' | 'bottom'
type HorizontalAlign = 'left' | 'center' | 'right'

export type GroupProps = {
  /**
   * HTML element id
   */
  id?: string;
  /**
   * Spacing between group elements.
   */
  spacing?: Size;
  /**
   * True if the group should take the full width. Each element will have equal width.
   */
  takeFullWidth?: boolean
  /**
   * True if the group children should take full height.
   */
  takeFullHeight?: boolean

  /**
   * Align the group vertically.
   */
  withVerticalAlign?: VerticalAlign
  /**
   * Align the group horizontally.
   */
  withHorizontalAlign?: HorizontalAlign,

  /**
   * Reverse the ordering of child elements
   */
  reversed?: boolean,

  /**
   * If true, all items are aligned such that their flex container baselines align.
   */
  withBaselineAlign?: boolean
}

withDefaults(defineProps<GroupProps>(), {
  spacing: 'l',
  takeFullWidth: false,
  withVerticalAlign: 'top',
  withHorizontalAlign: 'left',
});

const emit = defineEmits<{
  /**
   * Emitted when group was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: Event): void
}>()
</script>

<style lang="scss">
@import "../sizes";

:root {
  @include generate-size-variables('cmc-group-spacing');
}
</style>

<style scoped lang="scss">
@import "../sizes";

.cmc-group {
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  flex-wrap: wrap;
  @include generate-size-classes('cmc-group-spacing', 'gap', true);

  &.cmc-group-reversed {
    flex-direction: row-reverse;
  }
  &.cmc-group-take-full-width {
    display: flex;
    :deep(> *) {
      flex: 1;
      flex-grow: 1;
    }
  }
  &.cmc-group-take-full-height {
    align-items: stretch;
  }

  &.cmc-group-vertical-align-center {
    align-items: center;
  }

  &.cmc-group-vertical-align-bottom {
    align-items: flex-end;
  }

  &.cmc-group-vertical-align-top {
    align-items: flex-start;
  }

  &.cmc-group-horizontal-align-center {
    justify-content: center;
  }

  &.cmc-group-horizontal-align-right {
    justify-content: flex-end;
  }

  &.cmc-group-horizontal-align-left {
    justify-content: flex-start;
  }

  &.cmc-group-align-baseline {
    align-items: baseline;
  }
}
</style>