import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import CmcList from '@/components/nextgen/display/list/CmcList.vue';
import CmcListRow from '@/components/nextgen/display/list/CmcListRow.vue';
import CmcListHeader from '@/components/nextgen/display/list/CmcListHeader.vue';
import CmcListCol from '@/components/nextgen/display/list/CmcListCol.vue';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Organization, AssignedServiceConnection } from './types';
import EmptyMessage from '@/components/EmptyMessage.vue';
import { ColLayout } from '@/components/nextgen/display/list/types';
import ConnectionAssignmentRow from './ConnectionAssignmentRow.vue';
import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';
import { Sortable, sortInMemory, Direction, lastSortedBy } from '@/components/nextgen/composables/useListSorting'

type Props = {
  /**
   * Name of the organization
   */
  organization: Organization;

  /**
   * List of available service connections
   */
   availableConnections: AssignedServiceConnection[];
  
   /**
   * Key to uniquely assign to each row
   */
    componentKey: number;

}

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganizationServicesList',
  props: {
    organization: {},
    availableConnections: {},
    componentKey: {}
  },
  setup(__props: any) {

defineComponent({
  CmcList, CmcListRow, CmcListHeader, CmcListCol, CmcTitle
})

const { t } = useI18n();

const props = __props;

const listDetailsCols = ref<ColLayout[]>([
  { u: '3-12', asColType: 'text-with-icon' },
  { u: '2-12', asColType: 'badge' },
  { u: '2-12', asColType: 'badge' },
  { u: '5-12', asColType: 'text' },
]);

const sortableServiceName = ref<Sortable>({
  comparator: (a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  },
  default: true,
  sortOrder: Direction.ASCENDING,
  columnId: "serviceName",
  tableId: "assignedServices"
})

const sortableStatus = ref<Sortable>({
  comparator: (a, b) => {
    const statusA = !a.status.reachable ? t('unreachable') : a.overrideStatus ? t(a.overrideStatus.statusLabel) : t('connected');
    const statusB = !b.status.reachable ? t('unreachable') : b.overrideStatus ? t(b.overrideStatus.statusLabel) : t('connected');
    return statusA.localeCompare(statusB);
  },
  default: false,
  sortOrder: Direction.ASCENDING,
  columnId: "serviceStatus",
  tableId: "assignedServices"
})

const sortableState = ref<Sortable>({
  comparator: (a, b) => {
    const stateA = t(a.overrideStatus?.statusLabel || a.state?.toLowerCase() || '');
    const stateB = t(b.overrideStatus?.statusLabel || b.state?.toLowerCase() || '');
    return stateA.localeCompare(stateB);
  },
  default: false,
  sortOrder: Direction.ASCENDING,
  columnId: "serviceState",
  tableId: "assignedServices"
})

const connectionQuotas = computed<Record<string, any>>(() => {
  return (props.organization.quotas || []).reduce((acc: Record<string, any>, q) => {
    acc[q.serviceConnection.id] = q.id;
    return acc;
  }, {});
});

const connections = computed<AssignedServiceConnection[]>(() => {
  return props.availableConnections.map(sc => ({
    ...sc,
    quotaId: quotaOrDefault(sc),
  }));
})

function connectionIsAssigned(connection : AssignedServiceConnection): boolean {
  return !!props.organization.serviceConnections.find(sc => sc.id === connection.id);
}

function quotaOrDefault(connection : AssignedServiceConnection): string | undefined {
  if (connectionQuotas.value[connection.id]) {
    return connectionQuotas.value[connection.id];
  } else if (connection.quotas && connection.quotas.length > 0) {
    return connection.quotas[0].id;
  }
  return undefined;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcList, {
    class: "organization-services-list",
    layout: listDetailsCols.value
  }, {
    default: _withCtx(() => [
      _createVNode(CmcListHeader, null, {
        default: _withCtx(() => [
          _createVNode(CmcListCol, {
            sortable: sortableServiceName.value,
            "other-columns-hook": _unref(lastSortedBy),
            onSort: _cache[0] || (_cache[0] = (sortingInstructions) => _unref(sortInMemory)(_ctx.availableConnections, sortingInstructions))
          }, {
            default: _withCtx(() => [
              _createVNode(CmcTitle, {
                title: "service",
                "with-i18n": "",
                heading: "h5",
                "with-bold": ""
              })
            ]),
            _: 1
          }, 8, ["sortable", "other-columns-hook"]),
          _createVNode(CmcListCol, {
            sortable: sortableStatus.value,
            "other-columns-hook": _unref(lastSortedBy),
            onSort: _cache[1] || (_cache[1] = (sortingInstructions) => _unref(sortInMemory)(_ctx.availableConnections, sortingInstructions))
          }, {
            default: _withCtx(() => [
              _createVNode(CmcTitle, {
                title: "service_status",
                "with-i18n": "",
                heading: "h5",
                "with-bold": ""
              })
            ]),
            _: 1
          }, 8, ["sortable", "other-columns-hook"]),
          _createVNode(CmcListCol, {
            sortable: sortableState.value,
            "other-columns-hook": _unref(lastSortedBy),
            onSort: _cache[2] || (_cache[2] = (sortingInstructions) => _unref(sortInMemory)(_ctx.availableConnections, sortingInstructions))
          }, {
            default: _withCtx(() => [
              _createVNode(CmcTitle, {
                title: "state",
                "with-i18n": "",
                heading: "h5",
                "with-bold": ""
              })
            ]),
            _: 1
          }, 8, ["sortable", "other-columns-hook"]),
          _createVNode(CmcListCol, null, {
            default: _withCtx(() => [
              _createVNode(CmcTitle, {
                title: "details",
                "with-i18n": "",
                heading: "h5",
                "with-bold": ""
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(connections.value, (sc) => {
        return (_openBlock(), _createBlock(ConnectionAssignmentRow, {
          key: sc.id+_ctx.componentKey,
          assigned: connectionIsAssigned(sc),
          connection: sc,
          organization: props.organization
        }, null, 8, ["assigned", "connection", "organization"]))
      }), 128)),
      (connections.value.length < 1)
        ? (_openBlock(), _createBlock(EmptyMessage, {
            key: 0,
            icon: "fa fa-plug",
            textLabel: "no_available_connections"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["layout"]))
}
}

})