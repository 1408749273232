<template>
  <div>
    <base-menu-page
      :sections="sections"
      :header="header"
      :operations="operations"
    >
      <router-view></router-view>
    </base-menu-page>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useResellerOrgNavigationGuard } from '@/app/composables/useResellerOrgNavigationGuard';
import { useRouter, useRoute } from 'vue-router'

const { t } = useI18n();
const { checkAccess } = useResellerOrgNavigationGuard();
const router = useRouter()
const route = useRoute()

onMounted(() => {
  checkAccess();
});

const header = computed(() => ({
  title: t('legal.name'),
  subtitle: t('legal.description'),
  logo: 'book',
}));

const sections = computed(() => ([
  {
    title: t('disclaimer.title'),
    to: { name: 'legalDisclaimer' },
  },
  {
    title: t('terms_of_service_tab_title'),
    to: { name: 'terms-of-service' },
  },
]));

const operations = computed(() => {
  return [  
        {
            route: 'terms-of-service',
            href: '/admin/legal/terms-of-service/add',
            action: () => router.push({ name: 'addTermOfService' }),
            label: 'terms_of_service.add',
            icon: 'fa fa-plus-circle'
        }
  ].filter(o => route.name === o.route);
})
</script>
