import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { defineComponent } from 'vue';
import CmcBlock from "@/components/nextgen/layout/CmcBlock.vue";
import CmcGroup from "@/components/nextgen/layout/CmcGroup.vue";
import ColorCard from "./ColorCard.vue";

export type ColorSwatchProps = {
  /**
   * Array of #hex color values
   */
  modelValue?: string[];

  /**
   * Whether the colors can be edited.
   */
  readonly?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ColorSwatch',
  props: {
    modelValue: { default: () => [] },
    readonly: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcBlock,
  CmcGroup,
  ColorCard,
})



const updateColor = (v: string, index: number) => {
  emit('update:modelValue', v, index);
}

const emit = __emit


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, {
    withBorder: "",
    paddingVertical: "3xl",
    paddingHorizontal: "2xl"
  }, {
    default: _withCtx(() => [
      _createVNode(CmcGroup, {
        withVerticalAlign: "center",
        spacing: "3xl",
        takeFullHeight: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (color, idx) => {
            return (_openBlock(), _createBlock(ColorCard, {
              key: idx,
              title: '#' + (idx + 1),
              color: color,
              readonly: _ctx.readonly,
              "onUpdate:modelValue": ($event: any) => (updateColor($event, idx))
            }, null, 8, ["title", "color", "readonly", "onUpdate:modelValue"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})