import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { computed, defineComponent, ref } from 'vue';
  import CmcTextArea from './CmcTextArea.vue';
  import CmcTextInput from './CmcTextInput.vue';
  import { EditorLanguage } from "../enums/NextGenEnums";
  import { useI18n } from 'vue-i18n';

  type Props = {
    /**
     * HTML element id
     */
    id?: string;

    /**
     * Value of the text area
     */
    modelValue?: string;

    /**
     * Label on top of the input
     */
    label?: string;

    /**
     * True if the label is a label key.
     */
    withLabelI18n?: boolean;

    /**
     * Description to display under label.
     */
    description?: string;

    /**
     * True if the description is a label key.
     */
    withDescriptionI18n?: boolean;

    /**
     * Show a warning tooltip next to the label
     */
    withWarningTooltip?: string;

    /**
     * True if the warning tooltip is a label key
     */
    withWarningTooltipI18n?: boolean;

    /**
     * Set a placeholder in the input.
     */
    placeholder?: string;

    /**
     * True if the placeholder is an i18n label.
     */
    withPlaceholderI18n?: boolean;

    /**
     * Show a tooltip next to the label
     */
    withTooltip?: string;

    /**
     * True if the tooltip is a label key.
     */
    withTooltipI18n?: boolean;

    /**
     * Make the input read-only.
     */
    readOnly?: boolean;

    /**
     * Inherit read only flag from form. Defaults to true.
     */
    inheritReadOnly?: boolean

    /**
     * Will show an error text under the input.
     */
    withErrorText?: string;

    /**
     * True if the error text is i18n.
     */
    withErrorTextI18n?: boolean;

    /**
     * Disable the input
     */
    disabled?: boolean;

    /**
     * True if the input should obscure the initial value
     * Defaults to true
     */
    obscureInitialValue?: boolean;
  };

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CmcSensitiveTextArea',
  props: {
    id: {},
    modelValue: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    placeholder: {},
    withPlaceholderI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    disabled: { type: Boolean },
    obscureInitialValue: { type: Boolean, default: true }
  },
  emits: ["update:modelValue", "click", "focus", "blur"],
  setup(__props: any, { emit: __emit }) {

  defineComponent({
    CmcTextArea,
    CmcTextInput
  });

  const { t } = useI18n();

  const props = __props;

  const emit = __emit;

  const isEditMode = ref(false);
  const hasInitialValue = ref(props.modelValue !== '' && props.modelValue !== undefined && props.modelValue !== null);

  const adjustedPlaceholder = computed(() => {
    if (props.placeholder) {
      return props.placeholder;
    }
    if (hasInitialValue.value) {
      return t('input_new_value');
    }
    return undefined;
  });

  const isDisabled = computed(() => (!isEditMode.value || props.disabled) && hasInitialValue.value);

  const displayTextInput = computed(() => (!isEditMode.value && hasInitialValue.value) || props.readOnly);

  const actualValue = computed(() => {
    if (props.obscureInitialValue && displayTextInput.value) {
      return '[' + t('redacted') + ']';
    }
    return props.modelValue;
  });
  
  const toggleEdit = () => {
    if (props.obscureInitialValue) {
      emit('update:modelValue', '');
    }
    isEditMode.value = !isEditMode.value;
  };

return (_ctx: any,_cache: any) => {
  const _component_cmc_icon = _resolveComponent("cmc-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'cmc-sensitive-text-area-wrapper',
      {'cmc-sensitive-text-area-disabled': isDisabled.value}
    ])
  }, [
    (displayTextInput.value)
      ? (_openBlock(), _createBlock(CmcTextInput, {
          key: 0,
          type: 'password',
          modelValue: actualValue.value,
          label: _ctx.label,
          "with-label-i18n": _ctx.withLabelI18n,
          description: _ctx.description,
          "with-description-i18n": _ctx.withDescriptionI18n,
          "with-warning-tooltip": _ctx.withWarningTooltip,
          "with-warning-tooltip-i18n": _ctx.withWarningTooltipI18n,
          placeholder: adjustedPlaceholder.value,
          "with-placeholder-i18n": _ctx.withPlaceholderI18n,
          "with-tooltip": _ctx.withTooltip,
          "with-tooltip-i18n": _ctx.withTooltipI18n,
          "read-only": _ctx.readOnly,
          "inherit-read-only": _ctx.inheritReadOnly,
          "with-error-text": _ctx.withErrorText,
          "with-error-text-i18n": _ctx.withErrorTextI18n,
          disabled: isDisabled.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('update:modelValue', $event))),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('click', $event))),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (emit('focus', $event))),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (emit('blur', $event)))
        }, {
          rhs: _withCtx(() => [
            (!isEditMode.value && !_ctx.disabled && hasInitialValue.value)
              ? (_openBlock(), _createBlock(_component_cmc_icon, {
                  key: 0,
                  class: "rhs-icon",
                  icon: "edit",
                  size: "l",
                  svg: "",
                  "with-clickable": !_ctx.disabled,
                  onClick: toggleEdit
                }, null, 8, ["with-clickable"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "label", "with-label-i18n", "description", "with-description-i18n", "with-warning-tooltip", "with-warning-tooltip-i18n", "placeholder", "with-placeholder-i18n", "with-tooltip", "with-tooltip-i18n", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n", "disabled"]))
      : (_openBlock(), _createBlock(CmcTextArea, {
          key: 1,
          modelValue: actualValue.value,
          label: _ctx.label,
          "with-label-i18n": _ctx.withLabelI18n,
          description: _ctx.description,
          "with-description-i18n": _ctx.withDescriptionI18n,
          "with-warning-tooltip": _ctx.withWarningTooltip,
          "with-warning-tooltip-i18n": _ctx.withWarningTooltipI18n,
          "with-placeholder": adjustedPlaceholder.value,
          "with-placeholder-i18n": _ctx.withPlaceholderI18n,
          "with-tooltip": _ctx.withTooltip,
          "with-tooltip-i18n": _ctx.withTooltipI18n,
          "read-only": _ctx.readOnly,
          "inherit-read-only": _ctx.inheritReadOnly,
          "with-error-text": _ctx.withErrorText,
          "with-error-text-i18n": _ctx.withErrorTextI18n,
          disabled: _ctx.disabled,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (emit('update:modelValue', $event)))
        }, null, 8, ["modelValue", "label", "with-label-i18n", "description", "with-description-i18n", "with-warning-tooltip", "with-warning-tooltip-i18n", "with-placeholder", "with-placeholder-i18n", "with-tooltip", "with-tooltip-i18n", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n", "disabled"]))
  ], 2))
}
}

})