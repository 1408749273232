<template>
  <cmc-read-only
    :id="id ? `cmc-checkbox-group-${id}` : undefined"
    :label="label"
    :modelValue="modelValue?.map(v => valueLabels[v]).join(', ')"
    :with-label-i18n="withLabelI18n"
    :read-only="readOnly"
    :inherit-read-only="inheritReadOnly"
    :with-error-text="withErrorText"
    :with-error-text-i18n="withErrorTextI18n"
  >
    <cmc-stack
      class="cmc-checkbox-group"
      spacing="2xs"
    >
      <cmc-stack spacing="none">
        <cmc-label
          v-bind="props"
          as-header
        ></cmc-label>
        <cmc-text
          v-if="withErrorText"
          :text="withErrorText"
          :with-i18n="withErrorTextI18n"
          as-error
          size="m"
        ></cmc-text>
      </cmc-stack>
      <cmc-checkbox
        v-for="opt in options"
        :id="opt.id"
        :key="opt.value"
        :label="opt.label"
        :with-label-i18n="opt.withI18n"
        :model-value="checkedValues[opt.value]"
        :disabled="disabled"
        :checkbox-color="opt.color"
        reversed
        :as-toggle="asToggle"
        as-group
        large
        @update:model-value="updateChecked(opt.value, $event)"
      ></cmc-checkbox>
    </cmc-stack>
  </cmc-read-only>
</template>

<script setup lang="ts">
import { computed, defineComponent } from 'vue'
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcCheckbox from './CmcCheckbox.vue'
import CmcStack from '../layout/CmcStack.vue'
import CmcReadOnly from './CmcReadOnly.vue'
import { useI18n } from 'vue-i18n';

defineComponent({
  CmcText, CmcCheckbox, CmcStack, CmcReadOnly, CmcLabel,
})

type Option = {
  /**
   * HTML element id for the checkbox
   */
  id?: string;
  /**
   * Value of the option.
   */
  value: any;
  /**
   * Label of the option.
   */
  label: string;
  /**
   * True if the label of the option is i18n.
   */
   withI18n?: boolean;

   /**
    * Color of the checkbox in hexadecimal format.
    */
   color?: string;
}

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Values that are checked.
   */
  modelValue?: any[]

  /**
   * Options to offer in the checkbox.
   */
  options: Option[]

  /**
   * Label of the checkbox group.
   */
  label?: string

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the checkbox.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Should be display in read-only mode
   */
  readOnly?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean

  /**
   * Should the checkbox be disabled
   */
  disabled?: boolean;

  /**
   * Display checkboxes as toggle.
   */
  asToggle?: boolean
   /**
    * True if the optional
    */
    asOptional?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inheritReadOnly: true,
  asOptional: false,
})

const checkedValues = computed(() => {
  return (props.modelValue || []).reduce((acc, cur) => {
    acc[cur] = true
    return acc
  }, {})
})

const emit = defineEmits<{
  /**
   * Emitted when toggled.
   * @arg any[]
   */
  (event: 'update:modelValue', values: any[]): void
}>()

const updateChecked = (value: any, checked: boolean) => {
  if (checked) {
    return emit('update:modelValue', [...new Set([...(props.modelValue || []), value])])
  } else {
    return emit('update:modelValue', [...(props.modelValue || [])].filter(v => v !== value))
  }
}

const { t } = useI18n()
const valueLabels = computed(() => {
  return props.options.reduce((acc, opt) => {
    acc[opt.value] = opt.withI18n ? t(opt.label) : opt.label;
    return acc
  }, {} as { [key: string]: string })
})
</script>
