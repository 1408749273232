<template>
  <cmc-card
    title="assigned_services"
    with-title-i18n
    padding="none"
  >
    <template #rhs-content>
      <cmc-button
        v-if="hasEditPermission"
        text="assign_connection.label"
        with-i18n
        lhs-icon="plus"
        call-to-action
        @click="$router.push(`${$route.path}/assign`)"
      ></cmc-button>
    </template>
    <div class="loading-wrapper">
      <cmc-loader 
        v-if="loading" 
      >
      </cmc-loader>
      <cmc-message-display
        v-if="!hasAssignedConnections"
        id="select-service-scope-warning"
        icon="assign-service"
        clickable-message="assign_connection.label"
        :to-navigate-on-click="`${$route.path}/assign`"
        with-clickable-message-i18n
      />
      <organization-services-list
        v-else
        :organization="organization"
        :availableConnections="availableConnections"
        :componentKey="componentKey"
      />
    </div>
  </cmc-card>
</template>

<script setup lang="ts">
import CmcCard from '@/components/nextgen/display/CmcCard.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';
import CmcMessageDisplay from '@/components/nextgen/display/CmcMessageDisplay.vue';
import CmcAppLink from '@/components/nextgen/navigation/CmcAppLink.vue';
import OrganizationServicesList from './OrganizationServicesList.vue';
import CmcLoader from '@/components/nextgen/display/CmcLoader.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { AssignedServiceConnection, Organization } from './types';
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { useI18n } from 'vue-i18n';
import authz from '@/authz';

defineComponent({
  CmcCard, CmcButton, CmcMessageDisplay, CmcAppLink, CmcLoader
})

type Props = {
  /**
   * Name of the organization
   */
  organization: Organization;

  /**
   * Whether to hide the title
   */
  hideTitle?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hideTitle: false,
});

const { t } = useI18n();
const hasEditPermission = ref(false);
const loading = ref(true);
const componentKey = ref(0);
const availableConnections = ref<AssignedServiceConnection[]>([]);

const hasAssignedConnections = computed<boolean>(() => {
  const availableConnectionIds = availableConnections.value.map(a => a.id);
  return props.organization.serviceConnections.filter(sc => availableConnectionIds.includes(sc.id)).length > 0;
});

onMounted(async () => {
  await Promise.all([
      fetchAvailableConnections(),
      setCanEdit()
    ])
    loading.value = false;
});

/**
 * Loads all data.
 */
async function fetchAvailableConnections(): Promise<void> {
  const resp = await apis.organizations.findServiceConnections(props.organization.id);
      if (resp.status !== 200 || !resp.data) {
        notify.error(t('unexpected_error'));
      } else {
        const responseData = resp.data;
        availableConnections.value = responseData.map((sc: { id: string | number; }) => ({
          ...sc,
          state: assignedConnectionState.value[sc.id],
        }));
      }
}

async function setCanEdit() {
      const orgPermissions = await apis.users.getEffectivePermissions(props.organization.id);
      const allPermissions = orgPermissions ? [
        ...(orgPermissions.system || []),
        ...(orgPermissions.subs || []),
        ...(orgPermissions.service || []),
        ...(orgPermissions.global || [])
      ]
        : [];

      hasEditPermission.value = authz.hasPermission('reseller:connections') ||
        authz.hasPermission('reseller:assignConnections') ||
        allPermissions.some(p => p === 'reseller:assignConnections' || p === 'reseller:connections');
    }

    const assignedConnectionState = computed<Record<string, any>>(() => {
  return props.organization.serviceConnections.reduce((acc: Record<string, any>, a) => {
    acc[a.id] = a.state;
    return acc;
  }, {} as Record<string, any>);
});

</script>
<style scoped>
.loading-wrapper {
  position:relative;
  min-height: 7.5rem;
}

</style>
