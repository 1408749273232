<template>
  <div
    class="cmc-batch-input-wrapper"
  >
    <cmc-stack
      spacing="2xl"
    >
      <cmc-text-input
        type="text"
        :model-value="modelValue"
        :label="formElement.label"
        with-label-i18n
        :with-tooltip="formElement.descriptionLabel"
        with-tooltip-i18n
        :with-error-text="fieldValueError"
        @update:modelValue="emit('update:modelValue', $event)"
      />
      <cmc-stack
        spacing="m"
      >
        <cmc-checkbox
          label="batch_creation"
          with-label-i18n
          :with-warning-tooltip="disabled ? formElement.disabledDescriptionLabel : ''"
          with-warning-tooltip-i18n
          :disabled="inputsDisabled"
          as-toggle
          reversed
          :model-value="batchEnabled"
          @update:model-value="changeBatchEnabled"
        />
        <cmc-stack
          spacing="xs"
        >
          <cmc-stack
            v-if="batchEnabled && !inputsDisabled"
            spacing="3xs"
          >
            <cmc-grid
              contextual
            >
              <cmc-grid-col
                u="2-3"
              >
                <cmc-read-only
                  :label="batchLabel"
                  with-label-i18n
                  :with-error-text="batchValueError"
                  :inherit-read-only="inheritReadOnly"
                  :read-only="readOnly"
                  :model-value="batchSize"
                />
                <cmc-counter
                  :label="batchLabel"
                  with-label-i18n
                  :min="2"
                  :model-value="batchSize"
                  :inherit-read-only="false"
                  @update:model-value="changeBatchSize"
                />
              </cmc-grid-col>
              <cmc-grid-col
                u="1-3"
              >
                <cmc-text-input
                  :inherit-read-only="false"
                  type="text"
                  label="starting_suffix"
                  with-label-i18n
                  :model-value="startingSuffix"
                  @update:model-value="changeBatchSuffix"
                />
              </cmc-grid-col>
            </cmc-grid>
            <cmc-read-only
              v-if="canGenerateSampleText"
              :model-value="firstSample"
              label="sample"
              with-label-i18n
              :inherit-read-only="inheritReadOnly"
              :read-only="readOnly"
            >
              <cmc-text
                :text="sampleText"
                size="m"
                as-description
              />
            </cmc-read-only>
          </cmc-stack>
          <cmc-pair
            v-if="batchValueError"
            spacing="3xs"
            stretch-rhs
          >
            <cmc-icon
              icon="exclamation-filled-bold"
              color="red"
              svg
            />
            <cmc-text
              :text="batchValueError"
              with-i18n
              as-error
            />
          </cmc-pair>
        </cmc-stack>
      </cmc-stack>
    </cmc-stack>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import CmcCheckbox from '../inputs/CmcCheckbox.vue';
import CmcTextInput from '../inputs/CmcTextInput.vue';

export type BatchProps = {
  modelValue?: string;
  formElement: Record<string, any>;
  errors?: Record<string, any>;
  disabled?: boolean;
  readOnly?: boolean;
  inheritReadOnly?: boolean;
}

export type BatchConfig = {
  enabled: boolean,
  startingSuffix?: string,
  batchSize?: string,
}

const props = withDefaults(defineProps<BatchProps>(), {
  disabled: false,
  inheritReadOnly: true,
})

const { t } = useI18n()
const inputsDisabled = computed<boolean>(() => {
  return props.formElement.disabled || props.disabled;
});
const canGenerateSampleText = computed<boolean>(() => {
  if (!props.modelValue || !batchSize.value || !startingSuffix.value
    || isNaN(Number(batchSize.value)) || Number(batchSize.value) < 2
    || isNaN(Number(startingSuffix.value)) || Number(startingSuffix.value) < 0) {
    return false;
  }
  return true;
})
const firstSample = computed<string|undefined>(() => {
  return props.modelValue + '-' + startingSuffix.value;
})
const lastSample = computed<string|undefined>(() => {
  const suffixDigits = startingSuffix.value.length;
  const suffix = Number(startingSuffix.value);
  const lastSuffix = String(suffix + Number(batchSize.value) - 1).padStart(suffixDigits, '0');
  return props.modelValue + '-' + lastSuffix;
});
const sampleText = computed<string|undefined>(() => {
  return t('sample') + ': ' + firstSample.value + ' ' + t('to_lowercase') + ' ' + lastSample.value;
});
const fieldValueError = computed<string|undefined>(() => {
  if (!props.errors || !props.errors[props.formElement.field]) {
    return undefined;
  }
  return getErrorLabelValue(props.errors[props.formElement.field]);
})
const batchValueError = computed<string|undefined>(() => {
  if (!props.errors || !props.errors['batch']) {
    return undefined;
  }
  return getErrorLabelValue(props.errors['batch']);
})
const batchInfo = computed<BatchConfig>(() => {
  return {
    enabled: batchEnabled.value,
    startingSuffix: startingSuffix.value,
    batchSize: batchSize.value,
  };
});
const batchLabel = computed<string>(() => {
  return props.formElement.batchLabel ? props.formElement.batchLabel : 'batch_size';
});
const batchSize: Ref<string> = ref(props.formElement.defaultBatchSize ? String(props.formElement.defaultBatchSize) : '2');
const startingSuffix: Ref<string> = ref(props.formElement.defaultStartingSuffix || '01');
const batchEnabled: Ref<boolean> = ref(props.formElement.defaultEnabled || false);

const emit = defineEmits<{
  /**
   * Emitted when the field value is updated
   */
  (event: 'update:modelValue', value: any): void,

  /**
   * Emitted on mount as well as when any of the batch fields are updated
   */
  (event: 'update:batch', value: BatchConfig): void,
}>()

// emit on mounted the default values
onMounted(() => {
  emit('update:batch', batchInfo.value);
})

const changeBatchEnabled = (v: boolean) => {
  batchEnabled.value = v;
  emit('update:batch', batchInfo.value);
};
const changeBatchSize = (v: string) => {
  batchSize.value = v;
  emit('update:batch', batchInfo.value);
}
const changeBatchSuffix = (v: string) => {
  startingSuffix.value = v;
  emit('update:batch', batchInfo.value);
}
function getErrorLabelValue(errorList: Record<string, any>[]) {
  if (!errorList || errorList.length == 0) {
    return undefined;
  }
  return errorList.map(err => t(err.context.labelKey, err.context)).join("\n");
}
</script>
<style scoped lang="scss">
.cmc-batch-input-wrapper {
  max-width: 24rem;
}
</style>