<template>
  <div
    :role="isColumnHeader ? 'columnheader' : 'cell'"
    class="list-column"
    :class="[ actionColumn ? 'action-column' : colSizeClass, direction, { right: alignRight, 'no-click': nonClickable }, allowOverflow? '': 'no-overflow', allowWrapping? 'wrap' : '']"
    @click="click"
    @keyup.enter="click"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      v-if="isSortable"
      class="list-column-value"
      tabindex="0"
      :aria-label="$t('sort_column_action', { sortableKey })"
      @keyup.enter="sort"
      @focusin="focus = true"
      @focusout="focus = false"
    >
      <column-text v-if="textColumn">
        <slot></slot>
      </column-text>
      <template v-else>
        <slot></slot>
      </template>
      <cmc-icon
        v-if="showSort || hover || focus"
        class="sort-icon"
        :class="{asc: sortAscending, desc: !sortAscending}"
        with-clickable
        size="s"
        :svg="true"
        :icon="sortIcon"
        @click="sort"
      />
    </div>
    <template v-else>
      <column-text v-if="textColumn">
        <slot></slot>
      </column-text>
      <template v-else>
        <slot></slot>
      </template>
    </template>
  </div>
</template>

<script>
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";

export default {
  name: 'ListColumn',
  components: {CmcIcon},
  props: {
    size: {
      type: Number,
      default: 1,
      validator: i => [0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6].includes(i),
    },
    alignRight: {
      type: Boolean,
    },
    actionColumn: {
      type: Boolean,
      default: false,
    },
    nonClickable: {
      type: Boolean,
    },
    direction: {
      type: String,
    },
    textColumn: {
      type: Boolean,
    },
    allowOverflow: {
      type: Boolean,
      default: false,
    },
    allowWrapping: {
      type: Boolean,
      default: false,
    },
    sortableKey: {
      type: String,
    },
    showSort: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['sortColumn'],
  data() {
    return {
      sortAscending: true,
      hover: false,
      focus: false,
    }
  },
  computed: {
    colSizeClass() {
      return `size-${this.size}`;
    },
    isColumnHeader() {
      const parent = this.$parent;
      return (parent && parent.$options.name === 'ListHeader');
    },
    isSortable() {
      return (this.sortableKey || '').length > 0 && this.isColumnHeader;
    },
    sortIcon() {
      const sortQueryParam = this.$route.query.sort;
      if (sortQueryParam && sortQueryParam.includes(this.sortableKey)) {
        return sortQueryParam.includes('asc') ? 'sort-ascending' : 'sort-descending';
      }

      return 'sort-ascending';
    },
    hasFocus() {
      return this.$refs.columnValue;
    }
  },
  watch: {
    '$route.query.sort'(newParam) {
      if (newParam && !newParam.includes(this.sortableKey)) {
        this.sortAscending = true;
      }
    }
  },
  methods: {
    click($event) {
      if (this.actionColumn || this.nonClickable) {
        $event.preventDefault();
      }
    },
    sort() {
      if (this.isSortable) {
        // Only alternate the sort direction if we already sort on this column so that enabling sorting starts with ASC.
        if (this.showSort) {
          this.sortAscending = !this.sortAscending;
        }
        this.$emit('sortColumn', { sortAscending: this.sortAscending, sortKey: this.sortableKey });
      }
    }
  }
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';

.list-column-value {
  display: flex;
  align-items: center;
}

.sortable-icon {
  margin-left: 5px;
}

.no-overflow {
  overflow: hidden;
}
.wrap {
  word-break: normal;
  overflow-wrap: anywhere;
}
.list-column {
    user-select: text;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 3px 0px;
    font-size: 14px;
    text-align: left;
    min-width: 0;
    text-overflow: ellipsis;

    :deep(.base-tooltip:hover, .base-tooltip.active) {
      z-index: 9999;
    }

    &.horizontal {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      &.right {
        justify-content: flex-end;
        align-items: flex-start;
      }
    }

    &.right {
      text-align: right;
      align-items: flex-end;
    }

    &.size-0\.5{
        flex: 0.5;
    }
    &.size-1 {
        flex: 1;
    }
    &.size-1\.5{
        flex: 1.5;
    }

    &.size-2 {
        flex: 2;
    }

    &.size-2\.5 {
        flex: 2.5;
    }

    &.size-3 {
        flex: 3;
    }

    &.size-4 {
        flex: 4;
    }

    &.size-5 {
      flex: 5;
    }

    &.size-6 {
      flex: 6;
    }

    @include mixins.phone {
        flex: none;
        margin-bottom: 8px;
        min-width: 150px;
    }
}
.sort-icon {
  margin-left: .3rem;
}

.no-click {
  cursor: initial;
}
</style>
