<template>
  <div class="overview-info-item">
    <div class="overview-info-item-header"> 
      {{ $t("billing_settings.invoice_information") }}
    </div>
    <div class="invoice-info">
      <div class="detail-text key">
        {{ $t('reports.invoice_id') }}
      </div>
      <div class="value">
        {{ invoice.invoiceId }}
      </div>
    </div>
    <div class="invoice-info">
      <div class="detail-text key">
        {{ $t('start_date') }}
      </div>
      <div class="value">
        {{ formatDate(invoice.detail.startDate) }}
      </div>
    </div>
    <div class="invoice-info">
      <div class="detail-text key">
        {{ $t('end_date') }}
      </div>
      <div class="value">
        {{ formatInvoiceEndDate(invoice) }}
      </div>
    </div>
    <div class="invoice-info">
      <div class="detail-text key">
        {{ $t('reports.issued_date') }}
      </div>
      <div class="value">
        {{ formatDate(invoice.issuedDate) }}
      </div>
    </div>
    <div class="invoice-info">
      <div class="detail-text key">
        {{ $t('reports.due_date') }}
      </div>
      <div class="value">
        {{ formatDate(invoice.dueDate) }}
      </div>
    </div>
    <div class="invoice-info">
      <div class="detail-text key">
        {{ $t('currency') }}
      </div>
      <div class="value">
        {{ invoice.detail.currency }}
      </div>
    </div>
    <div 
      v-if="invoice.detail.paymentMethod"
      class="invoice-info" 
    >
      <div class="detail-text key">
        {{ $t('reports.payment_method') }}
      </div>
      <div class="value">
        {{ invoice.detail.paymentMethod }}
      </div>
    </div>
    <div class="invoice-info">
      <div class="detail-text key">
        {{ $t('total') }}
      </div>
      <div class="value">
        {{ formattedInvoiceTotal }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LIST_SIMPLE_DATE_FORMAT } from '@/utils/dates';
import { currencyMixin } from '@/mixins/currencyMixin';
import { invoiceMixin } from '@/mixins/invoiceMixin';

export default {
  name: 'InvoiceSummaryFields',
  mixins: [currencyMixin, invoiceMixin],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'locale',
    ]),
    formattedInvoiceTotal() {
      return this.formatShortLocaleCurrency(this.invoice.detail.total, this.invoice.detail.currency, navigator.language);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return this.$t('not_available');
      }
      return this.$date(date, LIST_SIMPLE_DATE_FORMAT, true);
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

.overview-info-item-header {
  @include mixins.not-phone {
    margin: 0px 0px 5px;
  }

  @include mixins.phone {
    margin: 20px 0px 5px;
  }
}

.invoice-info {
  display: flex;
  flex-direction: column;
  @include mixins.not-phone {
    flex-direction: row;
  }
}

.invoice-info .key {
  @include mixins.not-phone {
    flex-basis: 45%;
  }
}

.key {
  flex-basis: 100%;
}
</style>
