import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "placeholder-col"
}
const _hoisted_2 = {
  key: 2,
  class: "placeholder-col"
}

import { computed } from 'vue'

import CmcList from '../display/list/CmcList.vue';
import CmcListHeader from '../display/list/CmcListHeader.vue';
import CmcListRow from '../display/list/CmcListRow.vue';
import CmcListCol from '../display/list/CmcListCol.vue';
import CmcPartialCheckbox from './CmcPartialCheckbox.vue';
import CmcAmount from '../typography/CmcAmount.vue';
import { ColLayout } from '../display/list/CmcListCol.vue';
import { PartialCheckboxState } from './constants';

export type ListCheckboxSelectHeader = {
  /**
   * Label on top of the text area
   */
  label: string;

  /**
   * Is the label i18n.
   */
  withI18n?: boolean;

  /**
   * Whether or not the header should be rendered as a partial checkbox which can
   * select or deselect all checkboxes within the same column (limited to first header/column)
   */
  withPartialCheckbox?: boolean;

  /**
   * Whether or not to include the count in the header if it is a partial checkbox
   * 
   */
  withCount?: boolean;

  /**
   * Tooltip in the header,
   */
  withTooltip?: string;

  /**
   * Is tooltip i18n.
   */
  withTooltipI18n?: boolean;
}
export type ListCheckboxSelectCol = {
  /**
   * Text content to render in the column.
   */
  text?: string;

  /**
   * Amount to render in the column
   */
  amount?: number;

  /**
   * Currency if the column is an amount to display with a currency
   */
  currency?: string;

  /**
   * True if the column amount value should be bold
   */
  withBold?: boolean;

  /**
   * The locale :shrug:
   */
  locale?: string;

  /**
   * True if the currency should be displayed in the column
   */
  showCurrency?: boolean;

  /**
   * True if the text is 18n
   */
  withTextI18n?: boolean;

  /**
   * True if the text should be rendered as a description
   */
  asDescription?: boolean;

  /**
   * An icon to display next to the comparison amount, if desired.
   * Ex. 'arrow-down-bold', 'arrow-up-bold'
   */
  comparisonIcon?: string;

  /**
   * A comparison amount to be displayed next to the amount, with the comparisonIcon
   * in the format <amount> <comparisonIcon> <comparisonAmount>.
   */
  comparisonAmount?: string;
}
export type ListCheckboxSelectRow = {
  /**
   * HTML element id for the checkbox
   */
  id?: string;

  /**
   * Value of the row corresponding to the checkbox
   * Omit if the first column should be empty (no checkbox).
   */
  value?: any;

  /**
   * Label for the checkbox in column 1
   * Omit if the first column should be empty (no checkbox).
   */
  label?: string;

  /**
   * True if the label is i18n.
   */
  withI18n?: boolean;

  /**
   * Color of the checkbox in hexadecimal format.
   */
  color?: string;

  /**
   * Text or amount content to be displayed in columns 2-n
   */
  columns?: ListCheckboxSelectCol[];
}
type Props = {
  id?: string;

  /**
   * Model value of the component. This is a list of values that are checked
   */
  modelValue: any[];

  /**
   * Headers of the list.
   */
  headers: ListCheckboxSelectHeader[];

  /**
   * Rows/Content of the list.
   */
  rows: ListCheckboxSelectRow[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListMultiSelect',
  props: {
    id: {},
    modelValue: {},
    headers: {},
    rows: {},
    layout: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

/**
 * Computed value to determine state of partial checkbox header
 */
const checkedState = computed(() => {
  const allValues = props.rows.filter(row => row.value).map(row => row.value);
  const numSelected = Object.values(checkedValues.value).length;
  if (numSelected == 0) {
    return PartialCheckboxState.UNCHECKED;
  } else if (numSelected == allValues.length) {
    return PartialCheckboxState.CHECKED;
  } 
  return PartialCheckboxState.PARTIAL;
})

/**
 * Computed value to track values of selected checkboxes 
 */
const checkedValues = computed(() => {
  return (props.modelValue || []).reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {});
});

/**
 * Used to compute the Count for the partial checkbox header
 */
const headerCount = computed(() => {
  return {
    numSelected: Object.values(checkedValues.value).length,
    total: props.rows.filter(row => row.value).map(row => row.value).length
  }
})

const rowChecked = (value: any, checked: boolean) => {
  if (checked) {
    return emit('update:modelValue', [...new Set([...(props.modelValue || []), value])])
  } else {
    return emit('update:modelValue', [...(props.modelValue || [])].filter(v => v !== value))
  }
}
const headerChecked = (checked: boolean) => {
  if (checked) {
    return emit('update:modelValue', props.rows.filter(row => row.value).map(row => row.value));
  } else {
    return emit('update:modelValue', []);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_title = _resolveComponent("cmc-title")!
  const _component_cmc_checkbox = _resolveComponent("cmc-checkbox")!
  const _component_cmc_text = _resolveComponent("cmc-text")!
  const _component_cmc_icon = _resolveComponent("cmc-icon")!
  const _component_cmc_group = _resolveComponent("cmc-group")!

  return (_openBlock(), _createBlock(CmcList, {
    class: "cmc-list-multi-select",
    layout: _ctx.layout
  }, {
    default: _withCtx(() => [
      _createVNode(CmcListHeader, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
            return (_openBlock(), _createBlock(CmcListCol, {
              key: header.label
            }, {
              default: _withCtx(() => [
                (header.withPartialCheckbox)
                  ? (_openBlock(), _createBlock(CmcPartialCheckbox, {
                      key: 0,
                      checkedState: checkedState.value,
                      label: header.label,
                      "with-label-i18n": header.withI18n,
                      "with-tooltip": header.withTooltip,
                      "with-tooltip-i18n": header.withTooltipI18n,
                      "with-count": header.withCount ? headerCount.value : undefined,
                      reversed: "",
                      "as-title": "",
                      "heading-for-title": "h5",
                      "onUpdate:checkedStatus": _cache[0] || (_cache[0] = ($event: any) => (headerChecked($event)))
                    }, null, 8, ["checkedState", "label", "with-label-i18n", "with-tooltip", "with-tooltip-i18n", "with-count"]))
                  : (_openBlock(), _createBlock(_component_cmc_title, _mergeProps({
                      key: 1,
                      ref_for: true
                    }, header, {
                      title: header.label,
                      "with-tooltip": header.withTooltip,
                      "with-tooltip-i18n": header.withTooltipI18n,
                      "with-tooltip-placement": "right",
                      heading: "h5"
                    }), null, 16, ["title", "with-tooltip", "with-tooltip-i18n"]))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
        return (_openBlock(), _createBlock(CmcListRow, {
          key: row.id,
          value: row.id
        }, {
          default: _withCtx(() => [
            _createVNode(CmcListCol, null, {
              default: _withCtx(() => [
                (row.value)
                  ? (_openBlock(), _createBlock(_component_cmc_checkbox, {
                      key: 0,
                      label: row.label,
                      "with-label-i18n": row.withI18n,
                      "model-value": checkedValues.value[row.value],
                      reversed: "",
                      maxLineDisplay: 1,
                      "checkbox-color": row.color,
                      "onUpdate:modelValue": ($event: any) => (rowChecked(row.value, $event))
                    }, null, 8, ["label", "with-label-i18n", "model-value", "checkbox-color", "onUpdate:modelValue"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_1))
              ]),
              _: 2
            }, 1024),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.columns, (col) => {
              return (_openBlock(), _createBlock(CmcListCol, {
                key: col.text
              }, {
                default: _withCtx(() => [
                  (col.text)
                    ? (_openBlock(), _createBlock(_component_cmc_text, {
                        key: 0,
                        text: col.text,
                        "with-i18n": col.withTextI18n,
                        "as-description": col.asDescription,
                        "without-wrap": ""
                      }, null, 8, ["text", "with-i18n", "as-description"]))
                    : (col.amount || col.amount == 0)
                      ? (_openBlock(), _createBlock(_component_cmc_group, {
                          key: 1,
                          spacing: "2xs",
                          withBaselineAlign: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(CmcAmount, {
                              amount: col.amount,
                              currency: col.currency,
                              locale: col.locale,
                              "with-bold": col.withBold,
                              "show-currency": col.showCurrency,
                              "reserve-currency-space": !col.showCurrency,
                              "max-fraction-digits": 2,
                              "without-wrap": "",
                              "hide-narrow-currency": ""
                            }, null, 8, ["amount", "currency", "locale", "with-bold", "show-currency", "reserve-currency-space"]),
                            _createVNode(_component_cmc_group, {
                              spacing: "3xs",
                              "with-baseline-align": ""
                            }, {
                              default: _withCtx(() => [
                                (col.comparisonIcon)
                                  ? (_openBlock(), _createBlock(_component_cmc_icon, {
                                      key: 0,
                                      icon: col.comparisonIcon,
                                      svg: "",
                                      size: "s"
                                    }, null, 8, ["icon"]))
                                  : _createCommentVNode("", true),
                                (col.comparisonAmount)
                                  ? (_openBlock(), _createBlock(_component_cmc_text, {
                                      key: 1,
                                      text: col.comparisonAmount,
                                      size: "m"
                                    }, null, 8, ["text"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["layout"]))
}
}

})