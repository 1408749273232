<template>
  <cmc-list-row v-if="props.assigned">
    <cmc-list-col>
      <cmc-service-connection
        :name="props.connection.name"
        :type="props.connection.type"
      />
    </cmc-list-col>
    <cmc-list-col>
      <cmc-badge
        with-transparent
        :text="connectionTooltipMessage"
        with-i18n
        :color="connectionTooltipColor"
      />
    </cmc-list-col>
    <cmc-list-col>
      <cmc-state-badge 
        :state="connection.state"
        :override-state="overrideStatus.statusLabel"
        :override-state-color="overrideStatus.statusColor"
        with-transparent
      />
    </cmc-list-col>
    <cmc-list-col>
      <cmc-block 
        v-if="detailsDataPresent"
        padding-top="3xs"
      >
        <cmc-stack
          spacing="none"
        >
          <cmc-block v-if="quotaName !== ''">
            <cmc-pair
              stretch-rhs
              spacing="3xs"
            >
              <cmc-text
                text="quota"
                with-i18n
                as-description
              />
              <cmc-text :text="quotaName" />
            </cmc-pair>
          </cmc-block>
          <cmc-block v-if="hasAssignedOrganization"> 
            <cmc-pair
              stretch-rhs
              spacing="3xs"
            >
              <cmc-text
                text="linked_to_service_organization"
                with-i18n
                as-description
              />
              <cmc-text :text="assignedOrganizationName" />
            </cmc-pair>
          </cmc-block>
          <cmc-block
            v-for="param in params"
            :key="`${param.label}`"
          >
            <cmc-pair
              stretch-rhs
              spacing="3xs"
            >
              <cmc-text
                :text="param.label"
                with-i18n
                as-description
              />
              <cmc-text :text="param.value" />
            </cmc-pair>
          </cmc-block>
        </cmc-stack>
      </cmc-block>
      <cmc-block v-else>
        -
      </cmc-block>
    </cmc-list-col>
    <template #action-rhs>
      <cmc-icon
        v-if="hasEditPermission"
        icon="edit"
        svg
        with-clickable
        @click="handleEditConnection(props.connection.id, props.connection.quotaId || '')"
      />
    </template>
  </cmc-list-row>
</template>
<script setup lang="ts">

import { useRouter } from 'vue-router'
import CmcListRow from '@/components/nextgen/display/list/CmcListRow.vue';
import CmcListCol from '@/components/nextgen/display/list/CmcListCol.vue';
import CmcText from "@/components/nextgen/typography/CmcText.vue";
import CmcStack from "@/components/nextgen/layout/CmcStack.vue";
import CmcBlock from "@/components/nextgen/layout/CmcBlock.vue";
import { computed, defineComponent, onMounted, ref } from 'vue';
import { AssignedServiceConnection, BackendOrganization, OverrideStatus, Color, Parameter, Organization } from './types';
import { upperFirstCharOnly } from '@/utils/label';
import CmcServiceConnection from '@/components/nextgen/display/CmcServiceConnection.vue';
import CmcBadge from '@/components/nextgen/display/CmcBadge.vue';
import CmcStateBadge from '@/components/nextgen/display/CmcStateBadge.vue';
import CmcPair from '@/components/nextgen/layout/CmcPair.vue';
import authz from '@/authz';
import apis from '@/utils/apis';

defineComponent({
  CmcListRow, CmcListCol, CmcServiceConnection, CmcBadge
})

const router = useRouter();

type Props = {
  /**
   * Connection to be rendered 
   */
  connection: AssignedServiceConnection;

  /**
   * Whether the connection is assigned
   */
  assigned: boolean;
  /**
   * Name of the organization
   */
  organization: Organization;
}

const props = defineProps<Props>();
const quotaId = ref<string | undefined>(props.connection.quotaId);
const hasEditPermission = ref(false);

onMounted(async () => {
  await Promise.all([
    setCanEdit()
  ])
});

const reachable = computed<boolean>(() => {
  return (props.connection.status && props.connection.status.reachable);
});

const quotaName = computed<string>(() => {
  return upperFirstCharOnly(props.connection.quotas?.find((q: { id: string | undefined; }) => q.id === quotaId.value)?.name || '');
}) 

const assignedOrganization = computed<BackendOrganization>(() => props.connection.assignedOrganization);

const assignedOrganizationName = computed<string>(() => props.connection.assignedOrganization?.name || '');

const hasAssignedOrganization = computed<boolean>(() => !!assignedOrganization.value);

const overrideStatus = computed<OverrideStatus>(() => props.connection.overrideStatus || {});

const shouldOverrideStatus = computed<boolean>(() => !!(props.connection.overrideStatus?.statusLabel || props.connection.overrideStatus?.statusColor))

const connectionTooltipMessage = computed<string>(() => {
  if (!reachable.value) {
    return 'unreachable';
  }

  if (shouldOverrideStatus.value) {
    return (props.connection.overrideStatus?.statusLabel) || 'connected';
  }
  return 'connected';
})

const connectionTooltipColor = computed<Color>(() => {
  if (!reachable.value) {
    return 'red';
  }
  if (shouldOverrideStatus.value && overrideStatus.value.statusColor) {
    const colorToOverride = overrideStatus.value.statusColor?.toLocaleLowerCase() || 'green';
    if (['green', 'red', 'yellow', 'blue', 'grey'].includes(colorToOverride)) {
      return colorToOverride as Color;
    }
  }
  return 'green';
})

const params = computed<Parameter[]>(() => {
  let paramList = [];
  for(const [, value] of Object.entries(props.connection.assignedParameters || {})) {
    for (const [k, v] of Object.entries(value)) {
      paramList.push({
        label: k,
        value: v
      });
    }
  }
  return paramList;
})

const detailsDataPresent = computed<boolean>(() => {
  return quotaName.value !== '' || assignedOrganizationName.value !== '' || params.value.length > 0;
})

function handleEditConnection(connectionId: string, quotaId: string): void {
  router.push({ name: 'editAssignedConnection',params: { connectionId }, query: { isEdit: 'true', quotaId }});
}

async function setCanEdit() {
  const orgPermissions = await apis.users.getEffectivePermissions(props.organization.id);
  const allPermissions = orgPermissions ? [
    ...(orgPermissions.system || []),
    ...(orgPermissions.subs || []),
    ...(orgPermissions.service || []),
    ...(orgPermissions.global || [])
  ]
    : [];

  hasEditPermission.value = authz.hasPermission('reseller:connections') ||
    allPermissions.some(p => p === 'reseller:connections');
}

</script>
<style scoped>
.cmc-badge.connection-assignment-row-state {
  text-transform: capitalize;
}
</style>