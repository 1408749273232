<template>
  <cmc-block v-if="!plain">
    <router-link
      :to="routeObject"
      :target="withBlankTarget ? '_blank' : '_self'"
      :aria-labelledby="ariaLabelledBy"
      @keypress.enter.self="goTo(routeObject)"
    >
      <slot></slot>
    </router-link>
  </cmc-block>

  <cmc-block v-else>
    <router-link
      v-slot="{ navigate }"
      custom
      :to="routeObject"
    >
      <cmc-block
        :class="[$attrs.class]"
        :aria-labelledby="ariaLabelledBy"
        @keypress.enter.self="goTo(routeObject)"
        @click="navigate"
      >
        <slot></slot>
      </cmc-block>
    </router-link>
  </cmc-block>
</template>
  
<script setup lang="ts">
import { defineComponent, computed } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import { useRoute, useRouter, RouteLocationRaw, RouteLocationPathRaw } from 'vue-router';
import { useAttrs } from 'vue';
import { useStore } from 'vuex'

const router = useRouter();
const route = useRoute();
const attrs = useAttrs();
const store = useStore();

defineComponent({
  CmcGroup,
  CmcText,
  CmcDivider,
  CmcBlock,
})

type Props = {
  /**
   * The path to navigate to, with parameters like name, query, params. It can also be just a path string.
   */
  to: RouteLocationRaw;

  /**
   * True if the link is to be opened in a new tab. Defaults to false.
   */
  withBlankTarget?: boolean;

  /**
   * Whether the slot content is clickable or needs to be wrapped in a div. Defaults to false.
   */
  plain?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  withBlankTarget: false,
  plain: false
});

const selectedOrgCode = computed(() => store.getters.selectedOrganization?.entryPoint);

const workspaceQuery = computed(() => route.query.workspace);

const routeObject = computed(() => {
  return typeof props.to === 'object' ? { ...props.to, query: adaptedQuery.value } : { path: props.to, query: adaptedQuery.value }
});

const adaptedQuery = computed(() => {
  let query = typeof props.to === 'object' ? props.to.query || {} : extractQueryParams(props.to);
  if (shouldAddOrgQueryParam.value && !query.org) {
    query = { ...query, org: selectedOrgCode.value };
  }
  if (workspaceQuery.value && inheritWorkspaceQuery(props.to)) {
    query = { ...query, workspace: workspaceQuery.value };
  }
  return query;
});

const shouldAddOrgQueryParam = computed(() => {
  const selectedOrg = store.getters.selectedOrganization;
  const myOrg = store.getters.myOrganization;
  if (!selectedOrg) {
    return false;
  }
  return !(myOrg && myOrg.id === selectedOrg.id);
});

const inheritWorkspaceQuery = (toRoute: RouteLocationRaw) => {
  if (toService(toRoute) === false) {
    return false;
  }
  const record = router.resolve(toRoute);
  return record.path.startsWith('/services') && !(record.meta || {}).requiresWorkspaceQuery;
};

const ariaLabelledBy = computed(() => attrs['aria-labelledby']);

const extractQueryParams = (path: string) => {
  const urlParts = path.split('?');
  if (urlParts.length >= 2) {
    const urlSearchParams = new URLSearchParams(urlParts[1]);
    const result: Record<string, string> = {};
    urlSearchParams.forEach((value, key) => { result[key] = value; });
    return result;
  }
  return {};
};

const toService = (toRoute: RouteLocationRaw): boolean | null => {
  if (typeof toRoute === 'string') {
    return toRoute.startsWith('/services')
  }

  return (toRoute as RouteLocationPathRaw).path?.startsWith('/services') || null
};

const goTo = (route: RouteLocationRaw) => { router.push(route); };

</script>
<style>
:root {
  --ng-text-main: var(--text-primary);
  --ng-text-link: var(--ng-primary-blue);
}
</style>

<style scoped lang="scss">
@use '@/styles/mixins.scss';
@use '@/styles/variables.scss';

:deep(a:not(.fake-a):not(.link-on-primary)) {
  color: var(--ng-text-main);
}

:deep(a:not(.fake-a):not(.link-on-primary):hover) {
  color: var(--ng-text-link);
}
</style>