import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, Teleport as _Teleport } from "vue"

const _hoisted_1 = { key: 1 }

import { Ref, inject, defineComponent, ref, ComponentPublicInstance, watch } from 'vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import { FooterProps } from './useFooter';


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcFooter',
  setup(__props) {

defineComponent({
  CmcStack,
  CmcBlock,
  CmcDivider,
  CmcIcon,
  CmcPair,
  CmcAlign,
})


const { opened, component, props } = inject('cmc-footer') as { component: Ref<any>, props: Ref<FooterProps>, opened: Ref<boolean> } 
  
const footerRef = ref<ComponentPublicInstance>();
const innerRef = ref<ComponentPublicInstance>();
const canExpand = ref(false);
const expanded = ref(false);

const doSizeCheck = () => {
  if (expanded.value) {
    return;
  }
  if (innerRef.value && footerRef.value) {
    canExpand.value = innerRef.value.$el.clientHeight > footerRef.value.$el.clientHeight;
  } else {
    canExpand.value = false;
  }
}

const observer = new MutationObserver(doSizeCheck);

watch([footerRef], async () => {
  try {
    if (footerRef.value?.$el) {
      doSizeCheck();
      observer.observe(footerRef.value?.$el, { childList: true, subtree: true });
    } else {
      observer.disconnect();
    }
  } catch(e) {
    console.error(e);
  }
})

const toggle = () => {
  if (innerRef.value && footerRef.value) {
    if (expanded.value) {
      footerRef.value.$el.style.maxHeight = props.value.height;
    } else {
      footerRef.value.$el.style.maxHeight = 'none';
    }
    expanded.value = !expanded.value;
  }
}

return (_ctx: any,_cache: any) => {
  return (_unref(opened))
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: _unref(props).target || 'body'
      }, [
        _createVNode(CmcStack, {
          ref_key: "footerRef",
          ref: footerRef,
          class: "cmc-footer",
          spacing: "none",
          style: _normalizeStyle({ 'max-height': _unref(props).height })
        }, {
          default: _withCtx(() => [
            _createVNode(CmcDivider),
            _createVNode(CmcBlock, {
              ref_key: "innerRef",
              ref: innerRef,
              "padding-horizontal": "2xl"
            }, {
              default: _withCtx(() => [
                _createVNode(CmcAlign, { "at-center": "" }, {
                  default: _withCtx(() => [
                    _createVNode(CmcPair, {
                      class: "cmc-pair",
                      "stretch-rhs": ""
                    }, {
                      default: _withCtx(() => [
                        (canExpand.value)
                          ? (_openBlock(), _createBlock(CmcBlock, {
                              key: 0,
                              "padding-top": "s",
                              "padding-right": "xs",
                              onClick: toggle
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcIcon, {
                                  size: "s",
                                  icon: expanded.value ? 'chevron-bold-down' : 'chevron-bold-right',
                                  class: "chevron",
                                  svg: ""
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createElementBlock("div", _hoisted_1)),
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component)), _mergeProps(_unref(props).props, {
                          expanded: expanded.value,
                          onExpand: toggle
                        }), null, 16, ["expanded"]))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        }, 8, ["style"])
      ], 8, ["to"]))
    : _createCommentVNode("", true)
}
}

})