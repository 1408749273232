import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

import { computed, defineComponent } from 'vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcStack from '../layout/CmcStack.vue';

type Props = {
  /**
   * Key-values to display
   */
  keyValues: {[key: string]: string},

  /**
   * True if the key-value pairs should have a narrow width
   */
  withNarrow?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcKeyValue',
  props: {
    keyValues: {},
    withNarrow: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({
  CmcBlock, CmcText, CmcStack, CmcTitle,
})

const props = __props

const keyValueList = computed(() => {
  let keyValues: string[][] = [];
  if (props.keyValues !== undefined && Object.keys(props.keyValues).length > 0) {
    keyValues = Object.keys(props.keyValues)
    .map(key => [key, props.keyValues[key]]);
  }
  return keyValues;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, { padding: "xs" }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        spacing: "xs",
        class: _normalizeClass(['cmc-key-value', {'cmc-key-value-narrow': _ctx.withNarrow}])
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(keyValueList.value, (entry) => {
            return (_openBlock(), _createBlock(CmcStack, {
              key: entry[0],
              spacing: '4xs'
            }, {
              default: _withCtx(() => [
                _createVNode(CmcTitle, {
                  title: entry[0],
                  heading: "h6",
                  "with-i18n": ""
                }, null, 8, ["title"]),
                _createVNode(CmcText, {
                  text: entry[1],
                  size: "m",
                  class: "entry-value"
                }, null, 8, ["text"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}
}

})