<template>
  <cmc-stack class="cmc-list-select">
    <cmc-read-only
      :id="id ? `cmc-list-select-${id}` : undefined"
      :label="label"
      with-label-i18n
      :model-value="getCategoryLabel()"
      :read-only="readOnly"
      :inherit-read-only="inheritReadOnly"
      :hide="(categories && categories.length < 1) || readOnly"
    >
      <cmc-stack
        spacing="none"
      >
        <cmc-label
          v-bind="props"
          as-header
        >
        </cmc-label>
        <cmc-block
          v-if="categories && categories.length > 1"
          padding-top="3xs"
        >
          <cmc-tabs
            :tabs="categories === undefined ? [] : categories"
            :activeTab="selectedCategory"
            @change="changeTab"
          />
        </cmc-block>
        <cmc-block
          padding-top="xl"
        >
          <cmc-list
            :layout="layout"
            with-input="radio"
            :disabled="disabled"
            :model-value="modelValue"
            :paginable="shouldBePaginated"
            :with-number-of-records="actualOptions.length"
            @pageSelected="selectPage"
            @pageSizeSelected="selectPageSize"
            @update:model-value="selectRow"
          >
            <cmc-list-header asHighlight>
              <cmc-list-col
                v-for="header in headers"
                :key="header.label"
              >
                <cmc-title
                  v-bind="header"
                  :title="header.label"
                  :with-tooltip="header.withTooltip"
                  :with-tooltip-i18n="header.withTooltipI18n"
                  with-tooltip-placement="right"
                  heading="h5"
                  with-bold
                ></cmc-title>
              </cmc-list-col>
            </cmc-list-header>
            <cmc-list-row
              v-for="opt in optionsToDisplay"
              :key="opt.value"
              :value="opt.value"
            >
              <cmc-list-col>
                <cmc-text
                  class="cmc-clickable-text"
                  v-bind="opt"
                  :text="opt.label"
                  :with-tooltip-placement="opt.withTooltipKeyValue? 'right' : 'auto'"
                  @click="selectRow(opt.value)"
                >
                  <template #lhs>
                    <cmc-icon
                      v-if="opt.imgUrl && layout[0].asColType === 'text-with-icon'"
                      :icon="opt.imgUrl"
                      size="l"
                      img
                    ></cmc-icon>
                  </template>
                  <template
                    v-if="opt.withTooltipKeyValue && Object.keys(opt.withTooltipKeyValue).length > 0"
                    #tooltip
                  >
                    <div>
                      <cmc-key-value
                        :key-values="opt.withTooltipKeyValue"
                        with-narrow
                      >
                      </cmc-key-value>
                    </div>
                  </template>
                </cmc-text>
              </cmc-list-col>
              <cmc-list-col
                v-for="(conf, idx) in opt.configs"
                :key="conf.label"
                :as-list-select-item-without-label="conf.options.length > 1 && isSelectWithoutLabel(conf.label)"
              >
                <cmc-block
                  :padding-horizontal="conf.options.length > 1 ? 'l' : 's'"
                  :padding-right="conf.options.length > 1 ? 'none' : 's'"
                  :padding-left="layout[idx + 1].asColType !== 'number' ? 'none' : undefined"
                  padding-vertical="none"
                >
                  <cmc-align
                    v-if="conf.options.length > 1"
                    :at-horizontal-center="layout[idx + 1].asColType === 'number'"
                  >
                    <div>
                      <cmc-select
                        v-bind="conf"
                        v-model="allConfigs[opt.value][conf.key]"
                        :allow-empty="false"
                        :as-number="layout[idx + 1].asColType === 'number'"
                        :inherit-read-only="false"
                        :disabled="disabled || opt.value !== modelValue"
                        @update:modelValue="updateSelectValue"
                      />
                    </div>
                  </cmc-align>
                  <cmc-text
                    v-else
                    v-bind="conf.options[0]"
                    :text="conf.options[0].label"
                    :with-i18n="conf.options[0].withLabelI18n"
                  ></cmc-text>
                </cmc-block>
              </cmc-list-col>
            </cmc-list-row>
          </cmc-list>
        </cmc-block>
      </cmc-stack>
    </cmc-read-only>
    <cmc-read-only
      v-for="roc in readOnlyConfig"
      :key="roc.label"
      :label="roc.label"
      :with-label-i18n="roc.withLabelI18n"
      :model-value="roc.value"
      :read-only="readOnly"
      :inherit-read-only="inheritReadOnly"
    ></cmc-read-only>
  </cmc-stack>
</template>

<script setup lang="ts">
import { computed, defineComponent, reactive, watch, ref } from 'vue';
import type { Ref } from 'vue'
import CmcList from '../display/list/CmcList.vue';
import CmcListHeader from '../display/list/CmcListHeader.vue';
import CmcListRow from '../display/list/CmcListRow.vue';
import CmcKeyValue from '../display/CmcKeyValue.vue';
import CmcListCol from '../display/list/CmcListCol.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';

import CmcTabs from '../navigation/CmcTabs.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcSelect, { SelectProps } from '../inputs/CmcSelect.vue';
import CmcPagination from '../pagination/CmcPagination.vue'
import { useI18n } from 'vue-i18n';
import { SingleSelectOption } from './types';
import { ColLayout } from '../display/list/types';
import { Tab } from '../navigation/types';

defineComponent({
  CmcList,
  CmcListCol,
  CmcListHeader,
  CmcTabs,
  CmcSelect,
  CmcBlock,
  CmcText,
  CmcTitle,
  CmcLabel,
  CmcStack,
  CmcReadOnly,
  CmcIcon,
  CmcKeyValue,
  CmcPagination
})

export type ListSelectConfig = {
  key: string;
} & SelectProps

export type ListSelectHeader = {
  /**
   * Label on top of the text area
   */
  label: string;

  /**
   * Is the label i18n.
   */
  withI18n?: boolean;

  /**
   * Tooltip in the header,
   */
  withTooltip?: string;

  /**
   * Is tooltip i18n.
   */
  withTooltipI18n?: boolean;
}

export type ListSelectOption = {
  /**
   * Value of the row.
   */
  value: string;

  /**
   * Label of the select option.
   */
  label: string;

  /**
   * Label is i18n or not.
   */
  withI18n?: boolean;

  /**
   * Tooltip content
   */
  withTooltip?: string;

  /**
   * Tooltip content
   * Keys are i18n
   */
  withTooltipKeyValue?: {[key: string]: string}

  /**
   * True if tooltip label is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * True if the tooltip content is html
   */
  withTooltipHtml?: boolean;

  /**
   * Image icon to show next to the option.
   */
  imgUrl?: string;

  /**
   * Category of the option.
   */
  category?: string;

  /**
   * Specific configuration for the option.
   */
  configs: ListSelectConfig[]
}

type Props = {

  id?: string;

  /**
   * Add a label on top of the select
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Model value of the list select. This is the value of the select row
   */
  modelValue: any;

  /**
   * Config selected for the current row.
   */
  config: {[key: string]: any };

  /**
   * Selected category
   */
  category?: string;

  /**
   * Categories available as tabs
   */
  categories?: Tab[];

  /**
   * Headers of the list.
   */
  headers: ListSelectHeader[];

  /**
   * Options of the list.
   */
  options: ListSelectOption[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];

  /**
   * Disable the list select.
   */
  disabled?: boolean;

  /**
   * Set the list select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  inheritReadOnly: true,
})


const selectedPage: Ref<number> = ref(1);
const pageSize: Ref<number> = ref(5);

  const getCategoryLabel = (): string | number | undefined => {
  if (props.categories?.length === 1) {
    return '';
  }
  return props.categories?.find(c => c.key === props.category)?.label || '';
};

const selectedCategory = computed<string | undefined>({
  get: () => {
    return props.categories?.length === 1 ? props.categories[0].key : props.category;
  },
  set: (newCategory) => {
    emit('update:category', newCategory);
  },
});

const actualOptions = computed(() => {
  return props.options.filter(opt => {
    return opt.category === selectedCategory.value
  });
});

const shouldBePaginated = computed(() => {
  return actualOptions.value.length > 5;
})

const optionsToDisplay = computed(() => {
  return shouldBePaginated.value ? actualOptions.value.slice((selectedPage.value - 1 ) * pageSize.value, Math.min(actualOptions.value.length, selectedPage.value * pageSize.value)) :  actualOptions.value;
});

let allConfigs: { [key: string]: any } = reactive(buildAllConfig());

function buildAllConfig() {
  const configMap = props.options.reduce((acc, cur) => {
    acc[cur.value] = {};
    return acc;
  }, {} as { [key: string]: any });
  if (props.modelValue) {
    configMap[props.modelValue] = { ...configMap[props.modelValue], ...(props.config || {}) };
  }
  return configMap;
}
const emit = defineEmits<{
  /**
   * Emitted when element is selected
   */
  (event: 'update:modelValue', value: any): void,

  /**
   * Emitted when config option is selected
   */
  (event: 'update:config', value: any): void

  /**
   * Emitted when config option is selected
   */
  (event: 'update:category', category: string | undefined): void

}>()

const selectRow = (v: string) => {
  let config;

  if (Object.entries(allConfigs[v]).length > 0) {
    config = allConfigs[v];
  } else {
    const configs = props.options.find(opt => opt.value === v)?.configs;
    config = configs?.reduce((acc, cur) => {
      acc[cur.key] = (cur.options[0] as SingleSelectOption).value;
      return acc;
    }, {} as {[key: string]: any});
  }
  emit('update:category', selectedCategory.value);
  emit('update:config', config);
  emit('update:modelValue', v);
};

const isSelectWithoutLabel = (selectLabel: string | undefined) => {
  return selectLabel == null ||  selectLabel == undefined ||  selectLabel == '';
}

const selectPage = (v: string) => {
  selectedPage.value = parseInt(v)
}

const selectPageSize = (v: string) => {
  pageSize.value = parseInt(v)
}

const changeTab = (v: string) => {
  selectedCategory.value = v;
}

const updateSelectValue = () => {
  if (allConfigs[props.modelValue]) {
    emit('update:config', allConfigs[props.modelValue]);
  }
};

// reassign allConfig when props options change on section reload
watch(() => props.options, () => {
  selectedCategory.value = props.category;
  const curModelVal = props.options.find(opt => opt.value === props.modelValue)
  if (!curModelVal) {
    selectRow(props.options[0]?.value);
  } else {
    reassignAllConfig();
  }
})

/**
 * If the props.options change it is possible that a selected value in the allConfigs is no longer a valid selectable option
 * In this case, we should reassign the allConfig value to the first option in the list
 */
function reassignAllConfig() {
  props.options.forEach(option => {
    option.configs.forEach((config) => {
      var currentOption;
      if (option.value === props.modelValue) {
        currentOption = config.options.find(opt => (opt as SingleSelectOption).value == props.config[config.key]);
      } else {
        currentOption = config.options.find(opt => (opt as SingleSelectOption).value == allConfigs[option.value][config.key]);
      }
      if (currentOption) {
        // sometimes there is a type mismatch between the value from the backend + frontend (1 vs '1.0')
        // which causes the select to appear empty (not recognize the selected value as one of the options)
        allConfigs[option.value][config.key] = (currentOption as SingleSelectOption).value;
      } else {
        // if the current selected value doesnt exist in the list of options anymore (due to reload)
        // then fall back to the first option in the list
        allConfigs[option.value][config.key] = (config.options[0] as SingleSelectOption).value;
      }
    })
  })
}

// this is for when confirm on change happens and the model value is set to null
watch(() => props.modelValue, () => {
  if (!props.modelValue) {
    allConfigs = buildAllConfig()
  }
});

const { t } = useI18n()

const readOnlyValue = computed(() => {
  const opt = props.options.find(opt => opt.value === props.modelValue);
  if (opt) {
    return opt.withI18n ? t(opt.label) : opt.label;
  }
  return '';
});

const readOnlyConfig = computed(() => {
  const config = [];
  if (props.categories && props.categories.length >= 1) {
    config.push({
        label: props.headers[0].label,
        withLabelI18n: true,
        value: readOnlyValue.value,
      });
  }
  const opt = props.options.find(opt => opt.value === props.modelValue);
  if (opt && props.config) {
    config.push(...opt.configs.map((c, idx) => ({
      label: props.headers[idx + 1].label,
      withLabelI18n: props.headers[idx + 1].withI18n,
      value: c.options.find((option: any) => option.value == props.config[c.key])?.label,
    })));
  }
  return config;
})
</script>

<style scoped lang="scss">
:deep(.cmc-align > div) {
  width: 100%;
}
:deep(.cmc-list-col-number .cmc-align > div) {
  width: auto;
}
:deep(.cmc-list-col-number .cmc-select) {
  min-width: 8rem;
}
:deep(.cmc-select) {
  max-width: 15rem;
}
:deep(.cmc-list-col .cmc-text.cmc-clickable-text) {
  cursor: pointer;
}
</style>
