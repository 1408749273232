<template>
  <cmc-block
    :class="['spinner-wrapper',
             { 'spinner-wrapper-dimmed': withDimmed},
             { 'spinner-wrapper-blur': withBlur},
             { 'spinner-wrapper-bg': (!withBlur && !withDimmed)}]"
    :padding-top="paddingTop"
  >
    <cmc-stack take-full-height>
      <cmc-align at-horizontal-center>
        <cmc-icon
          icon="progress"
          svg
          size="3xl"
          color="blue"
          spin
        >
        </cmc-icon>
      </cmc-align>
    </cmc-stack>
  </cmc-block>
</template>
<script setup lang="ts">
import CmcIcon from '@/components/nextgen/misc/CmcIcon.vue';
import { defineComponent } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import { Size } from '../sizes';
defineComponent([
    CmcIcon,
    CmcStack,
    CmcAlign,
    CmcBlock,
])

interface Props {
    /**
     * Specifies if the loader dimms the background
     */
    withDimmed?: boolean;

    /**
     * Specifies if the loader blurs the background
     */
    withBlur?: boolean;

    /**
     * The padding to set at the top of the div, default is 3xl
     */
    paddingTop?: Size;
}

withDefaults(defineProps<Props>(), {
  withDimmed: false,
  withBlur: false,
  paddingTop : '3xl',
})


</script>
<style lang="scss">
:root {
  --ng-loading-bg: var(--ng-primary-surface);
}
</style>

<style scoped lang="scss"> 
@use '@/styles/mixins.scss';

.spinner-wrapper {
  position: absolute;
  left:0;
  top:0;
  z-index: 10000;
  height: 100%;
  width: 100%;
  &.spinner-wrapper-blur {
    backdrop-filter: blur(10px);
  }
  &.spinner-wrapper-dimmed {
    background: rgba(217,217,217,.25);
  }
  &.spinner-wrapper-bg{
    background-color: var(--ng-loading-bg);
  }
}
</style>