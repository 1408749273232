<template>
  <div
    v-if="activeLanguages.length > 1"
    :class="[
      'cmc-language-picker-wrapper',
      {'cmc-language-picker-wrapper-as-long': asLong}
    ]"
  >
    <cmc-select
      :options="activeLanguages"
      :model-value="locale"
      :allow-empty="false"
      @update:modelValue="onUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import CmcSelect from "@/components/nextgen/inputs/CmcSelect.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { switchLanguage } from "@/i18n";
import { SingleSelectOption } from "./types";

type Props = {
  /**
   * If we want the language names to be displayed in long version.
   * Eg: English instead of EN
   */
  asLong?: boolean
}

const props = defineProps<Props>();
const emit = defineEmits(['switchLanguage']);

const store            = useStore();
const userBrandingInfo = computed(() => store.getters.userBrandingInfo);
const locale           = computed(() => store.getters.locale);
const loggedIn         = computed(() => store.getters.loggedIn);

const activeLanguages = computed<SingleSelectOption[]>(() => {
  return userBrandingInfo.value.activeLanguages.map((l: Record<string, any>) => ({
    value: l,
    label: props.asLong ? `languages.${l}.long` : `languages.${l}.short`,
    withLabelI18n: true
  }));
});

const onUpdate = (lang: string) => {
  emit('switchLanguage', lang);
  switchLanguage(lang);
  if (!loggedIn.value) {
    localStorage.setItem('localeBeforeLogin', lang);
  }
}
</script>

<style scoped lang="scss">
  .cmc-language-picker-wrapper {
    position: relative;
    width: 4.2rem;

    &.cmc-language-picker-wrapper-as-long {
      width: auto;
    }
  }
</style>