<template>
  <inline-svg 
    :src="svgSrc" 
    :aria-label="props.icon"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import InlineSvg from 'vue-inline-svg';
const props = defineProps<{
  icon: string,
}>()

const svgSrc = computed(() => {
  try {
    return require(`@/../public/static/icons/${props.icon}.svg`)
  } catch(_err) {
    return require(`@/../public/static/img/${props.icon}.svg`)
  }
})
</script>