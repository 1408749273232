import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, renderSlot as _renderSlot } from "vue"

import useReadOnly from './useReadOnly';
import CmcStack from '../layout/CmcStack.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

type Props = {
  /**
   * HTML element id
   */
  id?: string;
  /**
   * Label of the read-only field
   */
  label?: string;
  /**
   * True if the label is an i18n label
   */
  withLabelI18n?: boolean;
  /**
   * Value to display as read-only
   */
  modelValue?: string | number;
  /**
   * Show as read-only. (Forces read-only if the provide/inject is false)
   */
  readOnly?: boolean;
  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean
  /**
   * True if sensitive and should show an eye toggle.
   */
  withSensitive?: boolean;

  /**
   * True if you want the field to be copyable. Defaults to true.
   */
  withCopyable?: boolean;

    /**
   * True if you want the field to be copyable. Defaults to true.
   */
   withCopyableOnClick?: boolean;

   /**
   * Will show an error text under the input.
   */
  withErrorText?: string;
  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  takeFullWidth?: boolean;

  /**
   * True if you want to hide the read-only component
   */
  hide?: boolean;
  /**
   * maximum characters to display as read-only
   */
   maxLineDisplay?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcReadOnly',
  props: {
    id: {},
    label: {},
    withLabelI18n: { type: Boolean },
    modelValue: {},
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean },
    withSensitive: { type: Boolean },
    withCopyable: { type: Boolean, default: true },
    withCopyableOnClick: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    takeFullWidth: { type: Boolean, default: true },
    hide: { type: Boolean },
    maxLineDisplay: { default: 2 }
  },
  setup(__props: any) {

defineComponent({
  CmcText,
  CmcTitle,
  CmcStack,
  CmcBlock,
})

const props = __props;

const actualReadOnly = useReadOnly(props);

const { t } = useI18n()
const actualValue = computed(() => {
  if (props.modelValue == null || props.modelValue === 'undefined') {
    return t('none');
  }
  return props.modelValue + '';
})

const shouldRenderReadOnly = computed(() => {
  return (
    actualReadOnly.value &&
    (props.label?.trim() || props.modelValue?.toString().trim())
  );
});

return (_ctx: any,_cache: any) => {
  return (shouldRenderReadOnly.value)
    ? (_openBlock(), _createBlock(CmcStack, {
        key: 0,
        id: _ctx.id ? `cmc-read-only-${_ctx.id}` : undefined,
        spacing: "3xs",
        class: "cmc-read-only",
        "data-cmc-props": JSON.stringify({ ...props })
      }, {
        default: _withCtx(() => [
          (!_ctx.hide)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.label)
                  ? (_openBlock(), _createBlock(CmcTitle, {
                      key: 0,
                      title: _ctx.label,
                      "with-i18n": _ctx.withLabelI18n,
                      heading: "h5"
                    }, null, 8, ["title", "with-i18n"]))
                  : _createCommentVNode("", true),
                _createVNode(CmcBlock, {
                  "as-inline": !_ctx.takeFullWidth
                }, {
                  default: _withCtx(() => [
                    _createVNode(CmcStack, { spacing: "3xs" }, {
                      default: _withCtx(() => [
                        _createVNode(CmcText, {
                          text: actualValue.value,
                          size: "m",
                          "max-line-display": _ctx.maxLineDisplay,
                          "with-sensitive": _ctx.withSensitive,
                          "with-copyable": _ctx.withCopyable,
                          "with-copyable-on-click": _ctx.withCopyableOnClick
                        }, null, 8, ["text", "max-line-display", "with-sensitive", "with-copyable", "with-copyable-on-click"]),
                        (_ctx.withErrorText)
                          ? (_openBlock(), _createBlock(CmcText, {
                              key: 0,
                              text: _ctx.withErrorText,
                              "with-i18n": _ctx.withErrorTextI18n,
                              "as-error": "",
                              size: "s"
                            }, null, 8, ["text", "with-i18n"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["as-inline"])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["id", "data-cmc-props"]))
    : (!_unref(actualReadOnly))
      ? _renderSlot(_ctx.$slots, "default", {
          key: 1,
          id: _ctx.id
        })
      : _createCommentVNode("", true)
}
}

})