import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

import { provide, defineComponent, ref, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcText from '../typography/CmcText.vue';
import CmcRadio from './CmcRadio.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from '../inputs/CmcReadOnly.vue';

type Option = {
  /**
   * HTML element id for the checkbox
   */
  id?: string;
  /**
   * Value of the option.
   */
  value: any;
  /**
   * Label of the option.
   */
  label: string;
  /**
   * True if the label of the option is i18n.
   */
  withI18n?: boolean;

  /**
   * Should the radio be disabled.
   */
  disabled?: boolean;
}

export type CmcRadioGroupProps = {
  id?: string;
  modelValue: any;

  options?: Option[];

  /**
  * Label to display on the left-hand side of the radio.
  */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the checkbox.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Should be display in read-only mode.
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Should be display in read-only mode
   */
  readOnly?: boolean;

  /**
   * The radio group is implemented as a custom UI.
   */
  asCustom?: boolean;

  /**
   * Should the radio be disabled.
   */
  disabled?: boolean;

}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcRadioGroup',
  props: {
    id: {},
    modelValue: {},
    options: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    readOnly: { type: Boolean },
    asCustom: { type: Boolean },
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcLabel,
  CmcText,
  CmcStack,
  CmcRadio,
  CmcReadOnly,
})

const emit = __emit

const props = __props
const initialized = ref(false);
provide('cmc-radio-group-init',  (v: any) => {
  if (!initialized.value) {
    emit('update:modelValue', v)
  }
  initialized.value = true;
})
provide('cmc-radio-group-set-value', (v: any) => {
  emit('update:modelValue', v);
})
provide('cmc-radio-group-props', props)
provide('cmc-radio-group-name', `cmc-radio-group-name-${uuidv4}`)

const selectedOpt = computed(() => {
  const selected = props.options?.find(o => o.value === props.modelValue)
  if (selected) {
    return selected;
  }
  return undefined;
})


return (_ctx: any,_cache: any) => {
  return (!_ctx.asCustom)
    ? (_openBlock(), _createBlock(CmcReadOnly, {
        key: 0,
        id: _ctx.id ? `cmc-radio-${_ctx.id}` : undefined,
        label: _ctx.label,
        modelValue: selectedOpt.value?.label,
        "with-label-i18n": selectedOpt.value?.withI18n,
        "read-only": _ctx.readOnly,
        "with-error-text": _ctx.withErrorText,
        "with-error-text-i18n": _ctx.withErrorTextI18n
      }, {
        default: _withCtx(() => [
          _createVNode(CmcStack, {
            ref: "radioGroup",
            class: "cmc-radio-group",
            spacing: "2xs"
          }, {
            default: _withCtx(() => [
              _createVNode(CmcStack, { spacing: "none" }, {
                default: _withCtx(() => [
                  _createVNode(CmcLabel, _mergeProps(props, { "as-header": "" }), null, 16),
                  (_ctx.withErrorText)
                    ? (_openBlock(), _createBlock(CmcText, {
                        key: 0,
                        text: _ctx.withErrorText,
                        "with-i18n": _ctx.withErrorTextI18n,
                        "as-error": "",
                        size: "m"
                      }, null, 8, ["text", "with-i18n"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.options || []), (opt) => {
                return (_openBlock(), _createBlock(CmcRadio, _mergeProps({
                  key: opt.label,
                  ref_for: true
                }, { ...opt, value: opt.value, withLabelI18n: opt.withI18n, disabled: _ctx.disabled || opt.disabled }), null, 16))
              }), 128))
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }, 8, ["id", "label", "modelValue", "with-label-i18n", "read-only", "with-error-text", "with-error-text-i18n"]))
    : _renderSlot(_ctx.$slots, "default", {
        key: 1,
        ref: "radioGroup"
      })
}
}

})