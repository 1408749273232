import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "color-card" }

import { ref, defineComponent } from 'vue';
import CmcStack from "@/components/nextgen/layout/CmcStack.vue";
import CmcText from "@/components/nextgen/typography/CmcText";
import CmcTextInput from "@/components/nextgen/inputs/CmcTextInput";

export type ColorCardProps = {
  /**
   * Title.  Displayed above color pad.
   */
  title?: string;

  /**
   * Value of the color as a CSS hex string, eg. #FEDCAB
   */
  color?: string;

  /**
   * Whether the color can be edited.
   */
  readonly?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ColorCard',
  props: {
    title: { default: '' },
    color: { default: '' },
    readonly: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcStack,
  CmcText,
  CmcTextInput,
})

const props = __props;

const editColor = ref(props.color);

const updateValue = (v: string) => {
  let newValue = v;
  if (!newValue.startsWith('#')) {
    newValue = '#' + newValue;
  }
  editColor.value = newValue
  emit('update:modelValue', newValue.toUpperCase());
}

const emit = __emit


return (_ctx: any,_cache: any) => {
  const _component_cmc_block = _resolveComponent("cmc-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CmcStack, { spacing: "none" }, {
      default: _withCtx(() => [
        _createVNode(_unref(CmcText), {
          text: _ctx.title,
          size: "m"
        }, null, 8, ["text"]),
        _createElementVNode("div", {
          class: "color",
          style: _normalizeStyle({ 'background-color': editColor.value })
        }, null, 4),
        (!_ctx.readonly)
          ? (_openBlock(), _createBlock(_unref(CmcTextInput), {
              key: 0,
              "model-value": editColor.value,
              "onUpdate:modelValue": updateValue
            }, null, 8, ["model-value"]))
          : (_openBlock(), _createBlock(_component_cmc_block, {
              key: 1,
              paddingTop: "s"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(CmcText), {
                  text: editColor.value,
                  size: "m"
                }, null, 8, ["text"])
              ]),
              _: 1
            }))
      ]),
      _: 1
    })
  ]))
}
}

})