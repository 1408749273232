<template>
  <div class="l1-tabs">
    <div 
      v-for="(options,$index) in groups"
      :key="$index" 
      class="option-tabs" 
    >
      <div 
        v-for="option in options" 
        :key="option.value"
        class="option-tab" 
      >
        <div 
          v-if="option.separator"
          class="separator" 
        ></div>
        <div 
          v-else
          class="choice toggle-button clickable" 
          tabindex="0" 
          role="option" 
          :aria-selected="selected" 
          :class="{active: isSelected(option), inactive:!isSelected(option), slotted:option.slotted}" 
          @keyup.enter="clickedOption(option)" 
          @click="clickedOption(option)" 
        >
          <div v-if="!option.slotted">
            <div 
              v-if="option.icon"
              class="icon" 
            >
              <base-icon 
                :icon="`fa ${option.icon}`"
              />
            </div>
            <div 
              v-if="option.label"
              class="text" 
            >
              {{ $t(option.label) }}
            </div>
            <div 
              v-if="option.raw"
              class="text" 
            >
              {{ option.raw }}
            </div>
          </div>
          <slot 
            v-else
            :option="option" 
          ></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OptionTabs',
  props: {
    groups: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  emits: ['selected'],
  methods: {
    isSelected(option) {
      return option.value === this.selected;
    },
    clickedOption(option) {
      this.$emit('selected', option.value);
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/styles/mixins.scss';

.l1-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.option-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.option-tabs:before {
  content: "";
  border-right: 1px solid #d0d0d0;
  padding-left: 5px;
  margin-right: 5px;
  align-self: center;
  height: 80%;
}


.option-tabs:first-child:before {
  display: none;
}

@include mixins.phone {
  .option-tabs:before {
    display: none;
  }

  .l1-tabs {
    justify-content: space-evenly;
  }

  .option-tabs {
    flex-wrap: nowrap;
  }
}

.toggle-button {
  border: 0;
}

.separator {
  align-self: center;
  height: 40px;
  border-right: 1px solid #d0d0d0;
  margin-left: 5px;
  margin-right: 5px;
}


.choice {
  min-width: 60px;
  padding: 10px;
  margin: 5px;
}

.choice.inactive:hover {
  color: #444 !important;
}

.slotted {
  width: 90px;
}

.choice.inactive {
  color: #6B6B6B;
}

.choice.inactive:hover {
  color: inherit;
}

.choice.active {
  border-radius: 5px;
}

.icon {
  font-size: 20px;
}

.text {
  margin-top: 2px;
  font-size: 12px;
}
</style>
