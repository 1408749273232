import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { Size } from '../sizes';

export type StackProps = {
  /**
   * HTML element id.
   */
  id?: string,

  /**
   * Spacing between each stack element.
   */
  spacing?: Size,

  /**
   * Take full height of the parent container.
   */
  takeFullHeight?: boolean,


  /**
   * Reverse the ordering of child elements
   */
   reversed?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcStack',
  props: {
    id: {},
    spacing: { default: 'm' },
    takeFullHeight: { type: Boolean },
    reversed: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass([
      'cmc-stack',
      `cmc-stack-spacing-${_ctx.spacing}`,
      {
        'cmc-stack-take-full-height': _ctx.takeFullHeight,
        'cmc-stack-reversed': _ctx.reversed,
      }
    ])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})